import { CHEMISTRY_TAB } from '../../marketplace-custom-config';
import {
  getEmailForInvitation,
  removeCoachFromCoachList,
  inviteCoacheeToEngagement,
  reviewCoach,
  pickCoach,
  scheduleChemistry,
  getEngagement,
} from '../../util/api';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { localStorageSync } from '../../util/localStorage';
import { types as sdkTypes } from '../../util/sdkLoader';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/CoachListPage/SET_INITIAL_STATE';

export const FETCH_ENGAGEMENT_LISTING_REQUEST =
  'app/CoachListPage/FETCH_ENGAGEMENT_LISTING_REQUEST';
export const FETCH_ENGAGEMENT_LISTING_SUCCESS =
  'app/CoachListPage/FETCH_ENGAGEMENT_LISTING_SUCCESS';
export const FETCH_ENGAGEMENT_LISTING_ERROR = 'app/CoachListPage/FETCH_ENGAGEMENT_LISTING_ERROR';

export const FETCH_COACH_LISTINGS_REQUEST = 'app/CoachListPage/FETCH_COACH_LISTINGS_REQUEST';
export const FETCH_COACH_LISTINGS_SUCCESS = 'app/CoachListPage/FETCH_COACH_LISTINGS_SUCCESS';
export const FETCH_COACH_LISTINGS_ERROR = 'app/CoachListPage/FETCH_COACH_LISTINGS_ERROR';

export const REMOVE_COACH_LISTING_REQUEST = 'app/CoachListPage/REMOVE_COACH_LISTING_REQUEST';
export const REMOVE_COACH_LISTING_SUCCESS = 'app/CoachListPage/REMOVE_COACH_LISTING_SUCCESS';
export const REMOVE_COACH_LISTING_ERROR = 'app/CoachListPage/REMOVE_COACH_LISTING_ERROR';

export const FETCH_BUYER_EMAIL_REQUEST = 'app/CoachListPage/FETCH_BUYER_EMAIL_REQUEST';
export const FETCH_BUYER_EMAIL_SUCCESS = 'app/CoachListPage/FETCH_BUYER_EMAIL_SUCCESS';
export const FETCH_BUYER_EMAIL_ERROR = 'app/CoachListPage/FETCH_BUYER_EMAIL_ERROR';

export const FETCH_COACH_EMAIL_REQUEST = 'app/CoachListPage/FETCH_COACH_EMAIL_REQUEST';
export const FETCH_COACH_EMAIL_SUCCESS = 'app/CoachListPage/FETCH_COACH_EMAIL_SUCCESS';
export const FETCH_COACH_EMAIL_ERROR = 'app/CoachListPage/FETCH_COACH_EMAIL_ERROR';

export const ADD_EMAIL_REQUEST = 'app/CoachListPage/ADD_EMAIL_REQUEST';
export const ADD_EMAIL_SUCCESS = 'app/CoachListPage/ADD_EMAIL_SUCCESS';
export const ADD_EMAIL_ERROR = 'app/CoachListPage/ADD_EMAIL_ERROR';

export const INVITE_COACHEE_REQUEST = 'app/CoachListPage/INVITE_COACHEE_REQUEST';
export const INVITE_COACHEE_SUCCESS = 'app/CoachListPage/INVITE_COACHEE_SUCCESS';
export const INVITE_COACHEE_ERROR = 'app/CoachListPage/INVITE_COACHEE_ERROR';

export const COACHEE_REVIEW_REQUEST = 'app/CoachListPage/COACHEE_REVIEW_REQUEST';
export const COACHEE_REVIEW_SUCCESS = 'app/CoachListPage/COACHEE_REVIEW_SUCCESS';
export const COACHEE_REVIEW_ERROR = 'app/CoachListPage/COACHEE_REVIEW_ERROR';

export const BUYER_REVIEW_REQUEST = 'app/CoachListPage/BUYER_REVIEW_REQUEST';
export const BUYER_REVIEW_SUCCESS = 'app/CoachListPage/BUYER_REVIEW_SUCCESS';
export const BUYER_REVIEW_ERROR = 'app/CoachListPage/BUYER_REVIEW_ERROR';

export const SELECT_COACH_REQUEST = 'app/CoachListPage/SELECT_COACH_REQUEST';
export const SELECT_COACH_SUCCESS = 'app/CoachListPage/SELECT_COACH_SUCCESS';
export const SELECT_COACH_ERROR = 'app/CoachListPage/SELECT_COACH_ERROR';

export const SET_COACHEE_ACCESS = 'app/CoachListPage/SET_COACHEE_ACCESS';

export const FETCH_COACHEE_SUCCESS = 'app/CoachListPage/FETCH_COACHEE_SUCCESS';

export const BUYER_CONFIRMS_COACH_REQUEST = 'app/CoachListPage/BUYER_CONFIRMS_COACH_REQUEST';
export const BUYER_CONFIRMS_COACH_SUCCESS = 'app/CoachListPage/BUYER_CONFIRMS_COACH_SUCCESS';
export const BUYER_CONFIRMS_COACH_ERROR = 'app/CoachListPage/BUYER_CONFIRMS_COACH_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchEngagementListingError: null,
  fetchEngagementListingInProgress: false,
  engagementListing: null,
  fetchCoachListingsError: null,
  fetchCoachListingsInProgress: false,
  coachListings: [],
  removeCoachListingInProgress: false,
  removeCoachListingId: null,
  removeCoachListingError: null,
  buyerEmail: null,
  fetchBuyerEmailRequest: false,
  fetchBuyerEmailError: null,
  coachEmail: null,
  fetchCoachEmailRequest: false,
  fetchCoachEmailError: null,
  inviteCoacheeInProgress: false,
  inviteCoacheeError: null,
  scheduleChemistryRequest: false,
  scheduleChemistryRequestError: null,
  chemistryList: [],
  coacheeReviewInProgress: false,
  coacheeReviewError: null,
  buyerReviewInProgress: false,
  buyerReviewError: null,
  selectCoachInProgress: false,
  selectCoachError: null,
  coacheeInfo: null,
  buyerConfirmsCoachInProgress: false,
  buyerConfirmsCoachError: null,
  respondedTxs: [],
};

export default function coachListPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case SET_INITIAL_STATE:
      return initialState;

    case FETCH_ENGAGEMENT_LISTING_REQUEST:
      return {
        ...state,
        fetchEngagementListingInProgress: true,
        fetchEngagementListingError: null,
      };
    case FETCH_ENGAGEMENT_LISTING_SUCCESS:
      const { engagementListing, respondedTxs } = payload;
      const { chemistryList = [], coacheeId } = engagementListing.attributes.metadata;

      return {
        ...state,
        engagementListing,
        respondedTxs,
        fetchEngagementListingInProgress: false,
        chemistryList,
      };
    case FETCH_ENGAGEMENT_LISTING_ERROR:
      console.log(payload)
      return {
        ...state,
        fetchEngagementListingInProgress: false,
        fetchEngagementListingError: payload,
      };

    case FETCH_COACH_LISTINGS_REQUEST:
      return {
        ...state,
        fetchCoachListingsInProgress: true,
        fetchCoachListingsError: null,
      };
    case FETCH_COACH_LISTINGS_SUCCESS:
      return {
        ...state,
        fetchCoachListingsInProgress: false,
        coachListings: payload,
      };
    case FETCH_COACH_LISTINGS_ERROR:
      return {
        ...state,
        fetchCoachListingsInProgress: false,
        fetchCoachListingsError: payload,
      };

    case REMOVE_COACH_LISTING_REQUEST:
      return {
        ...state,
        removeCoachListingInProgress: true,
        removeCoachListingId: payload,
        removeCoachListingError: null,
      };
    case REMOVE_COACH_LISTING_SUCCESS:
      return {
        ...state,
        removeCoachListingInProgress: false,
        engagementListing: payload,
        removeCoachListingId: null,
      };
    case REMOVE_COACH_LISTING_ERROR:
      return {
        ...state,
        removeCoachListingInProgress: false,
        removeCoachListingError: payload,
        removeCoachListingId: null,
      };

    case FETCH_BUYER_EMAIL_REQUEST:
      return {
        ...state,
        fetchBuyerEmailRequest: true,
        fetchBuyerEmailError: null,
      };
    case FETCH_BUYER_EMAIL_SUCCESS:
      return {
        ...state,
        fetchBuyerEmailRequest: false,
        buyerEmail: payload,
      };
    case FETCH_BUYER_EMAIL_ERROR:
      return {
        ...state,
        fetchBuyerEmailRequest: false,
        fetchBuyerEmailError: payload,
      };

    case FETCH_COACH_EMAIL_REQUEST:
      return {
        ...state,
        fetchCoachEmailRequest: true,
        fetchCoachEmailError: null,
        coachEmail: null,
      };
    case FETCH_COACH_EMAIL_SUCCESS:
      return {
        ...state,
        fetchCoachEmailRequest: false,
        coachEmail: payload,
      };
    case FETCH_COACH_EMAIL_ERROR:
      return {
        ...state,
        fetchCoachEmailRequest: false,
        fetchCoachEmailError: payload,
      };
    case INVITE_COACHEE_REQUEST:
      return { ...state, inviteCoacheeInProgress: true };
    case INVITE_COACHEE_SUCCESS:
      return {
        ...state,
        inviteCoacheeInProgress: false,
        engagementListing: payload,
        chemistryList: payload.attributes.metadata.chemistryList || [],
      };
    case INVITE_COACHEE_ERROR:
      return { ...state, inviteCoacheeInProgress: false, inviteCoacheeError: payload };
      
    case ADD_EMAIL_REQUEST:
      return {
        ...state,
        scheduleChemistryRequest: true,
        scheduleChemistryRequestError: null,
      };
    case ADD_EMAIL_SUCCESS:
      return {
        ...state,
        scheduleChemistryRequest: false,
        chemistryList: payload,
      };
    case ADD_EMAIL_ERROR:
      return {
        ...state,
        scheduleChemistryRequest: false,
        scheduleChemistryRequestError: payload,
      };

    case COACHEE_REVIEW_REQUEST:
      return {
        ...state,
        coacheeReviewInProgress: true,
        coacheeReviewError: null,
      };
    case COACHEE_REVIEW_SUCCESS:
      return {
        ...state,
        coacheeReviewInProgress: false,
        engagementListing: payload,
      };
    case COACHEE_REVIEW_ERROR:
      return {
        ...state,
        coacheeReviewInProgress: false,
        coacheeReviewError: payload,
      };

    case BUYER_REVIEW_REQUEST:
      return {
        ...state,
        buyerReviewInProgress: true,
        buyerReviewError: null,
      };
    case BUYER_REVIEW_SUCCESS:
      return {
        ...state,
        buyerReviewInProgress: false,
        engagementListing: payload,
      };
    case BUYER_REVIEW_ERROR:
      return {
        ...state,
        buyerReviewInProgress: false,
        buyerReviewError: payload,
      };

    case SELECT_COACH_REQUEST:
      return {
        ...state,
        selectCoachInProgress: true,
        selectCoachError: null,
      };
    case SELECT_COACH_SUCCESS:
      return {
        ...state,
        selectCoachInProgress: false,
        engagementListing: payload,
      };
    case SELECT_COACH_ERROR:
      return {
        ...state,
        selectCoachInProgress: false,
        selectCoachError: payload,
      };

    case FETCH_COACHEE_SUCCESS:
      return {
        ...state,
        coacheeInfo: payload,
      };

    case BUYER_CONFIRMS_COACH_REQUEST:
      return {
        ...state,
        buyerConfirmsCoachInProgress: true,
        buyerConfirmsCoachError: null,
      };
    case BUYER_CONFIRMS_COACH_SUCCESS:
      return {
        ...state,
        buyerConfirmsCoachInProgress: false,
      };
    case BUYER_CONFIRMS_COACH_ERROR:
      return {
        ...state,
        buyerConfirmsCoachInProgress: false,
        buyerConfirmsCoachError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchEngagementListingRequest = () => ({
  type: FETCH_ENGAGEMENT_LISTING_REQUEST,
});

const fetchEngagementListingSuccess = response => ({
  type: FETCH_ENGAGEMENT_LISTING_SUCCESS,
  payload: response,
});

const fetchEngagementListingError = e => ({
  type: FETCH_ENGAGEMENT_LISTING_ERROR,
  payload: e,
});

const fetchCoachListingsRequest = () => ({
  type: FETCH_COACH_LISTINGS_REQUEST,
});

const fetchCoachListingsSuccess = response => ({
  type: FETCH_COACH_LISTINGS_SUCCESS,
  payload: response,
});

const fetchCoachListingsError = e => ({
  type: FETCH_COACH_LISTINGS_ERROR,
  payload: e,
});

const removeCoachListingRequest = id => ({
  type: REMOVE_COACH_LISTING_REQUEST,
  payload: id,
});

const removeCoachListingSuccess = response => ({
  type: REMOVE_COACH_LISTING_SUCCESS,
  payload: response,
});

const removeCoachListingError = e => ({
  type: REMOVE_COACH_LISTING_ERROR,
  payload: e,
});

const fetchBuyerEmailRequest = () => ({
  type: FETCH_BUYER_EMAIL_REQUEST,
});

const fetchBuyerEmailSuccess = response => ({
  type: FETCH_BUYER_EMAIL_SUCCESS,
  payload: response,
});

const fetchBuyerEmailError = e => ({
  type: FETCH_BUYER_EMAIL_ERROR,
  payload: e,
});

const fetchCoachEmailRequest = () => ({
  type: FETCH_COACH_EMAIL_REQUEST,
});

const fetchCoachEmailSuccess = response => ({
  type: FETCH_COACH_EMAIL_SUCCESS,
  payload: response,
});

const fetchCoachEmailError = e => ({
  type: FETCH_COACH_EMAIL_ERROR,
  payload: e,
});

const inviteCoacheeRequest = () => ({ type: INVITE_COACHEE_REQUEST });

const inviteCoacheeSuccess = payload => ({ type: INVITE_COACHEE_SUCCESS, payload });

const inviteCoacheeError = e => ({
  type: INVITE_COACHEE_ERROR,
  payload: e,
});

const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

const scheduleChemistryRequest = () => ({
  type: ADD_EMAIL_REQUEST,
});

export const scheduleChemistrySuccess = payload => ({
  type: ADD_EMAIL_SUCCESS,
  payload,
});

const scheduleChemistryError = e => ({
  type: ADD_EMAIL_ERROR,
  payload: e,
});

const coacheeReviewRequest = () => ({
  type: COACHEE_REVIEW_REQUEST,
});

const coacheeReviewSuccess = payload => ({
  type: COACHEE_REVIEW_SUCCESS,
  payload,
});

const coacheeReviewError = e => ({
  type: COACHEE_REVIEW_SUCCESS,
  payload: e,
});

const buyerReviewRequest = () => ({
  type: BUYER_REVIEW_REQUEST,
});

const buyerReviewSuccess = payload => ({
  type: BUYER_REVIEW_SUCCESS,
  payload,
});

const buyerReviewError = e => ({
  type: BUYER_REVIEW_SUCCESS,
  payload: e,
});

const selectCoachRequest = () => ({
  type: SELECT_COACH_REQUEST,
});

const selectCoachSuccess = payload => ({
  type: SELECT_COACH_SUCCESS,
  payload,
});

const selectCoachError = e => ({
  type: SELECT_COACH_ERROR,
  payload: e,
});

const fetchCoacheeSuccess = payload => ({
  type: FETCH_COACHEE_SUCCESS,
  payload,
});

const buyerConfirmsCoachRequest = () => ({
  type: BUYER_CONFIRMS_COACH_REQUEST,
});

const buyerConfirmsCoachSuccess = () => ({
  type: BUYER_CONFIRMS_COACH_SUCCESS,
});

const buyerConfirmsCoachError = e => ({
  type: BUYER_CONFIRMS_COACH_ERROR,
  payload: e,
});

// ================ Thunks ================ //

export const inviteCoachee = engagementId => (dispatch, getState, sdk) => {
  dispatch(inviteCoacheeRequest());

  return inviteCoacheeToEngagement(engagementId)
    .then(res => {
      dispatch(inviteCoacheeSuccess(denormalisedResponseEntities(res)[0]));
    })
    .catch(error => {
      dispatch(inviteCoacheeError(error));
      throw error;
    });
};

export const removeCoachListing = coachId => (dispatch, getState, sdk) => {
  const {
    engagementListing: {
      id: { uuid },
    },
    removeCoachListingInProgress,
  } = getState().CoachListPage;

  if (removeCoachListingInProgress) {
    return;
  }

  dispatch(removeCoachListingRequest(coachId));
  return removeCoachFromCoachList({ coachId, engagementId: uuid })
    .then(response => {
      const { coachList = {} } = response.data.attributes.metadata || {};
      const coachListingIds = Object.keys(coachList);
      dispatch(fetchCoachListings(coachListingIds)).then(() => {
        return dispatch(removeCoachListingSuccess(response.data));
      });
    })
    .catch(error => {
      dispatch(removeCoachListingError(storableError(error)));
    });
};

const fetchCoachListings = (coachListingIdsArray = []) => (dispatch, getState, sdk) => {
  const coachListingIds = coachListingIdsArray.join(',');
  return sdk.listings
    .query({
      ids: coachListingIds,
      include: ['author.profileImage'],
      'fields.image': ['variants.square-small2x'],
    })
    .then(response => {
      const listings = denormalisedResponseEntities(response);
      dispatch(fetchCoachListingsSuccess(listings));
    })
    .catch(error => dispatch(fetchCoachListingsError(storableError(error))));
};

const getBuyerEmailToInvite = engagementId => (dispatch, getState, sdk) => {
  dispatch(fetchBuyerEmailRequest());

  return getEmailForInvitation({ listingId: engagementId, engagementId })
    .then(response => {
      dispatch(fetchBuyerEmailSuccess(response.data));
    })
    .catch(error => dispatch(fetchBuyerEmailError(storableError(error))));
};

export const getCoachEmailToInvite = coachId => (dispatch, getState, sdk) => {
  dispatch(fetchCoachEmailRequest());
  const engagementId = getState().CoachListPage.engagementListing.id.uuid;

  return getEmailForInvitation({ listingId: coachId, engagementId })
    .then(response => {
      dispatch(fetchCoachEmailSuccess(response.data));
    })
    .catch(error => {
      dispatch(fetchCoachEmailError(storableError(error)));
    });
};

const fetchEngagementListing = (engagementId, isChemistryTab) => (dispatch, getState, sdk) => {
  dispatch(fetchEngagementListingRequest());

  return getEngagement(engagementId)
    .then(response => {
      const { engagementListing } = response.data;
      const {
        attributes: {
          metadata: { chemistryList = [], coachList },
        },
      } = engagementListing;

      dispatch(fetchEngagementListingSuccess(response.data));
      dispatch(fetchCoachListingsRequest());

      const coachIds = isChemistryTab
        ? chemistryList.map(coachItem => coachItem.coach.listingId)
        : coachList
        ? Object.keys(coachList)
        : [];
      dispatch(fetchCoachListings(coachIds));
    })
    .catch(error => {
      dispatch(fetchEngagementListingError(storableError(error)));
    });
};

export const bookChemistry = (mailList, coachId, coachListingId) => (dispatch, getState, sdk) => {
  const {
    engagementListing: {
      id: { uuid: engagementId },
    },
    scheduleChemistryRequest: scheduleChemistryInProgress,
  } = getState().CoachListPage;

  if (scheduleChemistryInProgress) {
    return Promise.resolve({});
  }

  dispatch(scheduleChemistryRequest());

  return scheduleChemistry(engagementId, { mailList, coachId, coachListingId })
    .then(response => {
      dispatch(scheduleChemistrySuccess(response.data));
      const key = `${engagementId}-chemistry`;
      localStorageSync.setItem(key, JSON.stringify(response.data));
    })
    .catch(e => {
      const error = storableError(e);
      console.error(error);
      dispatch(scheduleChemistryError(error));
    });
};

export const coacheeReview = ({ coachListingId, feedback, chemistry, skillsAndExperience }) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(coacheeReviewRequest());

  const {
    CoachListPage: {
      engagementListing: {
        id: { uuid: engagementId },
      },
    },
  } = getState();

  return reviewCoach({
    engagementId,
    coachListingId,
    feedback,
    chemistry,
    skillsAndExperience,
  })
    .then(response => dispatch(coacheeReviewSuccess(response.data)))
    .catch(error => dispatch(coacheeReviewError(storableError(error))));
};

export const buyerReview = ({ coachListingId, buyerFeedback }) => (dispatch, getState, sdk) => {
  dispatch(buyerReviewRequest());

  const {
    CoachListPage: {
      engagementListing: {
        id: { uuid: engagementId },
      },
    },
  } = getState();

  return reviewCoach({
    engagementId,
    coachListingId,
    buyerFeedback,
  })
    .then(response => dispatch(buyerReviewSuccess(response.data)))
    .catch(error => dispatch(buyerReviewError(storableError(error))));
};

export const selectCoach = coachListingId => (dispatch, getState, sdk) => {
  dispatch(selectCoachRequest());

  const {
    CoachListPage: {
      engagementListing: {
        id: { uuid: engagementId },
      },
    },
  } = getState();

  return pickCoach({ engagementId, coachListingId })
    .then(response => dispatch(selectCoachSuccess(response.data)))
    .catch(error => dispatch(selectCoachError(storableError(error))));
};

export const buyerConfirmsCoach = params => (dispatch, getState, sdk) => {
  dispatch(buyerConfirmsCoachRequest());

  return sdk.transactions
    .transition(params, { expand: true })
    .then(response => dispatch(buyerConfirmsCoachSuccess(response.data)))
    .catch(error => dispatch(buyerConfirmsCoachError(storableError(error))));
};

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const { engagementId } = params;

  dispatch(setInitialState());

  const isChemistryTab = search === `?tab=${CHEMISTRY_TAB}`;

  return Promise.all([
    dispatch(fetchEngagementListing(engagementId, isChemistryTab)).then(() => {
      if (isChemistryTab) {
        const {
          attributes: {
            metadata: { coacheeId },
          },
        } = getState().CoachListPage.engagementListing;
        if (coacheeId) {
          return sdk.users.show({ id: coacheeId }).then(response => {
            dispatch(fetchCoacheeSuccess(response.data.data));
          });
        }
      }
    }),
    dispatch(getBuyerEmailToInvite(engagementId)),
  ]);
};
