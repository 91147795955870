import React, { useEffect, useState } from 'react';
import { array, string, object } from 'prop-types';
import classNames from 'classnames';
import { countWords, truncateTextByWords } from '../../util/richText';
import { FormattedMessage } from 'react-intl';

import css from './AdminReviews.module.css';

const MAX_LENGTH_FOR_COMMENT = 62;

const AdminReview = props => {
  const {
    review: { name, comment, date },
  } = props;
  const [showSeeMore, setShowSeeMore] = useState(false);

  useEffect(() => {
    const isGreaterThanMaxLength = countWords(comment) > MAX_LENGTH_FOR_COMMENT;
    setShowSeeMore(isGreaterThanMaxLength);
  }, [comment]);

  const handleClickSeeMore = () => {
    setShowSeeMore(false);
  };

  return (
    <div className={css.review}>
      <div>
        <p className={css.reviewContent}>
          {truncateTextByWords(comment, showSeeMore ? MAX_LENGTH_FOR_COMMENT : undefined)}{' '}
          {showSeeMore && (
            <span className={css.seeMore} onClick={handleClickSeeMore}>
              <FormattedMessage id="AdminReview.seeMore" />
            </span>
          )}
        </p>
        <p className={css.reviewInfo}>
          {name}
          <span className={css.separator}>•</span>
          {date}
        </p>
      </div>
    </div>
  );
};

AdminReview.propTypes = {
  review: object.isRequired,
};

const AdminReviewsComponent = props => {
  const { className, rootClassName, reviews } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <ul className={classes}>
      {reviews.map((r, i) => {
        return (
          <li key={`Review_${i}`} className={css.reviewItem}>
            <AdminReview review={r} />
          </li>
        );
      })}
    </ul>
  );
};

AdminReviewsComponent.defaultProps = {
  className: null,
  rootClassName: null,
  reviews: [],
};

AdminReviewsComponent.propTypes = {
  className: string,
  rootClassName: string,
  reviews: array,
};

export default AdminReviewsComponent;
