import React from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import config from '../../config';
import { DATE_TYPE_DATETIME } from '../../util/types';
import { ensureListing } from '../../util/data';
import { BookingBreakdown } from '../../components';

import css from './TransactionPanel.module.css';
import { FormattedMessage, injectIntl } from 'react-intl';
import { formatMoney } from '../../util/currency';

// Functional component as a helper to build BookingBreakdown
const BreakdownMaybeComponent = props => {
  const {
    className,
    rootClassName,
    breakdownClassName,
    transaction,
    transactionRole,
    isPaymentForProjectListing = false,
    listingPrice,
    intl,
    showHeading,
  } = props;
  const { engagementDetails } = transaction.attributes.protectedData;
  const loaded = transaction && transaction.id && !isEmpty(engagementDetails);
  const listingAttributes = ensureListing(transaction.listing).attributes;
  const timeZone =
    loaded && listingAttributes.availabilityPlan
      ? listingAttributes.availabilityPlan.timezone
      : 'Etc/UTC';

  const classes = classNames(rootClassName || css.breakdownMaybe, className);
  const breakdownClasses = classNames(breakdownClassName || css.breakdown);

  const formattedPrice = formatMoney(intl, listingPrice);

  const displayBreakdownForProjectListing = isPaymentForProjectListing ? (
    <div className={classes}>
      <div className={breakdownClasses}>
        <div className={css.breakdownText}>
          <FormattedMessage id="BookingBreakdown.breakdown" />
        </div>
        <div className={css.lineItem}>
          <span className={css.itemLabel}>
            <FormattedMessage id="BookingBreakdown.engagementListing" />
          </span>
          <span className={css.itemValue}>{formattedPrice}</span>
        </div>
        <hr className={css.totalDivider} />
        <div className={css.lineItem}>
          <div className={css.itemLabel}>
            <FormattedMessage id="BookingBreakdown.total" />
          </div>
          <div className={css.totalPrice}>{formattedPrice}</div>
        </div>
      </div>
    </div>
  ) : null;

  return loaded ? (
    <div className={classes}>
      {showHeading && (
        <h3 className={css.bookingBreakdownHeading}>
          <FormattedMessage id="BookingBreakdown.heading" />
        </h3>
      )}
      <BookingBreakdown
        className={breakdownClasses}
        userRole={transactionRole}
        unitType={config.bookingUnitType}
        transaction={transaction}
        booking={transaction.booking}
        dateType={DATE_TYPE_DATETIME}
        timeZone={timeZone}
        isPaymentForProjectListing={isPaymentForProjectListing}
      />
    </div>
  ) : (
    displayBreakdownForProjectListing
  );
};

const BreakdownMaybe = injectIntl(BreakdownMaybeComponent);

export default BreakdownMaybe;
