import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconShortListAdded.module.css';

const IconShortListAdded = props => {
  const { className, rootClassName, onClick } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="37.13"
      height="31.581"
      viewBox="0 0 37.13 31.581"
    >
      <g transform="translate(-3550.006 606)">
        <path
          d="M10.749,12.7h-9a1.75,1.75,0,0,1,0-3.5h8.662c-.021.261-.032.53-.032.8a10.017,10.017,0,0,0,.369,2.7Z"
          transform="translate(3558.756 -608)"
          fill="#fff"
        />
        <circle cx="3" cy="3" r="3" transform="translate(3550.006 -600)" fill="#fff" />
        <path
          d="M19.75,22.28h-18a1.75,1.75,0,0,1,0-3.5H15.591A10.011,10.011,0,0,0,20.381,20a10.158,10.158,0,0,0,1.02-.051A1.751,1.751,0,0,1,19.75,22.28Z"
          transform="translate(3558.756 -608)"
          fill="#fff"
        />
        <circle cx="3" cy="3" r="3" transform="translate(3550.006 -590.419)" fill="#fff" />
        <circle cx="3" cy="3" r="3" transform="translate(3550.006 -580.419)" fill="#fff" />
        <path
          d="M18,1.75H0A1.75,1.75,0,0,1-1.75,0,1.75,1.75,0,0,1,0-1.75H18A1.75,1.75,0,0,1,19.75,0,1.75,1.75,0,0,1,18,1.75Z"
          transform="translate(3560.506 -577.419)"
          fill="#fff"
        />
      </g>
      <g transform="translate(-808.87 -507)">
        <path
          d="M-6062.13-6157.4a8.009,8.009,0,0,1-8-8,8.01,8.01,0,0,1,8-8,8.01,8.01,0,0,1,8,8A8.009,8.009,0,0,1-6062.13-6157.4Zm-3.312-7.79a.988.988,0,0,0-.706.293,1,1,0,0,0,0,1.415l2.5,2.493a.992.992,0,0,0,.707.293.992.992,0,0,0,.707-.293l4.993-4.986a.992.992,0,0,0,.294-.707,1,1,0,0,0-.292-.708,1,1,0,0,0-.707-.292,1,1,0,0,0-.708.292l-4.287,4.279-1.791-1.786A.993.993,0,0,0-6065.441-6165.186Z"
          transform="translate(6900.13 6680.396)"
          className={css.tick}
          fill="#2ecc71"
        />
      </g>
      {/* <g transform="translate(-808.87 -507)">
        <path d="M8,0A8,8,0,1,1,0,8,8,8,0,0,1,8,0Z" transform="translate(830 507)" fill="#2ecc71" />
        <path
          d="M7.491,0,2.5,4.985,0,2.493"
          transform="translate(834.689 513.717)"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="2"
        />
      </g> */}
    </svg>
  );
};

const { string, func } = PropTypes;

IconShortListAdded.defaultProps = {
  className: null,
  rootClassName: null,
  onClick: () => {},
};

IconShortListAdded.propTypes = {
  className: string,
  rootClassName: string,
  onClick: func,
};

export default IconShortListAdded;
