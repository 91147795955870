import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import css from './Skeleton.module.css';

const Skeleton = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return <div className={classes} />;
};

Skeleton.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

Skeleton.propTypes = {
  rootClassName: string,
  className: string,
};

export default Skeleton;
