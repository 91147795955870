import { client } from '../../prismic';
import { storableError } from '../../util/errors';

const LEADERSHIP_TAG = 'leadership';

// ================ Action types ================ //

export const FETCH_BLOGS_SUCCESS = 'app/LeadershipPage/FETCH_BLOGS_SUCCESS';
export const FETCH_BLOGS_ERROR = 'app/LeadershipPage/FETCH_BLOGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  blogs: [],
  fetchError: null,
};

const leadershipPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_BLOGS_SUCCESS:
      return {
        ...state,
        blogs: payload,
      };
    case FETCH_BLOGS_ERROR:
      return {
        ...state,
        fetchError: payload,
      };
    default:
      return state;
  }
};

export default leadershipPageReducer;

// ================ Action creators ================ //

export const fetchBlogsSuccess = payload => ({
  type: FETCH_BLOGS_SUCCESS,
  payload,
});

export const fetchBlogsError = payload => ({
  type: FETCH_BLOGS_ERROR,
  payload,
});

// ================ Thunks ================ //

export const loadData = () => dispatch => {
  return client
    .getAllByTag(LEADERSHIP_TAG)
    .then(response => dispatch(fetchBlogsSuccess(response)))
    .catch(error => dispatch(fetchBlogsError(storableError(error))));
};
