import React from 'react';
import { bool, func, string, array, object } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { Modal } from '..';
import css from './ModalAddToCoachList.module.css';
import { convertListingsToOptions } from '../../util/data';
import BookingPanelForm from '../BookingPanel/BookingPanelForm';
import { ERROR_CODE_REMOVE_BOOKED_CHEMISTRY_ENGAGEMENT, propTypes } from '../../util/types';

const ModalAddToCoachList = props => {
  const {
    id,
    isOpen,
    onClose,
    onManageDisableScrolling,
    error,
    onSubmit,
    role,
    inProgress,
    engagementList,
    projectListings,
    history,
    coachListing,
  } = props;

  const projectListingsOptions = convertListingsToOptions(projectListings, coachListing);

  const initialProjectListings = projectListings.reduce((result, project) => {
    if (engagementList.includes(project.id.uuid)) {
      result.push(project.id.uuid);
    }
    return result;
  }, []);

  const finalizeSubmitData = (values, e) => {
    const { projects } = values;
    const listProjects = [...new Set([...projects, ...initialProjectListings])];
    const finalizedProjects = listProjects.reduce(
      (result, project) => {
        if (initialProjectListings.includes(project) && !projects.includes(project)) {
          result.removeCoachList.push(project);
        } else if (
          !initialProjectListings.includes(project) &&
          projects.includes(project)
        ) {
          result.addCoachList.push(project);
        }
        return result;
      },
      {
        addCoachList: [],
        removeCoachList: [],
      }
    );

    onSubmit(finalizedProjects, e);
  };

  return (
    <Modal
      id={id}
      isOpen={isOpen}
      onClose={onClose}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
    >
      {projectListingsOptions.length === 0 ? (
        <FormattedMessage id="BookingPanel.noProjectAvailable" />
      ) : (
        <>
          <div className={css.bookingPanelFormModalHeading}>
            <FormattedMessage id="BookingPanel.modalHeading" />
          </div>

          <BookingPanelForm
            id={id}
            onSubmit={finalizeSubmitData}
            useCheckBox
            projectListings={projectListingsOptions}
            actionButtonText={<FormattedMessage id="BookingPanel.addToCoachList" />}
            history={history}
            role={role}
            inProgress={inProgress}
            addToCoachListError={error}
            initialValues={{ projects: initialProjectListings }}
            resetFormFlag={isOpen}
          />
        </>
      )}
      {error?.statusText === ERROR_CODE_REMOVE_BOOKED_CHEMISTRY_ENGAGEMENT ? (
        <div className={css.error}>
          <FormattedMessage id="BookingPanel.removeBookedEngagementError" />
        </div>
      ) : (
        error && (
          <div className={css.error}>
            <FormattedMessage id="BookingPanel.addToCoachError" />
          </div>
        )
      )}
    </Modal>
  );
};

ModalAddToCoachList.defaultProps = {
  isOpen: false,
  onClose: () => { },
  error: null,
  onSubmit: () => { },
  role: '',
  inProgress: false,
  engagementList: [],
  projectListings: [],
};

ModalAddToCoachList.propTypes = {
  id: string.isRequired,
  isOpen: bool,
  onClose: func,
  onManageDisableScrolling: func.isRequired,
  error: propTypes.error,
  onSubmit: func,
  role: string,
  inProgress: bool,
  engagementList: array,
  projectListings: array,
  history: object,
  coachListing: propTypes.listing,
};

ModalAddToCoachList.displayName = 'ModalAddToCoachList';

export default ModalAddToCoachList;
