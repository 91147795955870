import React from 'react';
import { string, object } from 'prop-types';
import { Logo } from '../../components';

import css from './EngagementAgreement.module.css';
import { stringifyDateToISO, timestampToDate } from '../../util/dates';

const EngagementAgreement = props => {
  const { className, currentListing, currentUser } = props;

  const { agreementDetails } = currentListing.attributes.publicData || {};
  const { companyName, agreementDate } = agreementDetails || {};
  const { profile } = currentUser?.attributes || {};
  const { companyName: profileCompanyName } = profile.publicData || {};
  
  const convertedAgreementDate = agreementDate
    ? stringifyDateToISO(timestampToDate(agreementDate))
    : stringifyDateToISO(new Date());

  return (
    <div className={className}>
      <div className={css.logo}>
        <Logo isBlackLogo />
      </div>
      <p className={css.heading}>PRIVATE & CONFIDENTIAL</p>
      <div className={css.mainSection}>
        <p className={css.title}>Agreement:</p>
        <p>This Agreement is being entered into between {companyName || profileCompanyName} (“Client”) and 
          Lanterne Rouge Online Limited (“The Marketplace”, “we” and “our”), trading as BOLDLY, 
          of 33 UBI AVENUE 3 #08-61 VERTEX SINGAPORE 408868, and shall be effective as 
          of {convertedAgreementDate} (“Effective Date”).</p>
        <p>This Agreement specifies the terms on which The Marketplace delivers coaching services to the Client, 
          and subsequently to the Coachee within the Client company, with an initial engagement commencing on the 
          Effective Date. Terms of Business herein extend to subsequent coaching engagements or engagements 
          delivered off The Marketplace unless otherwise terminated or amended.</p>
      </div>
      <div className={css.mainSection}>
        <p className={css.title}>Definitions:</p>
        <p><b>Agreement: </b>a third-party tool, independently validated and administered by The Marketplace 
          to ensure the Engagement commences with maximum effectiveness for the Coachee. The Marketplace typically 
          works with Hogan and ViaStrengths tools, depending on the Engagement level and development goals.</p>
        <p><b>Coach: </b>a subcontractor to The Marketplace who is qualified to deliver coaching services.</p>
        <p><b>Coachee: </b>the employee of the Client with whom the Coach will meet for individual development.</p>
        <p><b>Bundle: </b>An agreed Engagement length - ie number of sessions in total at the outset of the Engagement.</p>
        <p><b>Engagement: </b>the ‘bundled’ service provided to the Coachee, by The Marketplace, and paid 
          for by the Client. This includes coaching services, assessment, and key service moments to support 
          optimal professional development for the Coachee.</p>
        <p><b>Group: </b>individuals who join together for coaching on a common theme. Typically no more than 
          a 1:6 ratio of Coach to Coachees. The Coachees are colleagues and peers sharing similar developmental needs.</p>
        <p><b>Platform: </b>proprietary web-based portal designed to support the tracking of Engagement 
          progress and delivery of additional career development resources to support the coaching Engagement.</p>
        <p><b>Session: </b>a coaching meeting, whether delivered online or face to face, usually 1 hour in 
          duration, but may be at the length agreed between the Coach, Client and Coachee, charged at an hourly 
          rate or part thereof.</p>
        <p><b>Team: </b>individuals who are in a professional relationship to deliver shared work objectives. 
          This can include peer and direct reporting relationships, where colleagues need to develop their 
          interrelated skills and relationships in order to effectively execute their work.</p>
      </div>
      <div className={css.mainSection}>
        <p className={css.title}>Our Understanding:</p>
        <p>The Client seeks to fulfill their coaching needs by engaging qualified Coaches, subcontracted by 
          The Marketplace, for professional development of its employees (the Coachee(s)). The Marketplace 
          will act as a Brokerage whereby the Client will search for vetted coaches, longlist and shortlist 
          coaches for the Coachee to meet for chemistry meetings, and subsequently confirm a coaching engagement.</p>
      </div>
      <div className={css.mainSection}>
        <p className={css.title}>Roles & Relationships:</p>
        <p className={css.subTitle}>In agreeing to a coaching relationship, the Coach and Coachee understand the following. BOLDLY has 
          confirmed these responsibilities with the Coach, and the Client is responsible for confirming the 
          below commitments with the Coachee:</p>
        <ul>
          <li>Coaching may address specific projects, work performance, skill levels, and general conditions 
            in the Coachee’s profession or life as they impact on workplace performance.</li>
          <li>Coaching requires a co-active, collaborative approach.</li>
          <li>In the coaching relationship, the Coach plays the role of a facilitator of change, and it is 
            the Coachee’s responsibility to enact change and implement actions that have been agreed 
            on between sessions.</li>
          <li>Wherever professional support for mental health issues is required, the Coachee understands 
            that the Coach must recommend additional resources, including Counselors and Psychologists. 
            This referral process is made in the best interest of the Coachee.</li>
          <li>All matters discussed between the Coach and Coachee are considered strictly confidential, 
            unless the Coach has concerns about the Coachees intent to harm themselves, 
            or others around them.</li>
        </ul>
      </div>
      <div className={css.subSection}>
        <p className={css.title}>Coach Commitments</p>
        <p className={css.subTitle}>Throughout the working relationship the Coach:</p>
        <ul>
          <li>Engages in direct and personal conversations either face to face or  virtually, as jointly 
            agreed between the parties.</li>
          <li>Determines with the Coachee the most useful and desired outcomes.</li>
          <li>Applies contemporary organisational coaching practices.</li>
          <li>Provides a safe, yet challenging learning environment for the Coachee to promote exploration 
            and self-discovery.</li>
          <li>Takes a holistic, integral approach to coaching, partnering with the organisation to ensure 
            all required support is provided to the Coachee.</li>
          <li>Seeks feedback from the Coachee and reviews coaching outcomes achieved against the goals set.</li>
          <li>Communicates status updates regarding engagement cadence directly with the Client, and only 
            discusses case specifics in the presence of the Coachee, with their permission and collaboration.</li>
        </ul>
      </div>
      <div className={css.subSection}>
        <p className={css.title}>Coachee Commitments</p>
        <p className={css.subTitle}>Throughout the working relationship the Coachee:</p>
        <ul>
          <li>Participates with an open mind and readiness to move forward.</li>
          <li>Demonstrates a willingness to be actively involved, exploring all options given and will be 
            committed to coaching.</li>
          <li>Gives permission to the Coach to challenge and confront thinking, behaviours and feelings 
            during the coaching sessions in support of development goals.</li>
          <li>Explores their resources and hidden talents in order to maximize and realize potential.</li>
          <li>Sets with the Coach, the interim activities to be carried out between coaching sessions and 
            action these developmental items in between sessions in order to obtain the full benefit 
            from this coaching.</li>
          <li>Is aware of the ethical and confidentiality imposed on the Coach.</li>
          <li>
            <b>Agrees to not change coaching session bookings once set unless absolutely necessary, and 
              never cancel or change time within 24 hours of the set meeting. Understands that meetings 
              changed or canceled with less than 24 hours notice incurs a cost of 1 hour of coaching time.
            </b>
          </li>
        </ul>
      </div>
      <div className={css.subSection}>
        <p className={css.title}>Client Commitments</p>
        <p className={css.subTitle}>Throughout the working relationship the Client will:</p>
        <ul>
          <li>Only engage the The Marketplace to confirm a longlist and short list of Coaches for 
            pre-budgeted or approved Engagements.</li>
          <li>Respond promptly to The Marketplace with feedback on Coach shortlists and follow up chemistry 
            meetings with clear feedback on each Coach profile.</li>
          <li>Maintain the understanding that discussions held between the Coach and Coachee have been had 
            in the strictest confidence, and not attempt to breach this confidentiality in any way.</li>
          <li>Settle invoices on time to ensure Coach payments can be made in accordance with Engagement 
            delivery milestones.</li>
          <li>Escalate any concerns about the progress of the coaching Engagement to The Marketplace promptly 
            and in the spirit of collaboration to ensure a suitable resolution to any issues.</li>
          <li>Endeavour to support the Coachee to keep their coaching commitment, including alignment of 
            resources with the Coachees manager. Understands that meetings changed or canceled with less than 
            24 hours notice incurs a cost of 1 hour of coaching time.</li>
        </ul>
      </div>
      <div className={css.mainSection}>
        <p className={css.title}>Coaching Brokerage Services:</p>
        <p>Through this Agreement, the The Marketplace commits to deliver the Marketplace for self-serve 
          coach search, in addition to the following as optional services supporting the Engagement:</p>
      </div>
      <div className={css.subSection}>
        <p className={css.title}>Facilitated Chemistry Meetings</p>
        <p>Gaining the right chemistry fit is important for effective coaching. We recommend a 15-30 minute 
          virtual meeting between the Coachee and 2-3 Coaches chosen from the long-list. These meetings are 
          not charged, but we do ask for feedback from Coachees to pass onto Coaches. The chemistry meeting 
          is booked by our Virtual Assistant, Dan, via email, and will include a calendar invite and zoom 
          links unless otherwise instructed by the Client.</p>
      </div>
      <div className={css.subSection}>
        <p className={css.title}>Assessment Administration</p>
        <p>Assessment can be a valuable ‘jumping off’ point for coaching, as it enables the delivery of 
          unbiased feedback to support the development goal setting process. The Marketplace only works with 
          credible assessment tools, where validity and reliability have been demonstrated on a global 
          population over time. Our preference is to work with Hogan and ViaStrengths.</p>
        <p>The Assessment report should be debriefed by a qualified organisational psychologist. Sometimes 
          the Coach chosen to deliver the Engagement is qualified to debrief on the assessment report, however 
          in situations where the assessment is being carried out before the coach selection, or where the 
          chosen Coach is not qualified, The Marketplace will provide an organisational psychologist for the 
          debrief. This debrief session should include:</p>
        <ol className={css.listNoMargin}>
          <li>The Coachee</li>
          <li>The Coachee’s direct manager</li>
          <li>The Coach (once selected)</li>
        </ol>
        <p>This is a paid service - please discuss assessment and debriefing options based on the needs of the 
          Engagement with your The Marketplace contact.</p>
      </div>
      <div className={css.subSection}>
        <p className={css.title}>Tracking Engagement Length and Cadence</p>
        <p><b>Suggested: </b>6-10 X 60 Min Sessions, run every 2-3 weeks.</p>
        <p>To support sustained learning, we recommend Coaches and Coachees meet for a minimum of 6 sessions, 
          with 10 sessions being typical. Coaches and Coachees often find their own cadence for sessions 
          considering workload and coaching topics. As a guide, we recommend no longer than 3 weeks between 
          sessions, and a session length of 60 minutes. Most Engagements last 6 months +, with developmental 
          benefits enhanced at 12 months +.</p>
      </div>
      <div className={css.mainSection}>
        <p className={css.title}>General Terms of Business:</p>
        <ol type="a">
          <li>Non-Waiver.  The failure by either party at any time to enforce any of the provisions of this 
            Agreement or any right or remedy available hereunder or at law or in equity, or to exercise any 
            option herein provided, will not constitute a waiver of such provision, right, remedy or option 
            or in any way affect the validity of this Agreement.</li>
          <li>Severability / Interpretation.  Every provision of this Agreement will be construed, to the 
            extent possible, so as to be valid and enforceable.  If any provision of this Agreement so 
            construed is held by a court of competent jurisdiction to be invalid, illegal or otherwise 
            unenforceable, such provision will be deemed severed from this Agreement, and all other provisions 
            will remain in full force and effect.</li>
          <li>Force Majeure.  The Marketplace will not be liable or be deemed in default for any failure, delay 
            or interruption of services, which results directly or indirectly from any cause or circumstance 
            beyond the reasonable control of The Marketplace.</li>
          <li>Notice. Any notice hereunder will be in writing and will be deemed to have been received when 
            personally delivered, twenty-four (24) hours after it has been sent via overnight express courier, 
            or seventy-two (72) hours after it has been deposited in the applicable local mail, registered or 
            certified, postage pre-paid, properly addressed to the party to whom it is intended.</li>
          <li>Governing Law and Disputes.  This Agreement will in all respects be governed by and interpreted, 
            construed and enforced in accordance with the laws of Singapore without respect to its choice of 
            law provisions.  Each party irrevocably agrees that the courts of Singapore shall have exclusive 
            jurisdiction to settle any dispute or claim arising out of or in connection with this Agreement 
            or its subject matter or formation (including non-contractual disputes or claims. The parties hereby 
            disclaim the applicability of the United Nations’ Convention on the International Sale of Goods.</li>
          <li>Assignment.  Neither party may sell, assign, or transfer this Agreement, nor the rights accorded 
            thereunder, without the written consent of the other party, except in the event of the assignor’s 
            merger, consolidation, spin-off, split-off or acquisition.  In that event, assignment will be restricted 
            to any survivor, subsidiary or successor.  Notwithstanding the foregoing, either party may assign or 
            delegate any of its rights and obligations to an affiliate. Subject to the foregoing, this Agreement 
            shall insure to the benefit of and shall be binding upon The Marketplace and Client and their respective 
            successors and assigns.</li>
          <li>Relationship of the Parties. The parties are independent contractors. This Agreement does not create a 
            partnership, franchise, joint venture, agency, fiduciary or employment relationship between the parties.</li>
          <li>Third-Party Beneficiaries. There are no third-party beneficiaries under this Agreement.</li>
          <li>Miscellaneous.  This Agreement sets forth the entire agreement and understanding between The Marketplace 
            and Client regarding the subject matter hereof and supersedes any prior representations, advertisements, 
            statements, proposals, negotiations, discussions, understandings, or agreements regarding the same subject 
            matter.  This Agreement may not be modified or amended except by a writing signed by the party against whom 
            the same is sought to be enforced.  This Agreement may be executed in multiple Coachees, each of which shall 
            be deemed an original, and when taken together shall constitute one and the same agreement.  Those sections 
            of this Agreement which are intended by their nature to survive expiration or termination of the Agreement, 
            shall do so.</li>
          <li>This Agreement will terminate automatically and immediately in the event that:  (i) Client does not pay fees 
            due within sixty (60) days of invoice therefore; (ii) either party is otherwise in breach of the terms of this 
            Agreement and has failed to cure said breach after receiving a written notice and thirty (30) day opportunity 
            to remedy the breach. After the expiration of 12 months from the Effective Date, Client may terminate this 
            Agreement for any reason upon giving 30 days’ written notice.</li>
        </ol>
      </div>
      <div className={css.mainSection}>
        <p className={css.title}>Commercial Agreement:</p>
        <p>The Client agrees to engage Lanterne Rouge Online Pte Ltd. for the coaching services described herein on the 
          Agreement Date stated at the start of this document.</p>
      </div>
    </div>
  );
};

EngagementAgreement.defaultProps = {
  className: null,
  currentListing: null,
  currentUser: null
};

EngagementAgreement.propTypes = {
  className: string,
  currentListing: object,
  currentUser: object
};

export default EngagementAgreement;
