import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import { ListingLink } from '../../components';
import { EditListingEngagementForm } from '../../forms';

import css from './EditListingEngagementPanel.module.css';

const { Money } = sdkTypes;

const EditListingEngagementPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const {
    publicData: {
      reasonForCoaching,
      coachingFormat,
      goalsOfEngagement,
      isThisCoachingBeingUndertaken,
      shouldBeAwareOfAnyChanges,
      shouldBeAwareOfCompanyCompetencyOrLeadershipFramework,
      explainFurtherContext,
      howManyOneHourSessions,
      maxBudget,
      engagementStartDate,
    }
  } = currentListing.attributes;
  const maxBudgetAsMoney = maxBudget
    ? new Money(maxBudget.amount, maxBudget.currency)
    : null;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingEngagementPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingEngagementPanel.createListingTitle" />
  );

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <h3 className={css.subTitle}>
        <FormattedMessage
          id="EditListingEngagementPanel.subTitle"
        />
      </h3>
      <EditListingEngagementForm
        className={css.form}
        keepDirtyOnReinitialize
        initialValues={{
          reasonForCoaching,
          coachingFormat,
          goalsOfEngagement,
          isThisCoachingBeingUndertaken,
          shouldBeAwareOfAnyChanges,
          shouldBeAwareOfCompanyCompetencyOrLeadershipFramework,
          explainFurtherContext,
          howManyOneHourSessions,
          maxBudget: maxBudgetAsMoney,
          engagementStartDate: { date: engagementStartDate ? new Date(engagementStartDate) : null },
        }}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const {
            reasonForCoaching,
            coachingFormat,
            goalsOfEngagement,
            isThisCoachingBeingUndertaken,
            shouldBeAwareOfAnyChanges,
            shouldBeAwareOfCompanyCompetencyOrLeadershipFramework,
            explainFurtherContext,
            howManyOneHourSessions,
            maxBudget,
            engagementStartDate: startDateRaw,
          } = values;

          const startDate = new Date(startDateRaw.date);

          const updateValues = {
            publicData: {
              reasonForCoaching,
              coachingFormat,
              goalsOfEngagement,
              isThisCoachingBeingUndertaken,
              shouldBeAwareOfAnyChanges,
              shouldBeAwareOfCompanyCompetencyOrLeadershipFramework,
              explainFurtherContext,
              howManyOneHourSessions,
              maxBudget: {
                amount: maxBudget.amount,
                currency: maxBudget.currency
              },
              engagementStartDate: startDate.getTime(),
            },
          };

          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
      />
    </div>
  );
};

EditListingEngagementPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingEngagementPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingEngagementPanel;
