import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './IconSearch.module.css';

const IconSearch = props => {
  const { rootClassName, className, isCoachListing } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <>
      {isCoachListing ? <svg className={classes} xmlns="http://www.w3.org/2000/svg">
        <g
          transform="matrix(-1 0 0 1 20 1)"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M13 14l5.241 5.241" />
          <circle cx="7.5" cy="7.5" r="7.5" />
        </g>
      </svg> : <svg version="1.1" viewBox="0 0 1080 1080" width="1280" height="380" xmlns="http://www.w3.org/2000/svg">
        <path transform="translate(800,39)" d="m0 0 24 1 19 3 22 6 19 8 21 11 14 10 11 9 20 20 12 16 8 13 8 15 8 21 5 18 3 18 1 10v27l-3 23-5 20-7 19-8 17-10 16-10 13-9 10-7 8-10 9-15 11-19 11-11 6 13 27 11 28 9 28 7 29 5 29 3 29v61l-3 29-5 29-8 32-11 33-11 26-8 16-11 21-13 21-14 20-12 15-9 11-14 15-7 8-8 7-9 9-11 9-12 10-15 11-18 12-13 8-22 12-29 14-23 9-36 11-27 6-32 5-22 2-31 1-40-2-35-5-28-6-28-8-20-7-24-10-19-9-22-12-24-15-18-13-16-13-14-12-17-16-16-17-12-14-13-17-12-17-12-19-13-24-12-25-12-31-6-19-9-36-5-31-3-34v-42l3-33 6-36 7-28 12-36 10-24 12-25 14-24 9-14 12-17 12-15 9-11 14-15 22-22 11-9 8-7 13-10 20-14 21-13 26-14 26-12 34-12 24-7 32-7 29-4 26-2h41l34 3 26 4 30 7 7 2 2-11 7-21 6-13 9-17 10-14 8-10 12-13 11-10 18-13 17-10 23-10 21-6 13-3z" fill="#7C9BFE" />
        <path transform="translate(800,39)" d="m0 0 24 1 19 3 22 6 19 8 21 11 14 10 11 9 20 20 12 16 8 13 8 15 8 21 5 18 3 18 1 10v27l-3 23-5 20-7 19-8 17-10 16-10 13-9 10-7 8-10 9-15 11-19 11-17 8-24 8-21 4-7 1h-40l-24-4-26-8-20-9-18-11-16-12-13-12-12-13-13-18-10-18-7-15-7-21-5-25-1-9v-33l3-21 4-17 8-22 8-16 9-15 13-17 14-15 10-9 18-13 17-10 23-10 21-6 13-3z" fill="#FEFEFE" />
        <path transform="translate(458,474)" d="m0 0h21l20 4 15 6 14 8 13 11 9 9 9 13 8 16 5 17 2 13v18l-3 18-6 17-8 15-8 10-10 11-15 11-16 8-16 5-10 2-21 1-19-3-18-6-13-7-11-8-12-11-9-12-8-14-5-12-4-17-1-9v-15l3-19 5-15 7-14 8-11 9-10 10-9 18-11 19-7z" fill="#7C9BFE" />
        <path transform="translate(457,444)" d="m0 0h20l21 3 17 5 18 8 18 12 11 10 5 5 10 11 9 14 8 16 6 18 3 15 1 9v21l-3 19-5 17-5 12-8 15-8 11 6 7 93 93 2 6-1 8-4 6-6 3h-9l-6-4-96-96-5 2-15 10-18 8-16 5-16 3h-31l-18-3-21-7-19-10-12-9-10-9-11-11-10-14-9-17-6-16-4-16-2-21 1-19 4-20 5-15 8-16 9-14 9-11 10-10 13-10 13-8 15-7 15-5 15-3zm1 30-18 3-19 7-15 9-11 9-9 9-10 14-8 16-4 13-3 19v15l3 19 5 15 8 15 8 11 11 12 12 9 14 8 16 6 14 3 9 1 21-1 21-5 16-7 12-8 11-9 10-11 9-14 8-18 4-17 1-8v-18l-3-18-7-19-8-14-9-11-10-10-14-10-16-8-16-5-12-2z" fill="#fff" />
        <path transform="translate(756,150)" d="m0 0h18l12 3 10 6 8 8 6 12 4 14 1 7v30l-4 18-7 14-6 7-9 6-13 4h-22l-13-4-11-8-7-10-5-13-3-15v-29l3-15 5-12 6-9 9-8 9-4z" fill="#232323" />
        <path transform="translate(829,153)" d="m0 0h53v127h-32v-99h-21z" fill="#232323" />
        <path transform="translate(763,178)" d="m0 0 9 1 6 4 4 5 3 11v32l-3 10-3 5-6 4-4 1h-8l-7-3-6-7-3-11v-31l4-13 7-6z" fill="#FDFDFE" />
      </svg>}



    </>
  );
};

IconSearch.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconSearch.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconSearch;
