import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import classNames from 'classnames';

import { injectIntl, intlShape } from '../../util/reactIntl';
import Form from '../../components/Form/Form';
import FieldKeywordInput from '../../components/FieldKeywordInput/FieldKeywordInput';
import { compose } from 'redux';
import debounce from 'lodash/debounce';

import ListKeyWord from './ListKeyWord';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

import css from './ArticleSearchForm.module.css';

const DEBOUNCE_WAIT_TIME = 300;
const KEY_CODE_ARROW_UP = 38;
const KEY_CODE_ARROW_DOWN = 40;
const KEY_CODE_ENTER = 13;
const KEY_CODE_ESC = 27;
const DIRECTION_UP = 'up';
const DIRECTION_DOWN = 'down';

const ArticleSearchFormComponent = props => {
  const { onSubmit, intl, onSearchArticlesByKeyword } = props;

  const [result, setResult] = useState([]);
  const [inFetching, setInFetching] = useState(false);
  const [highlightIndex, setHighlightIndex] = useState(-1);
  const [focusInput, setFocusInput] = useState(false);

  const history = useHistory();

  const prevValue = useRef(null);
  const input = useRef(null);
  const inputRef = useRef(null);
  const searchBarRef = useRef(null);

  const handleFocus = e => {
    setFocusInput(true);
    input.current?.onFocus(e);
    searchBarRef.current.classList.add(css.focusSearchbar);
  };

  const handleSelectSearchItem = selectedItem => {
    return history.push({
      pathname: createResourceLocatorString(
        'KnowledgeHubPage',
        routeConfiguration(),
        { slug: selectedItem.uid },
        {}
      ),
    });
  };

  const changeHighlight = direction => {
    setHighlightIndex(prevIndex => {
      let index;

      if (direction === DIRECTION_UP) {
        index = Math.max(0, prevIndex - 1);
      } else if (direction === DIRECTION_DOWN) {
        index = Math.min(result.length - 1, prevIndex + 1);
      }

      return index;
    });
  };

  const finalize = () => {
    setResult([]);
    setInFetching(false);
    setHighlightIndex(-1);
    setFocusInput(false);
    prevValue.current = null;
  };

  const handleOnBlur = () => {
    if (!inFetching) {
      setHighlightIndex(-1);
      setFocusInput(false);
      prevValue.current = null;
    }
    searchBarRef.current.classList.remove(css.focusSearchbar);
  };

  const onClearKeyword = form => {
    form.change('search', null);
  };

  const onKeyDown = form => e => {
    const keyCode = e.keyCode;
    switch (keyCode) {
      case KEY_CODE_ARROW_UP: {
        e.preventDefault();
        return changeHighlight(DIRECTION_UP);
      }

      case KEY_CODE_ARROW_DOWN: {
        e.preventDefault();
        return changeHighlight(DIRECTION_DOWN);
      }

      case KEY_CODE_ENTER: {
        document.getElementById('search').blur();
        e.preventDefault();
        const searchItem = result.length && highlightIndex >= 0 ? result[highlightIndex] : null;

        const text = searchItem ? searchItem.title : form.getState().values['search'];
        form.change('search', text);

        if (searchItem) {
          handleSelectSearchItem(searchItem);
        } else {
          onSubmit({ search: text });
        }

        return finalize();
      }

      case KEY_CODE_ESC:
        return finalize();

      default:
        break;
    }
  };

  const handleSearch = debounce(
    formState => {
      const { values, dirty } = formState;
      if (!dirty) return;
      if (typeof window === 'undefined') return;
      const { search } = values;
      if (!search || search.length < 2) {
        prevValue.current = search;
        setResult([]);
        setHighlightIndex(-1);
        return;
      }
      if (inFetching) return;

      if (prevValue.current !== search) {
        prevValue.current = search;
        setInFetching(true);
        onSearchArticlesByKeyword(search)
          .then(result => setResult(result))
          .finally(() => setInFetching(false));
      }
    },
    DEBOUNCE_WAIT_TIME,
    { leading: false, trailing: true }
  );

  const handleSelectEnd = searchItem => {
    handleSelectSearchItem(searchItem);
    finalize();
  };

  return (
    <FinalForm
      onSubmit={values => {
        onSubmit({ search: values.search });
      }}
      render={formRenderProps => {
        const {
          rootClassName,
          className,
          desktopInputRoot,
          isMobile,
          form,
          values,
        } = formRenderProps;
        const classes = classNames(rootClassName || css.root, className);
        const desktopInputRootClass = desktopInputRoot || css.desktopInputRoot;

        return (
          <Form
            className={classes}
            onSubmit={e => e.preventDefault()}
            enforcePagePreloadFor="SearchPage"
          >
            <div
              onClick={() => {
                inputRef.current.focus();
              }}
            >
              <FieldKeywordInput
                name="search"
                id="search"
                autoComplete="off"
                className={isMobile ? css.mobileInputRoot : desktopInputRootClass}
                iconClassName={isMobile ? css.mobileIcon : css.desktopIcon}
                inputClassName={isMobile ? css.mobileInput : css.desktopInput}
                placeholder={intl.formatMessage({ id: 'ArticleSearchForm.placeholder' })}
                type="text"
                onKeyDown={onKeyDown(form)}
                onFocus={handleFocus}
                onBlur={handleOnBlur}
                inputRef={inputRef}
                onClearKeyword={() => onClearKeyword(form)}
                searchBarRef={searchBarRef}
              />
            </div>
            {result.length > 0 && focusInput && values.search ? (
              <ListKeyWord
                className={css.resultBox}
                result={result}
                currentIndex={highlightIndex}
                onSelectEnd={handleSelectEnd}
                currentSearchValue={values.search}
              />
            ) : null}

            <FormSpy onChange={handleSearch} subscription={{ values: true, dirty: true }} />
          </Form>
        );
      }}
    />
  );
};

const { func, string, bool } = PropTypes;

ArticleSearchFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  desktopInputRoot: null,
  isMobile: false,
};

ArticleSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  desktopInputRoot: string,
  onSubmit: func,
  isMobile: bool,
  onSearchArticlesByKeyword: func,
  handleSelectSearchItem: func,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(injectIntl)(ArticleSearchFormComponent);
