import React from 'react';
import { string, object, bool } from 'prop-types';
import classNames from 'classnames';

import css from './Agreement.module.css';
import { stringifyDateToISO, timestampToDate } from '../../util/dates';

const Agreement = props => {
  const {
    className,
    rootClassName,
    currentListing,
    currentUser,
    isShowNewClause
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const { agreementDetails, locationToSearch } = currentListing.attributes.publicData || {};
  const { firstName, lastName, agreementDate, listingLocation, email } = agreementDetails || {};
  const { email: userEmail, profile } = currentUser?.attributes || {};
  const { firstName: userFirstName, lastName: userLastName } = profile || {};
  
  const convertedAgreementDate = agreementDate
    ? stringifyDateToISO(timestampToDate(agreementDate))
    : stringifyDateToISO(new Date());

  const fullName = `${firstName || userFirstName} ${lastName || userLastName}`;

  return (
    <div className={classes}>
      <div className={css.headerSection}>
        <p className={css.title}>SUBCONTRACTORS AGREEMENT</p>
        between<br/>
        Lanterne Rouge Online Pte. Ltd. &amp; &nbsp;
        {fullName}.<br/>
        THIS AGREEMENT is dated <b>{convertedAgreementDate}</b> (the “<b>Agreement</b>”).
      </div>
      <div className={css.partiesSection}>
        <p className={css.title}>Parties</p>
        <ol>
          <li>
            <b>Lanterne Rouge Online Pte. Ltd.</b>
            &nbsp; (the “<b>Broker</b>”) operating the trademark BOLDLY (the “Marketplace”)
            of 33 UBI AVENUE 3 #08-61 VERTEX SINGAPORE 408868.
          </li>
          <li>
            <b>{fullName}</b> with the Email Address
            &nbsp;<b>{email || userEmail}</b> of <b>{listingLocation || locationToSearch}</b> (the “<b>Coach</b>”).
          </li>
        </ol>
      </div>
      <div className={css.backgroundSection}>
        <p className={css.title}>Background</p>
        The Broker wishes to engage the Coach on a subcontractor basis for services rendered to
        Clients, whether through the Marketplace or not, on the following terms.
      </div>
      <div className={css.agreedTermSection}>
        <p className={css.title}>Agreed Terms</p>
        <ol>
          <li>
            <b>Key terms</b>
            <ol>
              <li>
                The following definitions and rules of interpretation apply in this Agreement.
              </li>
              <b>Agreement: </b>this agreement and its schedules. <br/>
              <b>Business of the Broker and the Marketplace: </b>
              technology enabling the delivery of professional coaching, mentoring, career management,
              and related coaching and consulting services. <br/>
              <b>Business Opportunities: </b>
              any coaching or consulting opportunities which the Coach becomes aware of directly through
              the Marketplace or Broker, relating to the current Client during the Engagement for which
              the Coach is contracted, which relate to the Business of the Broker (or any Group Company)
              with that Client. <br/>
              <b>Broker Property: </b>
              all documents, materials, records, correspondence, and information
              (on whatever media and wherever located) relating to the Business
              or affairs of the Broker and Marketplace or its Clients provided for the
              Coach's use by the Broker during the Engagement, and any data or documents
              (including copies) produced, maintained or stored by the Coach on the Coach's
              computer systems or other electronic equipment during the Engagement.<br/>
              <b>Coachee and Company: </b>
              any end-users and receivers of service via the Marketplace or otherwise,
              to whom the Coach and Broker will deliver the Engagement.<br/>
              <b>Client: </b>
              the individual working within the Company who engages the Broker and Coach
              services on behalf of the Coachee and Company.<br/>
              <b>Commencement Date: </b>as stated by the date of this agreement.<br/>
              <b>Engagement: </b>
              the engagement of the Coach by the Broker on the terms of this Agreement.<br/>
              <b>Group: </b>
              the Broker, together with any of its subsidiaries, its holding company,
              and fellow subsidiaries of its holding company,
              and <b>Group Company </b>means any one of them.<br/>
              <b>Intellectual Property Rights: </b>
              patents, utility models, rights to inventions, copyright and neighbouring
              and related rights, trademarks and service marks, business names and domain names,
              rights in get-up and trade dress, goodwill and the right to sue for passing off or
              unfair competition, rights in designs, database rights, rights to use, and protect
              the confidentiality of, confidential information (including know-how and trade secrets),
              and all other intellectual property rights, in each case whether registered or
              unregistered and including all applications and rights to apply for and be granted,
              renewals or extensions of, and rights to claim priority from, such rights and all
              similar or equivalent rights or forms of protection which subsist or will subsist
              now or in the future in any part of the world.<br/>
              <b>Services:</b> the services to be provided by the Coach in a subcontractor
              capacity for the Broker (or any Group Company), including chemistry meeting
              and delivery of coaching meetings with Coachees, stakeholder management of
              Client where applicable, assessment debrief where applicable, delivery of
              reporting for mid-engagement, end-of-engagement, and engagement-completion,
              and any other activities deemed necessary to the delivery of professional coaching services.<br/>
              <b>Termination Date: </b>
              the date of termination of this Agreement, howsoever arising.<br/>
              <b>Works: </b>
              all records, reports, documents, papers, drawings, designs, transparencies,
              photos, graphics, logos, typographical arrangements, software, and all other
              materials in whatever form, including but not limited to hard copy and electronic
              form, prepared by the Coach in the provision of the Services.<br/>
              <li>
                Clause, Schedule and paragraph headings shall not affect the interpretation of
                this Agreement.
              </li>
              <li>
                The Schedules form part of this Agreement and shall have effect as if set out
                in full in the body of this Agreement. Any reference to this Agreement includes
                the Schedules.
              </li>
              <li>
                Unless the context otherwise requires, words in the singular shall include
                the plural and, in the plural, shall include the singular.
              </li>
              <li>
                A reference to writing or written includes fax and e-mail.
              </li>
              <li>
                Any words following the terms including, include, in particular, for example
                or any similar expression shall be construed as illustrative and shall not
                limit the sense of the words, description, definition, phrase or term preceding
                those terms.
              </li>
            </ol>
          </li>
          <li>
            <b>Term of engagement</b>
            <ol>
              <li>
                The Broker shall engage the Coach and the Coach shall provide the Services on
                the terms of this Agreement.
              </li>
              <li>
                The Engagement shall commence on the Commencement Date and shall continue unless
                and until terminated as provided by the terms of this Agreement.
              </li>
            </ol>
          </li>
          <li>
            <b>Duties and obligations</b>
            <ol>
              <li>
                During the Engagement the Coach shall:
                <ol>
                  <li>
                    provide the Services with all due care, skill and ability and use their
                    best endeavours to promote the interests of the Broker (or any Group Company);
                  </li>
                  <li>
                    unless prevented by ill health or accident, devote sufficient time to
                    the carrying out of the Services together with such additional time if any
                    as may be necessary for their proper performance; and
                  </li>
                  <li>
                    promptly give to the Broker all such information and reports as it may
                    reasonably require in connection with matters relating to the provision
                    of the Services or the Business of the Broker (or any Group Company).
                  </li>
                </ol>
              </li>
              <li>
                If the Coach is unable to provide the Services due to illness or injury,
                they shall advise the Broker of that fact as soon as reasonably practicable.
                For the avoidance of doubt, no fee shall be payable in accordance with Clause 5
                in respect of any period during which the Services are not provided.
              </li>
              <li>
                The Coach shall use reasonable endeavours to ensure that they are available
                on reasonable notice to provide such assistance or information as the Broker
                may require.
              </li>
              <li>
                Unless they have been specifically authorised to do so by the Broker in
                writing, the Coach shall not:
                <ol>
                  <li>
                    have any authority to incur any expenditure in the name of or for the account
                    of the Broker; or
                  </li>
                  <li>
                    hold themselves out as having authority to bind the Broker.
                  </li>
                </ol>
              </li>
              <li>
                The Coach shall comply with all reasonable standards of safety and comply
                with the Broker's and Client’s health and safety procedures from time to time in
                force at the premises where the Services are provided and report to the Broker any
                unsafe working conditions or practices.
              </li>
              <li>
                The Coach undertakes to the Broker that during the Engagement they shall
                take all reasonable steps to offer (or cause to be offered) to the Broker any
                Business Opportunities as soon as practicable after the same shall have come to
                their knowledge and in any event before the same shall have been offered by the
                Coach (or caused by the Coach to be offered) to any other party provided that
                nothing in this clause shall require the Coach to disclose any Business
                Opportunities to the Broker if to do so would result in a breach by the Coach
                of any obligation of confidentiality or of any fiduciary duty owed by the Coach
                to any third party.
              </li>
              <li>
                The Coach may use a third party to perform any administrative, clerical or secretarial
                functions which are reasonably incidental to the provision of the Services provided
                that:
                <ol>
                  <li>
                    the Broker will not be liable to bear the cost of such functions;
                  </li>
                  <li>
                    all insurances, compensation, confidentiality and contractual obligations are met
                    by the Coach; and.
                  </li>
                  <li>
                    at the Broker's request the third party shall be required to enter
                    into direct undertakings with the Broker, including with regard to confidentiality.
                  </li>
                </ol>
              </li>
              <li>
                The Coach shall:
                <ol>
                  <li>
                    comply with all applicable laws, regulations and sanctions relating to
                    anti-bribery and anti-corruption;
                  </li>
                  <li>
                    comply with all terms agreed between the Coach and Coachee, or Coach and Client;
                  </li>
                  <li>
                    promptly report to the Broker any request or demand for any undue financial
                    or other advantage of any kind received by the Coach in connection with the
                    performance of this Agreement; and
                  </li>
                  <li>
                    ensure that all persons associated with the Coach or other persons
                    who are performing services or providing goods in connection with this Agreement
                    comply with this Clause 3.8.
                  </li>
                </ol>
              </li>
              <li>
                Failure to comply with Clause 3.8 may result in the immediate termination
                of this Agreement.
              </li>
            </ol>
          </li>
          <li>
            <b>Fees</b>
            <ol>
              <li>
                Subject to the performance of the Services by the Coach to the satisfaction of
                the Broker,
                <b> the Broker shall pay the Coach a fee equal to 90% of the accounts billed
                  and collected on the Coaches behalf through the Marketplace.
                </b>
                In other words, a 10% margin of all billing through the Marketplace will be
                deducted by the Broker. The Coach shall establish their hourly rate and determine
                the hours of work they complete in agreement with the Coachee or Client, and
                therefore the Brokers fee in real terms is dependent on work agreed and
                completed by the Coach. If fewer hours are worked than agreed, the fee will
                be adjusted based on the hours worked.
              </li>
              <li>
                Fees will be paid monthly, in arrears, for coaching hours completed and
                confirmed by both the Coach and Coachee or Client, upon submission of an
                invoice by the Coach to the Broker.
              </li>
              <li>
                If the Coach is requested to extend their services to the Client off
                the marketplace in exchange for company shares, a 10% allocation of these
                shares will be applicable to the Broker in recognition of the client introduction.
                The Coach should contact connect@boldly.app to discuss.
              </li>
              <li>
                If the Coach is in a position to sell ancillary services to the Client once the introduction has been made, including but not limited to training courses, assessments, or group and team coaching etc, a 10% margin will still be applicable to the Broker in recognition of the client introduction. The Coach should contact connect@boldly.app to discuss.
              </li>
              {isShowNewClause && <li>Any obligations or choices the coach has to pay local taxes, bank fees, travel, online meeting subscriptions, accreditation, or any other costs associated with the delivery of the service are the responsibility of the coach, and should be taken into account in the hourly rate agreed for the work. No additional responsibility to be borne by the Broker for costs incurred by the Coach as part of delivery of any work secured through the platform.</li>}
            </ol>
          </li>
          <li>
            <b>Other activities</b>
            <ol>
              <li>
                Nothing in this Agreement shall prevent the Coach from being engaged, concerned or having any financial interest in any Capacity in any other business, trade, profession or occupation during the Engagement provided that:
                <ol>
                  <li>
                    such activity does not cause a breach of any of the Coach's obligations under this Agreement; and
                  </li>
                  <li>
                    the Coach shall give equal priority to the provision of the Services to the Broker and Client alongside any other business activities undertaken by the Coach during the course of the Engagement.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <b>Confidential information</b>
            <ol>
              <li>
                The Coach acknowledges that in the course of the Engagement they will have access to Confidential Information. The Coach has therefore agreed to accept the restrictions in this clause.
              </li>
              <li>
                The Coach shall not (except in the proper course of their duties), either during the Engagement or at any time after the Termination Date, use or disclose to any third party (and shall use their best endeavours to prevent the publication or disclosure of) any Confidential Information. This restriction does not apply to:
                <ol>
                  <li>
                    any use or disclosure authorised by the Broker or required by law; or
                  </li>
                  <li>
                    any information which is already in, or comes into, the public domain otherwise than through the Coach's unauthorised disclosure.
                  </li>
                </ol>
              </li>
              <li>
                At any stage during the Engagement, the Coach will promptly on request return all and any Broker Property in their possession to the Broker.
              </li>
            </ol>
          </li>
          <li>
            <b>Intellectual property</b>
            <ol>
              <li>
              The Broker acknowledges that no intellectual property claim exists between any parties of this Agreement with regards to coaching methodology, training content or the like.
              </li>
              <li>
                The Coach agrees to indemnify the Broker and keep it indemnified at all times against all or any costs, claims, damages or expenses incurred by the Broker, or for which the Broker may become liable, with respect to any intellectual property infringement claim or other claim relating to the Works supplied by the Coach to the Broker during the course of providing the Services.
              </li>
              <li>
                The Coach acknowledges that, except as provided by law, no further fees or compensation other than those provided for in this Agreement are due or may become due to the Coach in respect of the performance of their obligations under this clause.
              </li>
            </ol>
          </li>
          <li>
            <b>Termination</b>
            <ol>
              <li>
                Notwithstanding the provisions of Clause 3.2, the Broker may terminate the Engagement with immediate effect with no liability to make any further payment to the Coach (other than in respect of amounts accrued before the Termination Date) if at any time the Coach:
                <ol>
                  <li>
                    commits any gross misconduct affecting the Business of the Broker & the Marketplace (or any Group Company);
                  </li>
                  <li>
                    commits any serious or repeated breach or non-observance of any of the provisions of this Agreement or refuses or neglects to comply with any reasonable and lawful directions of the Broker;
                  </li>
                  <li>
                    is convicted of any criminal offense or commits any material breach of applicable laws;
                  </li>
                  <li>
                    is in the reasonable opinion of the Broker negligent or incompetent in the performance of the Services;
                  </li>
                  <li>
                    is incapacitated (including by reason of illness or accident) from providing the Services for an extended period; or
                  </li>
                  <li>
                    commits any fraud or dishonesty or acts in any manner which in the opinion of the Broker brings or is likely to bring the Coach or the Broker (or any Group Company) into disrepute or is materially adverse to the interests of the Broker (or any Group Company).
                  </li>


                </ol>
              </li>
              <li>
                The rights of the Broker under this clause are without prejudice to any other rights that it might have at law to terminate the Engagement or to accept any breach of this Agreement on the part of the Coach as having brought the agreement to an end. Any delay by the Broker in exercising its rights to terminate shall not constitute a waiver of these rights.
              </li>
            </ol>
          </li>
          <li>
            <b>Obligations on termination</b>
            <ol>
              <li>
                On the Termination Date the Coach shall:
              <ol>
                <li>
                  irretrievably delete any information relating to the Business of the Broker (or any Group Company) stored on any magnetic or optical disk or memory and all matter derived from such sources which is in their possession or under their control outside the premises of the Broker. For the avoidance of doubt, the contact details of business contacts made during the Engagement are regarded as Confidential Information, and as such, must be deleted from personal social or professional networking accounts; and
                </li>
                <li>
                  provide a signed statement that they have complied fully with their obligations under this clause.
                </li>
              </ol>
              </li>
            </ol>
          </li>
          <li>
            <b>Status</b><br/>
            The relationship of the Coach to the Broker will be that of independent contractor and nothing in this Agreement shall render them an employee, worker, agent or partner of the Broker and the Coach shall not hold themselves out as such.
          </li>
          <li>
            <b>Force majeure</b><br/>
            Neither party shall be in breach of this Agreement nor liable for delay in performing, or failure to perform, any of its obligations under this Agreement if such delay or failure result from events, circumstances or causes beyond its reasonable control. In such circumstances the time for performance shall be extended by a period equivalent to the period during which performance of the obligation has been delayed or failed to be performed. If the period of delay or non-performance continues for one (1) month, the party not affected may terminate this Agreement by giving 1 (one) week's written notice to the affected party.
          </li>
          <li>
            <b>Notices</b>
            <ol>
              <li>
                All notices required or permitted by this Agreement shall be in writing and in the English language and shall be sent to the recipient by hand, by courier, by registered post, by fax or by email at its address set out above (or such other address as notified by the recipient to other parties from time to time), or as otherwise directed by the recipient by notice given in accordance with this clause.
              </li>
              <li>
                Notices shall be deemed to have been duly given and received if sent by email, one (1) hour after the email is sent (unless a return email is received by the sender within that period stating that the addressee's email address is wrong or that the message cannot be delivered).
              </li>
            </ol>
          </li>
          <li>
            <b>Entire agreement</b>
            <ol>
              <li>
                This Agreement constitutes the entire agreement between the parties and supersedes and extinguishes all previous agreements, promises, assurances, warranties, representations and understandings between them, whether written or oral, relating to its subject matter.
              </li>
              <li>
                Each party agrees that it shall have no remedies in respect of any statement, representation, assurance or warranty (whether made innocently or negligently) that is not set out in this Agreement. Each party agrees that it shall have no claim for innocent or negligent misrepresentation based on any statement in this Agreement.
              </li>
            </ol>
          </li>
          <li>
            <b>Right of third parties</b><br/>
            This Agreement is personal to the parties. The provisions of the Contracts (Rights of Third Parties) Act (Cap 53B) do not apply to this Agreement. No person who is not a party to this Agreement (whether or not such person is named, referred to, or otherwise identified, or form part of a class of persons so named, referred to or identified in this Agreement) shall have any right under the Contracts (Rights of Third Parties) Act to enforce this Agreement or to enjoy the benefit of any term of this Agreement.
          </li>
          <li>
            <b>Governing law</b><br/>
            This Agreement and any dispute or claim arising out of or in connection with it or its subject matter or formation (including non-contractual disputes or claims) shall be governed by and construed in accordance with the laws of the Republic of Singapore.
          </li>
        </ol>
      </div>
      <b>This Agreement has been entered into on the date stated at the beginning of it.</b>
    </div>
  );
};

Agreement.defaultProps = {
  className: null,
  rootClassName: null,
  currentListing: null,
  currentUser: null,
  isShowNewClause: false,
};

Agreement.propTypes = {
  className: string,
  rootClassName: string,
  currentListing: object,
  currentUser: object,
  isShowNewClause: bool,
};

export default Agreement;
