import React from 'react';
import classNames from 'classnames';
import { func, string, bool } from 'prop-types';

import css from './ToggleSwitch.module.css';

const ToggleSwitch = props => {
  const {
    rootClassName,
    className,
    onChange,
    option,
    checked,
    addParamsWhenTurnOff,
  } = props;

  const handleOnChange = e => {
    let checkedOption;
    if (addParamsWhenTurnOff) {
      checkedOption = e.target.checked ? null : option;
    } else {
      checkedOption = e.target.checked ? option : null;
    }
    onChange(checkedOption);
  };

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <label className={css.switch}>
        <input type="checkbox" onChange={handleOnChange} checked={checked} />
        <span className={classNames(css.slider, css.round)}></span>
      </label>
    </div>
  );
};

ToggleSwitch.defaultProps = {
  rootClassName: null,
  className: null,
  onChange: null,
  urlParam: null,
  option: null,
  checked: null,
  addParamsWhenTurnOff: false,
};

ToggleSwitch.propTypes = {
  rootClassName: string,
  className: string,
  onChange: func,
  urlParam: string,
  option: string,
  checked: bool,
  addParamsWhenTurnOff: bool,
};

export default ToggleSwitch;
