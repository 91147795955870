import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconChemistry.module.css';

const IconChemistry = props => {
  const { className, rootClassName, isCoachListing } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <>
      {isCoachListing ? <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="26.303"
        height="30"
        viewBox="0 0 26.303 30"
      >
        <path
          d="M209.9,0a1.875,1.875,0,0,1,0,3.75v8.842L216.8,23.8a4.067,4.067,0,0,1-3.463,6.2H195.219a4,4,0,0,1-3.463-6.2L198.6,12.592V3.75a1.909,1.909,0,0,1-1.875-1.875A1.909,1.909,0,0,1,198.6,0Zm-7.5,3.75v9.375a1.892,1.892,0,0,1-.275.984l-2.859,4.641h10.02l-2.859-4.641a1.892,1.892,0,0,1-.275-.984V3.75Z"
          transform="translate(-191.1)"
          fill="#fff"
        />
      </svg> : <svg version="1.1" viewBox="0 0 1080 1080" width="1280" height="380" xmlns="http://www.w3.org/2000/svg">
        <path transform="translate(789,41)" d="m0 0h26l24 3 20 5 21 8 23 12 17 12 15 13 10 10 11 14 9 13 9 16 7 16 6 18 5 23 1 8v41l-3 19-6 22-6 15-8 16-8 14-9 12-12 14-10 10-11 9-14 10-16 9-8 4h-2l11 24 11 27 9 27 7 27 6 31 3 24 1 13v56l-3 30-5 30-8 32-11 33-11 26-12 25-10 17-12 19-12 17-11 14-11 13-11 12-25 25-11 9-8 7-13 10-20 14-18 11-18 10-22 11-27 11-30 10-27 7-26 5-33 4-15 1h-41l-26-2-33-5-28-6-27-8-25-9-25-11-24-12-17-10-24-16-12-9-14-11-10-9-8-7-8-8-6-5-7-8-8-8-9-11-10-12-13-18-10-15-10-17-10-18-10-21-11-27-9-28-6-23-6-31-3-23-1-14v-58l2-22 5-32 7-30 9-29 10-26 12-26 9-17 12-20 8-12 10-14 14-18 9-10 7-8 9-9 4-5 8-7 8-8 11-9 12-10 18-13 20-13 17-10 27-14 26-11 25-9 28-8 34-7 34-4 14-1h42l34 3 26 4 30 7 7 2 1-8 5-17 10-23 11-19 13-17 12-13 13-12 18-13 16-9 17-8 21-7 20-4z" fill="#7C9AFE" />
        <path transform="translate(789,41)" d="m0 0h26l24 3 20 5 21 8 23 12 17 12 15 13 10 10 11 14 9 13 9 16 7 16 6 18 5 23 1 8v41l-3 19-6 22-6 15-8 16-8 14-9 12-12 14-10 10-11 9-14 10-16 9-17 8-22 7-12 3-13 2-12 1h-24l-18-2-15-3-20-6-19-8-15-8-12-8-13-10-15-14-9-10-11-15-9-15-11-23-8-26-3-16-1-9v-38l4-24 6-21 10-23 11-19 13-17 12-13 13-12 18-13 16-9 17-8 21-7 20-4z" fill="#FDFDFE" />
        <path transform="translate(328,450)" d="m0 0h6l28 17 21 12 11 7 2 2v5l-6 6 2 4 10 5 6 1h24l10-8 12-5 18-4 16-2 15-1h24l18 2 26 5 16 1 12-1-3-4 1-5 9-6 29-17 23-14h7l15 14 7 7 13 17 10 15 9 16 12 25 11 29 2 6v7l-9 6-49 29-5 3-5-1-3-4-12 11-15 10v2l3 7v14l-5 10-8 7-5 2-7 1-2 13-6 9-8 6-5 2h-13l-2 10-6 8-5 4-8 3h-10l-2-1-1 7-6 8-7 5-8 2-18-1-25-5-16-5-9 10-9 5-4 1h-10l-10-4-7-6-4-7-2-4-6 2h-11l-11-4-7-6-4-8-2-7h-15l-10-5-7-8-3-10 1-11 1-3-11-2-8-6-5-6-3-9v-9l3-9 4-6-4-8-4-5-8 1-54-32-8-5-1-7 8-23 9-21 9-19 10-17 11-16 9-11 12-13 10-9z" fill="#FDFDFE" />
        <path transform="translate(379,508)" d="m0 0 4 2 5 4 9 4 10 2 13 1-2 4-12 14-9 14-4 10v14l5 10 5 4 7 3h14l14-7 10-8 16-15 10-9 10-5 3-1h7l23 13 28 17 23 15 14 10 17 13 22 18 13 11 4 5v10l-4 6-5 4h-10l-21-9-28-14-23-13-22-12-12-7h-5l-2 2v5l9 6 27 15 22 12 23 12 17 8 7 3 1 2v9l-3 6-6 5-3 1h-9l-11-5-24-9-31-15-24-14-11-7h-6l-2 5 1 3 25 15 22 12 21 10 26 10 2 4-2 9-7 6-2 1h-8l-7-4-20-6-20-8-19-10-18-11-5 1-1 5 2 4-10-2 4-10 1-9-2-9-6-10-6-4-6-2-7-1 1-6v-10l-4-10-5-6-10-5h-13l-10 5-8 8-8-12-8-5-8-2-11 1-8 4-12 13-4-4-7-8-3-3 1-5 8-21 11-24 10-18 12-17 9-11z" fill="#7C9AFE" />
        <path transform="translate(503,500)" d="m0 0h24l22 3 21 4 18 1 23-2 12 13 12 16 9 15 12 23 10 24 3 9-11 12-14 10-7 4-4-1-22-18-18-14-20-14-20-13-28-17-26-15-3-2-11 1-11 4-10 7-12 11-7 7-11 9-10 6-2 1h-10l-5-3-4-6v-10l7-14 9-11 22-24 8-6 10-4 14-3 16-2z" fill="#7C9AFE" />
        <path transform="translate(743,150)" d="m0 0h10l14 3 10 5 8 7 7 12 4 13 2 13v24l-3 17-5 12-5 8-5 5-5 4-8 4-8 2h-22l-13-4-10-7-7-9-5-11-4-18v-30l3-15 5-12 6-9 8-7 11-5z" fill="#232323" />
        <path transform="translate(331,462)" d="m0 0 10 5 40 24-6 7-11 11-13 17-13 21-8 15-10 23-8 21-2 3-6-2-28-17-16-9 3-11 11-27 11-23 12-20 10-13 12-14 10-10z" fill="#7C9AFE" />
        <path transform="translate(660,462)" d="m0 0 4 2 15 15 11 14 12 18 14 27 11 26 5 14v3l-29 17-20 12-2-2-10-26-9-20-10-19-9-14-12-16-12-14-7-6 1-3 32-19z" fill="#7C9AFE" />
        <path transform="translate(851,147)" d="m0 0h18l12 3 10 6 7 8 4 10v16l-4 10-7 8-5 4 9 6 6 7 3 8 1 12-2 11-5 10-9 8-8 4-8 2-16 1-13-2-10-4-9-6-6-7-4-10-1-5v-8h29l4 10 4 4 5 2h7l7-3 4-6v-11l-3-5-8-4-16-1v-25l16-2 6-3 3-6-1-9-5-5-2-1h-10l-5 2-3 10h-30v-7l3-10 5-8 9-8 9-4z" fill="#232323" />
        <path transform="translate(411,624)" d="m0 0h9l6 4 4 6 1 9-4 9-14 17-11 14-13 16-7 8-4 2h-11l-5-3-5-6-1-8 3-8 14-17 11-14 13-16 7-8 5-4z" fill="#7C9AFE" />
        <path transform="translate(742,179)" d="m0 0h11l8 4 4 6 3 12v28l-3 12-7 8-6 2h-9l-8-4-5-8-2-8v-32l3-11 5-6z" fill="#FDFDFE" />
        <path transform="translate(436,662)" d="m0 0h10l6 4 4 7v11l-4 6-11 14-13 16-7 9-5 4-3 1h-7l-8-3-5-6v-12l7-11 11-13 11-14 8-10z" fill="#7C9AFE" />
        <path transform="translate(362,618)" d="m0 0 8 1 6 4 4 6v11l-6 9-14 17-5 4-5 2-9-1-8-7-2-5v-7l3-6 13-16 7-8 4-3z" fill="#7C9AFE" />
        <path transform="translate(478,702)" d="m0 0 5 2 17 10 17 8 29 10v7l-6 7-10 2-19-2-21-5-7-3 3-8v-14l-5-10z" fill="#7C9AFE" />
        <path transform="translate(456,707)" d="m0 0h9l6 4 4 5 1 2v10l-6 9-11 13-7 3h-7l-6-3-5-6-1-2v-10l4-6 11-14 5-4z" fill="#7C9AFE" />
      </svg>}

    </>
  );
};

const { string } = PropTypes;

IconChemistry.defaultProps = {
  className: null,
  rootClassName: null,
};

IconChemistry.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconChemistry;
