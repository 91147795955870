import React from 'react';
import classNames from 'classnames';
import { func, string } from 'prop-types';
import css from './IconCircleClose.module.css';

const IconCircleClose = props => {
  const { className, rootClassName, onClick } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      onClick={onClick}
      className={classes}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="#212121" />
      <path
        d="M10.3965 10.383C10.9279 9.85156 11.7563 9.89056 12.2581 10.3923L15.9979 14.1322L19.7381 10.3919C20.2541 9.87595 21.0912 9.87486 21.6082 10.3919C22.1245 10.9082 22.1245 11.7457 21.6082 12.262L17.868 16.0023L21.6087 19.7429C22.1245 20.2606 22.1225 21.0983 21.6074 21.6135C21.0906 22.1284 20.2525 22.1289 19.7368 21.6113L16.0016 17.8686L12.2577 21.6126C11.7406 22.1297 10.9035 22.1286 10.3875 21.6126C9.87082 21.0959 9.87082 20.2592 10.3875 19.7425L14.1333 15.9967L10.396 12.2527C9.87954 11.7357 9.88099 10.8985 10.3965 10.383Z"
        fill="white"
      />
    </svg>
  );
};

IconCircleClose.defaultProps = {
  className: null,
  rootClassName: null,
  onClick: () => {},
};

IconCircleClose.propTypes = {
  className: string,
  rootClassName: string,
  onClick: func,
};

export default IconCircleClose;
