import { storableError } from '../../util/errors';
import { client } from '../../prismic';

// ================ Action types ================ //

export const FETCH_CASE_STUDY_REQUEST = 'app/CaseStudyPage/FETCH_CASE_STUDY_REQUEST';
export const FETCH_CASE_STUDY_SUCCESS = 'app/CaseStudyPage/FETCH_CASE_STUDY_SUCCESS';
export const FETCH_CASE_STUDY_ERROR = 'app/CaseStudyPage/FETCH_CASE_STUDY_ERROR';

export const FETCH_RELATED_CASE_STUDY_REQUEST =
  'app/CaseStudyPage/FETCH_RELATED_CASE_STUDY_REQUEST';
export const FETCH_RELATED_CASE_STUDY_SUCCESS =
  'app/CaseStudyPage/FETCH_RELATED_CASE_STUDY_SUCCESS';
export const FETCH_RELATED_CASE_STUDY_ERROR = 'app/CaseStudyPage/FETCH_RELATED_CASE_STUDY_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchCaseStudyInProgress: false,
  fetchCaseStudyError: null,
  caseStudy: null,
  relatedCaseStudies: [],
};

export default function caseStudyPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_CASE_STUDY_REQUEST:
      return {
        ...state,
        relatedCaseStudies: [],
        caseStudy: null,
        fetchCaseStudyInProgress: true,
        fetchCaseStudyError: null,
      };
    case FETCH_CASE_STUDY_SUCCESS: {
      return {
        ...state,
        fetchCaseStudyInProgress: false,
        caseStudy: payload,
      };
    }
    case FETCH_CASE_STUDY_ERROR:
      console.error(payload);
      return { ...state, fetchCaseStudyInProgress: false, fetchCaseStudyError: payload };

    case FETCH_RELATED_CASE_STUDY_SUCCESS: {
      return {
        ...state,
        relatedCaseStudies: payload,
      };
    }

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchCaseStudyRequest = searchParams => ({
  type: FETCH_CASE_STUDY_REQUEST,
  payload: searchParams,
});
const fetchCaseStudySuccess = response => ({
  type: FETCH_CASE_STUDY_SUCCESS,
  payload: response,
});
const fetchCaseStudyError = e => ({
  type: FETCH_CASE_STUDY_ERROR,
  error: true,
  payload: e,
});

const fetchRelatedCaseStudiesSuccess = response => ({
  type: FETCH_RELATED_CASE_STUDY_SUCCESS,
  payload: response,
});

// ================ Thunks ================ //

export const fetchCaseStudyByID = slug => (dispatch, getState, sdk) => {
  dispatch(fetchCaseStudyRequest());

  const params = {
    fetchLinks: [
      'case_study.title_of_article',
      'case_study.hero_image',
      'case_study.writer_name',
      'case_study.post_date',
    ],
  };

  return client
    .getByUID('case_study', slug, params)
    .then(response => {
      dispatch(fetchCaseStudySuccess(response));
      
      // Get content relationship data
      const { related_case_studies } = response.data;
      const relatedPosts = related_case_studies.map(item => item.case_study).filter(caseStudy => caseStudy.id);
      dispatch(fetchRelatedCaseStudiesSuccess(relatedPosts));
    })
    .catch(e => {
      dispatch(fetchCaseStudyError(storableError(e)));
    });
};

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const { slug } = params;

  return dispatch(fetchCaseStudyByID(slug));
};
