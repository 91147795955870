import classNames from 'classnames';
import { string } from 'prop-types';
import React from 'react';
import css from './IconCoachList.module.css';

const IconCoachList = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_175_1038)">
        <path
          d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
          fill="url(#paint0_linear_175_1038)"
        />
        <path
          d="M27.1232 16.2739H17.2602C17.0059 16.2739 16.762 16.1729 16.5821 15.9931C16.4023 15.8133 16.3013 15.5694 16.3013 15.315C16.3013 15.0607 16.4023 14.8168 16.5821 14.637C16.762 14.4572 17.0059 14.3561 17.2602 14.3561H27.1232C27.3775 14.3561 27.6214 14.4572 27.8012 14.637C27.9811 14.8168 28.0821 15.0607 28.0821 15.315C28.0821 15.5694 27.9811 15.8133 27.8012 15.9931C27.6214 16.1729 27.3775 16.2739 27.1232 16.2739Z"
          fill="white"
        />
        <path
          d="M13.1507 16.9863C14.0585 16.9863 14.7945 16.2503 14.7945 15.3424C14.7945 14.4346 14.0585 13.6986 13.1507 13.6986C12.2428 13.6986 11.5068 14.4346 11.5068 15.3424C11.5068 16.2503 12.2428 16.9863 13.1507 16.9863Z"
          fill="white"
        />
        <path
          d="M27.1232 21.5238H17.2602C17.0059 21.5238 16.762 21.4228 16.5821 21.243C16.4023 21.0631 16.3013 20.8192 16.3013 20.5649C16.3013 20.3106 16.4023 20.0667 16.5821 19.8869C16.762 19.707 17.0059 19.606 17.2602 19.606H27.1232C27.3775 19.606 27.6214 19.707 27.8012 19.8869C27.9811 20.0667 28.0821 20.3106 28.0821 20.5649C28.0821 20.8192 27.9811 21.0631 27.8012 21.243C27.6214 21.4228 27.3775 21.5238 27.1232 21.5238Z"
          fill="white"
        />
        <path
          d="M13.1507 22.2362C14.0585 22.2362 14.7945 21.5002 14.7945 20.5923C14.7945 19.6845 14.0585 18.9485 13.1507 18.9485C12.2428 18.9485 11.5068 19.6845 11.5068 20.5923C11.5068 21.5002 12.2428 22.2362 13.1507 22.2362Z"
          fill="white"
        />
        <path
          d="M13.1507 27.7156C14.0585 27.7156 14.7945 26.9796 14.7945 26.0718C14.7945 25.1639 14.0585 24.4279 13.1507 24.4279C12.2428 24.4279 11.5068 25.1639 11.5068 26.0718C11.5068 26.9796 12.2428 27.7156 13.1507 27.7156Z"
          fill="white"
        />
        <path
          d="M17.2602 27.0033C17.0061 27.0033 16.7625 26.9025 16.5827 26.723C16.4029 26.5435 16.3017 26.3 16.3013 26.046C16.3011 25.92 16.3257 25.7951 16.3738 25.6786C16.4219 25.562 16.4925 25.4561 16.5815 25.3669C16.6706 25.2777 16.7764 25.2069 16.8928 25.1586C17.0093 25.1103 17.1341 25.0855 17.2602 25.0855L25.4794 25.0685C25.7337 25.0685 25.9776 25.1695 26.1574 25.3493C26.3372 25.5292 26.4383 25.7731 26.4383 26.0274C26.4383 26.2817 26.3372 26.5256 26.1574 26.7054C25.9776 26.8853 25.7337 26.9863 25.4794 26.9863L17.2602 27.0033Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_175_1038"
          x1="7"
          y1="3.8"
          x2="32.88"
          y2="33.84"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7C9BFF" />
          <stop offset="1" stopColor="#FC4756" />
        </linearGradient>
        <clipPath id="clip0_175_1038">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

IconCoachList.defaultProps = {
  className: null,
  rootClassName: null,
};

IconCoachList.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconCoachList;
