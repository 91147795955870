import * as prismic from '@prismicio/client';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { client } from '../../prismic';
import { FOR_CLIENT_ARTICLE } from '../../marketplace-custom-config';

// ================ Action types ================ //

export const FETCH_ARTICLES_REQUEST = 'app/KnowledgeHubNavigationPage/FETCH_ARTICLES_REQUEST';
export const FETCH_ARTICLES_SUCCESS = 'app/KnowledgeHubNavigationPage/FETCH_ARTICLES_SUCCESS';
export const FETCH_ARTICLES_ERROR = 'app/KnowledgeHubNavigationPage/FETCH_ARTICLES_ERROR';

export const SEARCH_ARTICLES_BY_KEYWORD_REQUEST =
  'app/KnowledgeHubNavigationPage/SEARCH_ARTICLES_BY_KEYWORD_REQUEST';
export const SEARCH_ARTICLES_BY_KEYWORD_SUCCESS =
  'app/KnowledgeHubNavigationPage/SEARCH_ARTICLES_BY_KEYWORD_SUCCESS';
export const SEARCH_ARTICLES_BY_KEYWORD_ERROR =
  'app/KnowledgeHubNavigationPage/SEARCH_ARTICLES_BY_KEYWORD_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchArticlesInProgress: false,
  fetchArticlesError: null,
  pagination: null,
  articles: [],
  searchParams: null,
  searchArticlesByKeywordInProgress: false,
  searchArticlesByKeywordError: null,
  searchedArticles: [],
};

const KNOWLEDGE_HUB_PAGE_SIZE = 12;
const SEARCH_BY_KEYWORD_SIZE = 6;

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_ARTICLES_REQUEST:
      return {
        ...state,
        searchParams: payload,
        fetchArticlesInProgress: true,
        fetchArticlesError: null,
      };
    case FETCH_ARTICLES_SUCCESS: {
      return {
        ...state,
        fetchArticlesInProgress: false,
        articles: payload.data,
        pagination: payload.pagination,
      };
    }
    case FETCH_ARTICLES_ERROR:
      return {
        ...state,
        fetchArticlesInProgress: false,
        fetchArticlesError: payload,
      };

    case SEARCH_ARTICLES_BY_KEYWORD_REQUEST:
      return {
        ...state,
        searchArticlesByKeywordInProgress: true,
        searchArticlesByKeywordError: null,
      };
    case SEARCH_ARTICLES_BY_KEYWORD_SUCCESS: {
      return {
        ...state,
        searchArticlesByKeywordInProgress: false,
        searchedArticles: payload.data,
      };
    }
    case SEARCH_ARTICLES_BY_KEYWORD_ERROR:
      return {
        ...state,
        searchArticlesByKeywordInProgress: false,
        searchArticlesByKeywordError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchArticlesRequest = searchParams => ({
  type: FETCH_ARTICLES_REQUEST,
  payload: searchParams,
});
const fetchArticlesSuccess = response => ({
  type: FETCH_ARTICLES_SUCCESS,
  payload: response,
});
const fetchArticlesError = e => ({
  type: FETCH_ARTICLES_ERROR,
  error: true,
  payload: e,
});

const searchArticlesByKeywordRequest = searchParams => ({
  type: SEARCH_ARTICLES_BY_KEYWORD_REQUEST,
  payload: searchParams,
});
const searchArticlesByKeywordSuccess = response => ({
  type: SEARCH_ARTICLES_BY_KEYWORD_SUCCESS,
  payload: response,
});
const searchArticlesByKeywordError = e => ({
  type: SEARCH_ARTICLES_BY_KEYWORD_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const searchArticlesByKeyword = (keyword = '') => (dispatch, getState, sdk) => {
  dispatch(searchArticlesByKeywordRequest(search));

  return client
    .getByType('knowledge_hub', {
      predicates: [prismic.predicate.fulltext('my.knowledge_hub.title', keyword)],
      pageSize: SEARCH_BY_KEYWORD_SIZE,
    })
    .then(response => {
      const { results_per_page, total_results_size, total_pages, page, results } = response;

      const pagination = {
        page,
        perPage: results_per_page,
        totalItems: total_results_size,
        totalPages: total_pages,
      };

      dispatch(searchArticlesByKeywordSuccess({ data: results, pagination }));

      return results;
    })
    .catch(e => {
      dispatch(searchArticlesByKeywordError(storableError(e)));
      throw e;
    });
};

export const fetchArticlesInKnowledgeHub = (knowledgeHubParams, search) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(fetchArticlesRequest(search));

  return client
    .getByType('knowledge_hub', knowledgeHubParams)
    .then(response => {
      const { results_per_page, total_results_size, total_pages, page, results } = response;

      const pagination = {
        page,
        perPage: results_per_page,
        totalItems: total_results_size,
        totalPages: total_pages,
      };

      dispatch(fetchArticlesSuccess({ data: results, pagination }));
      return response;
    })
    .catch(e => {
      dispatch(fetchArticlesError(storableError(e)));
    });
};

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const { page = 1, category = FOR_CLIENT_ARTICLE } = parse(search);

  const knowledgeHubParams = {
    pageSize: KNOWLEDGE_HUB_PAGE_SIZE,
    page,
    predicates: [prismic.predicate.at('my.knowledge_hub.categories.category', category)],
  };

  return dispatch(fetchArticlesInKnowledgeHub(knowledgeHubParams, search));
};
