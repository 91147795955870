export const localStorageSync = {
  setItem: (key, value) => {
    if (typeof localStorage !== 'undefined') {
      return localStorage.setItem(key, value);
    }
    return;
  },
  getItem: key => {
    if (typeof localStorage !== 'undefined') {
      return localStorage.getItem(key);
    }
    return;
  },
  removeItem: key => {
    if (typeof localStorage !== 'undefined') {
      return localStorage.removeItem(key);
    }
    return;
  },
};
