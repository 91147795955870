import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.module.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Effective: September 24th, 2022</p>

      <p>
        These User Terms of Service (the “<b>User Terms</b>“) govern your, the Authorizer User’s (as
        defined below), access and use of our online (the “<b>Website</b>“) & mobile (the “
        <b>App</b>“) coach marketplace and our related services (the “<b>Services</b>“). Please read
        them carefully.
      </p>

      <p>
        <b>
          Please read these User Terms carefully to ensure that you understand each provision. These
          User Terms contain a Binding and Mandatory Arbitration and Class Action/Jury Trial waiver
          provision that requires the use of arbitration on an individual basis to resolve disputes,
          rather than jury trial or class actions and limits the remedies available to you in the
          event of certain disputes.
        </b>
      </p>

      <h2>These User Terms are Legally Binding</h2>
      <p>
        These User Terms are a legally binding contract between you and us. As part of these User
        Terms, you agree to comply with the most recent version of our Acceptable Use Policy, which
        is incorporated by reference into these User Terms. If you access or use the Services, or
        continue accessing or using the Services after being notified of a change to the User Terms
        or the Acceptable Use Policy, you confirm that you have read, understand and agree to be
        bound by the User Terms and the Acceptable Use Policy. “We”, “our” and “us” currently refers
        to BOLDLY (“<b>BOLDLY</b>“) and its parent company Lanterne Rouge Holdings Pte Ltd.
      </p>

      <h2>Customer's Choices and Instructions</h2>
      <p>
        An organization or other third party, or you through your association, or on your own, (“
        <b>Customer</b>“) have obtained permission to access our Services for accessing coaching
        based on Authorized User’s preferences (the “<b>Instance</b>“) via our Website or App. If
        you are accessing Instance from one of your employer’s platforms or have downloaded the App
        from your employer’s app marketplace, then the Customer is your employer. If you are joining
        us through one of your associations or on your own, then you are both the Customer and the
        Authorized User.
      </p>

      <h2>Customer's Contract with us and its effect on you</h2>
      <p>
        Customer has separately agreed to our Agreement (the “<b>Agreement</b>“) that permitted
        Customer to create a coaching project, so that you and others could receive coaching (each
        invitee granted access to the Services, including you, is an “<b>Authorized User</b>“). The
        Contract contains our commitment to deliver the Services to Customer, who may then invite
        Authorized Users to join its platform(s). When an Authorized User (including, you) submits
        content or information to the Services, such as messages or files (“<b>Customer Data</b>“),
        you acknowledge and agree that the Customer Data is owned by Customer and the Contract
        provides Customer with many choices and control over that Customer Data. For example,
        Customer may provision or deprovision access to the Services, enable or disable third party
        integrations manage permissions, retention and export settings, transfer or assign platform
        instance, share channels, or consolidate your instance or channels with other instance or
        channels, and these choices and instructions may result in the access, use, disclosure,
        modification or deletion of certain or all Customer Data.
      </p>
      <p>
        If you, as an individual, have signed up for the Services, then there will be no separate
        Agreement other than these Terms of Service, and you will be both Customer and Authorized
        User, for the purpose of these Terms of Service and Privacy Policy.
      </p>

      <h2>The Relationship Between You, Customer and Us</h2>
      <p>
        AS BETWEEN US AND CUSTOMER, YOU AGREE THAT IT IS SOLELY CUSTOMER’S RESPONSIBILITY TO (A)
        INFORM YOU AND ANY AUTHORIZED USERS OF ANY RELEVANT CUSTOMER POLICIES AND PRACTICES AND ANY
        SETTINGS THAT MAY IMPACT THE PROCESSING OF CUSTOMER DATA; (B) OBTAIN ANY RIGHTS, PERMISSIONS
        OR CONSENTS FROM YOU AND ANY AUTHORIZED USERS THAT ARE NECESSARY FOR THE LAWFUL USE OF
        CUSTOMER DATA AND THE OPERATION OF THE SERVICES; AND (C) RESPOND TO AND RESOLVE ANY DISPUTE
        WITH YOU AND ANY AUTHORIZED USER RELATING TO OR BASED ON CUSTOMER DATA, THE SERVICES OR
        CUSTOMER’S FAILURE TO FULFILL THESE OBLIGATIONS. BOLDLY MAKES NO REPRESENTATIONS OR
        WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, TO YOU RELATING TO THE SERVICES, WHICH
        ARE PROVIDED TO YOU ON AN “AS IS” AND “AS AVAILABLE” BASIS.
      </p>

      <h2>Access to Services and Instance</h2>
      <p>
        Subject to your compliance with these User Terms, we grant you a limited, revocable,
        non-exclusive, non-transferable access our Service and Instance via our website or through
        the “App,” which may be downloaded from Customer’s marketplace, in accordance with these
        User Terms.
      </p>
      <p>
        If you access the Services and/or Instance via our App, we also grant you a limited,
        non-exclusive and nontransferable license to: (a) download, install, and use the App from
        Customer marketplace in accordance with these User Terms and the terms of Customer
        marketplace on a single mobile device owned or otherwise controlled by you (“Mobile Device”)
        strictly in accordance with this Agreement, and (b) to access, stream, download and use on
        such Mobile Device the Services and/or Instance made available in or otherwise accessible
        through the App, strictly in accordance with this Agreement.
      </p>
      <p>
        If you access the Services through a Mobile Device, your wireless service carrier’s standard
        charges, data rates, and other fees may apply. Some or all of the Services’ or Instances’
        functionality may not work with all carriers or mobile devices. By accessing the Services or
        Instance on a Mobile Device, you agree that we may communicate with you by push
        notifications, text message, or other electronic means to your Mobile Device and that
        certain information about your usage of the Services through the mobile device may be
        communicated to us.
      </p>
      <p>
        If you access our Services or Instance through our Website, you need to make sure that your
        Internet connection is adequate for accessing our Services and Instance. You are solely
        responsible for your Internet connection including and not limited to the applicable
        charges, rates, tariffs and other fees that might apply.
      </p>

      <h2>Copyright</h2>
      <p>
        All content included on the BOLDLY Website and the BOLDLY App, such as text, graphics,
        logos, button icons, images, audio clips, digital downloads, data compilations, and
        software, is the property of BOLDLY or its content suppliers and protected by United States
        and international copyright laws. All software used on the BOLDLY Website and the BOLDLY App
        is the property of BOLDLY or its software suppliers and protected by United States and
        international copyright.
      </p>

      <h2>Digital Millennium Copyright Act</h2>
      <p>
        BOLDLY takes claims of copyright infringement seriously. If you believe any materials on the
        Website or App infringe your copyright, please inform BOLDLY by sending written notice by
        Mail to BOLDLY addressed to 33 UBI AVENUE 3 #08-61 VERTEX SINGAPORE 408868 or by email
        to <a href="mailto:connect@BOLDLY.app">connect@BOLDLY.app</a>. In accordance with the Online
        Copyright Infringement Liability Limitation Act of the Digital Millennium Copyright Act (17
        U.S.C. § 512) (“DMCA”), your written notice must include substantially the following:
      </p>
      <p>
        A reasonably detailed description of the copyrighted work(s) or other intellectual
        property(s) you claim has/have been infringed, or if the claim involves multiple words, a
        representative list of such works; Identification of the specific material you believe
        infringes upon our rights and sufficient information to enable BOLDLY to locate the specific
        material. Your company name (if any), your legal name (printed), mailing address, and phone
        number, and, if available, an email address; and a physical or electronic signature of a
        person authorized to act on behalf of the owner of the right that is allegedly violated or
        infringed, and the present date. The DMCA only authorizes BOLDLY to remove or disable access
        to specific infringing materials.
      </p>
      <p>
        If you fail to comply with all of the requirements of § 512(c)(3) of the DMCA, your notice
        may not be effective. Please be aware that if you knowingly materially misrepresent that
        material or activity on the is infringing your copyright, you may be held liable for damages
        (including costs and attorneys’ fees) under § 512(f) of the DMCA.
      </p>
      <p>
        Upon BOLDLY’s receipt of a valid, complete takedown notice, BOLDLY shall (i) remove or
        disable access to the specific allegedly infringing material identified in the notice, and
        (ii) take reasonable steps to contact the author(s) or other sources of the material.
      </p>
      <p>
        If BOLDLY removes or disables access to material as a result of a takedown notice, the
        person or entity supplying such material may instruct BOLDLY to restore such material by
        providing a counter-notification to BOLDLY. Upon receipt of a counter-notification, BOLDLY
        shall send a copy of the counter-notification to the original complaining party and inform
        such party that BOLDLY shall replace or restore access to the material within no less than
        10 but no more than 14 days unless BOLDLY receives official notice from the complaining
        party that it has filed an action seeking a court order.
      </p>

      <h2>Trademarks</h2>
      <p>
        “BOLDLY”, and other BOLDLY graphics, logos, page headers, button icons, scripts, and service
        names are trademarks, registered trademarks or trade dress of BOLDLY in Singapore and/or
        other countries. BOLDLY’s trademarks and trade dress may not be used in connection with any
        product or service that is not BOLDLY’s, in any manner that is likely to cause confusion
        among customers, or in any manner that disparages or discredits BOLDLY. All other trademarks
        not owned by BOLDLY that appear on BOLDLY are the property of their respective owners, who
        may or may not be affiliated with, connected to, or sponsored by BOLDLY.
      </p>

      <h2>Use of Services and Instance</h2>
      <p>
        <i>You Must be Over the Age of 18</i>
      </p>
      <p>
        The Services and Instance are not intended for and should not be used by anyone under the
        age of sixteen. You represent that you are over the age of 18 and are the intended recipient
        of Customer’s invitation to the Services. You may not access or use the Services and
        Instance for any purpose if either of the representations in the preceding sentence is not
        true. Without limiting the foregoing, you must be of legal working age.
      </p>
      <p>
        <i>To Use Our Services and Instance, You Must Follow Our Rules</i>
      </p>
      <p>
        To help ensure a safe and productive work environment, all Authorized Users must comply with
        our Acceptable Use Policy and remain vigilant in reporting inappropriate behaviour or
        content to the Customer and us.
      </p>
      <p>
        <i>Authorized User Term</i>
      </p>
      <p>
        These User Terms remain effective until Customer’s subscription for you expires or
        terminates, or your access to the Services has been terminated by Customer or us. Please
        contact Customer if you at any time or for any reason wish to terminate your account,
        including due to a disagreement with any updates to these User Terms or the Acceptable Use
        Policy.
      </p>

      <h2>Honor Code</h2>
      <p>
        All Authorized Users participating in any Instance or Service must agree to and strictly
        follow the following honor code of conduct:
      </p>
      <ol>
        <li>
          You will personally complete all coaching assessments, projects, and ‘homework’ to fulfill
          your engagement.
        </li>
        <li>
          You will give a minimum of 24 hours notice of any change to your coaching meeting time.
        </li>
        <li>
          You will share feedback with your coach and discuss any dissatisfaction with the
          engagement directly in order to resolve the issue before escalating to BOLDLY:&nbsp;
          <a href="mailto:connect@BOLDLY.app">connect@BOLDLY.app</a>.
        </li>
      </ol>

      <h2>Limitation of Liability</h2>
      <p>
        If we believe that there is a violation of the Contract, User Terms, the Acceptable Use
        Policy, or any of our other policies that can simply be remedied by Customer’s removal of
        certain Customer Data or taking other action, we will, in most cases, ask Customer to take
        action rather than intervene. We may directly step in and take what we determine to be
        appropriate action (including disabling your account) if the Customer does not take
        appropriate action or we believe there is a credible risk of harm to us, the Services,
        Authorized Users, or any third parties. IN NO EVENT WILL YOU OR WE HAVE ANY LIABILITY TO THE
        OTHER FOR ANY LOST PROFITS OR REVENUES OR FOR ANY INDIRECT, SPECIAL, INCIDENTAL,
        CONSEQUENTIAL, COVER OR PUNITIVE DAMAGES HOWEVER CAUSED, WHETHER IN CONTRACT, TORT OR UNDER
        ANY OTHER THEORY OF LIABILITY, AND WHETHER OR NOT THE PARTY HAS BEEN ADVISED OF THE
        POSSIBILITY OF SUCH DAMAGES. UNLESS YOU ARE ALSO A CUSTOMER (AND WITHOUT LIMITATION TO OUR
        RIGHTS AND REMEDIES UNDER THE CONTRACT), YOU WILL HAVE NO FINANCIAL LIABILITY TO US FOR A
        BREACH OF THESE USER TERMS. OUR MAXIMUM AGGREGATE LIABILITY TO YOU FOR ANY BREACH OF THE
        USER TERMS IS ONE HUNDRED DOLLARS ($100) IN THE AGGREGATE. THE FOREGOING DISCLAIMERS WILL
        NOT APPLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW AND DO NOT LIMIT EITHER PARTY’S RIGHT
        TO SEEK AND OBTAIN EQUITABLE RELIEF.
      </p>

      <h2>Survival</h2>
      <p>
        The sections titled “The Relationship Between You, Customer, and Us”, “Limitation of
        Liability”, and “Survival”, and all of the provisions under the general heading “General
        Provisions” will survive any termination or expiration of the User Terms.
      </p>

      <h1>General Provisions</h1>

      <h2>Email and BOLDLY Messages</h2>
      <p>
        Except as otherwise set forth herein, all notices under the User Terms will be by email,
        although we may instead choose to provide notice to Authorized Users through the Services
        (e.g., a BOLDLY bot notification). Notices to BOLDLY should be sent to&nbsp;
        <a href="mailto:connect@BOLDLY.app">connect@BOLDLY.app</a>. A notice will be deemed to have
        been duly given (a) the day after it is sent, in the case of a notice sent through email;
        and (b) the same day, in the case of a notice sent through the Services. Notices under the
        Contract will be delivered solely to Customer in accordance with the terms of that
        agreement.
      </p>

      <h2>Privacy Policy</h2>
      <p>
        Please review our Privacy Policy for more information on how we collect and use data
        relating to the use and performance of our products.
      </p>

      <h2>Modifications</h2>
      <p>
        As our business evolves, we may change these User Terms or the Acceptable Use Policy. If we
        make a material change to the User Terms or the Acceptable Use Policy, we will provide you
        with reasonable notice prior to the change taking effect either by emailing the email
        address associated with your account or by messaging you through the Services. You can
        review the most current version of the User Terms at any time by visiting this page, and by
        visiting the following for the most current versions of the other pages that are referenced
        in these User Terms: Acceptable Use Policy and Privacy Policy. Any material revisions to
        these User Terms will become effective on the date set forth in our notice, and all other
        changes will become effective on the date we publish the change. If you use the Services
        after the effective date of any changes, that use will constitute your acceptance of the
        revised terms and conditions.
      </p>

      <h2>Waiver</h2>
      <p>
        No failure or delay by either party in exercising any right under the User Terms, including
        the Acceptable Use Policy, will constitute a waiver of that right. No waiver under the User
        Terms will be effective unless made in writing and signed by an authorized representative of
        the party being deemed to have granted the waiver.
      </p>

      <h2>Severability</h2>
      <p>
        The User Terms, including the Acceptable Use Policy, will be enforced to the fullest extent
        permitted under applicable law. If any provision of the User Terms is held by a court of
        competent jurisdiction to be contrary to law, the provision will be modified by the court
        and interpreted so as best to accomplish the objectives of the original provision to the
        fullest extent permitted by law, and the remaining provisions of the User Terms will remain
        in effect.
      </p>

      <h2>Assignment</h2>
      <p>
        You may not assign any of your rights or delegate your obligations under these User Terms,
        including the Acceptable Use Policy, whether by operation of law or otherwise, without the
        prior written consent of us (not to be unreasonably withheld). We may assign these User
        Terms in their entirety (including all terms and conditions incorporated herein by
        reference), without your consent, to a corporate affiliate or in connection with a merger,
        acquisition, corporate reorganization, or sale of all or substantially all of our assets.
      </p>

      <h2>Governing Law</h2>
      <p>
        The Contract, and any disputes arising out of or related hereto, will be governed
        exclusively by the laws of Singapore, without regard to its conflicts of laws rules or the
        United Nations Convention on the International Sale of Goods.
      </p>

      <h2>Fees and Payment</h2>
      <p>
        Fees. Customers will pay all fees specified in their contract. Except as otherwise specified
        herein or in a contract, (i) fees are based on the Services and Content subscriptions
        purchased and not actual usage, (ii) payment obligations are non- cancelable and fees paid
        are non-refundable, and (iii) quantities purchased cannot be decreased during the relevant
        subscription term.
      </p>
      <p>
        Invoicing and Payment. Customers will provide BOLDLY with a valid credit card, or with a
        valid purchase order or alternative document reasonably acceptable to BOLDLY. If Customer
        provides credit card information to BOLDLY, Customer authorizes BOLDLY to charge such credit
        card for all Purchased Services listed in the Agreement for the initial subscription term
        and any renewal subscription term(s) as set forth in the “Term of Purchased Subscriptions”
        section below. Such charges shall be made in advance, either annually or in accordance with
        any different billing frequency stated in the applicable contract. If the contract specifies
        that payment will be by a method other than a credit card, BOLDLY will invoice Customer in
        advance and otherwise in accordance with the relevant contract. Unless otherwise stated in
        the contract, invoiced fees are due net 30 days from the invoice date. Customer is
        responsible for providing complete and accurate billing and contact information to BOLDLY
        and notifying BOLDLY of any changes to such information.
      </p>
      <p>
        Overdue Charges: If any invoiced amount is not received by BOLDLY by the due date, then
        without limiting BOLDLY’s rights or remedies, (a) those charges may accrue late interest at
        the rate of 1.5% of the outstanding balance per month, or the maximum rate permitted by law,
        whichever is lower, and/or (b) BOLDLY may condition future subscription renewals and
        contracts on payment terms shorter than those specified in the “Invoicing and Payment”
        section above.
      </p>
      <p>
        Suspension of Service and Acceleration: If any charge owing by Customer under this or any
        other agreement for services is 30 days or more overdue, (or 10 or more days overdue in the
        case of amounts Customer has authorized BOLDLY to charge to Customer’s credit card), BOLDLY
        may, without limiting its other rights and remedies, accelerate Customer’s unpaid fee
        obligations under such agreements so that all such obligations become immediately due and
        payable, and suspend Services until such amounts are paid in full, provided that, other than
        for customers paying by credit card or direct debit whose payment has been declined, BOLDLY
        will give Customer at least 10 days’ prior notice that its account is overdue, in accordance
        with the “Manner of Giving Notice” section below for billing notices, before suspending
        services to Customer.
      </p>
      <p>
        Payment Disputes. BOLDLY will not exercise its rights under the “Overdue Charges” or
        “Suspension of Service and Acceleration” section above if the Customer is disputing the
        applicable charges reasonably and in good faith and is cooperating diligently to resolve the
        dispute.
      </p>
      <p>
        Taxes. BOLDLY’s fees do not include any taxes, levies, duties or similar governmental
        assessments of any nature, including, for example, value-added, sales, use or withholding
        taxes, assessable by any jurisdiction whatsoever (collectively, “Taxes”). Customer is
        responsible for paying all Taxes associated with its purchases hereunder. If BOLDLY has the
        legal obligation to pay or collect Taxes for which Customer is responsible under this
        section, BOLDLY will invoice Customer and the Customer will pay that amount unless Customer
        provides BOLDLY with a valid tax exemption certificate authorized by the appropriate taxing
        authority. For clarity, BOLDLY is solely responsible for taxes assessable against it based
        on its income, property and employees.
      </p>

      <h2>Term and Termination</h2>
      <p>
        Term of Agreement. This Agreement commences on the date Customer first accepts it and
        continues until all subscriptions hereunder have expired or have been terminated.
      </p>
      <p>
        Term of Purchased Subscriptions. The term of each subscription shall be as specified in the
        applicable Contract. Except as otherwise specified in a contract, subscriptions will
        automatically renew for additional periods equal to the expiring subscription term or one
        year (whichever is shorter), unless either party gives the other written notice (email
        acceptable) at least 30 days before the end of the relevant subscription term. Except as
        expressly provided in the applicable Contract, renewal of promotional or one-time priced
        subscriptions will be at BOLDLY’s applicable list price in effect at the time of the
        applicable renewal. Notwithstanding anything to the contrary, any renewal in which
        subscription volume or subscription length for any Services has decreased from the prior
        term will result in re-pricing at renewal without regard to the prior term’s per-unit
        pricing.
      </p>
      <p>
        Termination: A party may terminate this Agreement for cause (i) upon 30 days written notice
        to the other party of a material breach if such breach remains uncured at the expiration of
        such period, or (ii) if the other party becomes the subject of a petition in bankruptcy or
        any other proceeding relating to insolvency, receivership, liquidation or assignment for the
        benefit of creditors.
      </p>
      <p>
        Refund or Payment upon Termination: No refunds applicable for any of BOLDLY’s Services on
        the marketplace or elsewhere.
      </p>
      <p>
        Surviving Provisions:The sections titled “Free Services,” “Fees and Payment,” “Proprietary
        Rights and Licenses,” “Confidentiality,” “Disclaimers,” “Mutual Indemnification,”
        “Limitation of Liability,” “Refund or Payment upon Termination,” “Removal of Content and
        Non-BOLDLY Applications,” “Surviving Provisions” and “General Provisions” will survive any
        termination or expiration of this Agreement, and the section titled “Protection of Customer
        Data” will survive any termination or expiration of this Agreement for so long as BOLDLY
        retains possession of Customer Data
      </p>

      <h2>Venue; Waiver of Jury Trial; Fees</h2>
      <p>
        The courts of Singapore will have exclusive jurisdiction to adjudicate any dispute arising
        out of or relating to these User Terms, including the Acceptable Use Policy, or their
        formation as a contract between us or their enforcement.&nbsp;
        <b>
          Each party hereby consents and submits to the exclusive jurisdiction of such courts. Each
          party also hereby waives any right to jury trial in connection with any action or
          litigation in any way arising out of or related to the User Terms
        </b>
        . In any action or proceeding to enforce rights under the User Terms, the prevailing party
        will be entitled to recover its reasonable costs and attorney’s fees.
      </p>

      <h2>Binding and Mandatory Arbitration</h2>
      <p>
        Except for any disputes, claims, suits, actions, causes of action, demands or proceedings
        (collectively, “<b>Disputes</b>”) in which either party seeks to bring an individual action
        in small claims court or seeks injunctive or other equitable relief for the alleged unlawful
        use of intellectual property, including, without limitation, copyrights, trademarks, trade
        names, logos, trade secrets or patents, you and BOLDLY agree (a) to waive your and BOLDLY’s
        respective rights to have any and all Disputes arising from or related to these User Terms
        of Service, use of our Services and Instance, resolved in a court, and (b) to waive your and
        BOLDLY’s respective rights to a jury trial.&nbsp;
        <b>
          <u>Instead, you and BOLDLY agree to arbitrate Disputes through binding arbitration</u>
        </b>
        &nbsp;(which is the referral of a Dispute to one or more persons charged with reviewing the
        Dispute and making a final and binding determination to resolve it instead of having the
        Dispute decided by a judge or jury in court). You and BOLDLY agree that (a) any arbitration
        will occur in Singapore, (b) arbitration will be conducted confidentially by a single
        arbitrator in accordance with the Commercial Arbitration Rules and the Supplementary
        Procedures for Consumer Related Disputes (the “<b>AAA Rules</b>”) then in effect, except as
        modified by this “Dispute Resolution” section, and (c) that the courts of Singapore, have
        exclusive jurisdiction over any appeals and the enforcement of an arbitration award.
      </p>

      <h2>Opt-Out Right</h2>
      <p>
        YOU HAVE THE RIGHT TO OPT OUT OF BINDING ARBITRATION WITHIN THIRTY (30) DAYS OF THE DATE YOU
        FIRST ACCEPTED THE USER TERMS BY WRITING TO:&nbsp;
        <a href="mailto:connect@BOLDLY.app">CONNECT@BOLDLY.APP</a> IN ORDER TO BE EFFECTIVE, THE OPT
        OUT NOTICE MUST INCLUDE YOUR FULL NAME AND CLEARLY INDICATE YOUR INTENT TO OPT OUT OF
        BINDING ARBITRATION. BY OPTING OUT OF BINDING ARBITRATION, YOU ARE AGREEING TO RESOLVE
        DISPUTES IN ACCORDANCE WITH THESE PROVISIONS.
      </p>

      <h2>Entire Agreement</h2>
      <p>
        The User Terms, including any terms incorporated by reference into the User Terms,
        constitute the entire agreement between you and us and supersede all prior and
        contemporaneous agreements, proposals or representations, written or oral, concerning its
        subject matter. To the extent of any conflict or inconsistency between the provisions in
        these User Terms and any pages referenced in these User Terms, the terms of these User Terms
        will first prevail; provided, however, that if there is a conflict or inconsistency between
        the Contract and the User Terms, the terms of the Contract will first prevail, followed by
        the provisions in these User Terms, and then followed by the pages referenced in these User
        Terms (e.g., the Privacy Policy). Customer will be responsible for notifying Authorized
        Users of those conflicts or inconsistencies and until such time the terms set forth herein
        will be binding.
      </p>

      <h2>Contacting BOLDLY</h2>
      <p>
        Please also feel free to contact us if you have any questions about BOLDLY ‘s User Terms of
        Service. You may contact us at <a href="mailto:connect@BOLDLY.app">connect@BOLDLY.app</a> or
        at our mailing address below:
      </p>
      <p>BOLDLY:33 UBI AVENUE 3 #08-61 VERTEX SINGAPORE 408868</p>
    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
