import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';

import css from './SectionVideo.module.css';
import { generateUrlForIntroductionVideo } from '../../util/data';
import { useIntl } from 'react-intl';
import Star from '../../assets/star.png';
import halfstar from '../../assets/star-half-empty.png'

const SectionVideo = props => {
  const intl = useIntl();
  const { rootClassName, className, url, titleId, subtitleId2, subtitleId02, subtitleId3, subtitleId03, subtitleId4, subtitleId04, subtitleId5, titleId1, hideStars,ishomeVideoStars } = props;

  const validUrl = url && generateUrlForIntroductionVideo(url);

  const isVimeoVideo = validUrl?.includes("vimeo");

  const classes = classNames(rootClassName || css.root, css.customroot, className, {[css.paddingForVimeoVideo]: isVimeoVideo});
  const title = titleId && intl.formatMessage({ id: titleId });
  const title1 = titleId1 && intl.formatMessage({ id: titleId1 });
  const subtitle2 = subtitleId2 && (
    <div>{intl.formatMessage({ id: subtitleId2 })}</div>
  );
  const subtitle02 = subtitleId02 && (
    <div>{intl.formatMessage({ id: subtitleId02 })}</div>
  );
  const subtitle3 = subtitleId3 && (
    <div>{intl.formatMessage({ id: subtitleId3 })}</div>
  );
  const subtitle03 = subtitleId03 && (
    <div>{intl.formatMessage({ id: subtitleId03 })}</div>
  );
  const subtitle4 = subtitleId4 && (
    <div>{intl.formatMessage({ id: subtitleId4 })}</div>
  );
  const subtitle04 = subtitleId04 && (
    <div>{intl.formatMessage({ id: subtitleId04 })}</div>
  );
  const subtitle5 = subtitleId5 && (
    <div className={css.subTitle}>{intl.formatMessage({ id: subtitleId5 })}</div>
  );

  return url ? (
    <div className={classes}>
      <div className={css.title}>{title} <span>{title1}</span></div>
      {ishomeVideoStars?<> <div className={css.Mainsubtitle}>
        <div className={classNames(!ishomeVideoStars ? css.hide : css.Star, css.Star1)}>
          <div className={css.flexconst}>
            <div className={css.StarRateing}>
              <img src={Star} />
              <img src={Star} />
              <img src={Star} />
              <img src={Star} />
              <img src={Star} />
            </div>
          </div>
          {subtitle2}
          {subtitle02}
        </div>
        <div className={classNames(!ishomeVideoStars ? css.hide : css.Star, css.Star2)}>
          <div>
            <div className={css.StarRateing}>
              <img src={Star} />
              <img src={Star} />
              <img src={Star} />
              <img src={Star} />
              <img src={halfstar} />

            </div>

          </div>
          {subtitle3}
          {subtitle03}
        </div>
        <div className={classNames(!ishomeVideoStars ? css.hide : css.Star, css.Star3)}>
          <div>
            <div className={css.StarRateing}>
              <img src={Star} />
              <img src={Star} />
              <img src={Star} />
              <img src={Star} />
              <img src={halfstar} />

            </div>

          </div>
          {subtitle4}
          {subtitle04}
        </div>
      </div>

      <div className={css.subtitle5}>{subtitle5}</div></>:null}
     

      <div className={classNames(css.video, { [css.vimeoVideo]: isVimeoVideo })}>
        <iframe
          className={classNames({ [css.introductionVideo]: !isVimeoVideo })}
          src={validUrl}
          allowFullScreen="allowfullscreen"
          frameBorder="0"
        />
      </div>
    </div>
  ) : null;
};

SectionVideo.defaultProps = {
  rootClassName: null,
  className: null,
  url: null,
  titleId: null,
  hideStars: false,
};

SectionVideo.propTypes = {
  rootClassName: string,
  className: string,
  url: string,
  titleId: string,
  hideStars: bool,
};

export default SectionVideo;
