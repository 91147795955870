import React from 'react';
import { useHistory } from 'react-router-dom';
import { func, string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import {
  IconSocialMediaLinkedIn,
  Logo,
  ExternalLink,
  NamedLink,
} from '../../components';

import css from './Footer.module.css';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

const mainLandingPagePath = '/';
const coachLandingPagePath = '/become-a-coach';
const EVENTS_KNOWLEDGE_HUB_SLUG = 'boldly-events';

const renderSocialMediaLinks = intl => {
  const {
    siteToLinkedIn
  } = config;

  const goToLinkedIn = intl.formatMessage({ id: 'Footer.goToLinkedIn' });

  const linkedInLink = siteToLinkedIn ? (
    <ExternalLink
      key="linkToLinkedIn"
      href={siteToLinkedIn}
      className={css.icon}
      title={goToLinkedIn}
    >
      <IconSocialMediaLinkedIn />
    </ExternalLink>
  ) : null;

  return [linkedInLink].filter(v => v != null);
};

const Footer = props => {
  const {
    rootClassName,
    className,
    intl,
    handleMoveToHowBoldlyWorksSection,
    handleMoveToCoachingCultureSection,
  } = props;
  const history = useHistory();
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);
  const isCoachHomePage = history.location.pathname === coachLandingPagePath;
  const { siteToSignup } = config;

  const handleClickHowBoldlyWorks = e => {
    e.preventDefault();

    history.location.pathname === mainLandingPagePath ||
    history.location.pathname === coachLandingPagePath
      ? handleMoveToHowBoldlyWorksSection()
      : history.push({
          pathname: createResourceLocatorString('LandingPage', routeConfiguration(), {}, {}),
          state: { moveToHowBoldlyWorksSection: true },
        });
  };

  return (
    <div className={classes}>
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          <div className={css.someLiksMobile}>{socialMediaLinks}</div>
          <div className={css.links}>
            <div className={css.organization} id="organization">
              <NamedLink name="LandingPage" className={css.logoLink}>
                <span className={css.logo}>
                  <Logo format="desktop" />
                </span>
              </NamedLink>
              <div className={css.organizationInfo}>
                <p className={css.organizationCopyright}>
                  <NamedLink name="LandingPage" className={css.copyrightLink}>
                    <FormattedMessage id="Footer.copyright" />
                  </NamedLink>
                </p>
              </div>
            </div>
            <div className={css.infoLinks}>
              <ul className={css.list}>
                <li className={css.listItemHeading}>
                  <FormattedMessage id="Footer.company" />
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="LandingPage"
                    className={css.link}
                    onClickFn={handleClickHowBoldlyWorks}
                  >
                    <FormattedMessage id="Footer.toLandingPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink className={css.link} name="CaseStudyNavigationPage">
                    <FormattedMessage id="Footer.toCaseStudies" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  {isCoachHomePage ? (
                    <ExternalLink className={css.link} key="Footer" href={siteToSignup}>
                      <FormattedMessage id="Footer.toNewListingPage" />
                    </ExternalLink>
                  ) : (
                    <NamedLink name="CoachLandingPage" className={css.link}>
                      <FormattedMessage id="Footer.toNewListingPage" />
                    </NamedLink>
                  )}
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    className={css.link}
                    key="linkToAboutUs"
                    name="KnowledgeHubPage"
                    params={{ slug: 'about-us' }}
                  >
                    <FormattedMessage id="Footer.toAboutPage" />
                  </NamedLink>
                </li>
              </ul>
            </div>
            <div className={css.searches}>
              <ul className={css.list}>
                <li className={css.listItemHeading}>
                  <FormattedMessage id="Footer.resources" />
                </li>
                <li className={css.listItem}>
                  <NamedLink name="CoachingFormatsPage" className={css.link}>
                    <FormattedMessage id="Footer.coaching" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="BlogPage" className={css.link}>
                    <FormattedMessage id="Footer.toBlog" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink className={css.link} name="KnowledgeHubNavigationPage">
                    <FormattedMessage id="Footer.toKnowledgeHubNavigation" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    className={css.link}
                    name="KnowledgeHubPage"
                    params={{ slug: EVENTS_KNOWLEDGE_HUB_SLUG }}
                  >
                    <FormattedMessage id="Footer.events" />
                  </NamedLink>
                </li>
              </ul>
            </div>
            <div className={css.searches}>
              <ul className={css.list}>
                <li className={css.listItemHeading}>
                  <FormattedMessage id="Footer.locations" />
                </li>
                <li className={css.listItem}>
                  <NamedLink name="HongKongPage" className={css.link}>
                    <FormattedMessage id="Footer.HongKong" />
                  </NamedLink>
                </li>
              </ul>
            </div>
            <div className={css.extraLinks}>
              <div className={css.someLinks}>{socialMediaLinks}</div>
              <div className={css.legalMatters}>
                <ul className={css.tosAndPrivacy}>
                  <li>
                    <NamedLink name="TermsOfServicePage" className={css.legalLink}>
                      <FormattedMessage id="Footer.termsOfUse" />
                    </NamedLink>
                  </li>
                  <li>
                    <NamedLink name="PrivacyPolicyPage" className={css.legalLink}>
                      <FormattedMessage id="Footer.privacyPolicy" />
                    </NamedLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={css.copyrightAndTermsMobile}>
            <NamedLink name="LandingPage" className={css.organizationCopyrightMobile}>
              <FormattedMessage id="Footer.copyright" />
            </NamedLink>
            <div className={css.tosAndPrivacyMobile}>
              <NamedLink name="PrivacyPolicyPage" className={css.privacy}>
                <FormattedMessage id="Footer.privacy" />
              </NamedLink>
              <NamedLink name="TermsOfServicePage" className={css.terms}>
                <FormattedMessage id="Footer.terms" />
              </NamedLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
  handleMoveToHowBoldlyWorksSection: () => {},
  handleMoveToCoachingCultureSection: () => {},
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
  handleMoveToHowBoldlyWorksSection: func,
  handleMoveToCoachingCultureSection: func,
};

export default injectIntl(Footer);
