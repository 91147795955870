import React from 'react';
import classNames from 'classnames';
import { array, func, number, string } from 'prop-types';
import { knowledgeHubArticles } from '../../marketplace-custom-config';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ArticleSearchForm.module.css';

export const boldString = (str, subStr) => {
  if (!subStr) return str;

  const words = subStr
    .trim()
    .replace(/\\/g, '\\\\')
    .split(/\s+/);

  const strRegExp = new RegExp(words.join('|'), 'gi');

  return str.replace(strRegExp, matchedStr => `<strong>${matchedStr}</strong>`);
};

const ListKeyWord = ({
  className,
  currentIndex,
  onSelectEnd,
  currentSearchValue = '',
  result = [],
}) => {
  const renderSearchItem = (searchItem, index) => {
    const { title, categories } = searchItem.data;

    const categoriesLabel = categories
      .map(data => {
        const categoryLabel = knowledgeHubArticles.find(
          articleCategory => articleCategory.key === data.category
        )?.label;
        return categoryLabel;
      })
      .join(' | ');

    const isHighlighted = index === currentIndex;
    const highlightedTitle = boldString(title, currentSearchValue);

    const handleMouseDown = e => {
      e.preventDefault();
    };

    const handleTouchMove = e => {
      e.preventDefault();
    };

    const handleMouseUp = e => {
      e.preventDefault();
      onSelectEnd(searchItem);
    };

    return (
      <li
        className={classNames(css.keywordItem, { [css.highlighted]: isHighlighted })}
        key={index}
        onMouseDown={handleMouseDown}
        onTouchMove={handleTouchMove}
        onMouseUp={handleMouseUp}
      >
        <div
          className={css.searchItemText}
          dangerouslySetInnerHTML={{ __html: highlightedTitle }}
        />
        <p className={css.breadcrumb}>
          <FormattedMessage
            id="ArticleSearchForm.breadcrumb"
            values={{ category: categoriesLabel }}
          />
        </p>
      </li>
    );
  };

  return (
    <div className={className}>
      <p className={css.topResultLabel}>
        <FormattedMessage id="ArticleSearchForm.topArticlesResultsLabel" />
      </p>
      <ul className={css.list}>{result.map(renderSearchItem)}</ul>
    </div>
  );
};

ListKeyWord.defaultProps = {
  className: null,
  currentIndex: null,
  currentSearchValue: null,
  result: [],
};

ListKeyWord.propTypes = {
  className: string,
  currentIndex: number,
  onSelectEnd: func,
  currentSearchValue: string,
  result: array,
};

export default ListKeyWord;
