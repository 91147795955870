import React, { Component } from 'react';
import { string, array, object, func } from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { ExternalLink, NamedLink } from '../../components';

import css from './SectionLocations.module.css';
import { STAY_IN_PLACE_PAGE_NAME } from '../../containers/LandingPage/resources';

class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(LocationImage);

const Item = ({ item, currentUser, onClickItems, intl }) => {
  const {
    id,
    imageLink,
    image,
    imageTitleId,
    imageAltTextId,
    imageSubTitleId,
    titleLink,
    pageName,
    imagePageName,
    isShowOriginalImage = false,
    isPublicPage = false,
    isVideoThumbnail = false,
    cover,
    
  } = item || {};

  const handleClick = (e, params) => {
    if (onClickItems) {
      e.preventDefault();
      onClickItems(params);
    }
  };


  const titleText = (
    <span className={`${css.text} ${css.nameText}`}>
      <FormattedMessage id={imageTitleId} />
    </span>
  );

  const altText = intl.formatMessage({
    id: imageAltTextId
  });

  const displayImage = (
    <div className={css.imageWrapper}>
      <div className={css.aspectWrapper}>
        <LazyImage
          src={image}
          alt={altText}
          className={classNames(css.locationImage, {
            [css.showOriginalImage]: isShowOriginalImage,
            [css.showVideoThumbnail]: isVideoThumbnail,
            [css.cover]: cover,
          })}
        />
      </div>
    </div>
  );

  return (
    <div className={css.content}>
      <div className={css.Addcontent}>

        {imageLink ? (
          <ExternalLink href={imageLink}>{displayImage}</ExternalLink>
        ) : imagePageName ? (
          <NamedLink name={imagePageName} onClickFn={e => handleClick(e, { id })}>
            {displayImage}
          </NamedLink>
        ) : (
          displayImage
        )}

        <div className={css.linkText}>
          {titleLink ? (
            <ExternalLink href={titleLink}>{titleText}</ExternalLink>
          ) : pageName ? (
            pageName !== STAY_IN_PLACE_PAGE_NAME ? (
              <NamedLink
                name={currentUser || isPublicPage ? pageName : 'SignupPage'}
                onClickFn={e => handleClick(e, { id })}
              >
                {titleText}
              </NamedLink>
            ) : !currentUser ? (
              <NamedLink name="SignupPage" onClickFn={e => handleClick(e, { id })}>
                {titleText}
              </NamedLink>
            ) : (
              titleText
            )
          ) : (
            titleText
          )}
        </div>
        {imageSubTitleId && (
          <span className={css.subText}>
            <FormattedMessage id={imageSubTitleId} />
          </span>
        )}
      </div>
    </div>
  );
};

Item.defaultProps = {
  item: {},
  currentUser: null,
  onClickItems: null,
};

Item.propTypes = {
  item: object,
  currentUser: object,
  onClickItems: func,
};

const SectionLocations = props => {
  const {
    rootClassName,
    className,
    title,
    subTitle,
    contentList,
    currentUser,
    onClickItems,
    intl,
    isWorkshop,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.title}>{title}</div>
      <div className={isWorkshop ? css.subTitle : css.subTitle1}>{subTitle}</div>
      <div className={css.contentContainer}>
        {contentList.map(item => (
          <Item key={item.id} item={item} intl={intl} currentUser={currentUser} onClickItems={onClickItems} />
        ))}
      </div>
    </div>
  );
};

SectionLocations.defaultProps = {
  rootClassName: null,
  className: null,
  contentList: [],
  title: null,
  subTitle: null,
  currentUser: null,
  onClickItems: null,
};

SectionLocations.propTypes = {
  rootClassName: string,
  className: string,
  title: object,
  subTitle: object,
  contentList: array,
  currentUser: object,
  onClickItems: func,
};

export default injectIntl(SectionLocations);
