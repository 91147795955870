import React, { useState } from 'react';
import { func, string, object, oneOf, bool } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import {
  Button,
  Agreement,
  EngagementAgreement
} from '../../components';
import config from '../../config'

import css from './EditListingAgreementPanel.module.css';
import { LISTING_STATE_DRAFT, COACH_LISTING, ENGAGEMENT_LISTING } from '../../util/types';

const EditListingAgreementPanel = props => {
  const {
    className,
    rootClassName,
    onSubmit,
    onConfirmAgreement,
    submitButtonText,
    currentListing,
    currentUser,
    listingType,
    handleMoveToProjectListingPage,
    updateInProgress,
  } = props;

  const { state: listingState, publicData } = currentListing?.attributes || {};
  const { agreementDetails } = publicData || {};
  const [isClickConfirmAgreementButton, setIsClickConfirmAgreementButton] = useState(!!agreementDetails);
  const classes = classNames(rootClassName || css.root, className);
  const panelTitle = <FormattedMessage id="EditListingAgreementPanel.createListingTitle" />;
  const textNote = <FormattedMessage id="EditListingAgreementPanel.textNote" />;
  const isDraftListing = listingState === LISTING_STATE_DRAFT;
  const isShowNewClause =
    !agreementDetails || agreementDetails?.agreementDate > config.addNewClauseTimestamp;

  const handleClickConfirmAgreement = () => {
    onConfirmAgreement();
    setIsClickConfirmAgreementButton(true);
  }

  const content =
    listingType === COACH_LISTING ? (
      <Agreement
        currentListing={currentListing}
        currentUser={currentUser}
        isShowNewClause={isShowNewClause}
      />
    ) : (
      <EngagementAgreement currentListing={currentListing} currentUser={currentUser} />
    );

  return (
    <div className={classes}>
      <h1 className={css.panelTitle}>{panelTitle}</h1>
      <div className={css.container}>{content}</div>

      <p className={css.textNote}>{textNote}</p>

      {isDraftListing && (
        <div className={css.buttonSection}>
          <Button
            className={css.submitButton}
            inProgress={!agreementDetails && updateInProgress}
            onClick={handleClickConfirmAgreement}
            disabled={isClickConfirmAgreementButton || updateInProgress}
          >
            <FormattedMessage id="EditListingAgreementPanel.confirmAgreement" />
          </Button>
          <Button
            className={css.submitButton}
            onClick={onSubmit}
            inProgress={!!agreementDetails && updateInProgress}
            disabled={!isClickConfirmAgreementButton || updateInProgress}
          >
            {submitButtonText}
          </Button>
        </div>
      )}

      {!isDraftListing && listingType === ENGAGEMENT_LISTING && (
        <Button
          className={css.submitButton}
          onClick={() => handleMoveToProjectListingPage(currentListing)}
          disabled={!isClickConfirmAgreementButton}
        >
          {submitButtonText}
        </Button>
      )}
    </div>
  );
};

EditListingAgreementPanel.defaultProps = {
  className: null,
  rootClassName: null,
  onConfirmAgreement: () => {},
  currentListing: null,
  currentUser: null,
  listingType: COACH_LISTING,
  handleMoveToProjectListingPage: () => {},
};

EditListingAgreementPanel.propTypes = {
  className: string,
  rootClassName: string,

  onSubmit: func.isRequired,
  onConfirmAgreement: func,
  submitButtonText: string.isRequired,
  updateInProgress: bool.isRequired,

  currentListing: object,
  currentUser: object,
  listingType: oneOf([COACH_LISTING, ENGAGEMENT_LISTING]),
  handleMoveToProjectListingPage: func,
};

export default EditListingAgreementPanel;
