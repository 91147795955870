import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import { Form, Button, FieldCheckboxGroup, FieldRadioButtonGroup } from '..';
import { requiredFieldArrayCheckbox, required } from '../../util/validators';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

import css from './BookingPanel.module.css';
import { COACHEE_ROLE } from '../../marketplace-custom-config';

const BookingPanelFormComponent = props => {
  const { resetFormFlag } = props;
  const ref = useRef({ formApi: null, initialValues: null });

  useEffect(() => {
    if (resetFormFlag && ref.current.formApi) {
      ref.current.formApi.change('projects', ref.current.initialValues.projects);
    }
  }, [resetFormFlag]);

  return (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    keepDirtyOnReinitialize
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        handleSubmit,
        inProgress,
        intl,
        invalid,
        projectListings,
        actionButtonText,
        useCheckBox,
        history,
        role,
        values,
        initialValues,
        id,
        form,
      } = fieldRenderProps;

      ref.current.formApi = form;
      ref.current.initialValues = initialValues;

      const modifyProjectsFieldFromLastSubmit = () => {
        const currentProjectIds = values.projects;
        const initialProjectIds = initialValues.projects;
        if (!currentProjectIds || !initialProjectIds) {
          return false;
        }
        if (currentProjectIds.length !== initialProjectIds.length) {
          return true;
        }
        for (let i = 0; i < currentProjectIds.length; i++) {
          if (!initialProjectIds.includes(currentProjectIds[i])) {
            return true;
          }
        }
        return false;
      }

      const projectsRequiredMessage = intl.formatMessage({
        id: 'BookingPanelForm.projectsRequired',
      });
      const projectsRequired = useCheckBox
        ? requiredFieldArrayCheckbox(projectsRequiredMessage)
        : required(projectsRequiredMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress || !modifyProjectsFieldFromLastSubmit();
      const isCoachee = role === COACHEE_ROLE;

      const handleDirectToCreateProject = () => {
        history.push(
          createResourceLocatorString('NewEngagementPage', routeConfiguration(), {}, {})
        );
      };

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {useCheckBox ? (
            <FieldCheckboxGroup
              id={id || "projects"}
              name="projects"
              className={css.projects}
              options={projectListings}
              twoColumns
            />
          ) : (
            <FieldRadioButtonGroup
              id="projects"
              name="projects"
              className={css.projects}
              options={projectListings}
              twoColumns
              validate={projectsRequired}
            />
          )}

          <div className={css.bottomWrapper}>
            <Button type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              {actionButtonText}
            </Button>

            {!isCoachee && (
              <Button type="none" className={css.redButton} onClick={handleDirectToCreateProject}>
                <FormattedMessage id="BookingPanelForm.createProject" />
              </Button>
            )}
          </div>
        </Form>
      );
    }}
  />
)};

BookingPanelFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inProgress: false,
  useCheckBox: false,
  projectListings: [],
  actionButtonText: null,
  role: null,
  resetFormFlag: true,
};

const { string, bool, func, array, node, shape, oneOfType } = PropTypes;

BookingPanelFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  inProgress: bool,
  intl: intlShape.isRequired,
  useCheckBox: bool,
  projectListings: array,
  actionButtonText: oneOfType([string, node]),
  role: string,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  resetFormFlag: bool,
};

const BookingPanelForm = injectIntl(BookingPanelFormComponent);
BookingPanelForm.displayName = 'BookingPanelForm';

export default BookingPanelForm;
