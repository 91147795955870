import React, { useState } from 'react';
import classNames from 'classnames';
import { string, bool, func } from 'prop-types';
import { isEmpty } from 'lodash';
import { FormattedMessage } from '../../util/reactIntl';
import { types as sdkTypes } from '../../util/sdkLoader';
import { PrimaryButton, Modal } from '..';
import { ConfirmProjectForm } from '../../forms';
import { propTypes } from '../../util/types';

import css from './TransactionPanel.module.css';

const { Money } = sdkTypes;

const ConfirmProjectActionButtonsMaybe = props => {
  const {
    className,
    rootClassName,
    showButtons,
    onConfirmProject,
    transitTransitionInProgress,
    transitTransitionError,
    onManageDisableScrolling,
    transaction,
  } = props;

  const { engagementDetails = {} } = transaction.attributes.protectedData;
  const { numberOfHours, pricePerHour, startDate } = engagementDetails;
  const pricePerHourMoney = pricePerHour
    ? new Money(pricePerHour.amount, pricePerHour.currency)
    : null;

  const [openConfirmProjectModal, setOpenConfirmProjectModal] = useState();
  const [initialValuesState, setInitialValuesState] = useState({
    numberOfHours,
    pricePerHour: pricePerHourMoney,
    startDate: { date: startDate ? new Date(startDate) : null },
  });

  const hasEngagementDetails = !isEmpty(engagementDetails);

  const classes = classNames(rootClassName || css.actionButtons, className);

  const handleConfirmProject = async values => {
    await onConfirmProject(values, hasEngagementDetails);
    setInitialValuesState(values);
  };

  return showButtons ? (
    <div className={classes}>
      <PrimaryButton
        className={css.confirmProjectButton}
        inProgress={transitTransitionInProgress}
        disabled={transitTransitionInProgress}
        onClick={() => setOpenConfirmProjectModal(true)}
      >
        <FormattedMessage
          id={
            hasEngagementDetails
              ? 'TransactionPanel.editConfirmProjectButton'
              : 'TransactionPanel.confirmProjectButton'
          }
        />
      </PrimaryButton>

      <Modal
        id="ConfirmProjectModal"
        isOpen={openConfirmProjectModal}
        onClose={() => setOpenConfirmProjectModal()}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
      >
        <ConfirmProjectForm
          initialValues={initialValuesState}
          engagementDetails={engagementDetails}
          onSubmit={handleConfirmProject}
          inProgress={transitTransitionInProgress}
          hasError={transitTransitionError}
        />
      </Modal>
    </div>
  ) : null;
};

ConfirmProjectActionButtonsMaybe.defaultProps = {
  className: null,
  rootClassName: null,
  showButtons: false,
  transitTransitionInProgress: false,
  transitTransitionError: null,
};

ConfirmProjectActionButtonsMaybe.propTypes = {
  className: string,
  rootClassName: string,
  showButtons: bool,
  onConfirmProject: func,
  transitTransitionInProgress: bool,
  transitTransitionError: propTypes.error,
  onManageDisableScrolling: func,
  transaction: propTypes.transaction,
};


export default ConfirmProjectActionButtonsMaybe;
