import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { IconReviewStar } from '../../components';

const Rating = props => {
  const { className, rootClassName, reviewStarClassName, rating } = props;
  const classes = classNames(rootClassName, className);

  const stars = Math.round(rating);
  const isHalfStarExist = !Number.isInteger(rating);

  return (
    <span className={classes}>
      {new Array(stars).fill().map((_, index) => {
        return (
          <IconReviewStar
            key={`star-${index + 1}`}
            className={reviewStarClassName}
            isFilled
            isHalf={isHalfStarExist && index === stars - 1}
          />
        );
      })}
    </span>
  );
};

Rating.defaultProps = {
  rootClassName: null,
  className: null,
  reviewStarClassName: null,
};

const { string, number } = PropTypes;

Rating.propTypes = {
  rating: number,
  reviewStartClassName: string,
  rootClassName: string,
  className: string,
};

export default Rating;
