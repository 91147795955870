import React, { Component, useState } from 'react';
import { array, string, func, bool } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { LISTING_STATE_PUBLISHED, propTypes } from '../../util/types';
import { ensureListing, ensureUser } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import {
  NamedLink,
  ResponsiveImage,
  ModalAddToCoachList,
  IconShortListAdded,
  IconAddToShortList,
} from '../../components';
import { BUYER_ROLE, OTHER_OPTION } from '../../marketplace-custom-config';
import { useDispatch, useSelector } from 'react-redux';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { useHistory } from 'react-router-dom';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { MAX_COACHES_IN_ENGAGEMENT } from '../../containers/ListingPage/ListingPage.duck';
import intersection from 'lodash/intersection';
import { getListingByState } from '../../util/listingHelpers';

import css from './ListingCard.module.css';

const getLanguagesInfo = (languageOptions, selectedLanguages, languagesOther) => {
  const result = languageOptions.map(language => {
    if (selectedLanguages.includes(language.key)) {
      return language.key === OTHER_OPTION ? languagesOther : language.label;
    }
  });
  return result.filter(Boolean);
};

// NOTE: Get own engagements which include a current coach
const getOwnEngagements = (buyerEngagements, engagementList) => {
  const buyerEngagementIds = buyerEngagements.map(e => e.id.uuid);
  return intersection(buyerEngagementIds, engagementList);
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    filtersConfig,
    setActiveListing,
    isAuthenticated,
    handleOpenWelcomeAnonymousModal,
    projectListings: rawProjectListings,
    addToCoachList,
    addToCoachListInProgress,
    addToCoachListError,
    from,
    onResetCoachListError,
  } = props;

  const [isOpenAddToCoachModal, setIsOpenAddToCoachModal] = useState(false);
  const history = useHistory();
  const currentUser = useSelector(state => state.user.currentUser);
  const dispatch = useDispatch();
  const onManageDisableScrolling = (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling));
  const role = currentUser?.attributes.profile.publicData.role
  const canViewCoachProfile =
    isAuthenticated &&
    role === BUYER_ROLE &&
    getListingByState('published', rawProjectListings).length > 0;

  const projectListings = rawProjectListings.filter(l => {
    const { coachList = {} } = l.attributes.metadata || {};
    const coachListingIds = Object.keys(coachList);
    if (
      coachListingIds.length >= MAX_COACHES_IN_ENGAGEMENT &&
      !coachListingIds.includes(listing.id.uuid)
    ) {
      return false;
    }
    return true;
  });

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const {
    id: { uuid: id },
    author,
  } = currentListing;
  const ensureAuthor = ensureUser(author);
  const {
    title = '',
    description,
    publicData: { firstName, locationToSearch, languages, languagesOther },
  } = currentListing.attributes;
  const engagementList = listing.attributes.metadata?.engagementList || [];
  const ownListings = getOwnEngagements(projectListings, engagementList);

  const slug = createSlug(title);

  const languageOptions = findOptionsForSelectFilter('languages', filtersConfig);

  const displayLanguages = languages ? (
    <FormattedMessage
      id="ListingCard.languages"
      values={{
        value: getLanguagesInfo(languageOptions, languages, languagesOther)
          .slice(0, 3)
          .join(', '),
      }}
    />
  ) : null;

  const publishedProjectListings = projectListings.filter(
    prj => prj.attributes.state === LISTING_STATE_PUBLISHED
  );

  const authorImage = (
    <div className={css.aspectWrapper}>
      <LazyImage
        rootClassName={css.rootForImage}
        alt={title}
        image={ensureAuthor.profileImage}
        variants={['landscape-crop', 'landscape-crop2x']}
        sizes={renderSizes}
      />
    </div>
  );

  const handleAddToCoachList = values => {
    const { addCoachList, removeCoachList } = values;

    addToCoachList({
      addCoachList,
      removeCoachList,
      coachListingId: listing.id.uuid,
    }).then(() => {
      setIsOpenAddToCoachModal(false);
    });
  };

  const handleOpenAddToCoachListModal = () => {
    if (projectListings.length === 0) {
      history.push(createResourceLocatorString('NewEngagementPage', routeConfiguration(), {}, {}));
    } else {
      setIsOpenAddToCoachModal(true);
      onResetCoachListError();
    }
  };

  return (
    <div className={classes}>
      {canViewCoachProfile ? (
        <NamedLink
          className={css.threeToTwoWrapper}
          name="ListingPage"
          params={{ id, slug }}
          state={{ from }}
        >
          {authorImage}
        </NamedLink>
      ) : (
        <div className={css.threeToTwoWrapper} onClick={handleOpenWelcomeAnonymousModal}>
          {authorImage}
        </div>
      )}
      <div className={css.info}>
        <div className={css.mainInfo}>
          {firstName &&
            (canViewCoachProfile ? (
              <NamedLink
                className={css.title}
                name="ListingPage"
                params={{ id, slug }}
                state={{ from }}
              >
                {firstName}
              </NamedLink>
            ) : (
              <div className={css.title} onClick={handleOpenWelcomeAnonymousModal}>
                {firstName}
              </div>
            ))}
          {locationToSearch && <div className={css.location}>{locationToSearch}</div>}
          {languages && <div className={css.languages}>{displayLanguages}</div>}
        </div>
        {ownListings.length > 0 ? (
          <IconShortListAdded
            className={css.addToLongList}
            onClick={
              isAuthenticated ? handleOpenAddToCoachListModal : handleOpenWelcomeAnonymousModal
            }
          />
        ) : (
          <IconAddToShortList
            className={css.addToLongList}
            onClick={
              isAuthenticated ? handleOpenAddToCoachListModal : handleOpenWelcomeAnonymousModal
            }
          />
        )}
      </div>
      {description && <p className={css.description}>{description}</p>}
      {isAuthenticated && (
        <ModalAddToCoachList
          id={`${listing.id.uuid}.addToCoachListModal`}
          isOpen={isOpenAddToCoachModal}
          onClose={() => setIsOpenAddToCoachModal(false)}
          onManageDisableScrolling={onManageDisableScrolling}
          onSubmit={handleAddToCoachList}
          useCheckBox
          projectListings={publishedProjectListings}
          history={history}
          role={currentUser?.attributes.profile.publicData.role}
          inProgress={addToCoachListInProgress}
          error={addToCoachListError}
          engagementList={ownListings}
          coachListing={listing}
        />
      )}
    </div>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  filtersConfig: config.custom.filters,
  setActiveListing: () => null,
  isAuthenticated: false,
  handleOpenWelcomeAnonymousModal: () => null,
  projectListings: [],
  addToCoachList: () => {},
  addToCoachListInProgress: false,
  addToCoachListError: null,
  from: '',
  onResetCoachListError: () => {},
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  filtersConfig: array,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  isAuthenticated: bool,
  handleOpenWelcomeAnonymousModal: func,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
  projectListings: array,
  addToCoachList: func,
  addToCoachListInProgress: bool,
  addToCoachListError: propTypes.error,
  form: string,
  onResetCoachListError: func,
};

export default injectIntl(ListingCardComponent);
