/**
 * HoverPopUp is a immediate child of Button component.
 * Hover Button toggles visibility of HoverPopUp.
 */
 import React from 'react';
 import PropTypes from 'prop-types';
 import classNames from 'classnames';

 import css from './HoverPopUp.module.css';

 const HoverPopUp = props => {
  const {
    children,
    className,
    isOpen,
    rootClassName,
  } = props;

  const openClasses = isOpen ? css.isOpen : css.isClosed;
  const classes = classNames(rootClassName || css.root, className, openClasses);

  return (
    <div className={classes}>
      {children}
    </div>
  );
 };

 HoverPopUp.defaultProps = {
   className: null,
   isOpen: false,
   rootClassName: null,
 };

 const { bool, node, string } = PropTypes;

 HoverPopUp.propTypes = {
   children: node.isRequired,
   className: string,
   isOpen: bool,
   rootClassName: string,
 };

 export default HoverPopUp;
