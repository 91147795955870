/**
 * Export loadData calls from ducks modules of different containers
 */
import { loadData as BlogPageLoader } from './BlogPage/BlogPage.duck';
import { setInitialValues as CheckoutPageInitialValues } from './CheckoutPage/CheckoutPage.duck';
import { loadData as ContactDetailsPageLoader } from './ContactDetailsPage/ContactDetailsPage.duck';
import { loadData as EditListingPageLoader } from './EditListingPage/EditListingPage.duck';
import { loadData as EmailVerificationPageLoader } from './EmailVerificationPage/EmailVerificationPage.duck';
import { loadData as InboxPageLoader } from './InboxPage/InboxPage.duck';
import { loadData as ListingPageLoader } from './ListingPage/ListingPage.duck';
import { loadData as ManageListingsPageLoader } from './ManageListingsPage/ManageListingsPage.duck';
import { loadData as PaymentMethodsPageLoader } from './PaymentMethodsPage/PaymentMethodsPage.duck';
import { loadData as ProfilePageLoader } from './ProfilePage/ProfilePage.duck';
import { loadData as SearchPageLoader } from './SearchPage/SearchPage.duck';
import { loadData as SingleBlogPageLoader } from './SingleBlogPage/SingleBlogPage.duck';
import { loadData as StripePayoutPageLoader } from './StripePayoutPage/StripePayoutPage.duck';
import {
  loadData as TransactionPageLoader,
  setInitialValues as TransactionPageInitialValues,
} from './TransactionPage/TransactionPage.duck';
import { loadData as CoachListPageLoader } from './CoachListPage/CoachListPage.duck';
import { loadData as CaseStudyNavigationPageLoader } from './CaseStudyNavigationPage/CaseStudyNavigationPage.duck';
import { loadData as CaseStudyPageLoader } from './CaseStudyPage/CaseStudyPage.duck';
import { loadData as KnowledgeHubNavigationPageLoader } from './KnowledgeHubNavigationPage/KnowledgeHubNavigationPage.duck';
import { loadData as KnowledgeHubPageLoader } from './KnowledgeHubPage/KnowledgeHubPage.duck';
import { loadData as LeadershipPageLoader } from './LeadershipPage/LeadershipPage.duck';
import { loadData as DesignThinkingPageLoader } from './DesignThinkingPage/DesignThinkingPage.duck';
import { loadData as TeamCoachingPageLoader } from './TeamCoachingPage/TeamCoachingPage.duck';

const getPageDataLoadingAPI = () => {
  return {
    BlogPage: {
      loadData: BlogPageLoader,
    },
    CheckoutPage: {
      setInitialValues: CheckoutPageInitialValues,
    },
    ContactDetailsPage: {
      loadData: ContactDetailsPageLoader,
    },
    EditListingPage: {
      loadData: EditListingPageLoader,
    },
    EmailVerificationPage: {
      loadData: EmailVerificationPageLoader,
    },
    InboxPage: {
      loadData: InboxPageLoader,
    },
    ListingPage: {
      loadData: ListingPageLoader,
    },
    ManageListingsPage: {
      loadData: ManageListingsPageLoader,
    },
    PaymentMethodsPage: {
      loadData: PaymentMethodsPageLoader,
    },
    ProfilePage: {
      loadData: ProfilePageLoader,
    },
    SearchPage: {
      loadData: SearchPageLoader,
    },
    SingleBlogPage: {
      loadData: SingleBlogPageLoader,
    },
    StripePayoutPage: {
      loadData: StripePayoutPageLoader,
    },
    TransactionPage: {
      loadData: TransactionPageLoader,
      setInitialValues: TransactionPageInitialValues,
    },
    CoachListPage: {
      loadData: CoachListPageLoader,
    },
    CaseStudyNavigationPage: {
      loadData: CaseStudyNavigationPageLoader,
    },
    CaseStudyPage: {
      loadData: CaseStudyPageLoader,
    },
    KnowledgeHubNavigationPage: {
      loadData: KnowledgeHubNavigationPageLoader,
    },
    KnowledgeHubPage: {
      loadData: KnowledgeHubPageLoader,
    },
    LeadershipPage: {
      loadData: LeadershipPageLoader,
    },
    DesignThinkingPage: {
      loadData: DesignThinkingPageLoader,
    },
    TeamCoachingPage: {
      loadData: TeamCoachingPageLoader,
    }
  };
};

export default getPageDataLoadingAPI;
