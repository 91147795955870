/**
 * Renders non-reversal line items that are not listed in the
 * `LINE_ITEMS` array in util/types.js
 *
 * The line items are rendered so that the line item code is formatted to human
 * readable form and the line total is printed as price.
 *
 * If you require another kind of presentation for your line items, add them to
 * the `LINE_ITEMS` array in util/types.js and create a specific line item
 * component for them that can be used in the `BookingBreakdown` component.
 */
import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_PROVIDER_BOLDLY_FEE, propTypes } from '../../util/types';
import { bool } from 'prop-types';

import css from './BookingBreakdown.module.css';

const LineItemProviderBoldlyFee = props => {
  const { transaction, intl, isProvider } = props;

  const providerBoldlyFee = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_PROVIDER_BOLDLY_FEE && !item.reversal
  );

  const formattedFee = providerBoldlyFee?.lineTotal ? formatMoney(intl, providerBoldlyFee.lineTotal) : null;

  return isProvider && providerBoldlyFee ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="BookingBreakdown.boldlyFee" />
      </span>
      <span className={css.itemValue}>{formattedFee}</span>
    </div>
  ) : null;
};

LineItemProviderBoldlyFee.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
  isProvider: bool,
};

export default LineItemProviderBoldlyFee;
