import React, { useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { PrimaryButton, SecondaryButton, Modal } from '../../components';
import { DeclineEngagementForm } from '../../forms';

import css from './TransactionPanel.module.css';

// Functional component as a helper to build ActionButtons for
// provider when state is preauthorized
const SaleActionButtonsMaybe = props => {
  const {
    className,
    rootClassName,
    showButtons,
    acceptInProgress,
    declineInProgress,
    acceptSaleError,
    declineSaleError,
    onAcceptSale,
    onDeclineSale,
    onManageDisableScrolling,
    transaction,
  } = props;
  const [openDeclineProjectModal, setOpenDeclineProjectModal] = useState();

  const buttonsDisabled = acceptInProgress || declineInProgress;

  const acceptErrorMessage = acceptSaleError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.acceptSaleFailed" />
    </p>
  ) : null;

  const handleDeclineAction = async values => {
    await onDeclineSale(transaction, values.reason);
    setOpenDeclineProjectModal(false);
  };

  const classes = classNames(rootClassName || css.actionButtons, className);

  return showButtons ? (
    <div className={classes}>
      <div className={css.actionErrors}>
        {acceptErrorMessage}
      </div>
      <div className={css.actionButtonWrapper}>
        <SecondaryButton
          inProgress={declineInProgress}
          disabled={buttonsDisabled}
          onClick={() => setOpenDeclineProjectModal(true)}
        >
          <FormattedMessage id="TransactionPanel.declineButton" />
        </SecondaryButton>
        <PrimaryButton
          className={css.acceptButton}
          inProgress={acceptInProgress}
          disabled={buttonsDisabled}
          onClick={onAcceptSale}
        >
          <FormattedMessage id="TransactionPanel.acceptButton" />
        </PrimaryButton>
      </div>

      <Modal
        id="DeclineProjectModal"
        isOpen={openDeclineProjectModal}
        onClose={() => setOpenDeclineProjectModal()}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
      >
        <DeclineEngagementForm
          onSubmit={handleDeclineAction}
          inProgress={declineInProgress}
          hasError={declineSaleError}
        />
      </Modal>
    </div>
  ) : null;
};

export default SaleActionButtonsMaybe;
