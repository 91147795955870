import React from 'react';
import { bool, string } from 'prop-types';
import { intlShape, FormattedMessage, FormattedDate } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { propTypes } from '../../util/types';

import css from './BookingBreakdown.module.css';

const { Money } = sdkTypes;

const DATE_FORMAT_OPTION = {
  month: 'short',
  day: 'numeric',
};

const LineItemForEngagementDetailsMaybe = props => {
  const { transaction, intl, timeZone } = props;
  const { engagementDetails } = transaction.attributes.protectedData;

  if (!engagementDetails) return null;

  const { startDate, numberOfHours, pricePerHour } = engagementDetails;
  const timeZoneMaybe = timeZone ? { timeZone } : null;
  const priceHourMoney = pricePerHour ? new Money(pricePerHour.amount, pricePerHour.currency) : null;
  const priceHour = pricePerHour ? formatMoney(intl, priceHourMoney) : null;

  return (
    <React.Fragment>
      <div key={`negotiation-item.code`}>
        <div className={css.lineItem}>
          <span className={css.itemLabel}>
            <FormattedMessage id="BookingBreakdown.engagementStartDate" />
          </span>
          <span className={css.itemValue}>
            <FormattedDate value={startDate} {...DATE_FORMAT_OPTION} {...timeZoneMaybe} />
          </span>
        </div>

        <hr className={css.totalDivider} />

        <div className={css.lineItem}>
          <span className={css.itemLabel}>
            <FormattedMessage id="BookingBreakdown.pricePerHour" />
          </span>
          <span className={css.itemValue}>
            {priceHour}
          </span>
        </div>

        <div className={css.lineItem}>
          <span className={css.itemLabel}>
            <FormattedMessage id="BookingBreakdown.numberOfHours" />
          </span>
          <span className={css.itemValue}>
            {numberOfHours}
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};

LineItemForEngagementDetailsMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
  isProvider: bool,
  timeZone: string,
};

export default LineItemForEngagementDetailsMaybe;
