import React, { Component } from 'react';
import { string, array, object, func } from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

import css from './SectionIconBox.module.css';

class IconBoxImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const Item = props => {
    const { imageTitleId } = props;
    return (
      <li className="test">
        <div className="test">
          <span className="test">{imageTitleId}</span>
        </div>
      </li>
    );
  };
const LazyImage = lazyLoadWithDimensions(IconBoxImage);

const SectionIconBox = props => {
  const {
    rootClassName,
    className,
    boxTitle,
    boxContent
  } = props;

  const classes = classNames(rootClassName || css.root, className, css.iconBoxItem);

  return (
    <div className={classes}>
      <div className={css.iconBoxHeading}>
        <div className={css.iconBoxImage} />
        <div className={css.iconBoxTitle}>{boxTitle}</div>
      </div>
      <div className={css.iconBoxSpacer} />
      <div className={css.iconBoxContent}>
        <div>{boxContent}</div>
      </div>
    </div>
  );
};

SectionIconBox.defaultProps = {
  rootClassName: null,
  className: null,
  boxTitle: null,
  boxContent: null
};

SectionIconBox.propTypes = {
  rootClassName: string,
  className: string,
  boxTitle: object,
  boxContent: object
};

export default injectIntl(SectionIconBox);
