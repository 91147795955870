import React from 'react';
import AddressLinkMaybe from './AddressLinkMaybe';
import { FormattedMessage } from '../../util/reactIntl';

import css from './TransactionPanel.module.css';

// Functional component as a helper to build detail card headings
const DetailCardHeadingsMaybe = props => {
  const {
    showDetailCardHeadings,
    coachName,
    location,
    geolocation,
    showAddress,
    transaction,
    companyName,
    engagementName,
  } = props;

  const { engagementDetails } = transaction.attributes.protectedData;

  return showDetailCardHeadings ? (
    <div className={css.detailCardHeadings}>
      {engagementDetails ? (
        <h2 className={css.detailCardTitle}>
          <FormattedMessage
            id={
              companyName
                ? 'TransactionPanel.engagementDetailTitleWithCompany'
                : 'TransactionPanel.engagementDetailTitleWithoutCompany'
            }
            values={{ companyName, engagementName }}
          />
        </h2>
      ) : (
        <>
          <h2 className={css.detailCardTitle}>{coachName}</h2>
          <p className={css.detailCardSubtitle}>
            <FormattedMessage id="TransactionPanel.engagementDetailTitleWithoutCompany" values={{ engagementName }} />
          </p>
        </>
      )}

      <AddressLinkMaybe location={location} geolocation={geolocation} showAddress={showAddress} />
    </div>
  ) : null;
};

export default DetailCardHeadingsMaybe;
