import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { createSlug, stringify } from '../../util/urlHelpers';
import { AvatarMedium, NamedLink } from '../../components';
import css from './TransactionPanel.module.css';

const createListingLink = (
  listingId,
  label,
  listingDeleted,
  provider,
  searchParams = {},
  className = ''
) => {
  if (!listingId) {
    return null;
  }

  if (!listingDeleted) {
    const params = { id: listingId };

    if (label) {
      params.slug = createSlug(label);
    }

    const to = { search: stringify(searchParams) };
    return (
      <NamedLink
        className={className}
        name={label ? 'ListingPage' : 'ListingPageCanonical'}
        params={params}
        to={to}
      >
        <AvatarMedium user={provider} disableProfileLink />
      </NamedLink>
    );
  } else {
    return <FormattedMessage id="TransactionPanel.deletedListingOrderTitle" />;
  }
};

// Functional component as a helper to build AddressLinkMaybe
const DetailCardImage = props => {
  const {
    className,
    rootClassName,
    avatarWrapperClassName,
    listingId,
    listingTitle,
    listingDeleted,
    image,
    provider,
    isCustomer,
    engagementTitle,
    hideDetailCardImage
  } = props;

  if (hideDetailCardImage) {
    return null;
  }

  const classes = classNames(rootClassName || css.detailCardImageWrapper, className);
  const listingLink = createListingLink(listingId, listingTitle, listingDeleted, provider);

  return (
    <React.Fragment>
      <div className={classes}>
        <div className={css.sectionEngagementImage}>
          <div className={css.engagementImageOverlay}>
            <div className={css.engagementImageMessage}>{engagementTitle}</div>
          </div>
        </div>
      </div>
      <div className={avatarWrapperClassName || css.avatarWrapper}>{listingLink}</div>
    </React.Fragment>
  );
};

export default DetailCardImage;
