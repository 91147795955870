import React, { Component } from 'react';
import { bool, string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Field, Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import {
  required,
  autocompleteSearchRequired,
  autocompletePlaceSelected,
  composeValidators,
} from '../../util/validators';
import { isUploadImageOverLimitError } from '../../util/errors';
import {
  Form,
  Avatar,
  Button,
  ImageFromFile,
  IconSpinner,
  FieldTextInput,
  LocationAutocompleteInputField,
  FieldCheckbox,
  NamedLink,
} from '../../components';
import { COACH_ROLE, BUYER_ROLE } from '../../marketplace-custom-config';
import { PLACE_TYPE_COUNTRY, PLACE_TYPE_PLACE } from '../../util/types';

import css from './ProfileSettingsForm.module.css';

const ACCEPT_IMAGES = 'image/*';
const UPLOAD_CHANGE_DELAY = 2000; // Show spinner so that browser has time to load img srcset
const MAX_LENGTH_CHARACTERS = 250;
const REQUIRED_LABEL = '*';

const identity = v => v;

class ProfileSettingsFormComponent extends Component {
  constructor(props) {
    super(props);

    this.uploadDelayTimeoutId = null;
    this.state = { uploadDelay: false };
    this.submittedValues = {};
  }

  componentDidUpdate(prevProps) {
    // Upload delay is additional time window where Avatar is added to the DOM,
    // but not yet visible (time to load image URL from srcset)
    if (prevProps.uploadInProgress && !this.props.uploadInProgress) {
      this.setState({ uploadDelay: true });
      this.uploadDelayTimeoutId = window.setTimeout(() => {
        this.setState({ uploadDelay: false });
      }, UPLOAD_CHANGE_DELAY);
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.uploadDelayTimeoutId);
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            className,
            currentUser,
            handleSubmit,
            intl,
            invalid,
            onImageUpload,
            pristine,
            profileImage,
            rootClassName,
            updateInProgress,
            updateProfileError,
            uploadImageError,
            uploadInProgress,
            form,
            values,
          } = fieldRenderProps;

          const user = ensureCurrentUser(currentUser);
          const { role } = user.attributes.profile.publicData || {};
          const isCoach = role === COACH_ROLE;
          const isBuyer = role === BUYER_ROLE;

          const maxLengthMessage = intl.formatMessage(
            { id: 'ProfileSettingsForm.maxLength' },
            { maxLength: MAX_LENGTH_CHARACTERS }
          );
          const maxLengthValidator = validators.maxLength(maxLengthMessage, MAX_LENGTH_CHARACTERS);

          // First name
          const firstNameLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.firstNameLabel',
          });
          const firstNamePlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.firstNamePlaceholder',
          });
          const firstNameRequiredMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.firstNameRequired',
          });
          const firstNameRequired = required(firstNameRequiredMessage);

          // Last name
          const lastNameLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.lastNameLabel',
          });
          const lastNamePlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.lastNamePlaceholder',
          });
          const lastNameRequiredMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.lastNameRequired',
          });
          const lastNameRequired = required(lastNameRequiredMessage);

          // LinkedIn Url
          const linkedInUrlLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.linkedInUrlLabel',
          });
          const linkedInUrlPlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.linkedInUrlPlaceholder',
          });
          const linkedInInvalidMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.linkedInUrlInvalid',
          });
          const linkedInValidUrl = validators.validURL(linkedInInvalidMessage);

          // Calendar URL
          const calendarUrlLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.calendarUrlLabel',
          });
          const calendarUrlPlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.calendarUrlPlaceholder',
          });
          const calendarInvalidMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.calendarUrlInvalid',
          });
          const calendarInValidUrl = validators.validURL(calendarInvalidMessage);

          // Company name
          const companyNameLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.companyNameLabel',
          });
          const companyNameRequiredMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.companyNameRequired',
          });
          const companyNameRequired = required(companyNameRequiredMessage);

          // Company location
          const addressLabel = intl.formatMessage({ id: 'ProfileSettingsForm.address' });
          const addressPlaceholderMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.addressPlaceholder',
          });
          const addressRequiredMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.addressRequired',
          });
          const addressNotRecognizedMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.addressNotRecognized',
          });

          // Company URL
          const companyURLMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.companyURL',
          });
          const companyURLInvalidMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.companyURLInvalidUrl',
          });
          const companyURLRequiredMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.companyURLRequired',
          });
          const companyURLRequired = required(companyURLRequiredMessage);
          const companyURLValidURL = validators.validURL(companyURLInvalidMessage);

          // Company summary
          const companySummaryLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.companySummaryLabel',
          });
          const companySummaryPlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.companySummaryPlaceholder',
          });

          // Opt in/out
          const optInLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.optInLabel',
          });

          const uploadingOverlay =
            uploadInProgress || this.state.uploadDelay ? (
              <div className={css.uploadingImageOverlay}>
                <IconSpinner />
              </div>
            ) : null;

          const hasUploadError = !!uploadImageError && !uploadInProgress;
          const errorClasses = classNames({ [css.avatarUploadError]: hasUploadError });
          const transientUserProfileImage = profileImage.uploadedImage || user.profileImage;
          const transientUser = { ...user, profileImage: transientUserProfileImage };

          // Ensure that file exists if imageFromFile is used
          const fileExists = !!profileImage.file;
          const fileUploadInProgress = uploadInProgress && fileExists;
          const delayAfterUpload = profileImage.imageId && this.state.uploadDelay;
          const imageFromFile =
            fileExists && (fileUploadInProgress || delayAfterUpload) ? (
              <ImageFromFile
                id={profileImage.id}
                className={errorClasses}
                rootClassName={css.uploadingImage}
                aspectRatioClassName={css.squareAspectRatio}
                file={profileImage.file}
              >
                {uploadingOverlay}
              </ImageFromFile>
            ) : null;

          // Avatar is rendered in hidden during the upload delay
          // Upload delay smoothes image change process:
          // responsive img has time to load srcset stuff before it is shown to user.
          const avatarClasses = classNames(errorClasses, css.avatar, {
            [css.avatarInvisible]: this.state.uploadDelay,
          });
          const avatarPlaceholderClasses = classNames(css.avatarPlaceholder, {
            [css.avatarError]: hasUploadError,
          });
          const avatarComponent =
            !fileUploadInProgress && profileImage.imageId ? (
              <Avatar
                className={avatarClasses}
                renderSizes="(max-width: 767px) 96px, 240px"
                user={transientUser}
                disableProfileLink
              />
            ) : null;

          const chooseAvatarLabel =
            profileImage.imageId || fileUploadInProgress ? (
              <div className={css.avatarContainer}>
                {imageFromFile}
                {avatarComponent}
                <div className={css.changeAvatar}>
                  <FormattedMessage id="ProfileSettingsForm.changeAvatar" />
                </div>
              </div>
            ) : (
              <div className={avatarPlaceholderClasses}>
                <div className={css.avatarPlaceholderText}>
                  {isCoach && <FormattedMessage id="ProfileSettingsForm.addYourProfilePicture" />}
                  {isBuyer && <FormattedMessage id="ProfileSettingsForm.addYourCompanyLogo" />}
                </div>
                <div className={css.avatarPlaceholderTextMobile}>
                  {isCoach && (
                    <FormattedMessage id="ProfileSettingsForm.addYourProfilePictureMobile" />
                  )}
                  {isBuyer && (
                    <FormattedMessage id="ProfileSettingsForm.addYourCompanyLogoMobile" />
                  )}
                </div>
              </div>
            );

          const submitError = updateProfileError ? (
            <div className={css.error}>
              <FormattedMessage id="ProfileSettingsForm.updateProfileFailed" />
            </div>
          ) : null;

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = updateInProgress;
          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues);
          const submitDisabled =
            invalid ||
            pristine ||
            pristineSinceLastSubmit ||
            uploadInProgress ||
            submitInProgress ||
            (isBuyer && !profileImage?.imageId);

          const companyForm = (
            <>
              <h3 className={css.sectionTitle}>
                <FormattedMessage id="ProfileSettingsForm.yourCompany" />
              </h3>
              <FieldTextInput
                type="text"
                id="companyName"
                name="companyName"
                label={companyNameLabel}
                validate={composeValidators(companyNameRequired, maxLengthValidator)}
                requiredLabel={REQUIRED_LABEL}
                requiredClassName={css.required}
                className={css.formMargins}
              />
              <LocationAutocompleteInputField
                className={classNames(css.locationAddress, css.formMargins)}
                inputClassName={css.locationAutocompleteInput}
                iconClassName={css.locationAutocompleteInputIcon}
                predictionsClassName={css.predictionsRoot}
                validClassName={css.validLocation}
                name="companyLocation"
                label={addressLabel}
                placeholder={addressPlaceholderMessage}
                useDefaultPredictions={false}
                format={identity}
                valueFromForm={values.companyLocation}
                validate={composeValidators(
                  autocompleteSearchRequired(addressRequiredMessage),
                  autocompletePlaceSelected(addressNotRecognizedMessage)
                )}
                placeTypes={[PLACE_TYPE_COUNTRY, PLACE_TYPE_PLACE]}
              />
              <FieldTextInput
                id="companyURL"
                name="companyURL"
                className={classNames(css.formMargins, css.formMarginsTop)}
                requiredLabel={REQUIRED_LABEL}
                requiredClassName={css.required}
                type="text"
                label={companyURLMessage}
                validate={composeValidators(companyURLRequired, companyURLValidURL)}
              />
              <FieldTextInput
                id="companySummary"
                name="companySummary"
                className={classNames(css.formMargins)}
                type="textarea"
                label={companySummaryLabel}
                placeholder={companySummaryPlaceholder}
              />
            </>
          );

          const linksForBuyer = isBuyer ? (
            <div className={css.linkWrapper}>
              <NamedLink name="NewEngagementPage" className={css.newEngagementLink}>
                <FormattedMessage id="ProfileSettingsForm.createEngagement" />
              </NamedLink>
              <NamedLink name="SearchPage" className={css.searchLink}>
                <FormattedMessage id="ProfileSettingsForm.browseButton" />
              </NamedLink>
            </div>
          ) : null;

          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedValues = values;
                handleSubmit(e);
              }}
            >
              <div className={css.sectionContainer}>
                {isCoach && (
                  <h3 className={css.sectionTitle}>
                    <FormattedMessage id="ProfileSettingsForm.yourProfilePicture" />
                  </h3>
                )}
                <Field
                  accept={ACCEPT_IMAGES}
                  id="profileImage"
                  name="profileImage"
                  label={chooseAvatarLabel}
                  type="file"
                  form={null}
                  uploadImageError={uploadImageError}
                  disabled={uploadInProgress}
                >
                  {fieldProps => {
                    const { accept, id, input, label, disabled, uploadImageError } = fieldProps;
                    const { name, type } = input;
                    const onChange = e => {
                      const file = e.target.files[0];
                      form.change(`profileImage`, file);
                      form.blur(`profileImage`);
                      if (file != null) {
                        const tempId = `${file.name}_${Date.now()}`;
                        onImageUpload({ id: tempId, file });
                      }
                    };

                    let error = null;

                    if (isUploadImageOverLimitError(uploadImageError)) {
                      error = (
                        <div className={css.error}>
                          <FormattedMessage id="ProfileSettingsForm.imageUploadFailedFileTooLarge" />
                        </div>
                      );
                    } else if (uploadImageError) {
                      error = (
                        <div className={css.error}>
                          <FormattedMessage id="ProfileSettingsForm.updateProfileFailed" />
                        </div>
                      );
                    }

                    return (
                      <div className={css.uploadAvatarWrapper}>
                        <label className={css.label} htmlFor={id}>
                          {label}
                        </label>
                        <input
                          accept={accept}
                          id={id}
                          name={name}
                          className={css.uploadAvatarInput}
                          disabled={disabled}
                          onChange={onChange}
                          type={type}
                        />
                        {error}
                      </div>
                    );
                  }}
                </Field>
                <div className={css.tip}>
                  {isCoach && <FormattedMessage id="ProfileSettingsForm.tip" />}
                </div>
                <div className={css.fileInfo}>
                  <FormattedMessage id="ProfileSettingsForm.fileInfo" />
                </div>
              </div>
              <div className={css.sectionContainer}>
                <h3 className={css.sectionTitle}>
                  <FormattedMessage id="ProfileSettingsForm.yourName" />
                </h3>
                <div className={css.nameContainer}>
                  <FieldTextInput
                    className={css.firstName}
                    type="text"
                    id="firstName"
                    name="firstName"
                    label={firstNameLabel}
                    placeholder={firstNamePlaceholder}
                    validate={composeValidators(firstNameRequired, maxLengthValidator)}
                  />
                  <FieldTextInput
                    className={css.lastName}
                    type="text"
                    id="lastName"
                    name="lastName"
                    label={lastNameLabel}
                    placeholder={lastNamePlaceholder}
                    validate={composeValidators(lastNameRequired, maxLengthValidator)}
                  />
                </div>
              </div>
              <div className={classNames(css.sectionContainer, { [css.lastSection]: !isCoach })}>
                {isCoach && (
                  <>
                    <FieldTextInput
                      className={css.linkedInUrl}
                      type="text"
                      id="linkedInUrl"
                      name="linkedInUrl"
                      label={linkedInUrlLabel}
                      placeholder={linkedInUrlPlaceholder}
                      validate={linkedInValidUrl}
                    />
                    <FieldTextInput
                      type="text"
                      id="calendarLink"
                      name="calendarLink"
                      label={calendarUrlLabel}
                      placeholder={calendarUrlPlaceholder}
                      validate={calendarInValidUrl}
                    />
                  </>
                )}
                {isBuyer && companyForm}
                {isCoach && (
                  <FieldCheckbox
                    type="checkbox"
                    className={css.optIn}
                    textClassName={css.optInLabel}
                    id="optIn"
                    name="optIn"
                    label={optInLabel}
                    checkboxWrapperClassName={css.optInCheckbox}
                    labelWrapperClassName={css.optInLabelWrapper}
                  />
                )}
              </div>
              {submitError}
              <FormattedMessage id="ProfileSettingsForm.note" />
              <div className={css.buttonWrapper}>
                <Button
                  className={css.submitButton}
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                  ready={pristineSinceLastSubmit}
                >
                  <FormattedMessage id="ProfileSettingsForm.saveChanges" />
                </Button>
                {linksForBuyer}
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

ProfileSettingsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  uploadImageError: null,
  updateProfileError: null,
  updateProfileReady: false,
};

ProfileSettingsFormComponent.propTypes = {
  rootClassName: string,
  className: string,

  uploadImageError: propTypes.error,
  uploadInProgress: bool.isRequired,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  updateProfileReady: bool,
  isCoach: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ProfileSettingsForm = compose(injectIntl)(ProfileSettingsFormComponent);

ProfileSettingsForm.displayName = 'ProfileSettingsForm';

export default ProfileSettingsForm;
