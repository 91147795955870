import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconEmptyShortlist.module.css';

const IconEmptyShortlist = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  
  return (
    <svg
      className={classes}
      width="221"
      height="256"
      viewBox="0 0 221 256"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.1835 113.456C23.1835 113.456 61.9544 111.903 70.3329 48.1721C77.7732 -8.42726 143.151 17.3347 162.049 39.8965C186.53 69.1235 173.48 119.4 205.15 125.751C236.819 132.103 216.138 202.719 168.066 195.96C108.266 187.552 122.734 222.496 104.783 237.286C91.899 247.901 39.0719 237.683 37.3112 199.149C35.8296 166.724 22.1519 166.826 12.457 163.15C-1.52521 157.847 -10.3355 119.444 23.1835 113.456Z"
        fill="#F2F3F3"
      />
      <path
        d="M154.983 184.233L116.892 146.594L109.349 154.227L147.44 191.866L154.983 184.233Z"
        fill="#C9D5FF"
      />
      <path
        d="M169.533 194.347L139.898 165.064C139.733 164.9 139.467 164.902 139.304 165.067L128.088 176.418C127.925 176.583 127.926 176.849 128.091 177.012L157.726 206.296C157.891 206.459 158.157 206.457 158.32 206.292L169.537 194.941C169.7 194.776 169.698 194.51 169.533 194.347Z"
        fill="#A0AFE0"
      />
      <path
        d="M83.1311 165.524C109.337 165.524 130.581 144.28 130.581 118.074C130.581 91.8686 109.337 70.6248 83.1311 70.6248C56.9255 70.6248 35.6816 91.8686 35.6816 118.074C35.6816 144.28 56.9255 165.524 83.1311 165.524Z"
        fill="#A0AFE0"
      />
      <path
        d="M83.131 157.782C105.061 157.782 122.838 140.004 122.838 118.074C122.838 96.1447 105.061 78.3672 83.131 78.3672C61.2014 78.3672 43.4238 96.1447 43.4238 118.074C43.4238 140.004 61.2014 157.782 83.131 157.782Z"
        fill="#EFF3FF"
      />
      <path
        d="M163.35 75.1796C152.22 71.5719 140.192 77.6736 136.569 88.8493C132.962 99.9793 139.063 112.007 150.239 115.63C161.415 119.252 173.412 113.09 177.02 101.96C180.629 90.8294 174.48 78.7871 163.35 75.1796ZM159.992 85.541C161.237 85.9448 162.335 86.7096 163.146 87.7382C163.956 88.7668 164.443 90.013 164.544 91.3187C164.646 92.6244 164.357 93.9308 163.715 95.0722C163.073 96.2135 162.106 97.1384 160.937 97.7296C159.769 98.3208 158.451 98.5516 157.151 98.3927C155.851 98.2339 154.628 97.6925 153.636 96.8373C152.644 95.9821 151.928 94.8516 151.58 93.5892C151.231 92.3268 151.266 90.9893 151.679 89.7465C152.793 86.3078 156.552 84.4265 159.992 85.541ZM151.589 111.467C147.891 110.275 144.723 107.834 142.627 104.562C146.549 102.334 150.65 101.713 154.36 102.916C158.071 104.118 161.088 106.996 162.897 111.132C159.28 112.552 155.283 112.671 151.589 111.467Z"
        fill="#CBD3EC"
      />
      <path
        d="M70.7116 176.603C68.7783 177.59 67.1811 179.127 66.122 181.022C65.0628 182.916 64.5892 185.083 64.761 187.246C64.9329 189.41 65.7425 191.474 67.0875 193.178C68.4325 194.881 70.2524 196.148 72.3172 196.817C74.382 197.486 76.5988 197.528 78.6874 196.937C80.776 196.347 82.6426 195.15 84.051 193.498C85.4594 191.847 86.3465 189.815 86.6 187.659C86.8536 185.504 86.4622 183.321 85.4754 181.388C84.1498 178.798 81.8509 176.839 79.083 175.942C76.3152 175.045 73.3045 175.283 70.7116 176.603ZM73.2677 181.61C73.8698 181.303 74.5497 181.181 75.221 181.261C75.8923 181.34 76.5249 181.618 77.0385 182.057C77.5521 182.497 77.9235 183.079 78.1057 183.73C78.2879 184.381 78.2727 185.071 78.0619 185.714C77.8511 186.356 77.4542 186.921 76.9217 187.338C76.3892 187.754 75.745 188.003 75.0708 188.053C74.3966 188.103 73.7228 187.951 73.1349 187.618C72.5469 187.284 72.0713 186.783 71.7684 186.179C71.367 185.373 71.2992 184.442 71.5799 183.586C71.8605 182.731 72.467 182.021 73.2677 181.61V181.61ZM79.6633 194.14C77.8785 195.055 75.8312 195.32 73.8722 194.891C74.4903 192.646 75.7607 190.923 77.5542 190.007C79.3477 189.092 81.4988 189.041 83.669 189.891C82.867 191.729 81.451 193.231 79.6633 194.14V194.14Z"
        fill="#CBD3EC"
      />
    </svg>
  );
};

IconEmptyShortlist.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconEmptyShortlist.propTypes = { rootClassName: string, className: string };

export default IconEmptyShortlist;
