import { sendMedooLoginLink as sendMedooLoginLinkHandler} from '../../util/api';

// ================ Action types ================ //

export const SEND_MEDOO_LOGIN_LINK_REQUEST = 'app/TopbarContainer/SEND_MEDOO_LOGIN_LINK_REQUEST';
export const SEND_MEDOO_LOGIN_LINK_SUCCESS = 'app/TopbarContainer/SEND_MEDOO_LOGIN_LINK_SUCCESS';
export const SEND_MEDOO_LOGIN_LINK_ERROR = 'app/TopbarContainer/SEND_MEDOO_LOGIN_LINK_ERROR';

// ================ Reducer ================ //

const initialState = {
  sendLoginLinkInProgress: false,
  sendLoginLinkError: null,
};

export default function topbarContainerReducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case SEND_MEDOO_LOGIN_LINK_REQUEST:
      return {
        sendLoginLinkInProgress: true,
        sendLoginLinkError: null,
      };
    case SEND_MEDOO_LOGIN_LINK_SUCCESS:
      return {
        sendLoginLinkInProgress: false,
      };
    case SEND_MEDOO_LOGIN_LINK_ERROR:
      return {
        sendLoginLinkInProgress: false,
        sendLoginLinkError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const sendLoginLinkRequest = () => ({
  type: SEND_MEDOO_LOGIN_LINK_REQUEST,
});

export const sendLoginLinkSuccess = () => ({
  type: SEND_MEDOO_LOGIN_LINK_SUCCESS,
});

export const sendLoginLinkError = error => ({
  type: SEND_MEDOO_LOGIN_LINK_ERROR,
  payload: error,
});

// ================ Thunks ================ //

export const sendMedooLoginLink = () => dispatch => {
  dispatch(sendLoginLinkRequest());

  return sendMedooLoginLinkHandler()
    .then(() => dispatch(sendLoginLinkSuccess()))
    .catch(error => dispatch(sendLoginLinkError(error)));
};
