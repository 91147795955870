import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconUser.module.css';

const IconUser = props => {
  const { className, rootClassName, isCoachListing } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <>
      {isCoachListing ? <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="37.5"
        height="30"
        viewBox="0 0 37.5 30"
      >
        <path
          d="M13.125,15a7.5,7.5,0,1,0-7.5-7.5A7.5,7.5,0,0,0,13.125,15ZM16.1,17.813H10.154A10.154,10.154,0,0,0,0,27.967,2.031,2.031,0,0,0,2.031,30H24.217a2.031,2.031,0,0,0,2.033-2.033A10.155,10.155,0,0,0,16.1,17.813Zm11.977.938H23.745a11.869,11.869,0,0,1,4.38,9.217A3.871,3.871,0,0,1,27.539,30h8.086A1.889,1.889,0,0,0,37.5,28.072,9.373,9.373,0,0,0,28.072,18.75ZM25.313,15A6.563,6.563,0,1,0,21.4,3.194,9.288,9.288,0,0,1,22.5,7.5a9.3,9.3,0,0,1-1.851,5.549A6.541,6.541,0,0,0,25.313,15Z"
          fill="#fff"
        />
      </svg> :

        <svg version="1.1" viewBox="0 0 1080 1080" width="1280" height="380" xmlns="http://www.w3.org/2000/svg">
          <path transform="translate(810,20)" d="m0 0 24 1 20 3 19 5 21 8 23 12 16 11 13 11 12 11 7 8 13 17 8 13 9 17 8 21 6 22 3 21v37l-3 21-6 23-8 20-12 23-12 17-12 14-14 14-14 11-10 7-17 10-10 5 14 29 12 31 7 22 7 27 5 26 4 30 1 14v58l-3 30-5 31-9 36-11 33-10 24-14 29-14 24-14 21-11 15-11 14-13 15-7 7-7 8-8 8-8 7-12 11-14 11-17 13-18 12-18 11-18 10-25 12-33 13-25 8-24 6-32 6-23 3-42 2-32-1-31-3-34-6-25-6-16-5-26-9-26-11-30-15-17-10-21-14-14-10-14-11-14-12-12-11-25-25-9-11-7-8-12-16-14-20-12-20-12-22-11-23-10-25-11-34-6-24-5-27-4-33-1-13v-48l3-33 6-36 8-32 11-33 10-24 12-25 13-24 11-17 14-20 11-14 11-13 11-12 13-14 8-7 7-7 11-9 12-10 16-12 18-12 18-11 18-10 25-12 27-11 27-9 31-8 27-5 35-4 12-1h45l32 3 33 5 26 6 9 3 1-8 6-20 7-17 11-20 10-14 8-10 11-12 14-13 18-13 16-9 21-10 26-8 24-4z" fill="#7B9AFE" />
          <path transform="translate(810,20)" d="m0 0 24 1 20 3 19 5 21 8 23 12 16 11 13 11 12 11 7 8 13 17 8 13 9 17 8 21 6 22 3 21v37l-3 21-6 23-8 20-12 23-12 17-12 14-14 14-14 11-10 7-17 10-24 10-24 7-17 3-11 1h-30l-24-3-22-6-21-8-16-8-16-10-13-10-12-11-12-12-11-14-10-15-10-19-8-21-5-18-4-24v-41l4-24 6-21 8-20 11-20 10-14 8-10 11-12 14-13 18-13 16-9 21-10 26-8 24-4z" fill="#FDFDFE" />
          <path transform="translate(514,408)" d="m0 0h28l16 3 19 7 14 9 10 9 8 11 5 13 1 13 11 1 10 5 7 7 4 8 1 4v50l4 2 9 11 6 13 2 8v21l-4 13-6 11-10 11-9 6 10 4 16 8 12 9 11 11 9 14 6 13 4 15 1 8v82l-3 3-5-1-1-1-1-81-3-17-6-15-7-11-9-10-9-8-17-9-13-4-16-2h-17l-17 2-15 5-13 7-13 11-8 10-8 14-5 16-1 6-1 85-3 3h-130l-2-2v-5l2-2h47v-44h-128l-9-3-10-9-5-10-1-4v-232l3-10 6-8 8-6 10-3h132l1-13 5-13 9-12 10-9 13-8 19-7z" fill="#FEFEFE" />
          <path transform="translate(311,482)" d="m0 0h131l8 16 6 8 5 5 10 8 4 2-1 5-9 10-6 4-1 5 2 2h9l17-3 12-4 11-6h28l19-3 15-5 14-7 10-8 8-8 8-14 3-7 10 1 8 5 5 6 2 5v42l-14-5-5-1h-19l-12 3-13 7-10 9-7 10-5 11-2 10v16l3 12 6 12 11 12 10 7-2 2-15 5-14 8-13 11-7 8-7 11-7 14h-59l-1-52-3-15-6-12-7-9-9-8-13-7 5-5 7-6 6-10 3-8 1-6v-10l-3-12-7-12-8-7-12-6-10-2h-8l-10 2-14 7-8 8-7 14-1 4v18l4 12 8 11 10 7-2 2-10 5-10 8-9 12-5 12-2 8-1 57h-45v-202l4-10 7-6z" fill="#7B9AFE" />
          <path transform="translate(516,417)" d="m0 0h24l16 3 16 6 12 7 11 10 7 11 3 8 1 11-2 10-4 9-6 8-8 8-11 7-11 5-19 5-9 1h-16l-12-1-12 7-16 5-3-1 7-11 1-7-9-6-9-7-8-8-7-13-2-10 1-11 4-11 8-11 9-8 14-8 18-6z" fill="#7B9AFE" />
          <path transform="translate(741,135)" d="m0 0h17l13 3 11 6 7 7 8 16 4 15 1 8v24l-3 17-5 14-7 10-9 8-13 5-5 1h-21l-13-4-10-6-7-8-6-12-4-14-1-7v-32l4-18 8-16 9-9 10-5z" fill="#232323" />
          <path transform="translate(878,136)" d="m0 0h36v82h15v28h-15v24h-32v-24h-62v-27l42-60z" fill="#232323" />
          <path transform="translate(389,605)" d="m0 0h15l12 3 11 6 10 9 7 11 4 12 1 9v48h-104v-55l4-13 6-10 5-6 11-8 9-4z" fill="#7B9AFE" />
          <path transform="translate(292,712)" d="m0 0h222l-1 9-1 28h-204l-8-4-7-8-2-7v-17z" fill="#7B9AFE" />
          <path transform="translate(602,543)" d="m0 0h16l13 4 10 6 5 4 9 12 5 15v17l-4 13-7 11-10 9-12 6-8 2h-18l-12-4-10-6-9-9-6-10-3-9-1-6v-11l3-12 5-10 9-10 10-7 11-4z" fill="#7B9AFE" />
          <path transform="translate(395,524)" d="m0 0 10 1 12 5 6 5 6 9 3 7 1 12-3 12-7 10-9 7-13 4h-9l-10-3-8-5-7-7-5-10-1-4v-14l4-11 9-10 8-5 6-2z" fill="#7B9AFE" />
          <path transform="translate(444,758)" d="m0 0h67l1 1v42l-1 1h-67z" fill="#7B9AFE" />
          <path transform="translate(743,165)" d="m0 0h13l6 3 5 6 3 9 1 6v26l-3 13-4 6-5 4-6 2h-7l-7-3-5-4-4-10-1-5v-32l4-13 5-5z" fill="#FCFCFE" />
          <path transform="translate(661,721)" d="m0 0 5 1 1 1v86l-2 2-6-1-1-2v-84z" fill="#FEFEFE" />
          <path transform="translate(556,721)" d="m0 0 5 1 1 1v86l-2 2-6-1-1-2v-84z" fill="#FEFEFE" />
          <path transform="translate(883,173)" d="m0 0h2v45h-30l7-12 11-17z" fill="#F3F6FE" />
          <path transform="translate(489,465)" d="m0 0h7l3 4-1 7-3 2-7-1-2-3v-6z" fill="#FEFEFE" />
          <path transform="translate(525,465)" d="m0 0h6l3 2 1 6-5 5-6-1-3-5 2-6z" fill="#FEFEFE" />
          <path transform="translate(560,465)" d="m0 0h7l3 3v6l-4 4-6-1-3-3 1-7z" fill="#FEFEFE" />
        </svg>
      }

    </>
  );
};

const { string } = PropTypes;

IconUser.defaultProps = {
  className: null,
  rootClassName: null,
};

IconUser.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconUser;
