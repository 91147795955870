import React, { useState } from 'react';
import classNames from 'classnames';
import { bool, node, number, string } from 'prop-types';

import css from './Tooltip.module.css';

const Tooltip = props => {
  const { top, left, right, bottom, children, content, className, contentClassName } = props;
  const [active, setActive] = useState(false);

  const showTip = () => {
    setActive(true);
  };

  const hideTip = () => {
    setActive(false);
  };

  const classes = classNames(css.tooltip, contentClassName, {
    [css.top]: top,
    [css.left]: left,
    [css.right]: right,
    [css.bottom]: bottom,
  });

  return (
    <div
      className={classNames(css.wrapper, className)}
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
      onPointerEnter={showTip}
      onPointerLeave={hideTip}
    >
      {children}
      {active && <div className={classes}>{content}</div>}
    </div>
  );
};

Tooltip.propTypes = {
  delay: number,
  top: bool,
  left: bool,
  right: bool,
  bottom: bool,
  children: node.isRequired,
  content: string,
  className: string,
  contentClassName: string,
};

Tooltip.defaultProps = {
  content: null,
  className: null,
  contentClassName: null,
};

export default Tooltip;
