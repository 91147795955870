import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { FieldSelect } from '../../components';

const FieldSelectOption = props => {
  const { rootClassName, className, id, name, label, validate, placeHolder, options } = props;
  const classes = classNames(rootClassName || className);
  return options ? (
    <FieldSelect className={classes} id={id} name={name} label={label} validate={validate}>
      {placeHolder && (
        <option disabled value="">
          {placeHolder}
        </option>
      )}
      {options.map(c => (
        <option key={c.key} value={c.key}>
          {c.label}
        </option>
      ))}
    </FieldSelect>
  ) : null;
};

FieldSelectOption.defaultProps = {
  rootClassName: null,
  className: null,
  label: null,
  placeHolder: null,
  validate: null,
  options: [],
};

const { string, func, array } = PropTypes;

FieldSelectOption.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  label: string,
  name: string.isRequired,
  placeHolder: string,
  validate: func,
  options: array,
};

export default FieldSelectOption;
