import { ensureTransaction } from './data';

/**
 * Transitions
 *
 * These strings must sync with values defined in Flex API,
 * since transaction objects given by API contain info about last transitions.
 * All the actions in API side happen in transitions,
 * so we need to understand what those strings mean.
 */

export const TRANSITION_PAID_BY_INVOICE_FOR_PROJECT_LISTING =
  'transition/paid-by-invoice-for-project-listing';

// When a customer makes a booking to a listing, a transaction is
// created with the initial request-payment transition.
// At this transition a PaymentIntent is created by Marketplace API.
// After this transition, the actual payment must be made on client-side directly to Stripe.
export const TRANSITION_REQUEST_PAYMENT = 'transition/request-payment';

// A customer can also initiate a transaction with an enquiry, and
// then transition that with a request.
export const TRANSITION_ENQUIRE = 'transition/enquire';
export const TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY = 'transition/request-payment-after-enquiry';

// Stripe SDK might need to ask 3D security from customer, in a separate front-end step.
// Therefore we need to make another transition to Marketplace API,
// to tell that the payment is confirmed.
export const TRANSITION_CONFIRM_PAYMENT = 'transition/confirm-payment';
export const TRANSITION_RECORD_CONFIRM_PAYMENT = 'transition/record-confirm-payment';
export const TRANSITION_CONFIRM_PAYMENT_FOR_ADMIN = 'transition/confirm-payment-for-admin';
export const TRANSITION_PAID_BY_CREDIT_CARD_FOR_PROJECT_LISTING =
  'transition/paid-by-credit-card-for-project-listing';

// If the payment is not confirmed in the time limit set in transaction process (by default 15min)
// the transaction will expire automatically.
export const TRANSITION_EXPIRE_PAYMENT = 'transition/expire-payment';

// When the provider accepts or declines a transaction from the
// SalePage, it is transitioned with the accept or decline transition.
export const TRANSITION_ACCEPT = 'transition/accept';
export const TRANSITION_DECLINE = 'transition/decline';

// The backend automatically expire the transaction.
export const TRANSITION_EXPIRE = 'transition/expire';

// Admin can also cancel the transition.
export const TRANSITION_CANCEL = 'transition/cancel';

// The backend will mark the transaction completed.
export const TRANSITION_COMPLETE = 'transition/complete';

// Reviews are given through transaction transitions. Review 1 can be
// by provider or customer, and review 2 will be the other party of
// the transaction.
export const TRANSITION_REVIEW_1_BY_PROVIDER = 'transition/review-1-by-provider';
export const TRANSITION_REVIEW_2_BY_PROVIDER = 'transition/review-2-by-provider';
export const TRANSITION_REVIEW_1_BY_CUSTOMER = 'transition/review-1-by-customer';
export const TRANSITION_REVIEW_2_BY_CUSTOMER = 'transition/review-2-by-customer';
export const TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD = 'transition/expire-customer-review-period';
export const TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD = 'transition/expire-provider-review-period';
export const TRANSITION_EXPIRE_REVIEW_PERIOD = 'transition/expire-review-period';

export const TRANSITION_ADD_TO_COACH_LIST = 'transition/add-to-coach-list';
export const TRANSITION_COACH_RESPOND = 'transition/coach-respond';
export const TRANSITION_REMOVE_COACH = 'transition/remove-coach';
export const TRANSITION_REMOVE_COACH_AFTER_RESPONDING = 'transition/remove-coach-after-responding';
export const TRANSITION_INVITE_TO_CHEMISTRY_AFTER_RESPONDING =
  'transition/invite-to-chemistry-after-responding';
export const TRANSITION_PICK_COACH = 'transition/coachee-selects-coach';
export const TRANSITION_REQUEST = 'transition/request';
export const TRANSITION_CUSTOMER_OFFER_FIRST_TIME = 'transition/customer-offer-first-time';
export const TRANSITION_COACH_ACCEPT = 'transition/coach-accept';
export const TRANSITION_COACH_DECLINE = 'transition/coach-decline';
export const TRANSITION_CUSTOMER_OFFER = 'transition/customer-offer';
export const TRANSITION_RECORD_PAID_BY_CARD = 'transition/record-paid-by-card';
export const TRANSITION_PAID_FOR_ADMIN_BY_CARD = 'transition/paid-for-admin-by-card';
export const TRANSITION_PAID_FOR_ADMIN_BY_INVOICE = 'transition/paid-for-admin-by-invoice';
export const TRANSITION_RECORD_PAID_BY_INVOICE = 'transition/record-paid-by-invoice';
export const TRANSITION_REQUEST_PAYMENT_FOR_PROJECT_LISTING =
  'transition/request-payment-for-project-listing';
export const TRANSITION_EXPIRE_PROJECT_LISTING_PAYMENT =
  'transition/expire-project-listing-payment';
export const TRANSITION_BOOK_CHEMISTRY_SESSION = 'transition/book-chemistry-session';
export const TRANSITION_SELECT_COACH = 'transition/select-coach';
export const TRANSITION_EXPIRE_ADMIN_PAYMENT = 'transition/expire-admin-payment';

/**
 * Actors
 *
 * There are 4 different actors that might initiate transitions:
 */

// Roles of actors that perform transaction transitions
export const TX_TRANSITION_ACTOR_CUSTOMER = 'customer';
export const TX_TRANSITION_ACTOR_PROVIDER = 'provider';
export const TX_TRANSITION_ACTOR_SYSTEM = 'system';
export const TX_TRANSITION_ACTOR_OPERATOR = 'operator';

export const TX_TRANSITION_ACTORS = [
  TX_TRANSITION_ACTOR_CUSTOMER,
  TX_TRANSITION_ACTOR_PROVIDER,
  TX_TRANSITION_ACTOR_SYSTEM,
  TX_TRANSITION_ACTOR_OPERATOR,
];

/**
 * States
 *
 * These constants are only for making it clear how transitions work together.
 * You should not use these constants outside of this file.
 *
 * Note: these states are not in sync with states used transaction process definitions
 *       in Marketplace API. Only last transitions are passed along transaction object.
 */
const STATE_INITIAL = 'initial';
const STATE_ENQUIRY = 'enquiry';
const STATE_RECORDED_PENDING_PAYMENT = 'recorded-pending-payment';
const STATE_RECORDED_PAYMENT_EXPIRED = 'recorded-payment-expired';
const STATE_PREAUTHORIZED = 'preauthorized';
const STATE_COMPLETED_PAYMENT_PROJECT_LISTING = 'completed-payment-project-listing';
const STATE_DECLINED = 'declined';
const STATE_ACCEPTED = 'accepted';
const STATE_CANCELED = 'canceled';
const STATE_DELIVERED = 'delivered';
const STATE_REVIEWED = 'reviewed';
const STATE_REVIEWED_BY_CUSTOMER = 'reviewed-by-customer';
const STATE_REVIEWED_BY_PROVIDER = 'reviewed-by-provider';
const STATE_ADDED = 'added';
const STATE_COACH_RESPONDED = 'coach-responded';
const STATE_REMOVED = 'removed';
const STATE_INVITED_TO_CHEMISTRY = 'invited-to-chemistry';
const STATE_AFTER_CHEMISTRY = 'after-chemistry';
const STATE_SELECTED_COACH = 'selected-coach';
const STATE_REQUESTED_ENGAGEMENT = 'requested-engagement';
const STATE_REQUESTED_PAYMENT = 'requested-payment';
const STATE_CUSTOMER_MADE_OFFER = 'customer-made-offer';
const STATE_COACH_DECLINED = 'coach-declined';
const STATE_RECORDED_COMPLETED = 'recorded-completed';
const STATE_PENDING_CUSTOMER_PAYMENT = 'pending-customer-payment';
const STATE_PENDING_PAYMENT_FOR_PROJECT_LISTING = 'pending-payment-for-project-listing';
const STATE_PENDING_ADMIN_PAYMENT = 'pending-admin-payment';
const STATE_ADMIN_PAYMENT_COMPLETED = 'admin-payment-completed';
const STATE_ADMIN_PAYMENT_EXPIRED = 'admin-payment-expired';
const STATE_PROJECT_LISTING_PAYMENT_EXPIRED = 'project-listing-payment-expired';

/**
 * Description of transaction process
 *
 * You should keep this in sync with transaction process defined in Marketplace API
 *
 * Note: we don't use yet any state machine library,
 *       but this description format is following Xstate (FSM library)
 *       https://xstate.js.org/docs/
 */
const stateDescription = {
  // id is defined only to support Xstate format.
  // However if you have multiple transaction processes defined,
  // it is best to keep them in sync with transaction process aliases.
  id: 'negotiated-process/release-1',

  // This 'initial' state is a starting point for new transaction
  initial: STATE_INITIAL,

  // States
  states: {
    [STATE_INITIAL]: {
      on: {
        [TRANSITION_REQUEST_PAYMENT_FOR_PROJECT_LISTING]: STATE_PENDING_PAYMENT_FOR_PROJECT_LISTING,
        [TRANSITION_PAID_BY_INVOICE_FOR_PROJECT_LISTING]: STATE_COMPLETED_PAYMENT_PROJECT_LISTING,
        [TRANSITION_ADD_TO_COACH_LIST]: STATE_ADDED,
        [TRANSITION_BOOK_CHEMISTRY_SESSION]: STATE_INVITED_TO_CHEMISTRY,
        [TRANSITION_SELECT_COACH]: STATE_REQUESTED_PAYMENT,
        [TRANSITION_PAID_FOR_ADMIN_BY_CARD]: STATE_PENDING_ADMIN_PAYMENT,
        [TRANSITION_PAID_FOR_ADMIN_BY_INVOICE]: STATE_ADMIN_PAYMENT_COMPLETED,
        [TRANSITION_SELECT_COACH]: STATE_REQUESTED_ENGAGEMENT,
      },
    },
    [STATE_PENDING_PAYMENT_FOR_PROJECT_LISTING]: {
      on: {
        [TRANSITION_PAID_BY_CREDIT_CARD_FOR_PROJECT_LISTING]: STATE_COMPLETED_PAYMENT_PROJECT_LISTING,
        [TRANSITION_EXPIRE_PROJECT_LISTING_PAYMENT]: STATE_PROJECT_LISTING_PAYMENT_EXPIRED,
      },
    },
    [STATE_PROJECT_LISTING_PAYMENT_EXPIRED]: {},
    [STATE_COMPLETED_PAYMENT_PROJECT_LISTING]: {},
    [STATE_ADDED]: {
      on: {
        [TRANSITION_REMOVE_COACH]: STATE_REMOVED,
        [TRANSITION_COACH_RESPOND]: STATE_COACH_RESPONDED,
      },
    },
    [STATE_COACH_RESPONDED]: {
      on: {
        [TRANSITION_REMOVE_COACH_AFTER_RESPONDING]: STATE_REMOVED,
        [TRANSITION_INVITE_TO_CHEMISTRY_AFTER_RESPONDING]: STATE_INVITED_TO_CHEMISTRY,
      },
    },
    [STATE_REMOVED]: {},
    [STATE_INVITED_TO_CHEMISTRY]: {
      on: {
        [TRANSITION_PICK_COACH]: STATE_SELECTED_COACH,
      },
    },
    [STATE_SELECTED_COACH]: {
      on: {
        [TRANSITION_REQUEST]: STATE_REQUESTED_ENGAGEMENT,
      },
    },
    [STATE_REQUESTED_ENGAGEMENT]: {
      on: {
        [TRANSITION_CUSTOMER_OFFER_FIRST_TIME]: STATE_CUSTOMER_MADE_OFFER,
      },
    },
    [STATE_CUSTOMER_MADE_OFFER]: {
      on: {
        [TRANSITION_COACH_DECLINE]: STATE_COACH_DECLINED,
        [TRANSITION_COACH_ACCEPT]: STATE_PENDING_CUSTOMER_PAYMENT,
      },
    },
    [STATE_COACH_DECLINED]: {
      on: {
        [TRANSITION_CUSTOMER_OFFER]: STATE_CUSTOMER_MADE_OFFER,
      },
    },
    [STATE_PENDING_CUSTOMER_PAYMENT]: {
      on: {
        [TRANSITION_RECORD_PAID_BY_INVOICE]: STATE_RECORDED_COMPLETED,
        [TRANSITION_RECORD_PAID_BY_CARD]: STATE_RECORDED_PENDING_PAYMENT,
        [TRANSITION_PAID_FOR_ADMIN_BY_CARD]: STATE_PENDING_ADMIN_PAYMENT,
      },
    },
    [STATE_PENDING_ADMIN_PAYMENT]: {
      on: {
        [TRANSITION_CONFIRM_PAYMENT_FOR_ADMIN]: STATE_ADMIN_PAYMENT_COMPLETED,
        [TRANSITION_EXPIRE_ADMIN_PAYMENT]: STATE_ADMIN_PAYMENT_EXPIRED,
      },
    },
    [STATE_ADMIN_PAYMENT_COMPLETED]: {},
    [STATE_ADMIN_PAYMENT_EXPIRED]: {},
    [STATE_RECORDED_PENDING_PAYMENT]: {
      on: {
        [TRANSITION_RECORD_CONFIRM_PAYMENT]: STATE_RECORDED_COMPLETED,
        [TRANSITION_EXPIRE]: STATE_RECORDED_PAYMENT_EXPIRED,
      },
    },
    [STATE_RECORDED_COMPLETED]: {},
    [STATE_RECORDED_PAYMENT_EXPIRED]: {},
  },
};

// Note: currently we assume that state description doesn't contain nested states.
const statesFromStateDescription = description => description.states || {};

// Get all the transitions from states object in an array
const getTransitions = states => {
  const stateNames = Object.keys(states);

  const transitionsReducer = (transitionArray, name) => {
    const stateTransitions = states[name] && states[name].on;
    const transitionKeys = stateTransitions ? Object.keys(stateTransitions) : [];
    return [
      ...transitionArray,
      ...transitionKeys.map(key => ({ key, value: stateTransitions[key] })),
    ];
  };

  return stateNames.reduce(transitionsReducer, []);
};

// This is a list of all the transitions that this app should be able to handle.
export const TRANSITIONS = getTransitions(statesFromStateDescription(stateDescription)).map(
  t => t.key
);

// This function returns a function that has given stateDesc in scope chain.
const getTransitionsToStateFn = stateDesc => state =>
  getTransitions(statesFromStateDescription(stateDesc))
    .filter(t => t.value === state)
    .map(t => t.key);

// Get all the transitions that lead to specified state.
const getTransitionsToState = getTransitionsToStateFn(stateDescription);

// This is needed to fetch transactions that need response from provider.
// I.e. transactions which provider needs to accept or decline
export const transitionsToRequested = [
  TRANSITION_ADD_TO_COACH_LIST,
  TRANSITION_CUSTOMER_OFFER_FIRST_TIME,
  TRANSITION_CUSTOMER_OFFER,
];

/**
 * Helper functions to figure out if transaction is in a specific state.
 * State is based on lastTransition given by transaction object and state description.
 */

const txLastTransition = tx => ensureTransaction(tx).attributes.lastTransition;

export const txIsAddedToCoachList = tx =>
  getTransitionsToState(STATE_ADDED).includes(txLastTransition(tx));

export const txIsCoachResponded = tx =>
  getTransitionsToState(STATE_COACH_RESPONDED).includes(txLastTransition(tx));

export const txIsInvitedToChemistry = tx =>
  getTransitionsToState(STATE_INVITED_TO_CHEMISTRY).includes(txLastTransition(tx));

  export const txIsCoacheeSelectsCoach = tx =>
  getTransitionsToState(STATE_SELECTED_COACH).includes(txLastTransition(tx));

export const txIsRequestedEngagement = tx =>
  getTransitionsToState(STATE_REQUESTED_ENGAGEMENT).includes(txLastTransition(tx));

export const txIsCustomerMadeOffer = tx =>
  getTransitionsToState(STATE_CUSTOMER_MADE_OFFER).includes(txLastTransition(tx));

export const txIsCoachDeclined = tx =>
  getTransitionsToState(STATE_COACH_DECLINED).includes(txLastTransition(tx));

export const txIsCoachAccepted = tx =>
  getTransitionsToState(STATE_PENDING_CUSTOMER_PAYMENT).includes(txLastTransition(tx));

export const txIsRecordedCompleted = tx =>
  getTransitionsToState(STATE_RECORDED_COMPLETED).includes(txLastTransition(tx));

export const txIsAdminPaymentCompleted = tx =>
  getTransitionsToState(STATE_ADMIN_PAYMENT_COMPLETED).includes(txLastTransition(tx));

export const txIsEnquired = tx =>
  getTransitionsToState(STATE_ENQUIRY).includes(txLastTransition(tx));

export const txIsPaymentPending = tx =>
  getTransitionsToState(STATE_RECORDED_PENDING_PAYMENT).includes(txLastTransition(tx));

export const txIsPaymentExpired = tx =>
  getTransitionsToState(STATE_RECORDED_PAYMENT_EXPIRED).includes(txLastTransition(tx));

// Note: state name used in Marketplace API docs (and here) is actually preauthorized
// However, word "requested" is used in many places so that we decided to keep it.
export const txIsRequested = tx =>
  getTransitionsToState(STATE_PREAUTHORIZED).includes(txLastTransition(tx));

export const txIsAccepted = tx =>
  getTransitionsToState(STATE_ACCEPTED).includes(txLastTransition(tx));

export const txIsDeclined = tx =>
  getTransitionsToState(STATE_DECLINED).includes(txLastTransition(tx));

export const txIsCanceled = tx =>
  getTransitionsToState(STATE_CANCELED).includes(txLastTransition(tx));

export const txIsDelivered = tx =>
  getTransitionsToState(STATE_DELIVERED).includes(txLastTransition(tx));

const firstReviewTransitions = [
  ...getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER),
  ...getTransitionsToState(STATE_REVIEWED_BY_PROVIDER),
];
export const txIsInFirstReview = tx => firstReviewTransitions.includes(txLastTransition(tx));

export const txIsInFirstReviewBy = (tx, isCustomer) =>
  isCustomer
    ? getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER).includes(txLastTransition(tx))
    : getTransitionsToState(STATE_REVIEWED_BY_PROVIDER).includes(txLastTransition(tx));

export const txIsReviewed = tx =>
  getTransitionsToState(STATE_REVIEWED).includes(txLastTransition(tx));

/**
 * Helper functions to figure out if transaction has passed a given state.
 * This is based on transitions history given by transaction object.
 */

const txTransitions = tx => ensureTransaction(tx).attributes.transitions || [];
export const hasPassedTransition = (transitionName, tx) =>
  !!txTransitions(tx).find(t => t.transition === transitionName);

const hasPassedStateFn = state => tx =>
  getTransitionsToState(state).filter(t => hasPassedTransition(t, tx)).length > 0;

export const txHasBeenAccepted = hasPassedStateFn(STATE_ACCEPTED);
export const txHasBeenDelivered = hasPassedStateFn(STATE_DELIVERED);

/**
 * Other transaction related utility functions
 */

export const transitionIsReviewed = transition =>
  getTransitionsToState(STATE_REVIEWED).includes(transition);

export const transitionIsFirstReviewedBy = (transition, isCustomer) =>
  isCustomer
    ? getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER).includes(transition)
    : getTransitionsToState(STATE_REVIEWED_BY_PROVIDER).includes(transition);

export const getReview1Transition = isCustomer =>
  isCustomer ? TRANSITION_REVIEW_1_BY_CUSTOMER : TRANSITION_REVIEW_1_BY_PROVIDER;

export const getReview2Transition = isCustomer =>
  isCustomer ? TRANSITION_REVIEW_2_BY_CUSTOMER : TRANSITION_REVIEW_2_BY_PROVIDER;

// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
export const isRelevantPastTransition = transition => {
  return [
    TRANSITION_ADD_TO_COACH_LIST,
    TRANSITION_INVITE_TO_CHEMISTRY_AFTER_RESPONDING,
    TRANSITION_REQUEST,
    TRANSITION_CUSTOMER_OFFER_FIRST_TIME,
    TRANSITION_CUSTOMER_OFFER,
    TRANSITION_COACH_DECLINE,
    TRANSITION_COACH_ACCEPT,
    TRANSITION_RECORD_CONFIRM_PAYMENT,
    TRANSITION_RECORD_PAID_BY_INVOICE,
    TRANSITION_CONFIRM_PAYMENT_FOR_ADMIN,
    TRANSITION_ACCEPT,
    TRANSITION_CANCEL,
    TRANSITION_COMPLETE,
    TRANSITION_RECORD_CONFIRM_PAYMENT,
    TRANSITION_DECLINE,
    TRANSITION_EXPIRE,
    TRANSITION_REVIEW_1_BY_CUSTOMER,
    TRANSITION_REVIEW_1_BY_PROVIDER,
    TRANSITION_REVIEW_2_BY_CUSTOMER,
    TRANSITION_REVIEW_2_BY_PROVIDER,
    TRANSITION_COACH_RESPOND,
  ].includes(transition);
};

export const isCustomerReview = transition => {
  return [TRANSITION_REVIEW_1_BY_CUSTOMER, TRANSITION_REVIEW_2_BY_CUSTOMER].includes(transition);
};

export const isProviderReview = transition => {
  return [TRANSITION_REVIEW_1_BY_PROVIDER, TRANSITION_REVIEW_2_BY_PROVIDER].includes(transition);
};

export const getUserTxRole = (currentUserId, transaction) => {
  const tx = ensureTransaction(transaction);
  const customer = tx.customer;
  if (currentUserId && currentUserId.uuid && tx.id && customer.id) {
    // user can be either customer or provider
    return currentUserId.uuid === customer.id.uuid
      ? TX_TRANSITION_ACTOR_CUSTOMER
      : TX_TRANSITION_ACTOR_PROVIDER;
  } else {
    throw new Error(`Parameters for "userIsCustomer" function were wrong.
      currentUserId: ${currentUserId}, transaction: ${transaction}`);
  }
};

export const txRoleIsProvider = userRole => userRole === TX_TRANSITION_ACTOR_PROVIDER;
export const txRoleIsCustomer = userRole => userRole === TX_TRANSITION_ACTOR_CUSTOMER;

// Check if the given transition is privileged.
//
// Privileged transitions need to be handled from a secure context,
// i.e. the backend. This helper is used to check if the transition
// should go through the local API endpoints, or if using JS SDK is
// enough.
export const isPrivileged = transition => {
  return [
    TRANSITION_REQUEST_PAYMENT,
    TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
    TRANSITION_REQUEST_PAYMENT_FOR_PROJECT_LISTING,
    TRANSITION_COACH_ACCEPT,
    TRANSITION_PAID_FOR_ADMIN_BY_CARD,
    TRANSITION_PAID_FOR_ADMIN_BY_INVOICE,
    TRANSITION_PAID_BY_INVOICE_FOR_PROJECT_LISTING,
  ].includes(transition);
};

export const isRelatedToProjectListings = transition => {
  return [
    TRANSITION_REQUEST_PAYMENT_FOR_PROJECT_LISTING,
    TRANSITION_EXPIRE_PROJECT_LISTING_PAYMENT,
    TRANSITION_PAID_BY_INVOICE_FOR_PROJECT_LISTING,
    TRANSITION_PAID_BY_CREDIT_CARD_FOR_PROJECT_LISTING,
  ].includes(transition);
};
