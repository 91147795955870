import globalClients from './images/global-clients.jpeg';
import setYourOwnRates from './images/mainAssets/coaching-skills.jpg';
import optionalSupervision from './images/optional-supervision.jpeg';
import instituteOfCoaching from './images/institute-of-coaching-logo.png';
import libraryProfessional from './images/LPC-Retina-Logo.png';
import positivePsychology from './images/positive-psychology.png';
import thoughtLeadership from './images/thought-leadershio-institute.png';
import researchWebinars from './images/EMCC.png';
// import Giovanni from './images/mainAssets/GIO photo.jpeg';
import samCoach from './images/mainAssets/coach-sam.jpg';
import pauline from './images/mainAssets/pauline.jpg';
import sara from './images/coach-sara.jpg';
import Aylam from './images/Aylam.jpeg'
import shailen from './images/shailen.png';
import coaching from './images/mainAssets/1-vs-1-coaching.jpg';
import groupAndTeamCoaching from './images/mainAssets/group-and-team-coaching.jpg';
import onDemandCoaching from './images/mainAssets/on-demand-coaching.jpg';
import toCoachOrNot from './images/mainAssets/to-coach-or-not.jpg';
import whoseRoleIsItAnyway from './images/mainAssets/whose-role-is-it-anyway.jpg';
import coachingSkills from './images/mainAssets/coaching-skills.jpg';
import careerCounsellingSkills from './images/mainAssets/career-counselling-skills.jpg';
import mentoringSkills from './images/mainAssets/mentoring-skills.jpg';
import buildingCoachingCulture from './images/mainAssets/building-coaching-culture.jpg';
import skillsForHR from './images/mainAssets/skills-for-hr.jpg';
import skillsForSupervisors from './images/mainAssets/skills-for-supervisors.jpg';
import choosingACoach from './images/coacheeAssets/choosing-a-coach.jpg';
import coachingVSMentoring from './images/coacheeAssets/coaching-vs-mentoring.jpg';
import whyUseACoach from './images/coacheeAssets/why-use-a-coach.jpg';
import askYourBoss from './images/coacheeAssets/how-to-ask-your-boss-for-a-coach.jpg';
import conductAChemistryMeeting from './images/coacheeAssets/how-to-conduct-a-chemistry-meeting.jpg';
import whatToExpectInYourFirstSession from './images/coacheeAssets/what-to-expect-in-your-first-session.jpg';
import whatAreChemistryMeetings from './images/coacheeAssets/what-are-chemistry-meetings.jpg';
import coachingIsEffective from './images/coacheeAssets/how-do-i-know-coaching-is-effective.jpg';
import whatAreCompetencies from './images/coacheeAssets/what-are-competencies.jpg';
import theRoleOfManagers from './images/mainAssets/the-role-of-managers.jpg';
import standardsAustralia from './images/standards-australia.png';
import avalyn from './images/avalyn.avif';
import elana from './images/elana.avif';
import erin from './images/erin.avif';
import jean from './images/jean.avif';
import signature from './images/JM.png';
import peterAttfield from './images/peter_attfield.jpg';
import wilson from './images/wilson.avif';
import Leadership from '../WorkshopsPage/images/Leadership.jpg'
import DesignThinking from '../WorkshopsPage/images//HR_design_thinkingjpg.jpg'
import TeamCoaching from '../WorkshopsPage/images/Team_Coaching.jpg'
import { GROUP_AND_TEAM_COACHING, ONE_VS_ONE_COACHING, ON_DEMAND_COACHING } from '../../util/types';
import ROB from '../../assets/coach-bob.jpeg';
import JUVERIA from '../../assets/javiera-coach.jpg';
import GIOVANNI from '../../assets/xxGiovanni-7383.jpg'
import ALYM from '../../assets/AYLAM 3x2.jpg'

import css from './LandingPage.module.css';

export const STAY_IN_PLACE_PAGE_NAME = 'StayInPlace';

/* Coach Homepage Resources */

const becomeCoachContent = [
  {
    id: 'globalClients',
    image: globalClients,
    imageTitleId: 'SectionLocations.globalClients',
    imageAltTextId: 'SectionLocations.globalClientsAltText',
    titleLink: 'https://boldlyapp.wistia.com/medias/yrz70tyyhd',
    imageLink: 'https://boldlyapp.wistia.com/medias/yrz70tyyhd',
  },
  {
    id: 'setYourOwn',
    image: setYourOwnRates,
    imageTitleId: 'SectionLocations.setYourOwnRates',
    imageAltTextId: 'SectionLocations.setYourOwnRatesAltText',
    titleLink: 'https://boldlyapp.wistia.com/medias/trarb8rhs3',
    imageLink: 'https://boldlyapp.wistia.com/medias/trarb8rhs3',
  },
  {
    id: 'optionalSupervision',
    image: optionalSupervision,
    imageTitleId: 'SectionLocations.optionalSupervision',
    imageAltTextId: 'SectionLocations.optionalSupervisionAltText',
    titleLink: 'https://boldlyapp.wistia.com/medias/oiilt6kz1c',
    imageLink: 'https://boldlyapp.wistia.com/medias/oiilt6kz1c',
  },
];

const coachIntroductionVideo = {
  url: 'https://boldlyapp.wistia.com/medias/12xxid3qf1',
  titleId: 'SectionVideo.coachIntroductionVideoTitle',
};

const coachButtonList = [
  {
    pageName: 'SignupPage',
    textId: 'SectionButton.signUp',
    className: css.redButton,
    type: 'signup',
  },
  {
    pageName: 'LoginPage',
    textId: 'SectionButton.logIn',
    type: 'signin',
  },
];

const yourPeersPageName = { pageName: STAY_IN_PLACE_PAGE_NAME };

const yourPeersContent = [
  {
    id: 'sam',
    image: samCoach,
    imageTitleId: 'SectionYourPeers.sam',
    imageAltTextId: 'SectionYourPeers.samAltText',
    imageSubTitleId: 'SectionYourPeers.samLocation',
    ...yourPeersPageName,
  },
  {
    id: 'sara',
    image: sara,
    imageTitleId: 'SectionYourPeers.sara',
    imageAltTextId: 'SectionYourPeers.saraAltText',
    imageSubTitleId: 'SectionYourPeers.saraLocation',
    ...yourPeersPageName,
  },
  {
    id: 'Aylam',
    image: ALYM,
    imageTitleId: 'SectionFindQualifiedCoaches.Aylam',
    imageAltTextId: 'SectionFindQualifiedCoaches.robAltText',
    imageSubTitleId: 'SectionFindQualifiedCoaches.robLocation',
    ...yourPeersPageName,
  },
  {
    id: 'pauline',
    image: pauline,
    imageTitleId: 'SectionYourPeers.pauline',
    imageAltTextId: 'SectionYourPeers.paulineAltText',
    imageSubTitleId: 'SectionYourPeers.paulineLocation',
    ...yourPeersPageName,
  },
  {
    id: 'tbc',
    image: shailen,
    imageTitleId: 'SectionYourPeers.tbc',
    imageAltTextId: 'SectionYourPeers.tbcAltText',
    imageSubTitleId: 'SectionYourPeers.tbcLocation',
    ...yourPeersPageName,
  },
  {
    id: 'giovanni',
    image: GIOVANNI,
    imageTitleId: 'SectionFindQualifiedCoaches.giovanni',
    imageAltTextId: 'SectionFindQualifiedCoaches.giovanniAltText',
    imageSubTitleId: 'SectionFindQualifiedCoaches.giovanniLocation',
    ...yourPeersPageName,
   
  },
];

const wantResourcesContent = [
  {
    id: 'instituteOfCoaching',
    image: instituteOfCoaching,
    imageTitleId: 'SectionWantResources.instituteOfCoaching',
    imageAltTextId: 'SectionWantResources.instituteOfCoachingAltText',
    titleLink: 'https://instituteofcoaching.org/',
    isShowOriginalImage: true,
    imageLink: 'https://instituteofcoaching.org/',
  },
  {
    id: 'libraryOfCoaching',
    image: libraryProfessional,
    imageTitleId: 'SectionWantResources.libraryOfCoaching',
    imageAltTextId: 'SectionWantResources.libraryOfCoachingAltText',
    titleLink: 'https://libraryofprofessionalcoaching.com/',
    isShowOriginalImage: true,
    imageLink: 'https://libraryofprofessionalcoaching.com/',
  },
  {
    id: 'positivePsychology',
    image: positivePsychology,
    imageTitleId: 'SectionWantResources.positivePsychology',
    imageAltTextId: 'SectionWantResources.positivePsychologyAltText',
    titleLink: 'https://positivepsychology.com/',
    isShowOriginalImage: true,
    imageLink: 'https://positivepsychology.com/',
    cover: true,
  },
  {
    id: 'thoughtLeadership',
    image: thoughtLeadership,
    imageTitleId: 'SectionWantResources.thoughtLeadership',
    imageAltTextId: 'SectionWantResources.thoughtLeadershipAltText',
    titleLink: 'https://thoughtleadership.org/',
    isShowOriginalImage: true,
    imageLink: 'https://thoughtleadership.org/',
  },
  {
    id: 'researchWebinars',
    image: researchWebinars,
    imageTitleId: 'SectionWantResources.researchWebinars',
    imageAltTextId: 'SectionWantResources.researchWebinarsAltText',
    titleLink: 'https://www.emccglobal.org/research/research_webinars/',
    isShowOriginalImage: true,
    imageLink: 'https://www.emccglobal.org/research/research_webinars/',
  },
  {
    id: 'thePsychology',
    image: standardsAustralia,
    imageTitleId: 'SectionWantResources.standardsAustralia',
    imageAltTextId: 'SectionWantResources.standardsAustraliaAltText',
    titleLink:
      'http://www.duncansutherland.com.au/images/stories/downloads/coaching_in_organisations.pdf',
    isShowOriginalImage: true,
    imageLink:
      'http://www.duncansutherland.com.au/images/stories/downloads/coaching_in_organisations.pdf',
  },
];

export const coachHomePageResources = {
  becomeCoachContent,
  coachIntroductionVideo,
  coachButtonList,
  yourPeersContent,
  wantResourcesContent,
};

/* Main Homepage Resources */

const findQualifiedCoachesContent = [
  {
    id: 'sam',
    image: samCoach,
    imageTitleId: 'SectionFindQualifiedCoaches.sam',
    imageAltTextId: 'SectionFindQualifiedCoaches.samAltText',
    imageSubTitleId: 'SectionFindQualifiedCoaches.samLocation',
    imagePageName: 'SearchPage',
    pageName: 'SearchPage',
    isPublicPage: true,
  },
  {
    id: 'sara',
    image: sara,
    imageTitleId: 'SectionFindQualifiedCoaches.sara',
    imageAltTextId: 'SectionFindQualifiedCoaches.saraAltText',
    imageSubTitleId: 'SectionFindQualifiedCoaches.saraLocation',
    imagePageName: 'SearchPage',
    pageName: 'SearchPage',
    isPublicPage: true,
  },
  {
    id: 'Aylam',
    image: ALYM,
    imageTitleId: 'SectionFindQualifiedCoaches.Aylam',
    imageAltTextId: 'SectionFindQualifiedCoaches.robAltText',
    imageSubTitleId: 'SectionFindQualifiedCoaches.robLocation',
    imagePageName: 'SearchPage',
    pageName: 'SearchPage',
    isPublicPage: true,
  },
  {
    id: 'pauline',
    image: pauline,
    imageTitleId: 'SectionFindQualifiedCoaches.pauline',
    imageAltTextId: 'SectionFindQualifiedCoaches.paulineAltText',
    imageSubTitleId: 'SectionFindQualifiedCoaches.paulineLocation',
    imagePageName: 'SearchPage',
    pageName: 'SearchPage',
    isPublicPage: true,
  },
  {
    id: 'shailen',
    image: shailen,
    imageTitleId: 'SectionFindQualifiedCoaches.shailen',
    imageAltTextId: 'SectionFindQualifiedCoaches.shailenAltText',
    imageSubTitleId: 'SectionFindQualifiedCoaches.shailenLocation',
    imagePageName: 'SearchPage',
    pageName: 'SearchPage',
    isPublicPage: true,
  },
  {
    id: 'giovanni',
    image: GIOVANNI,
    imageTitleId: 'SectionFindQualifiedCoaches.giovanni',
    imageAltTextId: 'SectionFindQualifiedCoaches.giovanniAltText',
    imageSubTitleId: 'SectionFindQualifiedCoaches.giovanniLocation',
    imagePageName: 'SearchPage',
    pageName: 'SearchPage',
    isPublicPage: true,
  },
];

const onePlatformContent = [
  {
    id: ONE_VS_ONE_COACHING,
    image: coaching,
    imageTitleId: 'SectionLocations.coaching',
    imageAltTextId: 'SectionLocations.coachingAltText',
    pageName: 'CoachingFormatsPage',
    imagePageName: 'CoachingFormatsPage',
    isPublicPage: true,
  },
  {
    id: GROUP_AND_TEAM_COACHING,
    image: groupAndTeamCoaching,
    imageTitleId: 'SectionLocations.groupAndTeamCoaching',
    imageAltTextId: 'SectionLocations.groupAndTeamCoachingAltText',
    pageName: 'CoachingFormatsPage',
    imagePageName: 'CoachingFormatsPage',
    isPublicPage: true,
  },
  {
    id: ON_DEMAND_COACHING,
    image: onDemandCoaching,
    imageTitleId: 'SectionLocations.onDemandCoaching',
    imageAltTextId: 'SectionLocations.onDemandCoachingAltText',
    pageName: 'CoachingFormatsPage',
    imagePageName: 'CoachingFormatsPage',
    isPublicPage: true,
  },
];

const mainButtonList = [
  {
    pageName: 'SearchPage',
    textId: 'SectionButton.searchCoaches',
    className: css.redButton,
  },
  {
    pageName: 'NewEngagementPage',
    textId: 'SectionButton.newEngagement',
  },
];

const freeCoachingResourcesContent = [
  {
    id: 'toCoachOrNot',
    image: toCoachOrNot,
    imageTitleId: 'SectionFreeCoachingResources.toCoachOrNot',
    imageAltTextId: 'SectionFreeCoachingResources.toCoachOrNotAltText',
    imageSubTitleId: 'SectionFreeCoachingResources.toCoachOrNotSubTitle',
    titleLink: 'https://www.canva.com/design/DAFaUFUUkRU/GPgBqQ_tqzjDMfE1U4iCAA/view?utm_content=DAFaUFUUkRU&utm_campaign=designshare&utm_medium=link&utm_source=editor',
    imageLink: 'https://www.canva.com/design/DAFaUFUUkRU/GPgBqQ_tqzjDMfE1U4iCAA/view?utm_content=DAFaUFUUkRU&utm_campaign=designshare&utm_medium=link&utm_source=editor',
  },
  {
    id: 'theRoleOfManagers',
    image: theRoleOfManagers,
    imageTitleId: 'SectionFreeCoachingResources.theRoleOfManagers',
    imageAltTextId: 'SectionFreeCoachingResources.theRoleOfManagersAltText',
    imageSubTitleId: 'SectionFreeCoachingResources.theRoleOfManagersSubTitle',
    titleLink: 'https://www.canva.com/design/DAFaWZu2E4s/10dqi4oZligX2xs2QdT4lA/view?utm_content=DAFaWZu2E4s&utm_campaign=designshare&utm_medium=link&utm_source=editor',
    imageLink: 'https://www.canva.com/design/DAFaWZu2E4s/10dqi4oZligX2xs2QdT4lA/view?utm_content=DAFaWZu2E4s&utm_campaign=designshare&utm_medium=link&utm_source=editor',
  },
  {
    id: 'whoseRoleIsItAnyway',
    image: whoseRoleIsItAnyway,
    imageTitleId: 'SectionFreeCoachingResources.whoseRoleIsItAnyway',
    imageAltTextId: 'SectionFreeCoachingResources.whoseRoleIsItAnywayAltText',
    imageSubTitleId: 'SectionFreeCoachingResources.whoseRoleIsItAnywaySubTitle',
    titleLink: 'https://www.canva.com/design/DAFaWX9Xqac/jMpRBKufyjs-jZVSFepeQw/view?utm_content=DAFaWX9Xqac&utm_campaign=designshare&utm_medium=link&utm_source=editor',
    imageLink: 'https://www.canva.com/design/DAFaWX9Xqac/jMpRBKufyjs-jZVSFepeQw/view?utm_content=DAFaWX9Xqac&utm_campaign=designshare&utm_medium=link&utm_source=editor',
  },
];

const mainIntroductionVideo = {
  url: 'https://boldlyapp.wistia.com/medias/jdokj233cm',
  titleId: 'SectionVideo.mainIntroductionVideoTitle',
  titleId1: 'SectionVideo.coacheeIntroductionVideoTitle1',
  subtitleId2: 'SectionVideo.mainIntroductionVideoSubtitle2',
  subtitleId02: 'SectionVideo.mainIntroductionVideoSubtitle02',  
  subtitleId3: 'SectionVideo.mainIntroductionVideoSubtitle3',
  subtitleId03: 'SectionVideo.mainIntroductionVideoSubtitle03',
  subtitleId4: 'SectionVideo.mainIntroductionVideoSubtitle4',
  subtitleId04: 'SectionVideo.mainIntroductionVideoSubtitle04',
  subtitleId5: 'SectionVideo.mainIntroductionVideoSubtitle5',


};

export const coachingCultureWorkshopsContent = [
  {
    id: 'mentoringProgramsForOrganisations',
    image: mentoringSkills,
    imageTitleId: 'SectionLocations.mentoringProgramsForOrganisations',
    imageAltTextId: 'SectionLocations.mentoringProgramsForOrganisationsAltText',
    pageName: 'MentoringProgramsPage',
    imagePageName: 'MentoringProgramsPage',
    isPublicPage: true,
  },
  {
    id: 'firstTimeManagersProgram',
    image: careerCounsellingSkills,
    imageTitleId: 'SectionLocations.firstTimeManagersProgram',
    imageAltTextId: 'SectionLocations.firstTimeManagersProgramAltText',
    pageName: 'FirstTimeManagersProgramPage',
    imagePageName: 'FirstTimeManagersProgramPage',
    isPublicPage: true,
  },
  {
    id: 'aspiringFemaleLeadersProgram',
    image: coachingSkills,
    imageTitleId: 'SectionLocations.aspiringFemaleLeadersProgram',
    imageAltTextId: 'SectionLocations.aspiringFemaleLeadersProgramAltText',
    pageName: 'AspiringFemaleLeadersProgramPage',
    imagePageName: 'AspiringFemaleLeadersProgramPage',
    isPublicPage: true,
  },
  {
    id: 'coachingSkillsForSupervisors',
    image: skillsForSupervisors,
    imageTitleId: 'SectionLocations.coachingSkillsForSupervisors',
    imageAltTextId: 'SectionLocations.coachingSkillsForSupervisorsAltText',
    pageName: 'CoachingSkillsForSupervisorsPage',
    imagePageName: 'CoachingSkillsForSupervisorsPage',
    isPublicPage: true,
  },
  {
    id: 'careerCounsellingSkillsForHR',
    image: skillsForHR,
    imageTitleId: 'SectionLocations.careerCounsellingSkillsForHR',
    imageAltTextId: 'SectionLocations.careerCounsellingSkillsForHRAltText',
    pageName: 'CareerCounselingSkillsForHRPage',
    imagePageName: 'CareerCounselingSkillsForHRPage',
    isPublicPage: true,
  },
  {
    id: 'buildingCoachingCulture',
    image: buildingCoachingCulture,
    imageTitleId: 'SectionLocations.buildingCoachingCulture',
    imageAltTextId: 'SectionLocations.buildingCoachingCultureAltText',
    pageName: 'BuildingCoachingCulturePage',
    imagePageName: 'BuildingCoachingCulturePage',
    isPublicPage: true,
  },
  {
    id: 'leadership',
    image: Leadership,
    imageTitleId: 'WorkshopsPage.leadership',
    imageAltTextId: 'WorkshopsPage.leadershipAlt',
    pageName: 'LeadershipPage',
    imagePageName: 'LeadershipPage',
    isPublicPage: true,
  },
  {
    id: 'designThinking',
    image: DesignThinking,
    imageTitleId: 'WorkshopsPage.designThinking',
    imageAltTextId: 'WorkshopsPage.designThinkingAlt',
    pageName: 'DesignThinkingPage',
    imagePageName: 'DesignThinkingPage',
    isPublicPage: true,
  },
  {
    id: 'teamCoaching',
    image: TeamCoaching,
    imageTitleId: 'WorkshopsPage.teamCoaching',
    imageAltTextId: 'WorkshopsPage.teamCoachingAlt',
    pageName: 'TeamCoachingPage',
    imagePageName: 'TeamCoachingPage',
    isPublicPage: true,
  },
];

export const mainHomePageResources = {
  findQualifiedCoachesContent,
  onePlatformContent,
  mainButtonList,
  freeCoachingResourcesContent,
  mainIntroductionVideo,
  coachingCultureWorkshopsContent,
};

/* Coachee Homepage Resources */

const whatIsCoachingContent = [
  {
    id: 'choosingACoach',
    image: choosingACoach,
    imageTitleId: 'SectionWhatIsCoaching.choosingACoach',
    imageAltTextId: 'SectionWhatIsCoaching.choosingACoachAltText',
    titleLink: 'https://boldlyapp.wistia.com/medias/2bp55zu936',
    imageLink: 'https://boldlyapp.wistia.com/medias/2bp55zu936',
    isVideoThumbnail: true,
  },
  {
    id: 'coachingVSMentoring',
    image: coachingVSMentoring,
    imageTitleId: 'SectionWhatIsCoaching.coachingVersusMentoring',
    imageAltTextId: 'SectionWhatIsCoaching.coachingVersusMentoringAltText',
    titleLink: 'https://boldlyapp.wistia.com/medias/mqgtab2bil',
    imageLink: 'https://boldlyapp.wistia.com/medias/mqgtab2bil',
    isVideoThumbnail: true,
  },
  {
    id: 'whyUseACoach',
    image: whyUseACoach,
    imageTitleId: 'SectionWhatIsCoaching.whyUseACoach',
    imageAltTextId: 'SectionWhatIsCoaching.whyUseACoachAltText',
    titleLink: 'https://boldlyapp.wistia.com/medias/sm7lka6n3q',
    imageLink: 'https://boldlyapp.wistia.com/medias/sm7lka6n3q',
    isVideoThumbnail: true,
  },
];

const coacheeIntroductionVideo = {
  url: 'https://boldlyapp.wistia.com/medias/jdokj233cm',
  titleId: 'SectionVideo.coacheeIntroductionVideoTitle',
};

const yourCoachingResourcesContent = [
  {
    id: 'askYourBoss',
    image: askYourBoss,
    imageTitleId: 'SectionYourCoachingResources.askYourBoss',
    imageAltTextId: 'SectionYourCoachingResources.askYourBossAltText',
    titleLink: 'https://www.canva.com/design/DAFaUL8fACc/DQoTV-OGO7ZBP3UJAlbvcg/view?utm_content=DAFaUL8fACc&utm_campaign=designshare&utm_medium=link&utm_source=editor',
  },
  {
    id: 'conductAChemistryMeeting',
    image: conductAChemistryMeeting,
    imageTitleId: 'SectionYourCoachingResources.conductAChemistryMeeting',
    imageAltTextId: 'SectionYourCoachingResources.conductAChemistryMeetingAltText',
    titleLink: 'https://www.canva.com/design/DAFao_zqB4M/LvAPDd_Yd8ExKtoXxcMS4w/view?utm_content=DAFao_zqB4M&utm_campaign=designshare&utm_medium=link&utm_source=editor',
  },
  {
    id: 'whatToExpectInYourFirstSession',
    image: whatToExpectInYourFirstSession,
    imageTitleId: 'SectionYourCoachingResources.whatToExpectInYourFirstSession',
    imageAltTextId: 'SectionYourCoachingResources.whatToExpectInYourFirstSessionAltText',
    titleLink: 'https://www.canva.com/design/DAFhuPy4YIA/QRi2HUtqvYYFwmEMIxm__w/view?utm_content=DAFhuPy4YIA&utm_campaign=designshare&utm_medium=link&utm_source=editor',
  },
  {
    id: 'whatAreChemistryMeetings',
    image: whatAreChemistryMeetings,
    imageTitleId: 'SectionYourCoachingResources.whatAreChemistryMeetings',
    imageAltTextId: 'SectionYourCoachingResources.whatAreChemistryMeetingsAltText',
    titleLink: 'https://boldlyapp.wistia.com/medias/g2bc67i37p',
    isVideoThumbnail: true,
  },
  {
    id: 'coachingIsEffective',
    image: coachingIsEffective,
    imageTitleId: 'SectionYourCoachingResources.coachingIsEffective',
    imageAltTextId: 'SectionYourCoachingResources.coachingIsEffectiveAltText',
    titleLink: 'https://boldlyapp.wistia.com/medias/pnsdh8s8s5',
    isVideoThumbnail: true,
  },
  {
    id: 'whatAreCompetencies',
    image: whatAreCompetencies,
    imageTitleId: 'SectionYourCoachingResources.whatAreCompetencies',
    imageAltTextId: 'SectionYourCoachingResources.whatAreCompetenciesAltText',
    titleLink: 'https://boldlyapp.wistia.com/medias/zsyy4ndsna',
    isVideoThumbnail: true,
  },
];

export const coacheeHomePageResources = {
  whatIsCoachingContent,
  coacheeIntroductionVideo,
  yourCoachingResourcesContent,
};

export const coachReviewCarousel = [
  {
    id: 'avalyn',
    role: 'MainLandingPageSection.avalyn.role',
    review: 'MainLandingPageSection.avalyn.review',
    thumbnail: avalyn,
  },
  {
    id: 'wilson',
    role: 'MainLandingPageSection.wilson.role',
    review: 'MainLandingPageSection.wilson.review',
    thumbnail: wilson,
  },
  {
    id: 'jean francois',
    role: 'MainLandingPageSection.jean-francois.role',
    review: 'MainLandingPageSection.jean-francois.review',
    thumbnail: jean,
  },
  {
    id: 'erin',
    role: 'MainLandingPageSection.erin.role',
    review: 'MainLandingPageSection.erin.review',
    thumbnail: erin,
  },
  {
    id: 'elana',
    role: 'MainLandingPageSection.elana.role',
    review: 'MainLandingPageSection.elana.review',
    thumbnail: elana,
  },
];

export const clientReviewCarousel = [
  {
    id: 'peter-attfield',
    role: 'MainLandingPageSection.peter-attfield.role',
    name: 'MainLandingPageSection.peter-attfield.name',
    review: 'MainLandingPageSection.peter-attfield.review',
    thumbnail: peterAttfield,
    signature: signature,
  },
];

export const mainLandingCarousel = [
  {
    id: 'mindset',
    quoteId: 'MainLandingPageSection.mindsetCarouselItem',
    rating: 'MainLandingPageSection.mindsetRating',
  },
  {
    id: 'goals',
    quoteId: 'MainLandingPageSection.goalsCarouselItem',
    rating: 'MainLandingPageSection.goalsRating',
  },
  {
    id: 'recommend',
    quoteId: 'MainLandingPageSection.recommendCarouselItem',
    rating: 'MainLandingPageSection.recommendRating',
  },
  {
    id: 'skills',
    quoteId: 'MainLandingPageSection.skillsCarouselItem',
    rating: 'MainLandingPageSection.skillsRating',
  },
];