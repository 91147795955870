import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../util/reactIntl';
import ExternalLink from '../ExternalLink/ExternalLink';

import sapLogo from './images/SAP.png';
import hrtechLogo from './images/hrtech.png';
import hoganLogo from './images/hogan.webp';
import HRTECH from '../../assets/hrtech3.png'
import auraLogo from './images/AURA.png';
import fitbotsLogo from './images/fitbots.png';
import getAheadLeadershipLogo from './images/getAheadLeadership.png';
import css from './SectionOurPartnerships.module.css';

const SectionOurPartnerships = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.title}>
        <div className={css.SectionOurPartnerships}>
          <h2 className={`${css.SectionOurPartnershipstitle} h1`}>{<FormattedMessage id="SectionOurPartnerships.title" />}</h2>
          <h2 className={`${css.sectionPartnerships} h1`}>{<FormattedMessage id="SectionOurPartnerships.title1" />}</h2>
        </div>
      </div>
      <div className={css.SectionOurPartnershipssubTitle}>
        <FormattedMessage id="SectionOurPartnerships.subTitle" />
      </div>
      <div className={css.SectionOurPartnershipssubTitle}>
        <FormattedMessage id="SectionOurPartnerships.subTitle1" />
      </div>

      <div className={css.container}>
        <ExternalLink href="https://boldly.app/blog/boldly-coach-marketplace-now-available-on-sap-store">
          <img src={sapLogo} alt="SAP logo" className={`${css.sapLogo} ${css.sapLogos}`} />
        </ExternalLink>

        {/* <ExternalLink href="https://boldly.app/blog/the-vital-role-of-professional-coaches-in-cultivating-resiliency">
          <img src={auraLogo} alt="AURA logo" className={css.auraLogo} />
        </ExternalLink> */}

        <ExternalLink href="https://www.youtube.com/watch?v=8dl0TaibR34">
          <img src={HRTECH} alt="hrtech logo" className={`${css.sapLogo} ${css.sapLogos} ${css.hrtechLogo}`} />
        </ExternalLink>
        <ExternalLink href="https://boldly.app/blog/leveraging-hogan-assessment-tools-empowering-executive">
          <img src={hoganLogo} alt="horgan logo" className={`${css.sapLogo} ${css.sapLogos}`} />
        </ExternalLink>

      </div>
      {/* <div className={css.secondContainer}>
        <div className={css.sapLogoSpace} /> */}

        {/* <ExternalLink href="https://boldly.app/blog/powerful-role-of-a-coach-in-setting-okrs">
          <img src={fitbotsLogo} alt="fitbots logo" className={css.fitbotsLogo} />
        </ExternalLink>

        <ExternalLink href="https://boldly.app/blog/the-power-of-an-onboarding-partnership">
          <img
            src={getAheadLeadershipLogo}
            alt="Get Ahead Leadership logo"
            className={css.getAheadLeadershipLogo}
          />
        </ExternalLink> */}
        {/* <div className={css.hrtechLogoSpace} /> */}
      {/* </div> */}
    </div>
  );
};

SectionOurPartnerships.defaultProps = { rootClassName: null, className: null };

SectionOurPartnerships.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionOurPartnerships;