import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconShortList.module.css';

const IconShortList = props => {
  const { className, rootClassName, isCoachListing } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <>
      {isCoachListing ? <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="30.25"
        height="25.581"
        viewBox="0 0 30.25 25.581"
      >
        <path
          d="M18,1.75H0A1.75,1.75,0,0,1-1.75,0,1.75,1.75,0,0,1,0-1.75H18A1.75,1.75,0,0,1,19.75,0,1.75,1.75,0,0,1,18,1.75Z"
          transform="translate(10.5 2.95)"
          fill="#fff"
        />
        <circle cx="3" cy="3" r="3" fill="#fff" />
        <path
          d="M18,1.75H0A1.75,1.75,0,0,1-1.75,0,1.75,1.75,0,0,1,0-1.75H18A1.75,1.75,0,0,1,19.75,0,1.75,1.75,0,0,1,18,1.75Z"
          transform="translate(10.5 12.531)"
          fill="#fff"
        />
        <circle cx="3" cy="3" r="3" transform="translate(0 9.581)" fill="#fff" />
        <circle cx="3" cy="3" r="3" transform="translate(0 19.581)" fill="#fff" />
        <path
          d="M0,1.781A1.75,1.75,0,0,1-1.75.034,1.75,1.75,0,0,1,0-1.719L15-1.75A1.75,1.75,0,0,1,16.75,0,1.75,1.75,0,0,1,15,1.75L0,1.781Z"
          transform="translate(10.5 22.5)"
          fill="#fff"
        />
      </svg> :

        <svg version="1.1" viewBox="0 0 1080 1080" width="1280" height="380" xmlns="http://www.w3.org/2000/svg">
          <path transform="translate(796,28)" d="m0 0h25l18 2 20 4 24 8 21 10 18 11 16 13 12 11 11 12 13 18 12 21 8 19 7 24 3 16 1 9v37l-3 20-6 23-7 18-8 16-9 15-12 16-14 15-11 10-16 12-15 9-15 8 11 23 11 27 10 30 7 27 6 31 3 24 1 14v58l-3 30-5 30-9 36-10 30-10 24-14 29-13 23-14 21-11 15-11 14-9 10-7 8-11 12-12 12-8 7-10 9-14 11-12 9-24 16-17 10-23 12-21 10-29 11-25 8-24 6-27 5-32 4-12 1h-49l-30-3-32-5-26-6-26-8-20-7-24-10-25-12-23-13-21-14-14-10-14-11-14-12-12-11-22-22-9-11-8-9-10-13-13-18-13-21-13-23-11-23-11-27-11-34-7-29-5-28-3-29-1-22v-22l2-35 4-28 5-25 8-31 9-26 10-24 12-25 11-20 12-19 14-20 11-14 11-13 11-12 7-7 4-5 8-7 9-9 11-9 12-10 18-13 15-10 18-11 22-12 19-9 25-10 37-12 24-6 36-6 29-3h56l30 3 31 5 29 7 3 1 1-9 8-24 12-25 12-18 9-11 9-10 11-11 14-11 14-9 23-12 25-9 23-5z" fill="#7C9AFE" />
          <path transform="translate(796,28)" d="m0 0h25l18 2 20 4 24 8 21 10 18 11 16 13 12 11 11 12 13 18 12 21 8 19 7 24 3 16 1 9v37l-3 20-6 23-7 18-8 16-9 15-12 16-14 15-11 10-16 12-15 9-21 10-21 7-23 5-19 2h-23l-24-3-21-5-22-8-16-8-15-9-12-9-14-12-11-11-9-11-11-16-9-16-8-18-6-18-5-24-1-8v-41l4-24 6-21 8-19 10-19 12-17 12-14 14-14 14-11 14-9 23-12 25-9 23-5z" fill="#FEFEFE" />
          <path transform="translate(489,410)" d="m0 0h16l10 4 8 6 7 11 2 7v13l-4 11-8 9 15 10 9 10 6 11 3 9 1 5v62l-3 3h-48l1 30 4-1h7l8 3 7 8 1 4 5-2h10l8 4 6 8v2l4-1h9v-14l2-13 5-12 6-9 9-9 12-7-6-8-4-7-2-9v-7l3-11 6-9 7-6 8-4 4-1h15l10 4 8 6 6 8 3 8 1 11-3 12-6 9-3 4 12 7 10 10 6 10 4 10 1 4v66l-2 2h-95l-1 18-4 22-6 18-12 24 8 1 5 5 1 4v24l-3 7-6 3h-105l-6-4-2-4v-28l3-5 6-3 4-1-2-9-9-15-12-18-4-8-5-28-6-12-9-8-4-2v14l-3 5h-109l-2-2v-66l4-12 5-9 9-10 9-7 6-3-6-8-4-7-2-10 1-11 4-10 8-9 8-5 7-2h15l9 3 9 7 6 8 3 8 1 11-3 12-6 9-3 4 12 7 10 10 6 10 4 10 1 8 11 1 12 5 19 9-1-50-18-1-3-3v-62l3-12 5-10 7-9 9-8 10-6-8-9-4-11v-13l4-11 6-8 11-7z" fill="#fff" />
          <path transform="translate(479,549)" d="m0 0h6l5 4 1 2 1 80h8l2-2 2-7 5-4 7 1 4 4 1 18 2 3h6l2-2 3-9 3-2h7l5 5 1 17 1 4h6l3-2 3-9 6-3 6 2 3 3 1 8-1 29-3 22-5 18-8 18-8 16h-69l-4-15-9-15-12-18-4-9-4-23-4-11-7-10-8-7-16-9-7-8v-5l3-3 3-1h10l12 5 26 13h7l2-4 1-89z" fill="#7C9AFE" />
          <path transform="translate(748,141)" d="m0 0h17l12 3 10 5 8 8 6 10 5 16 2 14v20l-3 19-5 13-7 11-9 7-12 5-5 1h-21l-11-3-8-4-8-7-6-9-5-13-3-17v-26l3-16 5-13 7-11 11-8 11-4z" fill="#232323" />
          <path transform="translate(862,140)" d="m0 0h15l12 3 10 6 7 7 5 10 2 7v15l-4 13-8 13-9 10-15 15-8 7 47 1v25h-92v-24l11-10 11-9 13-12 12-12 7-11 3-11-2-11-5-4-9-1-6 3-4 6-2 8h-30l1-12 4-11 4-6 7-7 10-5 6-2z" fill="#232323" />
          <path transform="translate(626,579)" d="m0 0h9l12 3 8 4 10 9 4 5 4 8 2 6 1 8v47l-1 1h-84l-1-1-1-19-3-6-1-1v-22l2-11 6-12 11-11 12-6z" fill="#7C9AFE" />
          <path transform="translate(359,579)" d="m0 0h9l12 3 8 4 10 9 6 8 4 11v6l-7 6-2 5v9l4 8 6 6v15l-1 1h-89l-1-1v-48l2-11 6-12 10-10 9-5 8-3z" fill="#7C9AFE" />
          <path transform="translate(492,479)" d="m0 0h10l12 3 9 5 9 8 6 9 4 13v53h-39l-1-16-3-8-7-6-6-2h-8l-10 5-4 6-1 3-1 17-1 1h-9v-53l4-13 7-10 9-8 11-5z" fill="#7C9AFE" />
          <path transform="translate(752,170)" d="m0 0h8l8 3 5 6 3 8 1 6v28l-3 13-7 8-5 2h-11l-8-4-5-8-2-9v-32l4-13 7-6z" fill="#FEFEFE" />
          <path transform="translate(471,775)" d="m0 0h97l1 1v19h-98z" fill="#7C9AFE" />
          <path transform="translate(630,521)" d="m0 0 10 2 6 4 5 6 3 9-1 10-5 8-8 6-3 1h-13l-8-4-6-7-3-8v-7l4-10 8-7 5-2z" fill="#7C9AFE" />
          <path transform="translate(363,521)" d="m0 0 10 2 6 4 5 6 3 8-1 11-5 8-8 6-3 1h-13l-8-4-6-7-3-9 1-10 5-8 6-5 5-2z" fill="#7C9AFE" />
          <path transform="translate(496,421)" d="m0 0 10 2 8 5 5 8 1 3v11l-4 8-8 7-5 2h-12l-8-4-7-8-2-5v-11l4-8 5-5 7-4z" fill="#7C9AFE" />
        </svg>
      }
    </>
  );
};

const { string } = PropTypes;

IconShortList.defaultProps = {
  className: null,
  rootClassName: null,
};

IconShortList.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconShortList;
