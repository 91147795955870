import React from 'react';
import { FormattedMessage } from 'react-intl';

const FormattedMessageWithClass = props => (
  <FormattedMessage {...props}>
    {txt => <span className={props.className}>{txt}</span>}
  </FormattedMessage>
);

export default FormattedMessageWithClass;
