import React from 'react';
import loadable from '@loadable/component';
import config from './config';
import getPageDataLoadingAPI from './containers/pageDataLoadingAPI';
import { NotFoundPage } from './containers';

// routeConfiguration needs to initialize containers first
// Otherwise, components will import form container eventually and
// at that point css bundling / imports will happen in wrong order.
import { NamedRedirect } from './components';
import { ENGAGEMENT_LISTING, GROUP_AND_TEAM_COACHING, ONE_VS_ONE_COACHING, ON_DEMAND_COACHING } from './util/types';
import { ADMIN_ROLE, BUYER_ROLE, COACHEE_ROLE, COACH_ROLE, GUEST_ROLE } from './marketplace-custom-config';

const pageDataLoadingAPI = getPageDataLoadingAPI();

const AboutPage = loadable(() => import(/* webpackChunkName: "AboutPage" */ './containers/AboutPage/AboutPage'));
const AuthenticationPage = loadable(() => import(/* webpackChunkName: "AuthenticationPage" */ './containers/AuthenticationPage/AuthenticationPage'));
const CheckoutPage = loadable(() => import(/* webpackChunkName: "CheckoutPage" */ './containers/CheckoutPage/CheckoutPage'));
const ContactDetailsPage = loadable(() => import(/* webpackChunkName: "ContactDetailsPage" */ './containers/ContactDetailsPage/ContactDetailsPage'));
const EditListingPage = loadable(() => import(/* webpackChunkName: "EditListingPage" */ './containers/EditListingPage/EditListingPage'));
const EmailVerificationPage = loadable(() => import(/* webpackChunkName: "EmailVerificationPage" */ './containers/EmailVerificationPage/EmailVerificationPage'));
const InboxPage = loadable(() => import(/* webpackChunkName: "InboxPage" */ './containers/InboxPage/InboxPage'));
const LandingPage = loadable(() => import(/* webpackChunkName: "LandingPage" */ './containers/LandingPage/LandingPage'));
const ListingPage = loadable(() => import(/* webpackChunkName: "ListingPage" */ /* webpackPrefetch: true */ './containers/ListingPage/ListingPage'));
const PasswordChangePage = loadable(() => import(/* webpackChunkName: "PasswordChangePage" */ './containers/PasswordChangePage/PasswordChangePage'));
const PasswordRecoveryPage = loadable(() => import(/* webpackChunkName: "PasswordRecoveryPage" */ './containers/PasswordRecoveryPage/PasswordRecoveryPage'));
const PasswordResetPage = loadable(() => import(/* webpackChunkName: "PasswordResetPage" */ './containers/PasswordResetPage/PasswordResetPage'));
const PaymentMethodsPage = loadable(() => import(/* webpackChunkName: "PaymentMethodsPage" */ './containers/PaymentMethodsPage/PaymentMethodsPage'));
const PrivacyPolicyPage = loadable(() => import(/* webpackChunkName: "PrivacyPolicyPage" */ './containers/PrivacyPolicyPage/PrivacyPolicyPage'));
const ProfilePage = loadable(() => import(/* webpackChunkName: "ProfilePage" */ './containers/ProfilePage/ProfilePage'));
const ProfileSettingsPage = loadable(() => import(/* webpackChunkName: "ProfileSettingsPage" */ './containers/ProfileSettingsPage/ProfileSettingsPage'));
const SearchPage = loadable(() => import(/* webpackChunkName: "SearchPage" */ /* webpackPrefetch: true */  './containers/SearchPage/SearchPage'));
const StripePayoutPage = loadable(() => import(/* webpackChunkName: "StripePayoutPage" */ './containers/StripePayoutPage/StripePayoutPage'));
const TermsOfServicePage = loadable(() => import(/* webpackChunkName: "TermsOfServicePage" */ './containers/TermsOfServicePage/TermsOfServicePage'));
const TransactionPage = loadable(() => import(/* webpackChunkName: "TransactionPage" */ './containers/TransactionPage/TransactionPage'));
const ManageListingsPage = loadable(() => import(/* webpackChunkName: "ManageListingsPage" */ './containers/ManageListingsPage/ManageListingsPage'));
const MentoringProgramsPage = loadable(() => import(/* webpackChunkName: "MentoringProgramsPage" */'./containers/MentoringProgramsPage/MentoringProgramsPage'));
const AdsMarketplacePage = loadable(() => import(/* webpackChunkName: "AdsMarketplacePage" */'./containers/AdsMarketplacePage/AdsMarketplacePage'));
const AdsMarketplaceHongKong = loadable(() => import(/* webpackChunkName: "AdsMarketplaceHongKong" */'./containers/AdsMarketplaceHongKong/AdsMarketplaceHongKong'));
const AdsMarketplaceNewZealand = loadable(() => import(/* webpackChunkName: "AdsMarketplaceNewZealand" */'./containers/AdsMarketplaceNewZealand/AdsMarketplaceNewZealand'));
const AdsMarketplaceSingapore = loadable(() => import(/* webpackChunkName: "AdsMarketplaceSingapore" */'./containers/AdsMarketplaceSingapore/AdsMarketplaceSingapore'));
const AdsMarketplaceDubai = loadable(() => import(/* webpackChunkName: "AdsMarketplaceDubai" */'./containers/AdsMarketplaceDubai/AdsMarketplaceDubai'));
const AdsMarketplaceBelgium = loadable(() => import(/* webpackChunkName: "AdsMarketplaceBelgium" */'./containers/AdsMarketplaceBelgium/AdsMarketplaceBelgium'));
const AdsWorkshopHongKong = loadable(() => import(/* webpackChunkName: "AdsWorkshopHongKong" */'./containers/AdsWorkshopHongKong/AdsWorkshopHongKong'));
const AdsWorkshopNewZealand = loadable(() => import(/* webpackChunkName: "AdsWorkshopNewZealand" */'./containers/AdsWorkshopNewZealand/AdsWorkshopNewZealand'));
const AdsWorkshopDubai = loadable(() => import(/* webpackChunkName: "AdsWorkshopDubai" */'./containers/AdsWorkshopDubai/AdsWorkshopDubai'));
const AdsWorkshopSingapore = loadable(() => import(/* webpackChunkName: "AdsWorkshopSingapore" */'./containers/AdsWorkshopSingapore/AdsWorkshopSingapore'));
const AdsWorkshopBelgium = loadable(() => import(/* webpackChunkName: "AdsWorkshopBelgium" */'./containers/AdsWorkshopBelgium/AdsWorkshopBelgium'));
const AdsExecutivePage = loadable(() => import(/* webpackChunkName: "AdsExecutivePage" */'./containers/AdsExecutivePage/AdsExecutivePage'));
const AdsExecutiveAuckland = loadable(() => import(/* webpackChunkName: "AdsExecutiveAuckland" */'./containers/AdsExecutiveAuckland/AdsExecutiveAuckland'));
const AdsExecutiveHongKong = loadable(() => import(/* webpackChunkName: "AdsExecutiveHongKong" */'./containers/AdsExecutiveHongKong/AdsExecutiveHongKong'));
const AdsExecutiveSingapore = loadable(() => import(/* webpackChunkName: "AdsExecutiveSingapore" */'./containers/AdsExecutiveSingapore/AdsExecutiveSingapore'));
const AdsExecutiveDubai = loadable(() => import(/* webpackChunkName: "AdsExecutiveDubai" */'./containers/AdsExecutiveDubai/AdsExecutiveDubai'));
const AdsCoachingFormatsPage = loadable(() => import(/* webpackChunkName: "AdsCoachingFormatsPage" */'./containers/AdsCoachingFormatsPage/AdsCoachingFormatsPage'));
const AdsLeadershipPage = loadable(() => import(/* webpackChunkName: "AdsLeadershipPage" */'./containers/AdsLeadershipPage/AdsLeadershipPage'));
const AdsCorporateCoaching = loadable(() => import(/* webpackChunkName: "AdsCorporateCoaching" */'./containers/AdsCorporateCoaching/AdsCorporateCoaching'));
const AdsWorkshop = loadable(() => import(/* webpackChunkName: "AdsWorkshop" */'./containers/AdsWorkshop/AdsWorkshop'));
const FirstTimeManagersProgramPage = loadable(() => import(/* webpackChunkName: "FirstTimeManagersProgramPage" */ './containers/FirstTimeManagersProgramPage/FirstTimeManagersProgramPage'));
const AspiringFemaleLeadersProgramPage = loadable(() => import(/* webpackChunkName: "AspiringFemaleLeadersProgramPage" */ './containers/AspiringFemaleLeadersProgramPage/AspiringFemaleLeadersProgramPage'));
const CoachingSkillsForSupervisorsPage = loadable(() => import(/* webpackChunkName: "CoachingSkillsForSupervisorsPage" */ './containers/CoachingSkillsForSupervisorsPage/CoachingSkillsForSupervisorsPage'));
const BuildingCoachingCulturePage = loadable(() => import(/* webpackChunkName: "BuildingCoachingCulturePage" */ './containers/BuildingCoachingCulturePage/BuildingCoachingCulturePage'));
const CareerCounselingSkillsForHRPage = loadable(() => import(/* webpackChunkName: "CareerCounselingSkillsForHRPage" */ './containers/CareerCounselingSkillsForHRPage/CareerCounselingSkillsForHRPage'));
const CoachingFormatsPage = loadable(() => import(/* webpackChunkName: "CoachingFormatsPage" */ './containers/CoachingFormatsPage/CoachingFormatsPage'));
const BlogPage = loadable(() => import(/* webpackChunkName: "BlogPage" */ './containers/BlogPage/BlogPage'));
const SingleBlogPage = loadable(() => import(/* webpackChunkName: "SingleBlogPage" */ './containers/SingleBlogPage/SingleBlogPage'));
const CoachListPage = loadable(() => import(/* webpackChunkName: "CoachListPage" */ './containers/CoachListPage/CoachListPage'));
const CaseStudyNavigationPage = loadable(() => import(/* webpackChunkName: "CaseStudyNavigationPage" */ './containers/CaseStudyNavigationPage/CaseStudyNavigationPage'));
const CaseStudyPage = loadable(() => import(/* webpackChunkName: "CaseStudyPage" */ './containers/CaseStudyPage/CaseStudyPage'));
const KnowledgeHubNavigationPage = loadable(() => import(/* webpackChunkName: "KnowledgeHubNavigationPage" */ './containers/KnowledgeHubNavigationPage/KnowledgeHubNavigationPage'));
const KnowledgeHubPage = loadable(() => import(/* webpackChunkName: "KnowledgeHubPage" */ './containers/KnowledgeHubPage/KnowledgeHubPage'));
const WorkshopsPage = loadable(() => import(/* webpackChunkName: "WorkshopsPage" */ './containers/WorkshopsPage/WorkshopsPage'));
const LeadershipPage = loadable(() => import(/* webpackChunkName: "LeadershipPage" */ './containers/LeadershipPage/LeadershipPage'));
const DesignThinkingPage = loadable(() => import(/* webpackChunkName: "DesignThinkingPage" */ './containers/DesignThinkingPage/DesignThinkingPage'));
const TeamCoachingPage = loadable(() => import(/* webpackChunkName: "TeamCoachingPage" */ './containers/TeamCoachingPage/TeamCoachingPage'));
const HongKongPage = loadable(() => import(/* webpackChunkName: "HongKongPage" */'./containers/HongKongPage/HongKongPage'));
// IMPORTANT NOTE: Whenever adding a new static page, please update it to the defaultPublicPaths 
// variable in the file server/resources/sitemap/sitemapDefault.

// Styleguide helps you to review current components and develop new ones
const StyleguidePage = loadable(() => import(/* webpackChunkName: "StyleguidePage" */ './containers/StyleguidePage/StyleguidePage'));

export const ACCOUNT_SETTINGS_PAGES = [
  'ContactDetailsPage',
  'PasswordChangePage',
  'StripePayoutPage',
  'PaymentMethodsPage',
];

// https://en.wikipedia.org/wiki/Universally_unique_identifier#Nil_UUID
const draftId = '00000000-0000-0000-0000-000000000000';
const draftSlug = 'draft';

const RedirectToLandingPage = () => <NamedRedirect name="LandingPage" />;

// NOTE: Most server-side endpoints are prefixed with /api. Requests to those
// endpoints are indended to be handled in the server instead of the browser and
// they will not render the application. So remember to avoid routes starting
// with /api and if you encounter clashing routes see server/index.js if there's
// a conflicting route defined there.

// Our routes are exact by default.
// See behaviour from Routes.js where Route is created.

const routeConfiguration = () => ([
  {
    path: '/',
    name: 'LandingPage',
    component: LandingPage,
  },
  {
    path: '/a',
    name: 'GetaCoachPage',
    component: ()=><></>,
  },
  {
    path: '/become-a-coach',
    name: 'CoachLandingPage',
    component: () => <LandingPage isShowCoachHomepage />,
  },
  {
    path: '/about',
    name: 'AboutPage',
    component: AboutPage,
  },
  {
    path: '/mentoring-programs-for-organisations',
    name: 'MentoringProgramsPage',
    component: MentoringProgramsPage,
  },
  {
    path: '/ads-marketplace',
    name: 'AdsMarketplacePage',
    component: AdsMarketplacePage,
  },
  {
    path: '/ads-executive',
    name: 'AdsExecutivePage',
    component: AdsExecutivePage,
  },
  {
    path: '/ads-corporate-coaching',
    name: 'AdsCorporateCoaching',
    component: AdsCorporateCoaching,
  },
  {
    path: '/ads-coaching-formats',
    name: 'AdsCoachingFormatsPage',
    component: AdsCoachingFormatsPage,
  },
  {
    path: '/ads-leadership',
    name: 'AdsLeadershipPage',
    component: AdsLeadershipPage,
  },
  {
    path: '/ads-workshop',
  name: 'AdsWorkshop',
  component: AdsWorkshop,
  },
  {
  path: '/ads-executive-auckland',
  name: 'AdsExecutiveAuckland',
  component: AdsExecutiveAuckland,
  },
  {
    path: '/ads-executive-hongkong',
    name: 'AdsExecutiveHongKong',
    component: AdsExecutiveHongKong,
  },
  {
    path: '/ads-executive-singapore',
    name: 'AdsExecutiveSingapore',
    component: AdsExecutiveSingapore,
  },
  {
    path: '/ads-workshop-sg',
    name: 'AdsWorkshopSingapore',
    component: AdsWorkshopSingapore,
  },
  {
    path: '/ads-workshop-eu',
    name: 'AdsWorkshopBelgium',
    component: AdsWorkshopBelgium,
  },
  {
    path: '/ads-executive-dubai',
    name: 'AdsExecutiveDubai',
    component: AdsExecutiveDubai,
  },
  {
    path: '/ads-marketplace-hk',
    name: 'AdsMarketplaceHongKong',
    component: AdsMarketplaceHongKong,
  },
  {
    path: '/ads-marketplace-nz',
    name: 'AdsMarketplaceNewZealand',
    component: AdsMarketplaceNewZealand,
  },
  {
    path: '/ads-marketplace-sg',
    name: 'AdsMarketplaceSingapore',
    component: AdsMarketplaceSingapore,
  },
  {
    path: '/ads-marketplace-dxb',
    name: 'AdsMarketplaceDubai',
    component: AdsMarketplaceDubai,
  },
  {
    path: '/ads-workshop-dxb',
    name: 'AdsWorkshopDubai',
    component: AdsWorkshopDubai,
  },
  
  {
    path: '/ads-marketplace-belg',
    name: 'AdsMarketplaceBelgium',
    component: AdsMarketplaceBelgium,
  },
  {
    path: '/ads-workshop-hk',
    name: 'AdsWorkshopHongKong',
    component: AdsWorkshopHongKong,
  },
  {
    path: '/ads-workshop-nz',
    name: 'AdsWorkshopNewZealand',
    component: AdsWorkshopNewZealand,
  },
  {
    path: '/first-time-managers-program',
    name: 'FirstTimeManagersProgramPage',
    component: FirstTimeManagersProgramPage,
  },
  {
    path: '/aspiring-female-leaders-program',
    name: 'AspiringFemaleLeadersProgramPage',
    component: AspiringFemaleLeadersProgramPage,
  },
  {
    path: '/coaching-skills-for-supervisors',
    name: 'CoachingSkillsForSupervisorsPage',
    component: CoachingSkillsForSupervisorsPage,
  },
  {
    path: '/building-coaching-culture',
    name: 'BuildingCoachingCulturePage',
    component: BuildingCoachingCulturePage,
  },
  {
    path: '/career-counseling-skills-for-hr',
    name: 'CareerCounselingSkillsForHRPage',
    component: CareerCounselingSkillsForHRPage,
  },
  {
    path: '/coaching-formats',
    name: 'CoachingFormatsPage',
    component: CoachingFormatsPage,
  },
  {
    path: '/s',
    name: 'SearchPage',
    forRole: [BUYER_ROLE, GUEST_ROLE],
    component: SearchPage,
    loadData: pageDataLoadingAPI.SearchPage.loadData,
  },
  {
    path: '/l',
    name: 'ListingBasePage',
    auth: true,
    component: ListingPage,
    loadData: pageDataLoadingAPI.ListingPage.loadData,
  },
  {
    path: '/l/:slug/:id',
    name: 'ListingPage',
    auth: true,
    component: ListingPage,
    loadData: pageDataLoadingAPI.ListingPage.loadData,
  },
  {
    path: '/l/:slug/:id/checkout',
    name: 'CheckoutPage',
    auth: true,
    component: CheckoutPage,
    setInitialValues: pageDataLoadingAPI.CheckoutPage.setInitialValues,
  },
  {
    path: '/l/:slug/:id/:variant',
    name: 'ListingPageVariant',
    auth: true,
    authPage: 'LoginPage',
    component: ListingPage,
    loadData: pageDataLoadingAPI.ListingPage.loadData,
  },
  {
    path: '/l/new',
    name: 'NewListingPage',
    auth: true,
    component: () => (
      <NamedRedirect
        name="EditListingPage"
        params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'description' }}
      />
    ),
  },
  {
    path: '/l/:slug/:id/:type/:tab',
    name: 'EditListingPage',
    auth: true,
    component: EditListingPage,
    extraProps: { allowOnlyOneListing: true },
    loadData: pageDataLoadingAPI.EditListingPage.loadData,
  },
  {
    path: '/l/:slug/:id/:type/:tab/:returnURLType',
    name: 'EditListingStripeOnboardingPage',
    auth: true,
    component: EditListingPage,
    loadData: pageDataLoadingAPI.EditListingPage.loadData,
  },
  {
    path: '/engagement/new',
    name: 'NewEngagementPage',
    auth: true,
    component: () => (
      <NamedRedirect
        name="EditEngagementPage"
        params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'process' }}
      />
    ),
  },
  {
    path: '/engagement/:slug/:id/:type/:tab',
    name: 'EditEngagementPage',
    auth: true,
    component: props => <EditListingPage {...props} listingType={ENGAGEMENT_LISTING} />,
    loadData: pageDataLoadingAPI.EditListingPage.loadData,
  },

  // Canonical path should be after the `/l/new` path since they
  // conflict and `new` is not a valid listing UUID.
  {
    path: '/l/:id',
    name: 'ListingPageCanonical',
    component: ListingPage,
    loadData: pageDataLoadingAPI.ListingPage.loadData,
  },
  {
    path: '/u',
    name: 'ProfileBasePage',
    component: RedirectToLandingPage,
  },
  {
    path: '/u/:id',
    name: 'ProfilePage',
    component: ProfilePage,
    loadData: pageDataLoadingAPI.ProfilePage.loadData,
  },
  {
    path: '/profile-settings',
    name: 'ProfileSettingsPage',
    auth: true,
    authPage: 'LoginPage',
    component: ProfileSettingsPage,
  },

  // Note: authenticating with IdP (e.g. Facebook) expects that /login path exists
  // so that in the error case users can be redirected back to the LoginPage
  // In case you change this, remember to update the route in server/api/auth/loginWithIdp.js
  {
    path: '/login',
    name: 'LoginPage',
    component: AuthenticationPage,
    extraProps: { tab: 'login' },
  },
  {
    path: '/signup',
    name: 'SignupPage',
    component: AuthenticationPage,
    extraProps: { tab: 'signup' },
  },
  {
    path: '/confirm',
    name: 'ConfirmPage',
    component: AuthenticationPage,
    extraProps: { tab: 'confirm' },
  },
  {
    path: '/recover-password',
    name: 'PasswordRecoveryPage',
    component: PasswordRecoveryPage,
  },
  {
    path: '/inbox',
    name: 'InboxBasePage',
    auth: true,
    authPage: 'LoginPage',
    component: () => <NamedRedirect name="InboxPage" params={{ tab: 'sales' }} />,
  },
  {
    path: '/inbox/:tab',
    name: 'InboxPage',
    auth: true,
    authPage: 'LoginPage',
    component: InboxPage,
    loadData: pageDataLoadingAPI.InboxPage.loadData,
  },
  {
    path: '/order/:id',
    name: 'OrderPage',
    auth: true,
    authPage: 'LoginPage',
    component: props => <NamedRedirect name="OrderDetailsPage" params={{ ...props.params }} />,
  },
  {
    path: '/order/:id/details',
    name: 'OrderDetailsPage',
    auth: true,
    authPage: 'LoginPage',
    component: TransactionPage,
    extraProps: { transactionRole: 'customer' },
    loadData: params =>
      pageDataLoadingAPI.TransactionPage.loadData({ ...params, transactionRole: 'customer' }),
    setInitialValues: pageDataLoadingAPI.TransactionPage.setInitialValues,
  },
  {
    path: '/sale/:id',
    name: 'SalePage',
    auth: true,
    authPage: 'LoginPage',
    component: props => <NamedRedirect name="SaleDetailsPage" params={{ ...props.params }} />,
  },
  {
    path: '/sale/:id/details',
    name: 'SaleDetailsPage',
    auth: true,
    authPage: 'LoginPage',
    component: TransactionPage,
    extraProps: { transactionRole: 'provider' },
    loadData: params =>
      pageDataLoadingAPI.TransactionPage.loadData({ ...params, transactionRole: 'provider' }),
  },
  {
    path: '/engagements',
    name: 'ManageEngagementsPage',
    auth: true,
    authPage: 'LoginPage',
    component: ManageListingsPage,
    loadData: pageDataLoadingAPI.ManageListingsPage.loadData,
  },
  {
    path: '/account',
    name: 'AccountSettingsPage',
    auth: true,
    authPage: 'LoginPage',
    component: () => <NamedRedirect name="ContactDetailsPage" />,
  },
  {
    path: '/account/contact-details',
    name: 'ContactDetailsPage',
    auth: true,
    authPage: 'LoginPage',
    component: ContactDetailsPage,
    loadData: pageDataLoadingAPI.ContactDetailsPage.loadData,
  },
  {
    path: '/account/change-password',
    name: 'PasswordChangePage',
    auth: true,
    authPage: 'LoginPage',
    component: PasswordChangePage,
  },
  {
    path: '/account/payments',
    name: 'StripePayoutPage',
    auth: true,
    authPage: 'LoginPage',
    forRole: [ADMIN_ROLE],
    component: StripePayoutPage,
    loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
  },
  {
    path: '/account/payments/:returnURLType',
    name: 'StripePayoutOnboardingPage',
    auth: true,
    authPage: 'LoginPage',
    component: StripePayoutPage,
    loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
  },
  {
    path: '/account/payment-methods',
    name: 'PaymentMethodsPage',
    auth: true,
    authPage: 'LoginPage',
    component: PaymentMethodsPage,
    loadData: pageDataLoadingAPI.PaymentMethodsPage.loadData,
  },
  {
    path: '/terms-of-service',
    name: 'TermsOfServicePage',
    component: TermsOfServicePage,
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicyPage',
    component: PrivacyPolicyPage,
  },
  {
    path: '/notfound',
    name: 'NotFoundPage',
    component: props => <NotFoundPage {...props} />,
  },

  // Do not change this path!
  //
  // The API expects that the application implements /reset-password endpoint
  {
    path: '/reset-password',
    name: 'PasswordResetPage',
    component: PasswordResetPage,
  },

  // Do not change this path!
  //
  // The API expects that the application implements /verify-email endpoint
  {
    path: '/verify-email',
    name: 'EmailVerificationPage',
    auth: true,
    authPage: 'LoginPage',
    component: EmailVerificationPage,
    loadData: pageDataLoadingAPI.EmailVerificationPage.loadData,
  },
  {
    path: '/blog',
    name: 'BlogPage',
    component: BlogPage,
    loadData: pageDataLoadingAPI.BlogPage.loadData,
  },
  {
    path: '/blog/:slug',
    name: 'SingleBlogPage',
    component: SingleBlogPage,
    loadData: pageDataLoadingAPI.SingleBlogPage.loadData,
  },
  {
    path: '/coach-list/:engagementId',
    name: 'CoachListPage',
    component: CoachListPage,
    auth: true,
    authPage: 'LoginPage',
    loadData: pageDataLoadingAPI.CoachListPage.loadData,
  },
  {
    path: '/casestudies',
    name: 'CaseStudyNavigationPage',
    component: CaseStudyNavigationPage,
    loadData: pageDataLoadingAPI.CaseStudyNavigationPage.loadData,
  },
  {
    path: '/casestudies/:slug',
    name: 'CaseStudyPage',
    component: CaseStudyPage,
    loadData: pageDataLoadingAPI.CaseStudyPage.loadData,
  },
  {
    path: '/knowledge-hub',
    name: 'KnowledgeHubNavigationPage',
    component: KnowledgeHubNavigationPage,
    loadData: pageDataLoadingAPI.KnowledgeHubNavigationPage.loadData,
  },
  {
    path: '/knowledge-hub/:slug',
    name: 'KnowledgeHubPage',
    component: KnowledgeHubPage,
    loadData: pageDataLoadingAPI.KnowledgeHubPage.loadData,
  },
  {
    path: '/workshops',
    name: 'WorkshopsPage',
    component: WorkshopsPage,
  },
  {
    path: '/leadership',
    name: 'LeadershipPage',
    component: LeadershipPage,
    loadData: pageDataLoadingAPI.LeadershipPage.loadData,
  },
  {
    path: '/design-thinking',
    name: 'DesignThinkingPage',
    component: DesignThinkingPage,
    loadData: pageDataLoadingAPI.DesignThinkingPage.loadData,
  },
  {
    path: '/team-coaching',
    name: 'TeamCoachingPage',
    component: TeamCoachingPage,
    loadData: pageDataLoadingAPI.TeamCoachingPage.loadData,
  },
  {
    path: '/hongKong',
    name: 'HongKongPage',
    component: HongKongPage,
  }
]);

export default routeConfiguration;
