import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import config from '../../config';
import {
  optionalEmailFormatValid,
  required,
} from '../../util/validators';
import { Form, Button, FieldTextInput, FieldSelectOption } from '../../components';

import css from './EditListingSuccessFactorsForm.module.css';
import { yesNoOptions } from '../../marketplace-custom-config';

const OPTION_YES = 'yes';
const OPTION_NO = 'no';

const EditListingSuccessFactorsFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values
      } = formRenderProps;

      // Has the coachee completed an assessment recently?
      const hasCompletedAssessmentLabel = intl.formatMessage({
        id: 'EditListingSuccessFactorsForm.hasCompletedAssessmentLabel',
      });
      const hasCompletedAssessmentPlaceholder = intl.formatMessage({
        id: 'EditListingSuccessFactorsForm.hasCompletedAssessmentPlaceholder',
      });
      const hasCompletedAssessmentRequiredMessage = intl.formatMessage({
        id: 'EditListingSuccessFactorsForm.hasCompletedAssessmentRequired',
      });
      const hasCompletedAssessmentRequired = required(hasCompletedAssessmentRequiredMessage);

      // If yes, is this report available to the coach?
      const isReportAvailableLabel = intl.formatMessage({
        id: 'EditListingSuccessFactorsForm.isReportAvailableLabel',
      });

      const isReportAvailablePlaceholder = intl.formatMessage({
        id: 'EditListingSuccessFactorsForm.isReportAvailablePlaceholder',
      });
      const isReportAvailableRequiredMessage = intl.formatMessage({
        id: 'EditListingSuccessFactorsForm.isReportAvailableRequired',
      });
      const isReportAvailableRequired = required(isReportAvailableRequiredMessage);

      // If not, would you like BOLDLY to arrange a Hogan or ViaStrengths assessment?
      const boldlyArrangeLabel = intl.formatMessage({
        id: 'EditListingSuccessFactorsForm.boldlyArrangeLabel',
      });
      const boldlyArrangePlaceholder = intl.formatMessage({
        id: 'EditListingSuccessFactorsForm.boldlyArrangePlaceholder',
      });
      const boldlyArrangeRequiredMessage = intl.formatMessage({
        id: 'EditListingSuccessFactorsForm.boldlyArrangeRequired',
      });
      const boldlyArrangeRequired = required(boldlyArrangeRequiredMessage);

      // Do you expect a 30 minute briefing and debriefing between coach, coachee and coachees direct manager?
      const briefingAndDebriefingLabel = intl.formatMessage(
        { id: 'EditListingSuccessFactorsForm.briefingAndDebriefingLabel' }
      );
      const briefingAndDebriefingRequiredMessage = intl.formatMessage({
        id: 'EditListingSuccessFactorsForm.briefingAndDebriefingRequired',
      });
      const briefingAndDebriefingRequired = required(briefingAndDebriefingRequiredMessage);
      const briefingAndDebriefingPlaceholder = intl.formatMessage({
        id: 'EditListingSuccessFactorsForm.briefingAndDebriefingPlaceholder',
      });

      // If yes, who is the coachees direct manager?
      const coacheesDirectManagerLabel = intl.formatMessage({
        id: 'EditListingSuccessFactorsForm.coacheesDirectManagerLabel',
      });

      // Please share the direct manager’s email address
      const managersEmailLabel = intl.formatMessage(
        { id: 'EditListingSuccessFactorsForm.managersEmailLabel' }
      );

      const managersEmailInvalidMessage = intl.formatMessage({
        id: 'EditListingSuccessFactorsForm.managersEmailInvalidMessage',
      });

      const managersEmailInvalid = optionalEmailFormatValid(managersEmailInvalidMessage);

      // Are there other stakeholders who should be involved in the briefing and debrief calls? Please share their email addresses.
      const otherStakeholdersEmailsLabel = intl.formatMessage({
        id: 'EditListingSuccessFactorsForm.otherStakeholdersEmailsLabel',
      });

      // Are all stakeholders briefed
      const areAllStakeholdersBriefedLabel = intl.formatMessage(
        { id: 'EditListingSuccessFactorsForm.areAllStakeholdersBriefedLabel' }
      );
      const areAllStakeholdersBriefedPlaceholder = intl.formatMessage({
        id: 'EditListingSuccessFactorsForm.areAllStakeholdersBriefedPlaceholder',
      });
      const areAllStakeholdersBriefedRequiredMessage = intl.formatMessage({
        id: 'EditListingSuccessFactorsForm.areAllStakeholdersBriefedRequired',
      });
      const areAllStakeholdersBriefedRequired = required(areAllStakeholdersBriefedRequiredMessage);

      // Are all stakeholders briefed
      const isQualifiedCoachingNeedLabel = intl.formatMessage(
        { id: 'EditListingSuccessFactorsForm.isQualifiedCoachingNeedLabel' }
      );
      const isQualifiedCoachingNeedPlaceholder = intl.formatMessage({
        id: 'EditListingSuccessFactorsForm.isQualifiedCoachingNeedPlaceholder',
      });
      const isQualifiedCoachingNeedRequiredMessage = intl.formatMessage({
        id: 'EditListingSuccessFactorsForm.isQualifiedCoachingNeedRequired',
      });
      const isQualifiedCoachingNeedRequired = required(isQualifiedCoachingNeedRequiredMessage);
      
      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageUpdateListing}
          {errorMessageShowListing}

          <FieldSelectOption
            className={css.hasCompletedAssessment}
            id="hasCompletedAssessment"
            name="hasCompletedAssessment"
            options={yesNoOptions}
            label={hasCompletedAssessmentLabel}
            placeHolder={hasCompletedAssessmentPlaceholder}
            validate={hasCompletedAssessmentRequired}
          />

          {values.hasCompletedAssessment === OPTION_YES && (
            <FieldSelectOption
              className={css.hasCompletedAssessment}
              id="isReportAvailable"
              name="isReportAvailable"
              options={yesNoOptions}
              label={isReportAvailableLabel}
              placeHolder={isReportAvailablePlaceholder}
              validate={isReportAvailableRequired}
            />
          )}
          {values.hasCompletedAssessment === OPTION_NO && (
            <FieldSelectOption
              className={css.hasCompletedAssessment}
              id="isBoldlyArrange"
              name="isBoldlyArrange"
              options={yesNoOptions}
              label={boldlyArrangeLabel}
              placeHolder={boldlyArrangePlaceholder}
              validate={boldlyArrangeRequired}
            />
          )}

          <FieldSelectOption
            className={css.briefingAndDebriefing}
            id="briefingAndDebriefing"
            name="briefingAndDebriefing"
            options={yesNoOptions}
            label={briefingAndDebriefingLabel}
            placeHolder={briefingAndDebriefingPlaceholder}
            validate={briefingAndDebriefingRequired}
          />

          <div className={css.rowWrapper}>
            <FieldTextInput
              className={css.coacheesDirectManager}
              type="textarea"
              id="coacheesDirectManager"
              name="coacheesDirectManager"
              label={coacheesDirectManagerLabel}
            />
            <FieldTextInput
              className={css.managersEmail}
              type="textarea"
              id="managersEmail"
              name="managersEmail"
              label={managersEmailLabel}
              validate={managersEmailInvalid}
            />
          </div>

          <FieldSelectOption
            className={css.briefingAndDebriefing}
            id="areAllStakeholdersBriefed"
            name="areAllStakeholdersBriefed"
            options={yesNoOptions}
            label={areAllStakeholdersBriefedLabel}
            placeHolder={areAllStakeholdersBriefedPlaceholder}
            validate={areAllStakeholdersBriefedRequired}
          />

          <FieldTextInput
            className={css.otherStakeholdersEmails}
            type="textarea"
            id="otherStakeholdersEmails"
            name="otherStakeholdersEmails"
            label={otherStakeholdersEmailsLabel}
          />

          <FieldSelectOption
            className={css.briefingAndDebriefing}
            id="isQualifiedCoachingNeed"
            name="isQualifiedCoachingNeed"
            options={yesNoOptions}
            label={isQualifiedCoachingNeedLabel}
            placeHolder={isQualifiedCoachingNeedPlaceholder}
            validate={isQualifiedCoachingNeedRequired}
          />

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingSuccessFactorsFormComponent.defaultProps = {
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingSuccessFactorsFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

export default compose(injectIntl)(EditListingSuccessFactorsFormComponent);
