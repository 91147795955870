import React, { useEffect, useState } from 'react';
import { string, bool, func, object } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';
import css from './SectionHero.module.css';
import { COACH_ROLE } from '../../marketplace-custom-config';
import { useSelector } from 'react-redux';
import IMGHERO from '../../assets/Compressed Images/Home Page.png';
import IMGCOACHING from '../../assets/Compressed Images/Coaching page mockup.png';
import IMGWORKSHOP from '../../assets/Compressed Images/Workshop page mockup.png';
import IMGMENTORSHIP from '../../assets/Compressed Images/P2_ Mentoring for Organisations.png';
import IMGMANAGER from '../../assets/Compressed Images/P2_ First Time Managers Program.png';
import IMGASPIRINGLADIES from '../../assets/Compressed Images/P2_ Aspiring Female Leaders Program.png';
import IMGSUPERVSOR from '../../assets/Compressed Images/P2_ Coaching Skills for Supervisors.png';
import IMGCOUNSELLING from '../../assets/Compressed Images/P2_ Career Counselling Skills for HR.png';
import IMGBUILDINGCOACHING from '../../assets/Compressed Images/P2_ Building Coaching Culture.png';
import IMGBECOMEAPAGE from '../../assets/Compressed Images/Become a coach.png';
import { HashLink } from 'react-router-hash-link';


const SectionHero = props => {

  const [mounted, setMounted] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    rootClassName,
    className,
    role,
    isLandToCoachHomePage,
    isShowCoacheeHomePage,
    isCustomContent,
    theTitle,
    theSubTitle,
    isCustomContentBtn,
    customButton1,
    customButton2,
    handleClickFn,
    isWorkshopsPage,
    isHomePage,
    isMentorshipPage,
    isCoachingFormat,
    isManagerPage,
    isAspiringFemale,
    isSupervisorPage,
    isCounsellingHrPage,
    isBuildingCoachingCareerpage,
    handleEnquireBtn
  } = props;
  const isCoach = role === COACH_ROLE;
  const isCoachHomePage = (isCoach || isLandToCoachHomePage) && !isShowCoacheeHomePage;


  const { isAuthenticated } = useSelector(state => state.Auth);
  const history = useHistory()


  useEffect(() => {
    setMounted(true);
  }, []);

  const classes = classNames(rootClassName || className, {
    [css.backgroundImageForCoachM]: isCoachHomePage,
    [css.backgroundImageForCoachee]: isShowCoacheeHomePage,
  });

  const handleClick = (e, type) => {
    if (handleClickFn) {
      e.preventDefault();
      handleClickFn(type);
    }
  };


  const Signup = () => {
    history.push('signup')
  }

  const login = () => {
    history.push('login')
  }

  // const openModal = () => {
  //   setIsModalOpen(true);
  // };

  // const closeModal = () => {
  //   setIsModalOpen(false);
  // };
  const handleButtonClick = () => {
    console.log(window, 'zohoooooooooo');
    zohoForms('campaigns.zoho.com', 'https://npqusd-glf.maillist-manage.com/ua/Optin?od=11287ecc786fdd&amp;zx=12859b91f&amp;tD=110bf14b6ae55d3c1&amp;sD=110bf14b6ae65dd3b')
  };
  return (

    <div className={` ${isHomePage ? css.rootN : css.rootN} `}>
      <div className={` ${isHomePage ? css.heroContentN : css.heroContentP}`}>
        {isHomePage ? (
          <>
            {/* <h2 className={classNames(css.heroMainTitle, { [css.heroMainTitleFEDelay]: mounted })}>
              <FormattedMessage id={theTitle} />
            </h2>   sscccccccccc
                <h1 className={classNames(css.heroSubTitle, { [css.heroSubTitleFEDelay]: mounted })}>
                  <FormattedMessage id={theSubTitle} />
                </h1> */}
            <div className={css.containera}>
              <div className={css.content}>
                <div className={css.heading_text}>
                  <h1><FormattedMessage id="SectionHero.newTitle" /></h1>
                  <h1><FormattedMessage id="SectionHero.newTitle1" /></h1>
                  {/* <h1>coaching solutions</h1> */}
                  <span className={css.paragraphHeroSection}><p><FormattedMessage id="SectionHero.subTitleForWorkshops1" /></p></span>
                  <span className={css.paragraphHeroSection}><p><FormattedMessage id="SectionHero.subTitleForWorkshops2" /></p></span>
                  <span className={css.paragraphHeroSection}><p><FormattedMessage id="SectionHero.subTitleForWorkshops3" /></p></span>
                  {/* <span className={css.paragraphHeroSection}><p>coaching engagement managemnet platform.</p></span> */}
                </div>
                <div className={css.btn} dangerouslySetInnerHTML={{
                  __html: `<div id='embedLink' style="width:100%;height:100%;border:none;cursor: pointer !important;">
    <button type="button" purpose="nrmlBtn" class="" style="width:100%;height:100%;border:none;cursor: pointer !important; font-weight: 700;
  color: #FC3E44;" changetype="EMBED_BUTTON" id="EMBED_BUTTON" name="EMBED_BUTTON"
      value="Get started" formopenin="Popup" btntype="nrmlBtn"
      onclick="zc_loadForm('campaigns.zoho.com','https://npqusd-glf.maillist-manage.com/ua/Optin?od=11287ecc786fdd&amp;zx=12859b91f&amp;tD=110bf14b6ae55d3c1&amp;sD=110bf14b6ae66bfd9')"
      bgcolor="#f74765">
      <span class="zceditcnt" style="">Get Started</span>
    </button>`}}>
                  {/* <button onClick={handleButtonClick}className={css.btn}><span class="zceditcnt"><FormattedMessage id="SectionHowItWorks.getStarted" /></span></button> */}
                </div>



              </div>
              <div className={css.image}>
                <img src={IMGHERO} alt="Description of the image" className={css.responsiveImage} />
              </div>

            </div>
            {/* {isModalOpen && (
              <div className={css.modalOverlay} onClick={closeModal}>
                <div className={css.modalContent} onClick={(e) => e.stopPropagation()}>
                  <div className={css.modalclose}>
                    <button className={css.closeButton} onClick={closeModal}>X</button>
                  </div>
                  <form >
                    <div className={css.modalBody}>
                      <div className={css.formGroup}>
                        <label htmlFor="username">Username</label>
                        <input type="text" className={css.formControl} id="username" aria-describedby="emailHelp" placeholder="Username" value={name}
                          onChange={(e) => setname(e.target.value)} />
                        <small id="emailHelp" className={css.formText}>Your information is safe with us.</small>
                      </div>
                      <div className={css.formGroup}>
                        <label htmlFor="username">Last name</label>
                        <input type="text" className={css.formControl} id="lastname" aria-describedby="emailHelp" placeholder="lastname" value={lastname}
                          onChange={(e) => setlastname(e.target.value)} />
                      </div>
                      <div className={css.formGroup}>
                        <label htmlFor="contact Number">Contact Number</label>
                        <input type="number" className={css.formControl} id="contact Number" placeholder="contact Number" value={contact_name}
                          onChange={(e) => setContactNumber(e.target.value)} />

                      </div>

                      <div className={css.formGroup}>
                        <label htmlFor="email">Email</label>
                        <input type="email" className={css.formControl} id="Email" placeholder="Email" value={Email}
                          onChange={(e) => setEmail(e.target.value)} />
                      </div>
                    </div>
                    <div className={css.modalFooter}>
                      <button type="submit" className={`${css.btn} ${css.SubmitBtn}`}>Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            )} */}
          </>

        ) :
          isShowCoacheeHomePage ? (
            <>
              <h1 className={classNames(css.heroMainTitle, { [css.heroMainTitleFEDelay]: mounted })}>
                <FormattedMessage id="SectionHero.titleForCoachee" />

              </h1>
              <h2
                className={classNames(css.heroSubTitle, {
                  [css.heroSubTitleFEDelay]: mounted,
                  [css.paddingBottomForCoachee]: isShowCoacheeHomePage,
                })}
              >
                <FormattedMessage id="SectionHero.subTitleForCoachee" />
              </h2>
            </>
          ) : isCoachHomePage ? (
            <>
              <div className={`${css.containera} ${css.spaceTop}`}>
                <div>
                  <h1 className={classNames(css.heroMainTitle, css.coachingTitle, { [css.heroMainTitleFEDelay]: mounted })}>
                    <FormattedMessage id="SectionHero.titleForCoach" />
                  </h1>
                  <h1 className={classNames(css.heroMainTitle, css.coachingTitle, { [css.heroMainTitleFEDelay]: mounted })}>
                    <FormattedMessage id="SectionHero.titleForCoach1" />
                  </h1>
                  <h1 className={classNames(css.heroSubTitle, css.coachingSubTitle, { [css.heroSubTitleFEDelay]: mounted })}>
                    <FormattedMessage id="SectionHero.subTitleForCoach" />
                  </h1>
                  <h1 className={classNames(css.heroSubTitle, css.coachingSubTitle, { [css.heroSubTitleFEDelay]: mounted })}>
                    <FormattedMessage id="SectionHero.subTitleForCoach1" />
                    <h1 className={classNames(css.heroSubTitle, css.coachingSubTitle, { [css.heroSubTitleFEDelay]: mounted })}>
                      <FormattedMessage id="SectionHero.subTitleForCoach2" />
                    </h1>
                  </h1>
                  <div className={css.coachbtn}>
                    <div className={css.coachbutton}>
                      <button onClick={Signup} className={`${css.coachingbtn} ${css.signUpBtn}`}><FormattedMessage id="AuthenticationPage.signupLinkText" /></button>
                    </div>
                    <div className={css.coachbutton}>
                      <button onClick={login} className={`${css.coachingbtn} `}><FormattedMessage id="SectionButton.logIn" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className={css.image}>
                  <img src={IMGBECOMEAPAGE} alt="Description of the image" className={css.responsiveImage} />
                </div>
              </div>
            </>
          ) : isWorkshopsPage ? (
            <div className={css.flexColumn1}>
              <div className={css.containera}>
                <div>
                  <h1 className={classNames(css.heroMainTitle, css.coachingTitle, { [css.heroMainTitleFEDelay]: mounted })}>
                    <FormattedMessage id="SectionHero.titleForWorkshops" />
                  </h1>
                  <h1 className={classNames(css.heroMainTitle, css.coachingTitle, { [css.heroMainTitleFEDelay]: mounted })}>
                    <FormattedMessage id="SectionHero.titleForWorkshops1" />
                  </h1>
                  <h1 className={classNames(css.heroSubTitle, css.coachingSubTitle, { [css.heroSubTitleFEDelay]: mounted })}>
                    <FormattedMessage id="SectionHero.subTitleForWorkshops" />
                  </h1>
                  <h1 className={classNames(css.heroSubTitle, css.coachingSubTitle, { [css.heroSubTitleFEDelay]: mounted })}>
                    <FormattedMessage id="SectionHero.subTitleForWorkshops4" />
                    <h1 className={classNames(css.heroSubTitle, css.coachingSubTitle, { [css.heroSubTitleFEDelay]: mounted })}>
                      <FormattedMessage id="SectionHero.subTitleForWorkshops5" />
                    </h1>
                  </h1>
                  <div className={`${css.workshopBtn}`}>
                    <button onClick={handleEnquireBtn} className={`${css.btnWorkshop}`}> <FormattedMessage id="SectionCoachCultureWorkshops.button" /></button>
                  </div>
                </div>
                <div className={css.image1}>
                  <img src={IMGWORKSHOP} alt="Description of the image" className={css.responsiveImage} />
                </div>
              </div>
            </div>
          ) : isMentorshipPage ? (
            <>
              <div className={`${css.containera} ${css.spaceTop}`}>
                <div>
                  <h1 className={classNames(css.heroMainTitle, css.coachingTitle, { [css.heroMainTitleFEDelay]: mounted })}>
                    <FormattedMessage id="isMentorshipPage.title" />
                    {/* Mentoring frameworks */}
                  </h1>
                  <h1 className={classNames(css.heroMainTitle, css.coachingTitle, { [css.heroMainTitleFEDelay]: mounted })}>
                    {/* <FormattedMessage id="isMentorshipPage.title1" /> */}
                    that deliver impact
                  </h1>
                  <h1 className={classNames(css.heroSubTitle, css.coachingSubTitle, { [css.heroSubTitleFEDelay]: mounted })}>
                    {/* <FormattedMessage id="isMentorshipPage.subTitle" /> */}
                    Mentoring can form a complimentary part of a coaching
                  </h1>
                  <h1 className={classNames(css.heroSubTitle, css.coachingSubTitle, { [css.heroSubTitleFEDelay]: mounted })}>
                    {/* <FormattedMessage id="isMentorshipPage.subTitle1" /> */}
                    culture. Collaborate with us to build a bespoke mentoring
                    <h1 className={classNames(css.heroSubTitle, css.coachingSubTitle, { [css.heroSubTitleFEDelay]: mounted })}>
                      {/* <FormattedMessage id="isMentorshipPage.subTitle2" /> */}
                      operation to reinforce your broader talent strategy
                    </h1>
                  </h1>
                  <HashLink className={`${css.workshopBtn}`} smooth to='workshops#contact'>
                    <button className={`${css.btnWorkshop}`}> <FormattedMessage id="SectionCoachCultureWorkshops.button" /></button>
                  </HashLink>
                </div>
                <div className={css.mentorshipImage}>
                  <img src={IMGMENTORSHIP} alt="Description of the image" className={css.responsiveImage} />
                </div>
              </div>

            </>
          ) : isCoachingFormat ? (<>
            <div className={`${css.containera} ${css.spaceTop}`}>
              <div>
                <h1 className={classNames(css.heroMainTitle, css.coachingTitle, { [css.heroMainTitleFEDelay]: mounted })}>
                  <FormattedMessage id="isCoachingFormat.title" />
                  {/* One partner for all your */}
                </h1>
                <h1 className={classNames(css.heroMainTitle, css.coachingTitle, { [css.heroMainTitleFEDelay]: mounted })}>
                  <FormattedMessage id="isCoachingFormat.title1" />
                  {/* coaching needs */}
                </h1>
                <h1 className={classNames(css.heroSubTitle, css.coachingSubTitle, { [css.heroSubTitleFEDelay]: mounted })}>
                  <FormattedMessage id="isCoachingFormat.subTitle" />
                  {/* BOLDLY conducts extensive coach screening to */}
                </h1>
                <h1 className={classNames(css.heroSubTitle, css.coachingSubTitle, { [css.heroSubTitleFEDelay]: mounted })}>
                  <FormattedMessage id="isCoachingFormat.subTitle1" />
                  {/* benchmark across credentials and coaching models, */}
                  <h1 className={classNames(css.heroSubTitle, css.coachingSubTitle, { [css.heroSubTitleFEDelay]: mounted })}>
                    <FormattedMessage id="isCoachingFormat.subTitle2" />
                    {/* delivering quality coaches to your business globally */}
                  </h1>
                </h1>
                <div className={css.coachbtn}>
                  <div className={css.coachbutton}>

                    <div className={`${css.coachingbtn} `} dangerouslySetInnerHTML={{
                      __html: `<div id='embedLink' style="width:100%;height:100%;cursor: pointer !important;">
    <button type="button" purpose="nrmlBtn" class="" style="width:100%;height:100%;border:none; cursor: pointer !important;font-weight: 700;
  color: #FC3E44;" changetype="EMBED_BUTTON" id="EMBED_BUTTON" name="EMBED_BUTTON"
      value="Get started" formopenin="Popup" btntype="nrmlBtn"
      onclick="zc_loadForm('campaigns.zoho.com','https://npqusd-glf.maillist-manage.com/ua/Optin?od=11287ecc786fdd&amp;zx=12859b91f&amp;tD=110bf14b6ae55d3c1&amp;sD=110bf14b6ae66bfd9')"
      bgcolor="#f74765">
      <span class="zceditcnt" style="">Get Started</span>
    </button>`}}></div>

                    {/* <button onClick={handleButtonClick} className={`${css.coachingbtn} `}><FormattedMessage id="SectionHowItWorks.getStarted" /></button> */}
                  </div>
                  <div className={css.coachbutton}>
                    <button onClick={Signup} className={`${css.coachingbtn} `}><FormattedMessage id="SectionHero.imACoach" />
                    </button>
                  </div>
                </div>
              </div>
              <div className={css.image}>
                <img src={IMGCOACHING} alt="Description of the image" className={css.responsiveImage} />
              </div>
            </div>

          </>) : isManagerPage ? (<>
            <div className={`${css.containera} ${css.spaceTop}`}>
              <div>
                <h1 className={classNames(css.heroMainTitle, css.coachingTitle, { [css.heroMainTitleFEDelay]: mounted })}>
                  <FormattedMessage id="isManagerPage.title" />
                  {/* Invest in managers’ */}

                </h1>
                <h1 className={classNames(css.heroMainTitle, css.coachingTitle, { [css.heroMainTitleFEDelay]: mounted })}>
                  <FormattedMessage id="isManagerPage.title1" />
                  {/* human skills */}
                </h1>
                <h1 className={classNames(css.heroSubTitle, css.coachingSubTitle, { [css.heroSubTitleFEDelay]: mounted })}>
                  <FormattedMessage id="isManagerPage.subTitle" />
                  {/* Managers are the conduit to effective team performance. */}
                </h1>
                <h1 className={classNames(css.heroSubTitle, css.coachingSubTitle, { [css.heroSubTitleFEDelay]: mounted })}>
                  <FormattedMessage id="isManagerPage.subTitle1" />
                  {/* Ensure they are enabled with core capabilities through */}
                  <h1 className={classNames(css.heroSubTitle, css.coachingSubTitle, { [css.heroSubTitleFEDelay]: mounted })}>
                    <FormattedMessage id="isManagerPage.subTitle2" />
                    {/* BOLDLY’s comprehensive First Time Managers program. */}
                  </h1>
                </h1>
                <HashLink className={`${css.workshopBtn}`} smooth to='workshops#contact'>
                  <button className={`${css.btnWorkshop}`}> <FormattedMessage id="SectionCoachCultureWorkshops.button" /></button>
                </HashLink>
              </div>
              <div className={css.mentorshipImage}>
                <img src={IMGMANAGER} alt="Description of the image" className={css.responsiveImage} />
              </div>
            </div>

          </>) : isAspiringFemale ? (<>
            <div className={`${css.containera} ${css.spaceTop}`}>
              <div>
                <h1 className={classNames(css.heroMainTitle, css.coachingTitle, { [css.heroMainTitleFEDelay]: mounted })}>
                  <FormattedMessage id="isAspiringFemale.title" />
                  {/* Develop your future */}
                </h1>
                <h1 className={classNames(css.heroMainTitle, css.coachingTitle, { [css.heroMainTitleFEDelay]: mounted })}>
                  <FormattedMessage id="isAspiringFemale.title1" />
                  {/* female leaders */}
                </h1>
                <h1 className={classNames(css.heroSubTitle, css.coachingSubTitle, { [css.heroSubTitleFEDelay]: mounted })}>
                  <FormattedMessage id="isAspiringFemale.subTitle" />
                  {/* BOLDLY’s accelerator program is designed with unique */}
                </h1>
                <h1 className={classNames(css.heroSubTitle, css.coachingSubTitle, { [css.heroSubTitleFEDelay]: mounted })}>
                  <FormattedMessage id="isAspiringFemale.subTitle1" />
                  {/* insights into the development needs and motivations of */}
                  <h1 className={classNames(css.heroSubTitle, css.coachingSubTitle, { [css.heroSubTitleFEDelay]: mounted })}>
                    <FormattedMessage id="isAspiringFemale.subTitle2" />
                    {/* emerging female leaders. */}
                  </h1>
                </h1>
                <HashLink className={`${css.workshopBtn}`} smooth to='workshops#contact'>
                  <button className={`${css.btnWorkshop}`}> <FormattedMessage id="SectionCoachCultureWorkshops.button" /></button>
                </HashLink>
              </div>
              <div className={css.mentorshipImage}>
                <img src={IMGASPIRINGLADIES} alt="Description of the image" className={css.responsiveImage} />
              </div>
            </div>

          </>) : isSupervisorPage ? (<>
            <div className={`${css.containera} ${css.spaceTop}`}>
              <div>
                <h1 className={classNames(css.heroMainTitle, css.coachingTitle, { [css.heroMainTitleFEDelay]: mounted })}>
                  <FormattedMessage id="isSupervisorPage.title" />
                  {/* Enable your managers */}

                </h1>
                <h1 className={classNames(css.heroMainTitle, css.coachingTitle, { [css.heroMainTitleFEDelay]: mounted })}>
                  <FormattedMessage id="isSupervisorPage.title1" />
                  {/* to coach their teams */}
                </h1>
                <h1 className={classNames(css.heroSubTitle, css.coachingSubTitle, { [css.heroSubTitleFEDelay]: mounted })}>
                  {/* <FormattedMessage id="isSupervisorPage.subTitle" /> */}
                  BOLDLY’s coaching skills for supervisors programs brings
                </h1>
                <h1 className={classNames(css.heroSubTitle, css.coachingSubTitle, { [css.heroSubTitleFEDelay]: mounted })}>
                  <FormattedMessage id="isSupervisorPage.subTitle1" />
                  {/* practical coaching capability into your manager toolkit */}
                  <h1 className={classNames(css.heroSubTitle, css.coachingSubTitle, { [css.heroSubTitleFEDelay]: mounted })}>
                    <FormattedMessage id="isSupervisorPage.subTitle2" />
                    {/* with hands-on coaching experience and feedback. */}
                  </h1>
                </h1>
                <HashLink className={`${css.workshopBtn}`} smooth to='workshops#contact'>
                  <button className={`${css.btnWorkshop}`}> <FormattedMessage id="SectionCoachCultureWorkshops.button" /></button>
                </HashLink>
              </div>
              <div className={css.mentorshipImage}>
                <img src={IMGSUPERVSOR} alt="Description of the image" className={css.responsiveImage} />
              </div>
            </div>

          </>) : isCounsellingHrPage ? (<>
            <div className={`${css.containera} ${css.spaceTop}`}>
              <div>
                <h1 className={classNames(css.heroMainTitle, css.coachingTitle, { [css.heroMainTitleFEDelay]: mounted })}>
                  {/* <FormattedMessage id="isCounsellingHrPage.title" /> */}
                  Career counselling

                </h1>
                <h1 className={classNames(css.heroMainTitle, css.coachingTitle, { [css.heroMainTitleFEDelay]: mounted })}>
                  {/* <FormattedMessage id="isCounsellingHrPage.title1" /> */}
                  skills for your HR team
                </h1>
                <h1 className={classNames(css.heroSubTitle, css.coachingSubTitle, { [css.heroSubTitleFEDelay]: mounted })}>
                  {/* <FormattedMessage id="isCounsellingHrPage.subTitle" /> */}
                  Our program is tailored to your careers framework,
                </h1>
                <h1 className={classNames(css.heroSubTitle, css.coachingSubTitle, { [css.heroSubTitleFEDelay]: mounted })}>
                  {/* <FormattedMessage id="isCounsellingHrPage.subTitle1" /> */}
                  ensuring HR know how career transitions work, advising
                  <h1 className={classNames(css.heroSubTitle, css.coachingSubTitle, { [css.heroSubTitleFEDelay]: mounted })}>
                    {/* <FormattedMessage id="isCounsellingHrPage.subTitle2" /> */}
                    staff at key moments to drive engagement and retention
                  </h1>
                </h1>
                <HashLink className={`${css.workshopBtn}`} smooth to='workshops#contact'>
                  <button className={`${css.btnWorkshop}`}> <FormattedMessage id="SectionCoachCultureWorkshops.button" /></button>
                </HashLink>
              </div>
              <div className={css.mentorshipImage}>
                <img src={IMGCOUNSELLING} alt="Description of the image" className={css.responsiveImage} />
              </div>
            </div>

          </>) : isBuildingCoachingCareerpage ? (<>
            <div className={`${css.containera} ${css.spaceTop}`}>
              <div>
                <h1 className={classNames(css.heroMainTitle, css.coachingTitle, { [css.heroMainTitleFEDelay]: mounted })}>
                  {/* <FormattedMessage id="isBuildingCoachingCareerpage.title" /> */}
                  Build a coaching culture
                </h1>
                <h1 className={classNames(css.heroMainTitle, css.coachingTitle, { [css.heroMainTitleFEDelay]: mounted })}>
                  {/* <FormattedMessage id="isBuildingCoachingCareerpage.title1" /> */}
                  for long-term ROI
                </h1>
                <h1 className={classNames(css.heroSubTitle, css.coachingSubTitle, { [css.heroSubTitleFEDelay]: mounted })}>
                  {/* <FormattedMessage id="isBuildingCoachingCareerpage.subTitle" /> */}
                  BOLDLY collaborates with you to align coaching to your
                </h1>
                <h1 className={classNames(css.heroSubTitle, css.coachingSubTitle, { [css.heroSubTitleFEDelay]: mounted })}>
                  {/* <FormattedMessage id="isBuildingCoachingCareerpage.subTitle1" /> */}
                  business strategy. Our coaching culture model delivers a
                  <h1 className={classNames(css.heroSubTitle, css.coachingSubTitle, { [css.heroSubTitleFEDelay]: mounted })}>
                    {/* <FormattedMessage id="isBuildingCoachingCareerpage.subTitle2" /> */}
                    comprehensive coaching strategy for large organisations
                  </h1>
                </h1>
                <HashLink className={`${css.workshopBtn}`} smooth to='workshops#contact'>
                  <button className={`${css.btnWorkshop}`}> <FormattedMessage id="SectionCoachCultureWorkshops.button" /></button>
                </HashLink>
              </div>
              <div className={css.mentorshipImage}>
                <img src={IMGBUILDINGCOACHING} alt="Description of the image" className={css.responsiveImage} />
              </div>
            </div>

          </>) : <></>}
        {isCustomContentBtn ? (
          <div className={css.buttonWrapper}>
            {/* <NamedLink
              name="SearchPage"
              className={classNames(css.heroButton, css.redButton, {
                [css.heroButtonFEDelay]: mounted,
              })}
            >
              <FormattedMessage id="SectionHero.browseButton" />
            </NamedLink>
            <NamedLink
              name="NewEngagementPage"
              className={classNames(css.heroButton, { [css.heroButtonFEDelay]: mounted })}
            >
              <FormattedMessage id="SectionHero.imACoach" />
            </NamedLink> */}
            <HashLink smooth to='workshops#contact'>
              <button className={`${css.coachingbtn} ${css.btnWorkshop}`}> <FormattedMessage id="SectionCoachCultureWorkshops.button" /></button>
            </HashLink>
          </div>
        ) : isCustomContentBtn ? (
          <div className={css.buttonWrapper}>
            {/* <NamedLink
              name={customButton1.link}
              className={classNames(css.heroButton, css.redButton, {
                [css.heroButtonFEDelay]: mounted,
              })}
            >
              <FormattedMessage id={customButton1.label} />
            </NamedLink>
            <NamedLink
              name={customButton2.link}
              className={classNames(css.heroButton, { [css.heroButtonFEDelay]: mounted })}
            >
              <FormattedMessage id={customButton2.label} />
            </NamedLink> */}
            <HashLink smooth to='workshops#contact'>
              <button className={`${css.btn1} ${css.btnWorkshop1}`}><FormattedMessage id="SectionCoachCultureWorkshops.button" /></button>
            </HashLink>
          </div>
        ) :
          // !isShowCoacheeHomePage && (
          // <div className={`${css.buttonWrapper} ${css.becomeacoachmargin}`}>
          //   {isLandToCoachHomePage ? (
          //     !isAuthenticated ? (
          <>
            {/* <NamedLink
                    name="SignupPage"
                    className={classNames(css.heroButton, css.redButton, {
                      [css.heroButtonFEDelay]: mounted,
                    })}
                  >
                    <FormattedMessage id="SectionHero.signUpButton" />
                  </NamedLink>
                  <NamedLink
                    name="LoginPage"
                    className={classNames(css.heroButton, { [css.heroButtonFEDelay]: mounted })}
                  >
                    <FormattedMessage id="SectionHero.signInButton" />
                  </NamedLink> */}
            {/* <div className={css.coachbtn}>
                    <div className={css.coachbutton}>
                      <button onClick={handleButtonClick} className={`${css.coachingbtn} ${css.signUpBtn}`}><FormattedMessage id="AuthenticationPage.signupLinkText" /></button>
                    </div>
                    <div className={css.coachbutton}>
                      <button onClick={handleButtonClick} className={`${css.coachingbtn} `}><FormattedMessage id="SectionButton.logIn" />
                      </button>
                    </div>
                  </div> */}
          </>
          //     ) : null
          //   ) : null}
          // </div>
          // )
        }
      </div>

    </div>
  );
};



SectionHero.defaultProps = {
  rootClassName: null,
  className: null,
  role: null,
  isLandToCoachHomePage: false,
  isShowCoacheeHomePage: false,
  handleClickFn: null,
  isCustomContent: false,
  isCustomContentBtn: false,
  theTitle: null,
  theSubTitle: null,
  customButton1: false,
  customButton2: false,
  isWorkshopsPage: false,
};

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
  role: string,
  isLandToCoachHomePage: bool,
  isShowCoacheeHomePage: bool,
  handleClickFn: func,
  isCustomContent: bool,
  isCustomContentBtn: bool,
  theTitle: string,
  theSubTitle: string,
  customButton1: object,
  customButton2: object,
  isWorkshopsPage: bool,
};

export default SectionHero;