import React, { Component } from 'react';
import { bool, node, string, func } from 'prop-types';
import classNames from 'classnames';
import routeConfiguration from '../../routeConfiguration';
import { findRouteByRouteName } from '../../util/routes';
import { IconSpinner, IconCheckmark, HoverPopUp } from '../../components';

import css from './Button.module.css';

class Button extends Component {
  constructor(props) {
    super(props);
    this.state = { mounted: false, showingPopup: false };

    this.onMouseEnterCustom = this.onMouseEnterCustom.bind(this);
    this.onMouseLeaveCustom = this.onMouseLeaveCustom.bind(this);
    this.onClickCustom = this.onClickCustom.bind(this);
  }
  componentDidMount() {
    this.setState({ mounted: true }); // eslint-disable-line react/no-did-mount-set-state
  }

  prepareChildren() {
    return React.Children.map(this.props.children, child => {
      if (child
        && child.type
        && child.type === HoverPopUp) {
        return React.cloneElement(child, {
          isOpen: this.state.showingPopup,
        });
      } else {
        return child;
      }
    });
  };

  onMouseEnterCustom(e) {
    const { onMouseEnterCustomActive, onMouseEnter } = this.props;
    if (onMouseEnterCustomActive) {
      this.setState({ showingPopup: true });
    }
    onMouseEnter(e);
  };

  onMouseLeaveCustom(e) {
    const { onMouseLeaveCustomActive, onMouseLeave } = this.props;
    if (onMouseLeaveCustomActive) {
      this.setState({ showingPopup: false });
    }
    onMouseLeave(e);
  };

  onClickCustom(e) {
    const { onClickCustomActive, onClick } = this.props;
    if (onClickCustomActive) {
      this.setState({ showingPopup: true });
    }
    onClick(e);
  };

  render() {
    const {
      children,
      className,
      rootClassName,
      spinnerClassName,
      checkmarkClassName,
      inProgress,
      ready,
      disabled,
      enforcePagePreloadFor,
      onMouseEnterCustomActive,
      onMouseLeaveCustomActive,
      onClickCustomActive,
      inProgressClassName,
      ...rest
    } = this.props;

    const rootClass = rootClassName || css.root;
    const classes = classNames(rootClass, className, {
      [css.ready]: ready,
      [inProgressClassName || css.inProgress]: inProgress,
    });

    let content;

    if (inProgress) {
      content = <IconSpinner rootClassName={spinnerClassName || css.spinner} />;
    } else if (ready) {
      content = <IconCheckmark rootClassName={checkmarkClassName || css.checkmark} />;
    } else {
      content = this.prepareChildren();
    }

    const onOverButtonFn = enforcePreloadOfPage => () => {
      // Enforce preloading of given page (loadable component)
      const { component: Page } = findRouteByRouteName(enforcePreloadOfPage, routeConfiguration());
      // Loadable Component has a "preload" function.
      if (Page.preload) {
        Page.preload();
      }
    };

    const onOverButton = enforcePagePreloadFor ? onOverButtonFn(enforcePagePreloadFor) : null;
    const onOverButtonMaybe = onOverButton
      ? {
          onMouseOver: onOverButton,
          onTouchStart: onOverButton,
        }
      : {};

    // All buttons are disabled until the component is mounted. This
    // prevents e.g. being able to submit forms to the backend before
    // the client side is handling the submit.
    const buttonDisabled = this.state.mounted ? disabled : true;

    return (
      <button
        className={classes}
        {...onOverButtonMaybe}
        {...rest}
        disabled={buttonDisabled}
        onMouseEnter={this.onMouseEnterCustom}
        onMouseLeave={this.onMouseLeaveCustom}
        onClick={this.onClickCustom}
      >
        {content}
      </button>
    );
  }
}

Button.defaultProps = {
  rootClassName: null,
  className: null,
  spinnerClassName: null,
  checkmarkClassName: null,
  inProgressClassName: null,
  inProgress: false,
  ready: false,
  disabled: false,
  enforcePagePreloadFor: null,
  children: null,
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  onMouseEnterCustomActive: false,
  onMouseLeaveCustomActive: false,
  onClickCustomActive: false,
  onClick: () => {},
};

Button.propTypes = {
  rootClassName: string,
  className: string,
  spinnerClassName: string,
  checkmarkClassName: string,
  inProgressClassName: string,

  inProgress: bool,
  ready: bool,
  disabled: bool,
  enforcePagePreloadFor: string,

  children: node,
  onMouseEnter: func,
  onMouseLeave: func,
  onMouseEnterCustomActive: bool,
  onMouseLeaveCustomActive: bool,
  onClickCustomActive: bool,
  onClick: func,
};

export default Button;

export const PrimaryButton = props => {
  const classes = classNames(props.rootClassName || css.primaryButtonRoot, css.primaryButton, props.className);
  return <Button {...props} rootClassName={classes} />;
};
PrimaryButton.displayName = 'PrimaryButton';

export const SecondaryButton = props => {
  const classes = classNames(props.rootClassName || css.secondaryButtonRoot, css.secondaryButton);
  return <Button {...props} rootClassName={classes} />;
};
SecondaryButton.displayName = 'SecondaryButton';

export const InlineTextButton = props => {
  const classes = classNames(props.rootClassName || css.inlineTextButtonRoot, css.inlineTextButton);
  return <Button {...props} rootClassName={classes} />;
};
InlineTextButton.displayName = 'InlineTextButton';

export const SocialLoginButton = props => {
  const classes = classNames(props.rootClassName || css.socialButtonRoot, css.socialButton);
  return <Button {...props} rootClassName={classes} />;
};

SocialLoginButton.displayName = 'SocialLoginButton';
