import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { createSlug, stringify } from '../../util/urlHelpers';
import { types as sdkTypes } from '../../util/sdkLoader';
import { NamedLink } from '../../components';

import css from './TransactionPanel.module.css';
import EngagementDetailsForm from './EngagementDetailsForm';
import { INTERESTED_ENGAGEMENT, NOT_INTERESTED_ENGAGEMENT } from '../../marketplace-custom-config';
import { formatMoney } from '../../util/currency';

const { Money } = sdkTypes;

export const HEADING_ENQUIRED = 'enquired';
export const HEADING_PAYMENT_PENDING = 'pending-payment';
export const HEADING_PAYMENT_EXPIRED = 'payment-expired';
export const HEADING_ADDED_TO_COACH_LIST = 'added-to-coach-list';
export const HEADING_COACH_RESPOND = 'coach-respond';
export const HEADING_INVITED_TO_CHEMISTRY = 'invited-to-chemistry';
export const HEADING_AFTER_CHEMISTRY = 'after-chemistry';
export const HEADING_SELECTED_COACH = 'selected-coach';
export const HEADING_REQUESTED_ENGAGEMENT = 'requested-engagement';
export const HEADING_CUSTOMER_MADE_OFFER = 'customer-made-offer';
export const HEADING_COACH_DECLINED = 'coach-declined';
export const HEADING_COACH_ACCEPTED = 'coach-accepted';
export const HEADING_COMPLETED = 'coach-completed';
export const HEADING_REQUESTED = 'requested';
export const HEADING_ACCEPTED = 'accepted';
export const HEADING_DECLINED = 'declined';
export const HEADING_CANCELED = 'canceled';
export const HEADING_DELIVERED = 'delivered';

const createListingLink = (listingId, label, listingDeleted, searchParams = {}, className = '') => {
  if (!listingId) {
    return null;
  }

  if (!listingDeleted) {
    const params = { id: listingId };

    if (label) {
      params.slug = createSlug(label);
    }

    const to = { search: stringify(searchParams) };
    return (
      <NamedLink
        className={className}
        name={label ? 'ListingPage' : 'ListingPageCanonical'}
        params={params}
        to={to}
      >
        {label}
      </NamedLink>
    );
  } else {
    return <FormattedMessage id="TransactionPanel.deletedListingOrderTitle" />;
  }
};

const EngagementLink = props => {
  const { engagementId, engagementTitle, className, engagementName } = props;
  const params = { id: engagementId };

  if (!engagementId) {
    return null;
  }

  if (engagementTitle) {
    params.slug = createSlug(engagementTitle);
  }

  return (
    <NamedLink
      className={classNames(className, css.engagementLink)}
      name={engagementTitle ? 'ListingPage' : 'ListingPageCanonical'}
      params={params}
    >
      {engagementName}
    </NamedLink>
  );
};

const ListingDeletedInfoMaybe = props => {
  return props.listingDeleted ? (
    <p className={css.transactionInfoMessage}>
      <FormattedMessage id="TransactionPanel.messageDeletedListing" />
    </p>
  ) : null;
};

const HeadingCustomer = props => {
  const { className, id, values, listingDeleted, children } = props;
  return (
    <React.Fragment>
      <h1 className={className}>
        <span className={css.mainTitle}>
          <FormattedMessage id={id} values={values} />
        </span>
      </h1>
      {children}
      <ListingDeletedInfoMaybe listingDeleted={listingDeleted} />
    </React.Fragment>
  );
};

const HeadingCustomerWithSubtitle = props => {
  const { className, id, values, subtitleId, subtitleValues, children, listingDeleted } = props;
  return (
    <React.Fragment>
      <h1 className={className}>
        <span className={css.mainTitle}>
          <FormattedMessage id={id} values={values} />
        </span>
        <FormattedMessage id={subtitleId} values={subtitleValues} />
      </h1>
      {children}
      <ListingDeletedInfoMaybe listingDeleted={listingDeleted} />
    </React.Fragment>
  );
};

const CustomerBannedInfoMaybe = props => {
  return props.isCustomerBanned ? (
    <p className={css.transactionInfoMessage}>
      <FormattedMessage id="TransactionPanel.customerBannedStatus" />
    </p>
  ) : null;
};

const HeadingProvider = props => {
  const { className, id, values, isCustomerBanned, children } = props;
  return (
    <React.Fragment>
      <h1 className={className}>
        <span className={css.mainTitle}>
          <FormattedMessage id={id} values={values} />
        </span>
      </h1>
      {children}
      <CustomerBannedInfoMaybe isCustomerBanned={isCustomerBanned} />
    </React.Fragment>
  );
};

// Functional component as a helper to choose and show Order or Sale heading info:
// title, subtitle, and message
const PanelHeading = props => {
  const {
    className,
    rootClassName,
    panelHeadingState,
    customerName,
    providerName,
    listingId,
    listingTitle,
    listingDeleted,
    isCustomerBanned,
    companyName,
    onSubmitEngagementForm,
    transaction,
    intl,
    transitTransitionInProgress,
    transitTransitionError,
    engagementTitle,
    engagementName,
  } = props;

  const isCustomer = props.transactionRole === 'customer';
  const isProvider = props.transactionRole === 'provider';
  const {
    protectedData: { engagementId, declineReasons = [] },
    metadata: { engagementFeedback, sendEmailToNotAttendedChemistry },
  } = transaction.attributes;
  const { comments, rate: { amount, currency } = {}, interestedOrNot } = engagementFeedback || {};

  const interestedOrNotMessageId =
    interestedOrNot === INTERESTED_ENGAGEMENT
      ? 'TransactionPanel.interestedEngagement'
      : interestedOrNot === NOT_INTERESTED_ENGAGEMENT
      ? 'TransactionPanel.notInterestedEngagement'
      : null;
  const rateMoney = !isNaN(amount) && currency ? new Money(amount, currency) : null;
  const rateString = rateMoney ? formatMoney(intl, rateMoney) : null;

  const defaultRootClassName = isCustomer ? css.headingOrder : css.headingSale;
  const titleClasses = classNames(rootClassName || defaultRootClassName, className);
  const listingLink = createListingLink(listingId, listingTitle, listingDeleted);

  const breakLine = <br />;

  const headingCustomerInvitedToChemistry = (
    <HeadingCustomer
      className={titleClasses}
      id="TransactionPanel.orderCoachRespondedTitle"
      values={{ providerName }}
    >
      <p className={css.transactionInfoMessage}>
        <FormattedMessage
          id="TransactionPanel.orderCoachRespondedSubtitle"
          values={{
            providerName,
            breakLine,
            interestedOrNot: interestedOrNotMessageId ? (
              <span className={css.interestedOrNotText}>
                <FormattedMessage id={interestedOrNotMessageId} />
              </span>
            ) : null,
            rate: rateString,
            comments,
          }}
        />
      </p>
    </HeadingCustomer>
  );

  switch (panelHeadingState) {
    case HEADING_ENQUIRED:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id="TransactionPanel.orderEnquiredTitle"
          values={{ listingLink }}
          listingDeleted={listingDeleted}
        />
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.saleEnquiredTitle"
          values={{ customerName, listingLink }}
          isCustomerBanned={isCustomerBanned}
        />
      );
    case HEADING_PAYMENT_PENDING:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id="TransactionPanel.orderPaymentPendingTitle"
          values={{ listingLink }}
          listingDeleted={listingDeleted}
        />
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.salePaymentPendingTitle"
          values={{ customerName, listingLink }}
          isCustomerBanned={isCustomerBanned}
        >
          <p className={css.transactionInfoMessage}>
            <FormattedMessage
              id="TransactionPanel.salePaymentPendingInfo"
              values={{ customerName }}
            />
          </p>
        </HeadingProvider>
      );
    case HEADING_PAYMENT_EXPIRED:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id="TransactionPanel.orderPaymentExpiredTitle"
          values={{ listingLink }}
          listingDeleted={listingDeleted}
        />
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.salePaymentExpiredTitle"
          values={{ customerName, listingLink }}
          isCustomerBanned={isCustomerBanned}
        />
      );
    case HEADING_ADDED_TO_COACH_LIST:
      return isProvider ? (
        <HeadingProvider
          className={titleClasses}
          id={
            companyName
              ? 'TransactionPanel.saleAddedToCoachListTitle'
              : 'TransactionPanel.saleAddedToCoachListTitleWithoutCompanyName'
          }
          values={{ customerName, companyName }}
          isCustomerBanned={isCustomerBanned}
        >
          <div className={css.engagementDetailsWrapper}>
            <p className={css.transactionInfoMessage}>
              <FormattedMessage id="TransactionPanel.saleAddedToCoachListSubtitle" />
            </p>
            <EngagementLink
              engagementId={engagementId}
              engagementTitle={engagementTitle}
              className={css.engagementDetailTitle}
              engagementName={intl.formatMessage({
                id: 'TransactionPanel.engagementName',
              })}
            />
            <EngagementDetailsForm
              className={css.engagementForm}
              onSubmit={onSubmitEngagementForm}
              transitTransitionInProgress={transitTransitionInProgress}
              transitTransitionError={transitTransitionError}
              sendEmailToNotAttendedChemistry={sendEmailToNotAttendedChemistry}
            />
          </div>
        </HeadingProvider>
      ) : null;
    case HEADING_COACH_RESPOND:
      return isProvider ? (
        <HeadingProvider
          className={titleClasses}
          id={
            companyName
              ? 'TransactionPanel.saleCoachRespondedTitle'
              : 'TransactionPanel.saleCoachRespondedTitleWithoutCompanyName'
          }
          values={{ customerName, companyName }}
          isCustomerBanned={isCustomerBanned}
        >
          <div>
            <p className={css.transactionInfoMessage}>
              <FormattedMessage id="TransactionPanel.saleCoachRespondedSubtitle" />
            </p>
            <EngagementLink
              engagementId={engagementId}
              engagementTitle={engagementTitle}
              className={css.engagementDetailTitle}
              engagementName={intl.formatMessage({
                id: 'TransactionPanel.engagementName',
              })}
            />
            <p>
              <FormattedMessage id={interestedOrNotMessageId} />
            </p>
            <p>{rateString}</p>
            <p>{comments}</p>
          </div>
        </HeadingProvider>
      ) : (
        headingCustomerInvitedToChemistry
      );
    case HEADING_INVITED_TO_CHEMISTRY:
    case HEADING_AFTER_CHEMISTRY:
    case HEADING_SELECTED_COACH:
      return isProvider ? (
        <HeadingProvider
          className={titleClasses}
          id={
            companyName
              ? 'TransactionPanel.saleInvitedToChemistryTitle'
              : 'TransactionPanel.saleInvitedToChemistryTitleWithoutCompanyName'
          }
          values={{ customerName, companyName }}
          isCustomerBanned={isCustomerBanned}
        >
          <div>
            <p className={css.transactionInfoMessage}>
              <FormattedMessage
                id="TransactionPanel.saleInvitedToChemistrySubtitle"
                values={{
                  engagementDetailsLink: (
                    <EngagementLink
                      engagementId={engagementId}
                      engagementTitle={engagementTitle}
                      engagementName={engagementName}
                    />
                  ),
                  breakLine,
                  coacheeName: engagementName,
                }}
              />
            </p>
          </div>
        </HeadingProvider>
      ) : (
        <HeadingCustomer
          className={titleClasses}
          id="TransactionPanel.orderInvitedToChemistryTitle"
          values={{ providerName, engagementName }}
        >
          <p className={css.transactionInfoMessage}>
            <FormattedMessage
              id="TransactionPanel.orderInvitedToChemistrySubtitle"
              values={{
                providerName,
                breakLine,
              }}
            />
          </p>
        </HeadingCustomer>
      );

    case HEADING_REQUESTED_ENGAGEMENT:
      return isProvider ? (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.saleRequestedEngagementTitle"
          values={{ customerName, companyName }}
          isCustomerBanned={isCustomerBanned}
        >
          <div>
            <p className={css.transactionInfoMessage}>
              <FormattedMessage
                id="TransactionPanel.saleRequestedEngagementSubtitle"
                values={{ customerName, breakLine }}
              />
            </p>
          </div>
        </HeadingProvider>
      ) : (
        <HeadingCustomer
          className={titleClasses}
          id="TransactionPanel.orderRequestedEngagementTitle"
          values={{ providerName }}
        >
          <p className={css.transactionInfoMessage}>
            <FormattedMessage
              id="TransactionPanel.orderRequestedEngagementSubtitle"
              values={{
                providerName,
                breakLine,
              }}
            />
          </p>
        </HeadingCustomer>
      );
    case HEADING_CUSTOMER_MADE_OFFER:
      return isProvider ? (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.saleCustomerMadeOfferTitle"
          values={{ companyName: companyName || customerName }}
          isCustomerBanned={isCustomerBanned}
        >
          <div>
            <p className={css.transactionInfoMessage}>
              <FormattedMessage
                id="TransactionPanel.saleCustomerMadeOfferSubTitle"
                values={{ companyName: companyName || customerName }}
              />
            </p>
          </div>
        </HeadingProvider>
      ) : (
        <HeadingCustomer
          className={titleClasses}
          id="TransactionPanel.orderCustomerMadeOfferTitle"
          values={{ engagementName }}
        >
          <p className={css.transactionInfoMessage}>
            <FormattedMessage
              id="TransactionPanel.orderCustomerMadeOfferSubTitle"
              values={{ providerName }}
            />
          </p>
        </HeadingCustomer>
      );

    case HEADING_COACH_DECLINED:
      return isProvider ? (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.saleCoachDeclinedTitle"
          values={{ companyName }}
          isCustomerBanned={isCustomerBanned}
        >
          <div>
            <p className={css.transactionInfoMessage}>
              <FormattedMessage
                id="TransactionPanel.saleCoachDeclinedSubTitle"
                values={{ companyName }}
              />
            </p>
          </div>
        </HeadingProvider>
      ) : (
        <HeadingCustomer
          className={titleClasses}
          id="TransactionPanel.orderCoachDeclinedTitle"
          values={{ providerName, engagementName }}
        >
          <p className={css.transactionInfoMessage}>
            <FormattedMessage
              id="TransactionPanel.orderCoachDeclinedSubTitle"
              values={{ breakLine, declineReason: declineReasons[0] }}
            />
          </p>
        </HeadingCustomer>
      );
    case HEADING_COACH_ACCEPTED:
      return isProvider ? (
        <HeadingProvider
          className={titleClasses}
          id={
            companyName
              ? 'TransactionPanel.saleCoachAcceptedTitle'
              : 'TransactionPanel.saleCoachAcceptedTitleWithoutCompanyName'
          }
          values={{ companyName, customerName }}
          isCustomerBanned={isCustomerBanned}
        >
          <div>
            <p className={css.transactionInfoMessage}>
              <FormattedMessage
                id="TransactionPanel.saleCoachAcceptedSubTitle"
                values={{ breakLine }}
              />
            </p>
          </div>
        </HeadingProvider>
      ) : (
        <HeadingCustomer
          className={titleClasses}
          id="TransactionPanel.orderCoachAcceptedTitle"
          values={{ customerName, providerName, coacheeName: engagementName, companyName }}
        >
          <p className={css.transactionInfoMessage}>
            <FormattedMessage
              id="TransactionPanel.orderCoachAcceptedSubTitle"
              values={{ breakLine, customerName }}
            />
          </p>
        </HeadingCustomer>
      );
    case HEADING_COMPLETED:
      return isProvider ? (
        <HeadingProvider
          className={titleClasses}
          id={
            companyName
              ? 'TransactionPanel.saleCoachAcceptedTitle'
              : 'TransactionPanel.saleCoachAcceptedTitleWithoutCompanyName'
          }
          values={{ companyName, customerName }}
          isCustomerBanned={isCustomerBanned}
        >
          <div>
            <p className={css.transactionInfoMessage}>
              <FormattedMessage
                id="TransactionPanel.saleCoachAcceptedSubTitle"
                values={{ breakLine }}
              />
            </p>
          </div>
        </HeadingProvider>
      ) : (
        <HeadingCustomer
          className={titleClasses}
          id="TransactionPanel.orderCompletedTitle"
          values={{ customerName, engagementName }}
        >
          <p className={css.transactionInfoMessage}>
            <FormattedMessage id="TransactionPanel.orderCompletedSubTitle" />
          </p>
        </HeadingCustomer>
      );
    case HEADING_REQUESTED:
      return isCustomer ? (
        <HeadingCustomerWithSubtitle
          className={titleClasses}
          id="TransactionPanel.orderPreauthorizedTitle"
          values={{ customerName }}
          subtitleId="TransactionPanel.orderPreauthorizedSubtitle"
          subtitleValues={{ listingLink }}
        >
          {!listingDeleted ? (
            <p className={css.transactionInfoMessage}>
              <FormattedMessage
                id="TransactionPanel.orderPreauthorizedInfo"
                values={{ providerName }}
              />
            </p>
          ) : null}
        </HeadingCustomerWithSubtitle>
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.saleRequestedTitle"
          values={{ customerName, listingLink }}
        >
          {!isCustomerBanned ? (
            <p className={titleClasses}>
              <FormattedMessage id="TransactionPanel.saleRequestedInfo" values={{ customerName }} />
            </p>
          ) : null}
        </HeadingProvider>
      );
    case HEADING_ACCEPTED:
      return isCustomer ? (
        <HeadingCustomerWithSubtitle
          className={titleClasses}
          id="TransactionPanel.orderPreauthorizedTitle"
          values={{ customerName }}
          subtitleId="TransactionPanel.orderAcceptedSubtitle"
          subtitleValues={{ listingLink }}
        />
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.saleAcceptedTitle"
          values={{ customerName, listingLink }}
        />
      );
    case HEADING_DECLINED:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id="TransactionPanel.orderDeclinedTitle"
          values={{ customerName, listingLink }}
        />
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.saleDeclinedTitle"
          values={{ customerName, listingLink }}
          isCustomerBanned={isCustomerBanned}
        />
      );
    case HEADING_CANCELED:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id="TransactionPanel.orderCancelledTitle"
          values={{ customerName, listingLink }}
        />
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.saleCancelledTitle"
          values={{ customerName, listingLink }}
        />
      );
    case HEADING_DELIVERED:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id="TransactionPanel.orderDeliveredTitle"
          values={{ customerName, listingLink }}
          isCustomerBanned={isCustomerBanned}
        />
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.saleDeliveredTitle"
          values={{ customerName, listingLink }}
          isCustomerBanned={isCustomerBanned}
        />
      );
    default:
      console.warn('Unknown state given to panel heading.');
      return null;
  }
};

export default PanelHeading;
