import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { requiredFieldArrayCheckbox } from '../../util/validators';
import {
  Form,
  Button,
  FieldTextInput,
  FieldCheckboxGroup
} from '../../components';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';

import css from './EditListingIndustriesForm.module.css';
import { OTHER_OPTION } from '../../marketplace-custom-config';

const otherOptionComponent = (
  <FieldTextInput
    id="industriesOther"
    name="industriesOther"
    className={css.otherInput}
    type="text"
  />
)

const EditListingIndustriesFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        filterConfig,
        values
      } = formRenderProps;

      const industriesRequiredMessage = intl.formatMessage({
        id: 'EditListingIndustriesForm.industriesRequired',
      });

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingIndustriesForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingIndustriesForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingIndustriesForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const isOtherOptionSelected = values?.industries?.includes(OTHER_OPTION);
      const submitDisabled =
        invalid ||
        disabled ||
        submitInProgress ||
        (isOtherOptionSelected && !values.industriesOther) ||
        (!isOtherOptionSelected && !!values.industriesOther);
      const industriesOptions = findOptionsForSelectFilter('industries', filterConfig);

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}

          <FieldCheckboxGroup
            id="industries"
            name="industries"
            className={css.industries}
            validate={requiredFieldArrayCheckbox(industriesRequiredMessage)}
            options={industriesOptions}
            twoColumns
            otherOptionKey={OTHER_OPTION}
            otherOptionComponent={otherOptionComponent}
          />

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingIndustriesFormComponent.defaultProps = {
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingIndustriesFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

export default compose(injectIntl)(EditListingIndustriesFormComponent);
