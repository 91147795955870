import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconCoachValidated.module.css';

const IconCoachValidated = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="27.944"
      height="30.535"
      viewBox="0 0 27.944 30.535"
    >
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0H27.944V30.535H0z" />
        </clipPath>
      </defs>
      <g fill="#fff" clipPath="url(#a)">
        <path d="M3.744 20.7L0 26.407l4.242.392 2.047 3.736 3.654-5.567a13.374 13.374 0 01-6.2-4.266M24.2 20.7l3.744 5.7-4.244.4-2.047 3.736L18 24.968a13.374 13.374 0 006.2-4.268M26.136 12.853a.775.775 0 000-1.147l-1.879-1.712a.776.776 0 01-.217-.809l.772-2.422a.777.777 0 00-.574-.993l-2.483-.543a.775.775 0 01-.592-.592l-.543-2.484a.776.776 0 00-.993-.573l-2.427.771a.774.774 0 01-.808-.216L14.683.253a.776.776 0 00-1.146 0l-1.712 1.88a.776.776 0 01-.809.216l-2.423-.771a.774.774 0 00-.992.573l-.544 2.484a.775.775 0 01-.591.592l-2.484.543a.776.776 0 00-.573.993l.771 2.422a.776.776 0 01-.217.809l-1.879 1.712a.775.775 0 000 1.147l1.879 1.712a.775.775 0 01.217.808L3.409 17.8a.776.776 0 00.573.993l2.484.543a.775.775 0 01.591.592l.543 2.48a.775.775 0 00.992.573l2.423-.772a.776.776 0 01.809.217l1.712 1.879a.774.774 0 001.146 0l1.718-1.879a.774.774 0 01.808-.217l2.423.772a.776.776 0 00.993-.573l.543-2.484a.775.775 0 01.592-.592l2.483-.543a.777.777 0 00.574-.993l-.772-2.423a.775.775 0 01.217-.808zm-6.049-2.962l-7.13 7.13a.888.888 0 01-1.258 0l-3.566-3.565a.891.891 0 111.26-1.256l2.907 2.93 6.525-6.5a.89.89 0 011.259 1.259" />
      </g>
    </svg>
  );
};

const { string } = PropTypes;

IconCoachValidated.defaultProps = {
  className: null,
  rootClassName: null,
};

IconCoachValidated.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconCoachValidated;
