import React, { useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { Button, Form, FieldCurrencyInput } from '../../components';
import css from './EditListingPricingForm.module.css';

export const EditListingPricingFormComponent = props => {
  const [error, setError] = useState('');
  return (
    <FinalForm
      {...props}
      validate = {(values) => {
        const { intl } = props;
        const { minimum, price: maximum } = values;

        if (minimum?.amount >= maximum?.amount) {
          if (!error) {
            setError(intl.formatMessage({
              id: 'EditListingPricingForm.minLessThanMaxValid'
            }));
          }
        } else {
          if (error) {
            setError('');
          }
        }
      }}
      render={formRenderProps => {
        const {
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
        } = formRenderProps;

        const minimumMessage = intl.formatMessage({
          id: 'EditListingPricingForm.minimum',
        });
        const minimumPlaceholderMessage = intl.formatMessage({
          id: 'EditListingPricingForm.minimumPlaceholder',
        });

        const maximumMessage = intl.formatMessage({
          id: 'EditListingPricingForm.maximum',
        });
        const maximumPlaceholderMessage = intl.formatMessage({
          id: 'EditListingPricingForm.maximumPlaceholder',
        });

        const rateRequired = validators.required(
          intl.formatMessage({
            id: 'EditListingPricingForm.rateRequired',
          })
        );

        const textNote = intl.formatMessage({
          id: 'EditListingPricingForm.textNote',
        });

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;
        const { updateListingError, showListingsError } = fetchErrors || {};

        return (
          <Form onSubmit={handleSubmit} className={classes}>
            {updateListingError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingPricingForm.updateFailed" />
              </p>
            ) : null}
            {showListingsError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingPricingForm.showListingFailed" />
              </p>
            ) : null}
            <FieldCurrencyInput
              id="minimum"
              name="minimum"
              className={css.priceInput}
              autoFocus
              label={minimumMessage}
              placeholder={minimumPlaceholderMessage}
              currencyConfig={config.currencyConfig}
              validate={rateRequired}
              hasCustomError={!!error}
            />

            <FieldCurrencyInput
              id="price"
              name="price"
              className={css.priceInput}
              label={maximumMessage}
              placeholder={maximumPlaceholderMessage}
              currencyConfig={config.currencyConfig}
              validate={rateRequired}
              hasCustomError={!!error}
            />

            {error && <div className={css.validateErrorText}>{error}</div>}
            <p className={css.textNote}>{textNote}</p>

            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </Form>
        );
      }}
    />
  )
}

EditListingPricingFormComponent.defaultProps = { fetchErrors: null };

EditListingPricingFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPricingFormComponent);
