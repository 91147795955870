import React, { useRef, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { Form as FinalForm } from 'react-final-form';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  FieldCheckbox,
  ValidationError,
} from '../../components';
import * as validators from '../../util/validators';
import { FormattedMessage } from 'react-intl';

import css from './ChemistrySessionInvitationForm.module.css';

const MINIMUM_EMAIL_NUMBER = 2;
const MAXIMUM_EMAIL_NUMBER = 5;
const INPUT_EMAIL_CHECKBOX_VALUE = 'active';

const ChemistrySessionInvitationForm = ({ options, ...rest }) => {
  const [showInputEmail, setShowInputEmail] = useState(false);
  const emailOptionsRef = useRef(cloneDeep(options));
  return (
    <FinalForm
      {...rest}
      emailOptionsRef={emailOptionsRef}
      keepDirtyOnReinitialize
      render={fieldRenderProps => {
        const {
          handleSubmit,
          form,
          values: { emails, inputEmail, inputEmailCheckbox },
          emailOptionsRef,
          intl,
          formId,
          scheduleChemistryRequest,
        } = fieldRenderProps;

        const handleKeyDown = (event) => {
          if (event.keyCode === 13) {
            event.preventDefault();
          }
        }

        const handleRemoveEmail = key => {
          const updatedValues = emails.filter(email => email !== key);
          emailOptionsRef.current = emailOptionsRef.current.filter(opt => opt.key !== key);
          form.change('emails', updatedValues);
        };

        const handleScheduleChemistry = () => {
          const { invalid } = form.getFieldState('inputEmail');

          if (invalid || emailOptionsRef.current.length >= MAXIMUM_EMAIL_NUMBER) return;

          if (emailOptionsRef.current.length === MAXIMUM_EMAIL_NUMBER - 1) {
            setShowInputEmail(false);
          }

          const inputEmailCheckboxValue = inputEmailCheckbox || [];
          const isMarkedEmail = inputEmailCheckboxValue[0] === INPUT_EMAIL_CHECKBOX_VALUE;

          emailOptionsRef.current.push({
            key: inputEmail,
            label: inputEmail,
          });

          if (isMarkedEmail) {
            form.change('emails', [...emails, inputEmail]);
          }

          form.change('inputEmail', '');
          form.change('inputEmailCheckbox', undefined);
          form.resetFieldState('inputEmail');
          setShowInputEmail(false);
        };

        const emailRequiredMessage = intl.formatMessage({
          id: 'SignupForm.emailRequired',
        });
        const emailRequired = validators.required(emailRequiredMessage);

        const emailInvalidMessage = intl.formatMessage({
          id: 'SignupForm.emailInvalid',
        });
        const emailValid = validators.emailFormatValid(emailInvalidMessage);

        const duplicateEmailMessage = intl.formatMessage({
          id: 'ChemistrySessionInvitationForm.emailExist',
        });
        const currentEmails = emailOptionsRef.current.map(ele => ele.key);
        const notDuplicateEmail = validators.isNotDuplicated(currentEmails)(duplicateEmailMessage);

        const uniqueEmailsMessage = intl.formatMessage({
          id: 'ChemistrySessionInvitationForm.duplicatedEmail',
        });
        const uniqueEmails = validators.areUniqueItems(uniqueEmailsMessage);
        const { touched, invalid, error } = form?.getFieldState('emails') || {};
        const emailsFieldMeta = { touched: !!(touched && invalid && error), error };

        return (
          <Form onSubmit={handleSubmit}>
            <ul>
              {emailOptionsRef.current.map((option, index) => {
                const { key, label, disabled } = option;
                return (
                  <li key={key} className={css.item}>
                    <FieldCheckbox
                      id={`${formId}.${key}`}
                      textClassName={css.textCheckBox}
                      name="emails"
                      label={label}
                      value={key}
                      disabled={disabled}
                      validate={uniqueEmails}
                    />
                    {index > MINIMUM_EMAIL_NUMBER && (
                      <div className={css.removeEmail} onClick={() => handleRemoveEmail(key)}>
                        <FormattedMessage id="ChemistrySessionInvitationForm.removeEmail" />
                      </div>
                    )}
                  </li>
                );
              })}
            </ul>
            <ValidationError fieldMeta={emailsFieldMeta} />

            {showInputEmail && (
              <div className={css.inputEmail}>
                <FieldCheckbox
                  id="inputEmailCheckbox"
                  name="inputEmailCheckbox"
                  value={INPUT_EMAIL_CHECKBOX_VALUE}
                  textClassName={css.textCheckBox}
                  checkboxWrapperClassName={css.inputEmailCheckbox}
                />
                <FieldTextInput
                  onKeyDown={handleKeyDown}
                  type="email"
                  className={css.input}
                  name="inputEmail"
                  validate={validators.composeValidators(
                    emailRequired,
                    emailValid,
                    notDuplicateEmail
                  )}
                />
                <div className={css.actionButton} onClick={handleScheduleChemistry}>
                  <FormattedMessage id="ChemistrySessionInvitationForm.addEmail" />
                </div>
                <div className={css.actionButton} onClick={() => setShowInputEmail(false)}>
                  <FormattedMessage id="ChemistrySessionInvitationForm.removeEmail" />
                </div>
              </div>
            )}
            {!showInputEmail && emailOptionsRef.current.length < MAXIMUM_EMAIL_NUMBER && (
              <div className={css.addPeople} onClick={() => setShowInputEmail(true)}>
                <FormattedMessage id="ChemistrySessionInvitationForm.addMorePeople" />
              </div>
            )}
            <PrimaryButton
              className={css.submitButton}
              inProgressClassName={css.submitButtonInProgress}
              type="submit"
              inProgress={scheduleChemistryRequest}
              disabled={scheduleChemistryRequest}
            >
              <FormattedMessage id="ChemistrySessionInvitationForm.confirmButton" />
            </PrimaryButton>
          </Form>
        );
      }}
    />
  );
};

export default ChemistrySessionInvitationForm;
