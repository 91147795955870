import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { requiredFieldArrayCheckbox } from '../../util/validators';
import {
  Form,
  Button,
  FieldTextInput,
  FieldCheckboxGroup
} from '../../components';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';

import css from './EditListingCoachingAccreditationsForm.module.css';
import { OTHER_OPTION } from '../../marketplace-custom-config';

const otherOptionComponent = (
  <FieldTextInput
    id="coachingAccreditationsOther"
    name="coachingAccreditationsOther"
    className={css.otherInput}
    type="text"
  />
)

const EditListingCoachingAccreditationsFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        filterConfig,
        values
      } = formRenderProps;

      const coachingAccreditationsRequiredMessage = intl.formatMessage({
        id: 'EditListingCoachingAccreditationsForm.coachingAccreditationsRequired',
      });
      const textNote = intl.formatMessage({
        id: 'EditListingCoachingAccreditationsForm.textNote',
      });

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingCoachingAccreditationsForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingCoachingAccreditationsForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingCoachingAccreditationsForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const isOtherOptionSelected = values?.coachingAccreditations?.includes(OTHER_OPTION);
      const submitDisabled =
        invalid ||
        disabled ||
        submitInProgress ||
        (isOtherOptionSelected && !values.coachingAccreditationsOther) ||
        (!isOtherOptionSelected && !!values.coachingAccreditationsOther);
      const coachingAccreditationsOptions = findOptionsForSelectFilter('coachingAccreditations', filterConfig);

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}

          <FieldCheckboxGroup
            id="coachingAccreditations"
            name="coachingAccreditations"
            className={css.coachingAccreditations}
            validate={requiredFieldArrayCheckbox(coachingAccreditationsRequiredMessage)}
            options={coachingAccreditationsOptions}
            twoColumns
            otherOptionKey={OTHER_OPTION}
            otherOptionComponent={otherOptionComponent}
          />
          <p className={css.textNote}>{textNote}</p>

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingCoachingAccreditationsFormComponent.defaultProps = {
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingCoachingAccreditationsFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

export default compose(injectIntl)(EditListingCoachingAccreditationsFormComponent);
