import React, { useState } from 'react';

// Import moment from moment-timezone. 10-year range only.
// The full data included in moment-timezone dependency is mostly irrelevant
// and slows down the first paint.
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import { bool, func, string } from 'prop-types';
import { isInclusivelyAfterDay } from 'react-dates';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import {
  timeOfDayFromLocalToTimeZone,
  timeOfDayFromTimeZoneToLocal,
  getMonthStartInTimeZone,
  nextMonthFn,
  prevMonthFn,
  dateIsAfter,
} from '../../util/dates';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import { Form, Button, FieldTextInput, FieldDateInput, FieldCurrencyInput } from '../../components';
import config from '../../config';
import { getDefaultTimeZoneOnBrowser } from '../../util/dates';
import { bookingDateRequired, required, validNumberInRange } from '../../util/validators';
import NextMonthIcon from '../BookingTimeForm/NextMonthIcon';
import PreviousMonthIcon from '../BookingTimeForm/PreviousMonthIcon';
import { cloneDeep } from 'lodash';
import { formatMoney } from '../../util/currency';

import css from './ConfirmProjectForm.module.css';

const { Money } = sdkTypes;

const TODAY = new Date();

const defaultTimeZone = () =>
  typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Etc/UTC';

const Next = () => {
  return <NextMonthIcon />;
};

const Prev = props => {
  const { currentMonth, timeZone } = props;
  const prevMonthDate = prevMonthFn(currentMonth, timeZone);
  const currentMonthDate = getMonthStartInTimeZone(TODAY, timeZone);

  return dateIsAfter(prevMonthDate, currentMonthDate) ? <PreviousMonthIcon /> : null;
};

const getTotalPrice = (pricePerHour, numberOfHours) => {
  if (!pricePerHour) {
    return new Money(0, config.currencyConfig.currency);
  }
  const priceTotal = cloneDeep(pricePerHour);
  priceTotal.amount = priceTotal.amount * numberOfHours;
  return priceTotal;
};

const ConfirmProjectFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        className,
        handleSubmit,
        intl,
        values,
        inProgress,
        hasError,
      } = formRenderProps;

      const { pricePerHour, numberOfHours = 0 } = values;

      const totalPrice = getTotalPrice(pricePerHour, numberOfHours);
      values.totalPrice = totalPrice;

      const timeZone = defaultTimeZone();
      const [currentMonth, setCurrentMonth] = useState(getMonthStartInTimeZone(TODAY, timeZone));

      // engagement start date
      const startDateLabel = intl.formatMessage({
        id: 'BookingTimeForm.bookingStartTitle',
      });
      const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };
      const startDatePlaceholder = intl.formatDate(TODAY, dateFormattingOptions);

      // price
      const priceMessage = intl.formatMessage({
        id: 'ConfirmProjectForm.price',
      });
      const priceRequired = required(
        intl.formatMessage({
          id: 'ConfirmProjectForm.priceRequired',
        })
      );

      // number of hours
      const numberOfHoursLabel = intl.formatMessage({
        id: 'ConfirmProjectForm.numberOfHours',
      });
      const numberOfHoursValidator = validNumberInRange(
        intl.formatMessage({ id: 'ConfirmProjectForm.numberOfHoursIsRequired' }),
        0,
        null
      );

      const onMonthClick = monthFn => {
        setCurrentMonth(monthFn(currentMonth, timeZone));
      };

      const isOutsideRange = day => {
        return !isInclusivelyAfterDay(day, moment());
      };

      const classes = classNames(css.root, className);
   
      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <FieldDateInput
            className={css.fieldDateInput}
            name="startDate"
            id="engagementStartDate"
            label={startDateLabel}
            placeholderText={startDatePlaceholder}
            format={v =>
              v && v.date ? { date: timeOfDayFromTimeZoneToLocal(v.date, timeZone) } : v
            }
            parse={v =>
              v && v.date ? { date: timeOfDayFromLocalToTimeZone(v.date, timeZone) } : v
            }
            onPrevMonthClick={() => onMonthClick(prevMonthFn)}
            onNextMonthClick={() => onMonthClick(nextMonthFn)}
            navNext={<Next currentMonth={currentMonth} timeZone={timeZone} />}
            navPrev={<Prev currentMonth={currentMonth} timeZone={timeZone} />}
            useMobileMargins
            showErrorMessage={false}
            validate={bookingDateRequired('Required')}
            isOutsideRange={isOutsideRange}
          />

          <div className={css.row}>
            <FieldCurrencyInput
              id="pricePerHour"
              name="pricePerHour"
              className={css.priceInput}
              label={priceMessage}
              currencyConfig={config.currencyConfig}
              validate={priceRequired}
            />

            <FieldTextInput
              className={css.numberOfHoursInput}
              id="numberOfHours"
              name="numberOfHours"
              type="text"
              label={numberOfHoursLabel}
              validate={numberOfHoursValidator}
            />
          </div>       

          <hr className={css.divider} />
          <div className={css.totalPriceRow}>
            <span>
              <FormattedMessage id="ConfirmProjectForm.totalPriceLabel" />
            </span>
            <span className={css.totalPriceValue}>{formatMoney(intl, totalPrice)}</span>
          </div>
        
          {!!hasError && (
            <p className={css.actionError}>
              <FormattedMessage id="ConfirmProjectForm.submitFailed" />
            </p>
          )}

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={inProgress}
            disabled={inProgress}
          >
            <FormattedMessage id="ConfirmProjectForm.confirmButton" />
          </Button>
        </Form>
      );
    }}
  />
);

ConfirmProjectFormComponent.defaultProps = {
  className: null,
  inProgress: false,
  hasError: null,
};

ConfirmProjectFormComponent.propTypes = {
  className: string,
  onSubmit: func.isRequired,
  intl: intlShape.isRequired,
  inProgress: bool,
  hasError: propTypes.error,
};

export default compose(injectIntl)(ConfirmProjectFormComponent);
