import React from 'react'
import classNames from 'classnames';
import { array, string } from 'prop-types';

import css from './Process.module.css';

const Process = (props) => {
  const { processContentList, className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <ul className={css.contentBox}>
        {processContentList.map(
          ({ icon, title, description, itemClassName, iconBoxClassName, selected }, index) => (
            <li className={classNames(css.item, itemClassName)} key={index}>
              <div
                className={classNames(css.iconBox, iconBoxClassName, {
                  [css.activeItem]: selected,
                  [css.lastIcon]: index === processContentList.length - 1,
                })}
              >
                {icon}
              </div>
              <div className={css.content}>
                <p className={css.title}>
                  {index + 1}. {title}
                </p>
                <p className={css.description}>{description}</p>
              </div>
            </li>
          )
        )}
      </ul>
    </div>
  );
}

Process.defaultProps = {
  processContentList: [],
  className: null,
  rootClassName: null
};

Process.propTypes = {
  processContentList: array,
  className: string,
  rootClassName: string
};

export default Process;