import React from 'react';
import { func, node, string } from 'prop-types';
import classNames from 'classnames';

import css from './Overlay.module.css';

const Overlay = props => {
  const {
    className,
    rootClassName,
    message,
    errorMessage,
    children,
    overlayClassName,
    messageClassName,
    overlayContentClassName,
    onClick,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  const handleClick = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <div
      className={classes}
      onClick={onClick || handleClick}
    >
      <div className={overlayClassName || css.overlay} />
      <div className={overlayContentClassName || css.overlayContent}>
        {errorMessage ? <div className={css.errorMessage}>{errorMessage}</div> : null}
        {message ? <div className={messageClassName || css.message}>{message}</div> : null}
        {children}
      </div>
    </div>
  );
};

Overlay.defaultProps = {
  className: null,
  rootClassName: null,
  message: null,
  errorMessage: null,
  children: null,
  overlayClassName: null,
  messageClassName: null,
  overlayContentClassName: null,
};

Overlay.propTypes = {
  className: string,
  rootClassName: string,
  message: string,
  errorMessage: string,
  children: node,
  overlayClassName: string,
  messageClassName: string,
  overlayContentClassName: string,
  onClick: func,
};

export default Overlay;
