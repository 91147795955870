import { storableError } from '../../util/errors';
import { client } from '../../prismic';

// ================ Action types ================ //

export const FETCH_ARTICLE_REQUEST = 'app/KnowledgeHubPage/FETCH_ARTICLE_REQUEST';
export const FETCH_ARTICLE_SUCCESS = 'app/KnowledgeHubPage/FETCH_ARTICLE_SUCCESS';
export const FETCH_ARTICLE_ERROR = 'app/KnowledgeHubPage/FETCH_ARTICLE_ERROR';

export const FETCH_RELATED_ARTICLES_SUCCESS = 'app/KnowledgeHubPage/FETCH_RELATED_ARTICLES_SUCCESS';

// ================ Reducer ================ //

const initialState = {
  fetchArticleInProgress: false,
  fetchArticleError: null,
  article: null,
  relatedArticles: [],
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_ARTICLE_REQUEST:
      return {
        ...state,
        relatedArticles: [],
        article: null,
        fetchArticleInProgress: true,
        fetchArticleError: null,
      };
    case FETCH_ARTICLE_SUCCESS: {
      return {
        ...state,
        fetchArticleInProgress: false,
        article: payload,
      };
    }
    case FETCH_ARTICLE_ERROR:
      console.error(payload);
      return { ...state, fetchArticleInProgress: false, fetchArticleError: payload };

    case FETCH_RELATED_ARTICLES_SUCCESS: {
      return {
        ...state,
        relatedArticles: payload,
      };
    }

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchArticleRequest = searchParams => ({
  type: FETCH_ARTICLE_REQUEST,
  payload: searchParams,
});
const fetchArticleSuccess = response => ({
  type: FETCH_ARTICLE_SUCCESS,
  payload: response,
});
const fetchArticleError = e => ({
  type: FETCH_ARTICLE_ERROR,
  error: true,
  payload: e,
});

const fetchRelatedArticlesSuccess = response => ({
  type: FETCH_RELATED_ARTICLES_SUCCESS,
  payload: response,
});

// ================ Thunks ================ //

export const fetchArticleByID = slug => (dispatch, getState, sdk) => {
  dispatch(fetchArticleRequest());

  const params = {
    fetchLinks: [
      'knowledge_hub.title',
      'knowledge_hub.hero_image',
      'knowledge_hub.post_by',
      'knowledge_hub.post_date',
    ],
  };

  return client
    .getByUID('knowledge_hub', slug, params)
    .then(response => {
      dispatch(fetchArticleSuccess(response));

      // Get content relationship data
      const { related_articles } = response.data;
      const relatedArticles = related_articles
        .map(item => item.article)
        .filter(article => article.id);

      dispatch(fetchRelatedArticlesSuccess(relatedArticles));
    })
    .catch(e => {
      dispatch(fetchArticleError(storableError(e)));
    });
};

export const loadData = params => (dispatch, getState, sdk) => {
  const { slug } = params;

  return dispatch(fetchArticleByID(slug));
};
