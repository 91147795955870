import React from 'react';
import { bool, func, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Form, Button, FieldTextInput } from '../../components';
import { required } from '../../util/validators';
import coachLogo from '../../assets/icons/coach-gradient.png';

import css from './DeclineEngagementForm.module.css';

const DeclineEngagementFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        className,
        handleSubmit,
        intl,
        pristine,
        inProgress,
        hasError,
      } = formRenderProps;

      const reasonLabel = intl.formatMessage({
        id: 'DeclineEngagementForm.reasonLabel',
      });

      const reasonRequired = required(
        intl.formatMessage({
          id: 'DeclineEngagementForm.reasonRequired',
        })
      );

      const classes = classNames(css.root, className);

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <img src={coachLogo} className={css.logo} />
          <h3 className={css.heading}>
            <FormattedMessage id="DeclineEngagementForm.heading" />
          </h3>

          <FieldTextInput
            className={css.numberOfHoursInput}
            id="reason"
            name="reason"
            type="textarea"
            label={reasonLabel}
            validate={reasonRequired}
          />

          {!!hasError && (
            <p className={css.actionError}>
              <FormattedMessage id="DeclineEngagementForm.submitFailed" />
            </p>
          )}

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={inProgress}
            disabled={pristine || inProgress}
          >
            <FormattedMessage id="DeclineEngagementForm.saveButton" />
          </Button>
        </Form>
      );
    }}
  />
);

DeclineEngagementFormComponent.defaultProps = {
  className: null,
  inProgress: false,
  hasError: null,
};

DeclineEngagementFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  inProgress: bool,
  hasError: propTypes.error,
};

export default compose(injectIntl)(DeclineEngagementFormComponent);
