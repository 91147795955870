import * as prismic from '@prismicio/client';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { client } from '../../prismic';
import {
  COACHING_CASE_STUDY,
  COACHING_CULTURE_CASE_STUDY,
  MOST_RECENT_CASE_STUDY,
} from '../../marketplace-custom-config';

// ================ Action types ================ //

export const FETCH_CASE_STUDY_REQUEST = 'app/CaseStudyNavigationPage/FETCH_CASE_STUDY_REQUEST';
export const FETCH_CASE_STUDY_SUCCESS = 'app/CaseStudyNavigationPage/FETCH_CASE_STUDY_SUCCESS';
export const FETCH_CASE_STUDY_ERROR = 'app/CaseStudyNavigationPage/FETCH_CASE_STUDY_ERROR';

export const FETCH_NEWEST_CASE_STUDY_REQUEST = 'app/CaseStudyNavigationPage/FETCH_NEWEST_CASE_STUDY_REQUEST';
export const FETCH_NEWEST_CASE_STUDY_SUCCESS = 'app/CaseStudyNavigationPage/FETCH_NEWEST_CASE_STUDY_SUCCESS';
export const FETCH_NEWEST_CASE_STUDY_ERROR = 'app/CaseStudyNavigationPage/FETCH_NEWEST_CASE_STUDY_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchCaseStudyInProgress: false,
  fetchCaseStudyError: null,
  pagination: null,
  caseStudies: [],
  fetchNewestCaseStudyInProgress: false,
  fetchNewestCaseStudyError: null,
  newestCaseStudy: null,
  searchParams: null,
};

const CASE_STUDY_PAGE_SIZE = 12;

export default function caseStudyNavigationPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_CASE_STUDY_REQUEST:
      return {
        ...state,
        searchParams: payload,
        fetchCaseStudyInProgress: true,
        fetchCaseStudyError: null,
      };
    case FETCH_CASE_STUDY_SUCCESS: {
      return {
        ...state,
        fetchCaseStudyInProgress: false,
        caseStudies: payload.data,
        pagination: payload.pagination,
      };
    }
    case FETCH_CASE_STUDY_ERROR:
      return {
        ...state,
        fetchNewestCaseStudyInProgress: false,
        fetchNewestCaseStudyError: payload,
      };

    case FETCH_NEWEST_CASE_STUDY_REQUEST:
      return { ...state, fetchNewestCaseStudyInProgress: true, fetchNewestCaseStudyError: null };
    case FETCH_NEWEST_CASE_STUDY_SUCCESS: {
      return {
        ...state,
        newestCaseStudy: payload,
      };
    }
    case FETCH_NEWEST_CASE_STUDY_ERROR:
      console.error(payload);
      return {
        ...state,
        fetchNewestCaseStudyInProgress: false,
        fetchNewestCaseStudyError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchCaseStudyRequest = searchParams => ({
  type: FETCH_CASE_STUDY_REQUEST,
  payload: searchParams,
});
const fetchCaseStudySuccess = response => ({
  type: FETCH_CASE_STUDY_SUCCESS,
  payload: response,
});
const fetchCaseStudyError = e => ({
  type: FETCH_CASE_STUDY_ERROR,
  error: true,
  payload: e,
});

const fetchNewestCaseStudyRequest = searchParams => ({
  type: FETCH_NEWEST_CASE_STUDY_REQUEST,
  payload: searchParams,
});
const fetchNewestCaseStudySuccess = response => ({
  type: FETCH_NEWEST_CASE_STUDY_SUCCESS,
  payload: response,
});
const fetchNewestCaseStudyError = e => ({
  type: FETCH_NEWEST_CASE_STUDY_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const fetchCaseStudyPosts = (caseStudyParams, search) => (dispatch, getState, sdk) => {
  dispatch(fetchCaseStudyRequest(search));

  return client
    .getByType('case_study', caseStudyParams)
    .then(response => {
      const { results_per_page, total_results_size, total_pages, page, results } = response;

      const pagination = {
        page,
        perPage: results_per_page,
        totalItems: total_results_size,
        totalPages: total_pages,
      };

      dispatch(fetchCaseStudySuccess({ data: results, pagination }));
      return response;
    })
    .catch(e => {
      dispatch(fetchCaseStudyError(storableError(e)));
      throw e;
    });
};

export const fetchNewestCaseStudyPost = () => (dispatch, getState, sdk) => {
  dispatch(fetchNewestCaseStudyRequest());

  const recentPostParams = {
    type: 'case_study',
    orderings: {
      field: 'my.case_study.post_date',
      direction: 'desc',
    },
  };

  return client
    .getFirst(recentPostParams)
    .then(response => {
      dispatch(fetchNewestCaseStudySuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchNewestCaseStudyError(storableError(e)));
    });
};

const getCaseStudyPrismicParams = category => {
  const recentPostParams = {
    orderings: {
      field: 'my.case_study.post_date',
      direction: 'desc',
    },
  };

  switch (category) {
    case MOST_RECENT_CASE_STUDY:
      return recentPostParams;

    case COACHING_CASE_STUDY:
    case COACHING_CULTURE_CASE_STUDY:
      return {
        ...recentPostParams,
        predicates: [prismic.predicate.at('my.case_study.category', category)],
      };

    default:
      return recentPostParams;
  }
};

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const { newestCaseStudy } = getState().CaseStudyNavigationPage;
  const { page = 1, category } = parse(search);

  const caseStudyParams = {
    pageSize: CASE_STUDY_PAGE_SIZE,
    page,
    ...getCaseStudyPrismicParams(category),
  };

  if (newestCaseStudy) {
    return dispatch(fetchCaseStudyPosts(caseStudyParams, search));
  }
  return Promise.all([
    dispatch(fetchCaseStudyPosts(caseStudyParams, search)),
    dispatch(fetchNewestCaseStudyPost()),
  ]);
};
