import React from 'react';
import Modal from '../Modal/Modal';
import { FormattedMessage, useIntl } from 'react-intl';
import { bool, func, string } from 'prop-types';
import { propTypes } from '../../util/types';
import IconSpinner from '../IconSpinner/IconSpinner';

import css from './MyCoachingPopup.module.css';

const MyCoachingPopup = props => {
  const {
    isOpen,
    onClose,
    onManageDisableScrolling,
    className,
    sendLoginLinkInProgress,
    sendLoginLinkError,
    onSendMedooLoginLink,
  } = props;
  const intl = useIntl();
  const shouldShowPopupContent = !sendLoginLinkInProgress && !sendLoginLinkError;

  return (
    <Modal
      id="MyCoachingPopup.medooLoginLinkModal"
      className={className}
      contentClassName={css.content}
      isOpen={isOpen}
      onClose={onClose}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
    >
      {sendLoginLinkInProgress && <IconSpinner className={css.spinner} />}
      {sendLoginLinkError && <FormattedMessage id="MyCoachingPopup.medooLoginLinkErrorMessage" />}
      {shouldShowPopupContent && (
        <FormattedMessage
          id="MyCoachingPopup.medooLoginLinkModalContent"
          values={{
            lineBreak: <br />,
            here: (
              <button className={css.resendButton} onClick={onSendMedooLoginLink}>
                {intl.formatMessage({ id: 'MyCoachingPopup.here' })}
              </button>
            ),
          }}
        />
      )}
    </Modal>
  );
};

MyCoachingPopup.propTypes = {
  sendLoginLinkInProgress: bool,
  sendLoginLinkError: propTypes.error,
  isOpen: bool,
  onClose: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  className: string,
  onSendMedooLoginLink: func.isRequired,
};

MyCoachingPopup.defaultProps = {
  sendLoginLinkInProgress: false,
  sendLoginLinkError: null,
  isOpen: false,
  className: null,
};

export default MyCoachingPopup;
