import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form as FinalForm, Field } from 'react-final-form';
import classNames from 'classnames';
import { intlShape, injectIntl } from '../../util/reactIntl';
import { Form } from '../../components';

import IconSearchDesktop from './IconSearchDesktop';
import css from './TopbarSearchForm.module.css';

class TopbarSearchFormComponent extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.searchInput = React.createRef();
  }

  onSubmit(values) {
    this.props.onSubmit({ keywords: values.keywords });
    // blur search input to hide software keyboard
    if (this.searchInput.current) {
      this.searchInput.current.blur();
    }
  }
  
  render() {
    return (
      <FinalForm
        {...this.props}
        onSubmit={this.onSubmit}
        render={formRenderProps => {
          const {
            rootClassName,
            className,
            desktopInputRoot,
            intl,
            isMobile,
            isShowSearchBar,
            handleSubmit,
          } = formRenderProps;
          const classes = classNames(rootClassName, className);
          const keywordSearchWrapperClasses = 
            isShowSearchBar
            ? classNames(css.keywordSearchWrapper, isMobile ? css.mobileInputRoot : desktopInputRoot)
            : css.hideSearchBar;
          return (
            <Form className={classes} onSubmit={handleSubmit}>
              <div className={keywordSearchWrapperClasses}>
                <button className={css.searchSubmit}>
                  <div className={classNames(isMobile ? css.mobileIcon : css.desktopIcon || css.icon)}>
                    <IconSearchDesktop />
                  </div>
                </button>
                <Field
                  name="keywords"
                  render={({ input, meta }) => {
                    return (
                      <input
                        className={
                          isMobile
                            ? css.mobileInput
                            : css.desktopInput
                        }
                        {...input}
                        id="keyword-search"
                        ref={this.searchInput}
                        type="text"
                        placeholder={intl.formatMessage({
                          id: 'TopbarSearchForm.placeholder',
                        })}
                        autoComplete="off"
                      />
                    );
                  }}
                />
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

const { func, string, bool } = PropTypes;

TopbarSearchFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  desktopInputRoot: null,
  isMobile: false,
  isShowSearchBar: false,
};

TopbarSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  desktopInputRoot: string,
  onSubmit: func.isRequired,
  isMobile: bool,
  isShowSearchBar: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const TopbarSearchForm = injectIntl(TopbarSearchFormComponent);

export default TopbarSearchForm;
