import React from 'react';
import classNames from 'classnames';
import { string, bool, func } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { PrimaryButton } from '../Button/Button';

import css from './TransactionPanel.module.css';

const RedirectToCheckoutButtonMaybe = props => {
  const {
    className,
    rootClassName,
    showButtons,
    onRedirectToConfirmCoachPayment,
    inProgress,
    hasError,
  } = props;

  const errorMessage = hasError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.redirectToCheckoutFailed" />
    </p>
  ) : null;

  const classes = classNames(rootClassName || css.actionButtons, className);

  return showButtons ? (
    <div className={classes}>
      <div className={css.actionErrors}>
        {errorMessage}
      </div>
      <div className={css.actionButtonWrapper}>
        <PrimaryButton
          className={css.redirectToCheckoutButton}
          onClick={onRedirectToConfirmCoachPayment}
          inProgress={inProgress}
        >
          <FormattedMessage id="TransactionPanel.redirectToCheckoutButton" />
        </PrimaryButton>
      </div>
    </div>
  ) : null;
};

RedirectToCheckoutButtonMaybe.defaultProps = {
  className: null,
  rootClassName: null,
  showButtons: false,
  inProgress: false,
  hasError: null,
};

RedirectToCheckoutButtonMaybe.propTypes = {
  className: string,
  rootClassName: string,
  showButtons: bool,
  onRedirectToConfirmCoachPayment: func,
  inProgress: bool,
  hasError: propTypes.error,
};


export default RedirectToCheckoutButtonMaybe;
