import React from 'react';
import { string, bool } from 'prop-types';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

import { FormattedMessage } from '../../util/reactIntl';

import css from './SectionListItems.module.css';

const SEPARATOR = ";";

const SectionListItems = props => {
  const {
    rootClassName,
    className,
    idTitle,
    idListItems,
    isOrderedList,
    isHasSpacingUnderTitle,
  } = props;
  const intl = useIntl();
  const listItemsIntl = intl
    .formatMessage({ id: idListItems })
    .split(SEPARATOR)
    .map(option => option.trim());

  const classes = classNames(rootClassName || css.root, className);
  const displayList = listItemsIntl.map((item, index) => (
    <li key={index} className={css.item}>
      <span>{item}</span>
    </li>
  ));
  return (
    <div className={classes}>
      <div
        className={classNames(css.title, {
          [css.orderedListTitle]: isOrderedList,
          [css.spacing]: isHasSpacingUnderTitle,
        })}
      >
        <FormattedMessage id={idTitle} />
      </div>
      {isOrderedList ? <ol className={css.orderedList}>{displayList}</ol> : <ul>{displayList}</ul>}
    </div>
  );
};

SectionListItems.defaultProps = {
  rootClassName: null,
  className: null,
  isOrderedList: false,
  isHasSpacingUnderTitle: false,
};

SectionListItems.propTypes = {
  rootClassName: string,
  className: string,
  idTitle: string.isRequired,
  idListItems: string.isRequired,
  isOrderedList: bool,
  isHasSpacingUnderTitle: bool,
};

export default SectionListItems;
