import React, { useState } from 'react';
import { array, arrayOf, bool, func, object, oneOf, shape, string } from 'prop-types';
import { COACH_LISTING, propTypes, ENGAGEMENT_LISTING } from '../../util/types';
import { intlShape, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import routeConfiguration from '../../routeConfiguration';
import {
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_NEW,
  LISTING_PAGE_PARAM_TYPES,
  createSlug,
} from '../../util/urlHelpers';
import { ensureListing } from '../../util/data';
import { createResourceLocatorString } from '../../util/routes';
import {
  EditListingDescriptionPanel,
  EditListingLanguagesPanel,
  EditListingFeaturesPanel,
  EditListingLocationPanel,
  EditListingDevelopmentSituationsPanel,
  EditListingIndustriesPanel,
  EditListingCoachingAccreditationsPanel,
  EditListingAssessmentAccreditationsPanel,
  EditListingPricingPanel,
  EditListingProcessPanel,
  EditListingAgreementPanel,
  EditListingCoacheePanel,
  EditListingEngagementPanel,
  EditListingSuccessFactorsPanel,
  IconSearch,
  IconShortList,
  IconUser,
  IconChemistry,
  Modal,
} from '../../components';
import config from '../../config';

import css from './EditListingWizard.module.css';

export const PROCESS = 'process';
export const DESCRIPTION = 'description';
export const LANGUAGES = 'languages';
export const FEATURES = 'features';
export const DEVELOPMENT_SITUATIONS = 'developmentSituations';
export const INDUSTRIES = 'industries';
export const COACHING_ACCREDITATIONS = 'coachingAccreditations';
export const ASSESSMENT_ACCREDITATIONS = 'assessmentAccreditation';
export const LOCATION = 'location';
export const RATES = 'rates';
export const AGREEMENT = 'agreement';
export const COACHEE = 'coachee';
export const ENGAGEMENT = 'engagement';
export const SUCCESS_FACTORS = 'success-factors';

// EditListingWizardTab component supports these tabs
export const SUPPORTED_TABS = [
  PROCESS,
  DESCRIPTION,
  LANGUAGES,
  FEATURES,
  DEVELOPMENT_SITUATIONS,
  INDUSTRIES,
  COACHING_ACCREDITATIONS,
  ASSESSMENT_ACCREDITATIONS,
  LOCATION,
  RATES,
  AGREEMENT,
  COACHEE,
  ENGAGEMENT,
  SUCCESS_FACTORS
];

const pathParamsToNextTab = (params, tab, marketplaceTabs) => {
  const nextTabIndex = marketplaceTabs.findIndex(s => s === tab) + 1;
  const nextTab =
    nextTabIndex < marketplaceTabs.length
      ? marketplaceTabs[nextTabIndex]
      : marketplaceTabs[marketplaceTabs.length - 1];
  return { ...params, tab: nextTab };
};

// When user has update draft listing, he should be redirected to next EditListingWizardTab
const redirectAfterDraftUpdate = (
  listingId,
  params,
  tab,
  marketplaceTabs,
  history,
  options = {}
) => {
  const { editPageName = 'EditListingPage' } = options;
  const currentPathParams = {
    ...params,
    type: LISTING_PAGE_PARAM_TYPE_DRAFT,
    id: listingId,
  };
  const routes = routeConfiguration();

  // Replace current "new" path to "draft" path.
  // Browser's back button should lead to editing current draft instead of creating a new one.
  if (params.type === LISTING_PAGE_PARAM_TYPE_NEW) {
    const draftURI = createResourceLocatorString(editPageName, routes, currentPathParams, {});
    history.replace(draftURI);
  }

  // Redirect to next tab
  const nextPathParams = pathParamsToNextTab(currentPathParams, tab, marketplaceTabs);
  const to = createResourceLocatorString(editPageName, routes, nextPathParams, {});
  history.push(to);
};

export const coachListingProcessContent = [
  {
    icon: <IconSearch className={css.iconSearch} />,
    title: <FormattedMessage id="EditListingProcessPanel.searchTitle" />,
    description: <FormattedMessage id="EditListingProcessPanel.searchDescription" />,
    buttons: (
      <></>
    ),
  },
  {
    icon: <IconShortList />,
    title: <FormattedMessage id="EditListingProcessPanel.listTitle" />,
    description: <FormattedMessage id="EditListingProcessPanel.listDescription" />,
  },
  {
    icon: <IconChemistry />,
    title: <FormattedMessage id="EditListingProcessPanel.chemistryTitle" />,
    description: <FormattedMessage id="EditListingProcessPanel.chemistryDescription" />,
  },
  {
    icon: <IconUser />,
    title: <FormattedMessage id="EditListingProcessPanel.coachTitle" />,
    description: <FormattedMessage id="EditListingProcessPanel.coachDescription" />,
    buttons: (
      <></>
    ),
  },
];

export const pricingSearchModalContent = (
  <>
    <div className={css.pricingTitle}>
      <FormattedMessage id="EditListingProcessPanel.only" />
    </div>
    <div className={css.pricingWrapper}>
      <div className={css.pricing}>
        <FormattedMessage
          id="EditListingProcessPanel.priceForSearch"
          values={{ price: config.pricingForSearchCoach }}
        />
      </div>
      <span className={css.currency}>{config.currency}</span>
    </div>
    <p className={css.pricingContent}>
      <FormattedMessage id="EditListingProcessPanel.pricingSearchContent" />
    </p>
  </>
);

export const pricingGetACoachModalContent = (
  <>
    <div className={css.pricingTitle}>
      <FormattedMessage id="EditListingProcessPanel.howDoYouPay" />
    </div>
    <div className={css.pricingExplanation}>
      <FormattedMessage
        id="EditListingProcessPanel.agreedCoachRate"
      />
      <FormattedMessage
        id="EditListingProcessPanel.multiple"
      />
      <FormattedMessage
        id="EditListingProcessPanel.numberOfHours"
      />
      <FormattedMessage
        id="EditListingProcessPanel.add"
      />
      <FormattedMessage
        id="EditListingProcessPanel.tenPercent"
      />
    </div>
    <p className={css.pricingTitle}>
      <FormattedMessage id="EditListingProcessPanel.thatsIt" />
    </p>
  </>
);

const EditListingWizardTab = props => {
  const [showPricingSearch, setShowPricingSearch] = useState(false);
  const [showPricingGetACoach, setShowPricingGetACoach] = useState(false);
  const {
    tab,
    marketplaceTabs,
    params,
    errors,
    fetchInProgress,
    newListingPublished,
    history,
    images,
    listing,
    handleCreateFlowTabScrolling,
    handlePublishListing,
    onAddAvailabilityException,
    onDeleteAvailabilityException,
    onUpdateListing,
    onCreateListingDraft,
    onImageUpload,
    onUpdateImageOrder,
    onRemoveImage,
    onChange,
    onManageDisableScrolling,
    updatedTab,
    updateInProgress,
    intl,
    fetchExceptionsInProgress,
    availabilityExceptions,
    ensuredCurrentUser,
    listingType,
    onUpdateProfile,
    updateUserInProgress,
  } = props;

  const { type } = params;
  const isNewURI = type === LISTING_PAGE_PARAM_TYPE_NEW;
  const isDraftURI = type === LISTING_PAGE_PARAM_TYPE_DRAFT;
  const isNewListingFlow = isNewURI || isDraftURI;

  const currentListing = ensureListing(listing);
  const imageIds = images => {
    return images ? images.map(img => img.imageId || img.id) : null;
  };

  const onCompleteEditListingWizardTab = (tab, updateValues, passThrownErrors = false) => {
    const options = {
      editPageName:
        listingType === COACH_LISTING
          ? 'EditListingPage'
          : 'EditEngagementPage'
    };
    // JH to do: if current tab is agreement, we will go to publish without updating anything
    if (tab === AGREEMENT) {
      return handlePublishListing(currentListing.id);
    }

    // Normalize images for API call
    const { images: updatedImages, ...otherValues } = updateValues;
    const imageProperty =
      typeof updatedImages !== 'undefined' ? { images: imageIds(updatedImages) } : {};
    const updateValuesWithImages = { ...otherValues, ...imageProperty };

    if (isNewListingFlow) {
      const onUpsertListingDraft = isNewURI
        ? (tab, updateValues) => onCreateListingDraft({
          ...updateValues,
          publicData: {
            ...updateValues.publicData,
            listingType
          }
        })
        : onUpdateListing;

      const upsertValues = isNewURI
        ? updateValuesWithImages
        : { ...updateValuesWithImages, id: currentListing.id };

      return onUpsertListingDraft(tab, upsertValues)
        .then(async r => {
          if (tab === PROCESS && isNewURI) {
            const profile = {
              publicData: {
                coachListingId: r.data.data.id.uuid,
              },
            };
            await onUpdateProfile(profile);
          }

          if (tab !== marketplaceTabs[marketplaceTabs.length - 1]) {
            // Create listing flow: smooth scrolling polyfill to scroll to correct tab
            handleCreateFlowTabScrolling(false);

            // After successful saving of draft data, user should be redirected to next tab
            redirectAfterDraftUpdate(
              r.data.data.id.uuid,
              params,
              tab,
              marketplaceTabs,
              history,
              options
            );
          } else if (tab === marketplaceTabs[marketplaceTabs.length - 1]) {
            handlePublishListing(currentListing.id);
          }
        })
        .catch(e => {
          if (passThrownErrors) {
            throw e;
          }
          // No need for extra actions
          // Error is logged in EditListingPage.duck file.
        });
    } else {
      return onUpdateListing(tab, { ...updateValuesWithImages, id: currentListing.id });
    }
  };

  const handleConfirmAgreement = (tab, listingType) => {
    const { locationToSearch } = currentListing.attributes.publicData || {};
    const { email, profile } = ensuredCurrentUser.attributes || {};
    const { firstName, lastName, publicData } = profile || {};
    const { companyName } = publicData || {};

    const convertedAgreementDate = new Date().getTime();

    const updatedDataForCoachListingMaybe = {
      email,
      firstName,
      lastName,
      agreementDate: convertedAgreementDate,
      listingLocation: locationToSearch
    }

    const updatedDataForEngagementListingMaybe = {
      companyName,
      agreementDate: convertedAgreementDate
    };

    const updateValues = {
      publicData: {
        agreementDetails:
          listingType === COACH_LISTING
            ? updatedDataForCoachListingMaybe
            : updatedDataForEngagementListingMaybe
      }
    }

    return onUpdateListing(tab, { ...updateValues, id: currentListing.id });
  }

  const handleMoveToProjectListingPage = (listing) => {
    const routes = routeConfiguration();
    const id = listing.id.uuid;
    const slug = createSlug(listing.attributes.title);
    const pathParams = { id, slug };

    history.push(createResourceLocatorString('ListingPage', routes, pathParams, {}));
  }

  const panelProps = tab => {
    return {
      className: css.panel,
      errors,
      listing,
      onChange,
      panelUpdated: updatedTab === tab,
      updateInProgress,
      onManageDisableScrolling,
      // newListingPublished and fetchInProgress are flags for the last wizard tab
      ready: newListingPublished,
      disabled: fetchInProgress,
    };
  };

  const engagementListingProcessContent = [
    {
      icon: <IconSearch className={css.iconSearch} />,
      title: <FormattedMessage id="EditListingProcessPanel.searchTitle" />,
      description: (
        <FormattedMessage
          id="EditListingProcessPanel.searchDescriptionForEngagement"
          values={{
            pricingSearchLink: (
              <button className={css.modalLink} onClick={() => setShowPricingSearch(true)}>
                <FormattedMessage id="EditListingProcessPanel.learnAboutMarketplacePricing" />
              </button>
            )
          }}
        />
      ),
    },
    {
      icon: <IconShortList />,
      title: <FormattedMessage id="EditListingProcessPanel.listTitle" />,
      description: <FormattedMessage id="EditListingProcessPanel.listDescriptionForEngagement" />,
    },
    {
      icon: <IconChemistry />,
      title: <FormattedMessage id="EditListingProcessPanel.chemistryTitle" />,
      description: <FormattedMessage id="EditListingProcessPanel.chemistryDescriptionForEngagement" />,
    },
    {
      icon: <IconUser />,
      title: <FormattedMessage id="EditListingProcessPanel.coachTitle" />,
      description: (
        <FormattedMessage
          id="EditListingProcessPanel.coachDescriptionForEngagement"
          values={{
            pricingGetACoachLink: (
              <button className={css.modalLink} onClick={() => setShowPricingGetACoach(true)}>
                <FormattedMessage id="EditListingProcessPanel.learnAboutCoachPayments" />
              </button>
            )
          }}
        />
      ),
    },
  ];

  const getContentForProcessTab = listingType => {
    switch (listingType) {
      case COACH_LISTING:
        return coachListingProcessContent;
      case ENGAGEMENT_LISTING:
        return engagementListingProcessContent;
      default:
        return [];
    }
  }

  const onPricingModalClose = () => {
    setShowPricingSearch(false);
    setShowPricingGetACoach(false);
  }

  const { firstName, lastName } = ensuredCurrentUser.attributes.profile || {};

  switch (tab) {
    case PROCESS: {
      return (
        <>
          <EditListingProcessPanel
            {...panelProps(PROCESS)}
            className={classNames(css.panelProcess, css.panel)}
            currentListing={currentListing}
            submitButtonText={intl.formatMessage({ id: 'EditListingWizard.proceed' })}
            onSubmit={() => {
              const updatedData =
                listingType === ENGAGEMENT_LISTING
                  ? { title: `Engagement Listing` }
                  : { title: `${firstName} ${lastName}`, publicData: { firstName, lastName } }

              onCompleteEditListingWizardTab(tab, updatedData);
            }}
            processContentList={getContentForProcessTab(listingType)}
            listingType={listingType}
            updateUserInProgress={updateUserInProgress}
          />
          <Modal
            id="EditListingWizard.pricingModal"
            isOpen={showPricingSearch || showPricingGetACoach}
            onClose={onPricingModalClose}
            onManageDisableScrolling={onManageDisableScrolling}
            usePortal
          >
            <div className={
              classNames({
                [css.modalContentContainer]: showPricingSearch,
                [css.modalGetACoachContainer]: showPricingGetACoach
              })
            }>
              {showPricingSearch && pricingSearchModalContent}
              {showPricingGetACoach && pricingGetACoachModalContent}
            </div>
          </Modal>
        </>
      );
    }
    case DESCRIPTION: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewDescription'
        : 'EditListingWizard.saveEditDescription';
      return (
        <EditListingDescriptionPanel
          {...panelProps(DESCRIPTION)}
          ensuredCurrentUser={ensuredCurrentUser}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case LANGUAGES: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewLanguages'
        : 'EditListingWizard.saveEditLanguages';
      return (
        <EditListingLanguagesPanel
          {...panelProps(LANGUAGES)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case FEATURES: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewFeatures'
        : 'EditListingWizard.saveEditFeatures';
      return (
        <EditListingFeaturesPanel
          {...panelProps(FEATURES)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case DEVELOPMENT_SITUATIONS: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewDevelopmentSituations'
        : 'EditListingWizard.saveEditDevelopmentSituations';
      return (
        <EditListingDevelopmentSituationsPanel
          {...panelProps(DEVELOPMENT_SITUATIONS)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case INDUSTRIES: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewIndustries'
        : 'EditListingWizard.saveEditIndustries';
      return (
        <EditListingIndustriesPanel
          {...panelProps(INDUSTRIES)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case COACHING_ACCREDITATIONS: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewCoachingAccreditations'
        : 'EditListingWizard.saveEditCoachingAccreditations';
      return (
        <EditListingCoachingAccreditationsPanel
          {...panelProps(COACHING_ACCREDITATIONS)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case ASSESSMENT_ACCREDITATIONS: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewAssessmentAccreditations'
        : 'EditListingWizard.saveEditAssessmentAccreditations';
      return (
        <EditListingAssessmentAccreditationsPanel
          {...panelProps(ASSESSMENT_ACCREDITATIONS)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case LOCATION: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewLocation'
        : 'EditListingWizard.saveEditLocation';
      return (
        <EditListingLocationPanel
          {...panelProps(LOCATION)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case RATES: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewRates'
        : 'EditListingWizard.saveEditRates';
      return (
        <EditListingPricingPanel
          {...panelProps(RATES)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case AGREEMENT: {
      const submitButtonTranslationKey =
        listingType === ENGAGEMENT_LISTING
          ? isNewListingFlow
            ? 'EditListingWizard.nextPayment'
            : 'EditListingWizard.findACoachText'
          : 'EditListingWizard.publishButtonText';
      return (
        <EditListingAgreementPanel
          {...panelProps(AGREEMENT)}
          currentListing={currentListing}
          currentUser={ensuredCurrentUser}
          listingType={listingType}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onConfirmAgreement={() => handleConfirmAgreement(tab, listingType)}
          handleMoveToProjectListingPage={handleMoveToProjectListingPage}
          onSubmit={() => {
            onCompleteEditListingWizardTab(tab, {});
          }}
        />
      );
    }
    case COACHEE: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewCoachee'
        : 'EditListingWizard.saveEditCoachee';
      return (
        <EditListingCoacheePanel
          {...panelProps(COACHEE)}
          className={css.panel}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case ENGAGEMENT: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewEngagement'
        : 'EditListingWizard.saveEditEngagement';
      return (
        <EditListingEngagementPanel
          {...panelProps(ENGAGEMENT)}
          className={css.panel}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case SUCCESS_FACTORS: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewSuccessFactors'
        : 'EditListingWizard.saveEditSuccessFactors';
      return (
        <EditListingSuccessFactorsPanel
          {...panelProps(SUCCESS_FACTORS)}
          className={css.panel}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    default:
      return null;
  }
};

EditListingWizardTab.defaultProps = {
  listing: null,
  updatedTab: null,
  availabilityExceptions: [],
  listingType: COACH_LISTING,
  updateUserInProgress: false,
};

EditListingWizardTab.propTypes = {
  params: shape({
    id: string.isRequired,
    slug: string.isRequired,
    type: oneOf(LISTING_PAGE_PARAM_TYPES).isRequired,
    tab: oneOf(SUPPORTED_TABS).isRequired,
  }).isRequired,
  availabilityExceptions: arrayOf(propTypes.availabilityException),
  errors: shape({
    createListingDraftError: object,
    publishListingError: object,
    updateListingError: object,
    showListingsError: object,
    uploadImageError: object,
    fetchExceptionsError: object,
    addExceptionError: object,
    deleteExceptionError: object,
  }).isRequired,
  fetchInProgress: bool.isRequired,
  fetchExceptionsInProgress: bool.isRequired,
  newListingPublished: bool.isRequired,
  history: shape({
    push: func.isRequired,
    replace: func.isRequired,
  }).isRequired,
  images: array.isRequired,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: shape({
    attributes: shape({
      publicData: object,
      description: string,
      geolocation: object,
      pricing: object,
      title: string,
    }),
    images: array,
    ensuredCurrentUser: object
  }),
  listingType: oneOf([
    COACH_LISTING,
    ENGAGEMENT_LISTING
  ]),

  handleCreateFlowTabScrolling: func.isRequired,
  handlePublishListing: func.isRequired,
  onAddAvailabilityException: func.isRequired,
  onDeleteAvailabilityException: func.isRequired,
  onUpdateListing: func.isRequired,
  onCreateListingDraft: func.isRequired,
  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onRemoveImage: func.isRequired,
  onChange: func.isRequired,
  updatedTab: string,
  updateInProgress: bool.isRequired,
  onManageDisableScrolling: func.isRequired,
  onUpdateProfile: func,
  updateUserInProgress: bool,
  intl: intlShape.isRequired,
};

export default EditListingWizardTab;
