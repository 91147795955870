import React, { useEffect, useState } from 'react';
import { arrayOf, element, oneOfType, string } from 'prop-types';
import classNames from 'classnames';

import css from './HeroBanner.module.css';
import IMGLEADERSHIP from '../../assets/P2_ Leadership Development.png'
import { HashLink } from 'react-router-hash-link';
HashLink

const HeroBanner = props => {
  const { heading, subTitle, rootClassName, className, children, subHead, subHead2, subHead3, imageUrl } = props;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <div className={css.heroFirst}>
          <div>
            <h1 className={classNames(css.heroMainTitle, css.coachingTitle, { [css.heroMainTitleFEDelay]: mounted })}>
              {heading}
            </h1>
            <h1 className={classNames(css.heroMainTitle, css.coachingTitle, { [css.heroMainTitleFEDelay]: mounted })}>
              {subTitle}
            </h1>
            <div className={css.spaceTop}>
              <h2 className={classNames(css.heroSubTitle, { [css.heroSubTitleFEDelay]: mounted })}>
                {subHead}
              </h2>
              <h2 className={classNames(css.heroSubTitle, { [css.heroSubTitleFEDelay]: mounted })}>
                {subHead2}
              </h2>
              <h2 className={classNames(css.heroSubTitle, { [css.heroSubTitleFEDelay]: mounted })}>
                {subHead3}
              </h2>
            </div>
          </div>
          {/* <div className={css.buttonWrapper}> */}
          {/* {React.Children.map(children, (child, index) => {
            return React.cloneElement(child, {
              className: classNames(css.heroButton, {
                [css.heroButtonFEDelay]: mounted,
                [css.redButton]: index % 2 === 0,
              }),
            });
          })} */}
          <HashLink smooth to='/workshops#contact'>
            <button className={css.btn1}>ENQUIRE NOW</button>
          </HashLink>
          {/* </div> */}

        </div>
        <div className={css.image}>
          <img src={imageUrl} alt="Description of the image" className={css.responsiveImage} />
        </div>

      </div>

    </div>
  );
};

HeroBanner.defaultProps = {
  rootClassName: null,
  className: null,
};

HeroBanner.propTypes = {
  heading: oneOfType([string, element]).isRequired,
  subTitle: oneOfType([string, element]).isRequired,
  rootClassName: string,
  className: string,
  children: arrayOf(element),
};

export default HeroBanner;
