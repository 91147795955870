import React from 'react';
import PropTypes from 'prop-types';

const IconLogo = props => {
  const { className, isBlackLogo, ...rest } = props;

  return (
    <svg
      className={className}
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
      width="170"
      height="45.809"
      viewBox="0 0 170 45.809"
    >
      <defs>
        <clipPath id="a">
          <path fill="none" d="M0 0H170V45.809H0z"></path>
        </clipPath>
      </defs>
      <g clipPath="url(#a)">
        <path
          fill={isBlackLogo ? "#000000" : "#fc4756"}
          d="M19.652 15.459a8.18 8.18 0 014.764 7.551c0 5.04-4.343
           8.854-10.085 8.854H0V.8h14.043c5.517-.012 9.544 3.348 9.544
           7.945a7.911 7.911 0 01-3.935 6.714m-7.1-7.979H8.227v5.414h4.326a2.612 
           2.612 0 002.876-2.71 2.61 2.61 0 00-2.876-2.7m3.394 14.549a2.8 2.8 0 
           00-3.066-2.813H8.227v5.65h4.654a2.833 2.833 0 003.066-2.86zM85.615 
           24.036v7.841H65.871V.811h8.681v23.225zM118.091 16.301c0 9.072-6.662 
           15.573-16.051 15.573H89.459V.811h12.582c9.412-.023 16.051 6.409 16.051 
           15.493m-8.779 0a7.253 7.253 0 00-7.674-7.674h-3.58v15.406h3.578c4.6 0 
           7.669-3.141 7.669-7.732zM142.294 24.036v7.841h-19.756V.811h8.687v23.225zM159.138 
           19.718v12.159h-8.718V19.692L139.63.788h9.5l5.684 11.236 5.7-11.236h9.492zM44.419 
           0c-10 0-17.127 6.829-17.127 16.333s7.128 16.333 17.127 16.333c9.97 0 17.075-6.806 
           17.075-16.333S54.388 0 44.419 0"
        />
        <path
          fill="#fff"
          d="M38.004 31.641c.374.132.754.253 1.151.357L52.26 7.835l8.054 14.866a14.618 
          14.618 0 00.512-1.513L52.254 5.344z"
        />
        <path
          fill="#fff"
          d="M32.746 28.638c.3.253.61.495.926.725l7.335-12.265 8.86 14.825c.391-.115.771-.236 
          1.151-.374L41.007 14.808z"
        />
        <path
          fill={isBlackLogo ? "#000000" : "#fc4756"}
          d="M48.302 42.588v1.688a.683.683 0 01-.228.57 3.631 3.631 0 01-2.486.964 3.579 3.579 
          0 010-7.158 3.213 3.213 0 012.38.952.367.367 0 01.114.28.387.387 0 01-.394.393.407.407 
          0 01-.29-.114 2.56 2.56 0 00-1.813-.746 2.817 2.817 0 000 5.625 2.728 2.728 0 
          001.854-.746v-1.409h-2.03a.383.383 0 110-.766h2.434a.418.418 0 01.456.467M51.769 
          45.219v-6a.414.414 0 01.456-.456h3.729a.384.384 0 110 .767h-3.325v2.227h3.253a.383.383 
          0 110 .766h-3.253v2.383h3.325a.383.383 0 110 .766h-3.729a.414.414 0 01-.456-.456M61.442 
          45.291v-5.759h-1.844a.384.384 0 010-.767h4.558a.384.384 0 010 .767h-1.854v5.759a.426.426 
          0 01-.425.425.436.436 0 01-.435-.425M78.166 45.271a.44.44 0 01-.85.166l-.5-1.295h-3.55l-.5 
          1.295a.418.418 0 01-.4.28.44.44 0 01-.445-.435.6.6 0 01.041-.176l2.382-5.9a.759.759 0 
          01.7-.477.735.735 0 01.694.477l2.393 5.9a.932.932 0 01.031.166m-1.637-1.9l-1.481-3.843-1.481 
          3.843zM85.796 42.225a3.449 3.449 0 013.522-3.574 3.119 3.119 0 012.372 1.005.389.389 0 
          01.1.28.412.412 0 01-.4.4.4.4 0 01-.311-.135 2.379 2.379 0 00-1.761-.787 2.813 2.813 0 000 
          5.614 2.327 2.327 0 001.761-.787.4.4 0 01.311-.135.4.4 0 01.4.4.39.39 0 01-.1.28 3.1 3.1 0 
          01-2.372 1 3.449 3.449 0 01-3.522-3.574M98.234 38.651a3.577 3.577 0 11-3.429 3.574 3.375 
          3.375 0 013.429-3.574m0 .767a2.821 2.821 0 102.538 2.807 2.552 2.552 0 00-2.538-2.807M110.413 
          45.271a.44.44 0 01-.85.166l-.5-1.295h-3.553l-.5 1.295a.418.418 0 01-.4.28.44.44 0 01-.445-.435.6.6 
          0 01.041-.176l2.382-5.9a.759.759 0 01.7-.477.735.735 0 01.694.477l2.393 5.9a.933.933 0 
          01.031.166m-1.637-1.9l-1.481-3.843-1.481 3.843zM112.917 42.225a3.449 3.449 0 013.522-3.574 
          3.119 3.119 0 012.372 1.005.391.391 0 01.1.28.412.412 0 01-.4.4.4.4 0 01-.311-.135 2.377 2.377 0 
          00-1.761-.787 2.813 2.813 0 000 5.614 2.327 2.327 0 001.761-.787.4.4 0 01.311-.135.4.4 0 
          01.4.4.392.392 0 01-.1.28 3.1 3.1 0 01-2.372 1 3.449 3.449 0 01-3.522-3.574M127.105 
          45.292v-2.787h-4.039v2.787a.434.434 0 01-.425.425.429.429 0 01-.436-.425v-6.143a.427.427 0 
          01.425-.425.436.436 0 01.436.425v2.59h4.039v-2.59a.43.43 0 01.86 0v6.143a.43.43 0 01-.86 0"
        />
      </g>
    </svg>
  );
};

const { string, bool } = PropTypes;

IconLogo.defaultProps = {
  className: null,
  isBlackLogo: false
};

IconLogo.propTypes = {
  className: string,
  isBlackLogo: bool,
};

export default IconLogo;
