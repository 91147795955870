import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import Eleven from './images/7 Eleven.png';
import Dole from './images/Dole (1).png';
import Ikea from './images/Ikea (1).png';
import Mandarin from './images/Mandarin Oriental (1).png';
import Mercedes from './images/Mercedes (1).png';
// import Socomec from './images/Socomec (2).png';
import Zespri from './images/Zespri (1).png';
// import Cosmec from '../../assets/scsomec1.jpeg';
import Socomec from '../../assets/Socomec.png'

import css from './LogoBanner.module.css';

const LogoBanner = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <>
    <div className={classNames(css.container, css.containerLogoLap)}>
    <div>
    <div className={`${classes} ${css.FirstLogoBanner}`}>
      <img src={Eleven} alt="jardines logo" className={css.jardinesLogo} />
      <img src={Zespri} alt="bic logo" className={css.jardinesLogo} />
      <h1>uber</h1>    
      <img src={Ikea} alt="ikea logo" className={css.jardinesLogo} />     

    </div>
    <div className={classes}>
      <img src={Socomec} alt="fisher logo" className={css.fisherLogo} /> 
      <img src={Mandarin} alt="pizzahut logo" className={css.pizzahutLogo} />
      <img src={Mercedes} alt="kfc logo" className={css.pizzahutLogo} />
      <img src={Dole} alt="dole logo" className={css.jardinesLogo} />
    </div>
    </div>
    </div>

    
    {/* <div className={classNames(css.container, css.containerLogoBannerTab)}>
    <div>
    <div className={`${classes} ${css.FirstLogoBanner}  ${css.logos}`}>
      <img src={Eleven} alt="jardines logo" className={css.jardinesLogo} />
      <img src={Zespri} alt="bic logo" className={css.jardinesLogo} />
    </div>
    <div className={`${classes} ${css.FirstLogoBanner}  ${css.logos}`}>
    <h1>uber</h1>    
    <img src={Ikea} alt="ikea logo" className={css.jardinesLogo} />  
    </div>
    <div className={`${classes} ${css.FirstLogoBanner}  ${css.logos}`}>
      <img src={Socomec} alt="fisher logo" className={css.fisherLogo} /> 
      <img src={Mandarin} alt="pizzahut logo" className={css.pizzahutLogo} />
    </div>
    <div className={`${classes} ${css.FirstLogoBanner}  ${css.logos}`}>
    <img src={Mercedes} alt="kfc logo" className={css.pizzahutLogo} />
    <img src={Dole} alt="dole logo" className={css.jardinesLogo} />
    </div>
    </div>
  
    </div> */}


    {/* <div className={classNames(css.container, css.containerLogoBannerMobile)}>
    <div>
    <div className={`${classes} ${css.FirstLogoBanner} ${css.logos}`}>
      <img src={Eleven} alt="jardines logo" className={css.jardinesLogo} />
    </div>
    <div className={`${classes} ${css.FirstLogoBanner} ${css.logos}`}>
      <img src={Zespri} alt="bic logo" className={css.jardinesLogo} />
    </div>
    <div className={`${classes} ${css.FirstLogoBanner} ${css.logos}`}>
    <h1>uber</h1>    
    </div>
    <div className={`${classes} ${css.FirstLogoBanner} ${css.logos}`}>
    <img src={Ikea} alt="ikea logo" className={css.jardinesLogo} />  
    </div>
    <div className={`${classes} ${css.logos}`}>
      <img src={Socomec} alt="fisher logo" className={css.fisherLogo} /> 
    </div>
    <div className={`${classes} ${css.FirstLogoBanner} ${css.logos}`}>
      <img src={Mandarin} alt="pizzahut logo" className={css.pizzahutLogo} />
    </div>
    <div className={`${classes} ${css.FirstLogoBanner} ${css.logos}`}>
    <img src={Mercedes} alt="kfc logo" className={css.pizzahutLogo} />
    </div>
    <div className={`${classes} ${css.FirstLogoBanner} ${css.logos}`}>
    <img src={Dole} alt="dole logo" className={css.jardinesLogo} />
    </div>
    </div>
    </div> */}
    </>
  
  );
};

LogoBanner.defaultProps = { rootClassName: null, className: null };

LogoBanner.propTypes = {
  rootClassName: string,
  className: string,
};

export default LogoBanner;
