import React, { useState } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { array, bool, func, node, object, oneOfType, shape, string } from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import {
  propTypes,
  LISTING_STATE_DRAFT,
  LISTING_STATE_CLOSED,
  LINE_ITEM_NIGHT,
  LINE_ITEM_DAY,
  COACH_LISTING,
  LISTING_STATE_PUBLISHED,
} from '../../util/types';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { parse, stringify, generateSearchParamsByEngagementListing } from '../../util/urlHelpers';
import config from '../../config';
import {
  ModalInMobile,
  Button,
  Modal,
  Agreement,
  IconSearch,
  SplitButton,
  IconAddToShortList,
  IconShortListAdded,
  IconShortList,
  IconCalendar,
  IconChemistry,
  HoverPopUp,
  NamedLink,
  ModalAddToCoachList,
} from '../../components';
import { BookingTimeForm } from '../../forms';
import findIndex from 'lodash/findIndex';

import css from './BookingPanel.module.css';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { BUYER_ROLE, COACHEE_ROLE, COACH_ROLE } from '../../marketplace-custom-config';
import BookingPanelForm from './BookingPanelForm';
import { convertListingsToOptions } from '../../util/data';
import intersection from 'lodash/intersection';
const { Money } = sdkTypes;

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;
const TODAY = new Date();
const ADD_TO_COACH_LIST = 'addToCoachList';
const BOOK_CHEMISTRY = 'bookChemistry';
const SEARCH_PAGE = 'SearchPage';

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openBookModal = (isOwnListing, isClosed, history, location) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), book: true })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const closeBookModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'book');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };

const configActionForHover = {
  onMouseEnterCustomActive: true,
  onMouseLeaveCustomActive: true,
};

const getEngagementsCoachJoin = (buyerEngagements, engagementList) => {
  const buyerEngagementIds = buyerEngagements.map(e => e.id.uuid);
  const intersectionIds = intersection(buyerEngagementIds, engagementList);
  return intersectionIds;
};

const BookingPanel = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    isOwnListing,
    unitType,
    onSubmit,
    title,
    subTitle,
    onManageDisableScrolling,
    onFetchTimeSlots,
    monthlyTimeSlots,
    history,
    location,
    intl,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    currentUser,
    projectListings,
    addToCoachList,
    addToCoachListInProgress,
    addToCoachListError,
    onResetCoachListError,
  } = props;

  const [isOpenAgreementModal, setIsOpenAgreementModal] = useState(false);
  const [openBookingPanelModal, setOpenBookingPanelModal] = useState();
  const [openAddCoachListSuccessModal, setOpenAddCoachListSuccessModal] = useState(false);
  const [goStraightToEngagement, setGoStraightToEngagement] = useState(null);
  const price = listing.attributes.price;
  const { minPrice, listingType } = listing.attributes.publicData;
  const formattedMinPrice = minPrice
    ? formatMoney(intl, new Money(minPrice.amount, minPrice.currency))
    : null;
  const timeZone =
    listing.attributes.availabilityPlan && listing.attributes.availabilityPlan.timezone;
  const state = listing.attributes.state;
  const hasListingState = !!state;
  const isClosed = hasListingState && state === LISTING_STATE_CLOSED;
  const isDraft = hasListingState && state === LISTING_STATE_DRAFT;
  //Temporarily hide booking form on listing page until we build the coach profile view for coachee
  const showBookingTimeForm = hasListingState && !isClosed && false;
  const showClosedListingHelpText = listing.id && isClosed;
  const { formattedPrice, priceTitle } = priceData(price, intl);
  const isBook = !!parse(location.search).book;
  const { role } = currentUser?.attributes.profile.publicData || {};

  const engagementList = listing.attributes.metadata?.engagementList || [];

  const displayRangeOfPrice = !!formattedMinPrice
    ? `${price.currency}${formattedMinPrice} - ${formattedPrice}`
    : null;

  const subTitleText = !!subTitle
    ? subTitle
    : showClosedListingHelpText
    ? intl.formatMessage({ id: 'BookingPanel.subTitleClosedListing' })
    : null;

  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'BookingPanel.perNight'
    : isDaily
    ? 'BookingPanel.perDay'
    : 'BookingPanel.perUnit';

  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.bookingTitle);

  const publishedProjectListings = projectListings.filter(
    prj => prj.attributes.state === LISTING_STATE_PUBLISHED
  );

  const { agreementDetails } = listing.attributes.publicData || {};
  const isShowNewClause = agreementDetails?.agreementDate > config.addNewClauseTimestamp;

  const viewAgreementButtonMaybe = isOwnListing && !isEmpty(agreementDetails) && (
    <Button className={css.viewAgreementButton} onClick={() => setIsOpenAgreementModal(true)}>
      <FormattedMessage id="ListingPage.viewAgreementButton" />
    </Button>
  );

  const buyerClickHandler = clickType => e => {
    e.preventDefault();
    if (projectListings.length === 0) {
      history.push(createResourceLocatorString('NewEngagementPage', routeConfiguration(), {}, {}));
    }

    setOpenBookingPanelModal(clickType);
    onResetCoachListError();
  };

  const handleSubmitBookingPanelForm = values => {
    const { addCoachList, removeCoachList } = values;

    addToCoachList({
      addCoachList,
      removeCoachList,
      coachListingId: listing.id.uuid,
    }).then(() => {
      setOpenBookingPanelModal(false);
      setOpenAddCoachListSuccessModal(true);

      // Only go straight to coach list in success popup 
      // if user only update one coach to engagement
      const updatedCoachList = [...addCoachList, ...removeCoachList];
      if (updatedCoachList.length === 1) {
        setGoStraightToEngagement(updatedCoachList[0]);
      }
    });
  };

  const handleClickViewCoachList = () => {
    history.push(
      createResourceLocatorString(
        'CoachListPage',
        routeConfiguration(),
        { engagementId: listing.id.uuid },
        {}
      )
    );
  };

  const bookingPanelFormModal =
    openBookingPanelModal === ADD_TO_COACH_LIST ? (
      <ModalAddToCoachList
        id="BookingPanel.addToCoachListModal"
        isOpen={!!openBookingPanelModal}
        onClose={() => setOpenBookingPanelModal('')}
        onManageDisableScrolling={onManageDisableScrolling}
        onSubmit={handleSubmitBookingPanelForm}
        useCheckBox
        projectListings={publishedProjectListings}
        history={history}
        role={role}
        inProgress={addToCoachListInProgress}
        error={addToCoachListError}
        engagementList={engagementList}
        coachListing={listing}
      />
    ) : (
      <Modal
        id={`BookingPanel.${openBookingPanelModal}`}
        isOpen={!!openBookingPanelModal}
        onClose={() => setOpenBookingPanelModal('')}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
      >
        <div className={css.bookingPanelFormModalHeading}>
          <FormattedMessage id="BookingPanel.modalHeading" />
        </div>
        <BookingPanelForm
          onSubmit={() => {}}
          projectListings={convertListingsToOptions(publishedProjectListings, listing)}
          actionButtonText={<FormattedMessage id="BookingPanel.bookChemistrySession" />}
          history={history}
          role={role}
          initialValues={{}}
        />
      </Modal>
    );

  const viewCoachListLink = () => {
    return goStraightToEngagement ? (
      <FormattedMessage
        id="BookingPanel.viewCoachList"
        values={{
          link: (
            <NamedLink
              name="CoachListPage"
              params={{ engagementId: goStraightToEngagement }}
              className={css.link}
            >
              <FormattedMessage id="BookingPanel.here" />
            </NamedLink>
          ),
        }}
      />
    ) : (
      <FormattedMessage
        id="BookingPanel.viewCoachLists"
        values={{
          link: (
            <NamedLink name="ManageEngagementsPage" className={css.link}>
              <FormattedMessage id="BookingPanel.here" />
            </NamedLink>
          ),
        }}
      />
    );
  };

  const handleClickBack = () => {
    if (location.state?.from === SEARCH_PAGE) {
      history.goBack();
    } else {
      history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, {}));
    }
  };

  const addCoachListSuccessModal = (
    <Modal
      id="BookingPanel.addToCoachListSuccessModal"
      isOpen={openAddCoachListSuccessModal}
      onClose={() => setOpenAddCoachListSuccessModal(false)}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
    >
      <div className={css.addIconContainer}>
        <IconShortList className={css.addIcon} />
      </div>
      <div className={css.addToCoachListSuccessTitle}>
        <FormattedMessage id="BookingPanel.addToCoachListSuccessTitle" />
      </div>

      <div className={css.addToCoachListSuccessLineGroup}>
        <div>{viewCoachListLink()}</div>
        <div>
          <FormattedMessage
            id="BookingPanel.goBack"
            values={{
              link: (
                <span className={css.link} onClick={handleClickBack}>
                  <FormattedMessage id="BookingPanel.here" />
                </span>
              ),
            }}
          />
        </div>
      </div>
    </Modal>
  );

  const getGroupButtonsByRole = () => {
    switch (role) {
      case BUYER_ROLE:
        return (
          <>
            <SplitButton
              icon={
                getEngagementsCoachJoin(projectListings, engagementList).length > 0 ? (
                  <IconShortListAdded className={css.iconAddList} />
                ) : (
                  <IconAddToShortList className={css.iconAddList} />
                )
              }
              buttonText={<FormattedMessage id="BookingPanel.addToCoachList" />}
              className={css.redButton}
              iconRootClassName={classNames(css.redDarkColor, css.icon)}
              {...configActionForHover}
              hoverPopUp={
                <HoverPopUp className={classNames(css.popupContent, css.redPopUp)}>
                  <FormattedMessage id="BookingPanel.addToCoachListPopup" />
                </HoverPopUp>
              }
              onClick={buyerClickHandler(ADD_TO_COACH_LIST)}
            />
          </>
        );
      case COACHEE_ROLE:
      case COACH_ROLE:
      default:
        return null;
    }
  };
  const groupButtonMaybe = getGroupButtonsByRole();

  const agreementModal = (
    <Modal
      id="BookingPanel.agreementModal"
      isOpen={isOpenAgreementModal}
      onClose={() => setIsOpenAgreementModal(false)}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      containerClassName={css.agreementModal}
      contentClassName={css.agreementModalContent}
      closeButtonClassName={css.modalCloseButton}
    >
      <Agreement currentListing={listing} isShowNewClause={isShowNewClause} />
    </Modal>
  );

  const handleSearchCoach = listing => {
    const searchParams = generateSearchParamsByEngagementListing(listing);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  };

  return (
    <div className={classes}>
      <ModalInMobile
        className={css.stickyModal}
        containerClassName={css.modalContainer}
        id="BookingTimeFormInModal"
        isModalOpenOnMobile={isBook}
        onClose={() => closeBookModal(history, location)}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        {showBookingTimeForm && (
          <div className={css.modalHeading}>
            <h1 className={css.title}>{title}</h1>
          </div>
        )}
        <div className={css.bookingHeading}>
          {listingType === COACH_LISTING ? (
            <>
              {isOwnListing && (
                <div className={css.desktopPriceContainer}>
                  <div className={css.desktopPriceValue} title={priceTitle}>
                    {displayRangeOfPrice}
                  </div>
                  <div className={css.desktopPerUnit}>
                    <FormattedMessage id={unitTranslationKey} />
                  </div>
                  {viewAgreementButtonMaybe}
                </div>
              )}
              {groupButtonMaybe && <div className={css.buttonWrapper}>{groupButtonMaybe}</div>}
              <div className={css.bookingHeadingContainer}>
                {showBookingTimeForm && <h2 className={titleClasses}>{title}</h2>}
                {subTitleText ? <div className={css.bookingHelp}>{subTitleText}</div> : null}
              </div>
            </>
          ) : (
            isOwnListing && (
              <div className={css.buttonWrapper}>
                {!isDraft && (
                  <Button
                    className={classNames(css.customButton, css.violetButton)}
                    onClick={() => handleSearchCoach(listing)}
                  >
                    <span className={classNames(css.icon, css.violetDark)}>
                      <IconSearch className={css.iconSearch} />
                    </span>
                    <FormattedMessage id="ListingPage.searchCoaches" />
                  </Button>
                )}
                <Button className={css.customButton} onClick={handleClickViewCoachList}>
                  <span className={css.icon}>
                    <IconShortList />
                  </span>
                  <FormattedMessage id="ListingPage.viewCoachList" />
                </Button>
              </div>
            )
          )}
        </div>
        {showBookingTimeForm && (
          <BookingTimeForm
            className={css.bookingForm}
            formId="BookingPanel"
            submitButtonWrapperClassName={css.submitButtonWrapper}
            unitType={unitType}
            onSubmit={onSubmit}
            price={price}
            listingId={listing.id}
            isOwnListing={isOwnListing}
            monthlyTimeSlots={monthlyTimeSlots}
            onFetchTimeSlots={onFetchTimeSlots}
            startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            timeZone={timeZone}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
          />
        )}
      </ModalInMobile>
      <div className={css.openBookingForm}>
        {listingType === COACH_LISTING ? (
          <>
            {isOwnListing && (
              <>
                <div className={css.priceContainer}>
                  <div className={css.priceValue} title={priceTitle}>
                    {displayRangeOfPrice}
                  </div>
                  <div className={css.perUnit}>
                    <FormattedMessage id={unitTranslationKey} />
                  </div>
                </div>
                {viewAgreementButtonMaybe}
              </>
            )}
            {groupButtonMaybe && <div className={css.buttonWrapper}>{groupButtonMaybe}</div>}
            {showBookingTimeForm ? (
              <Button
                rootClassName={css.bookButton}
                onClick={() => openBookModal(isOwnListing, isClosed, history, location)}
              >
                <FormattedMessage id="BookingPanel.ctaButtonMessage" />
              </Button>
            ) : isClosed ? (
              <div className={css.closedListingButton}>
                <FormattedMessage id="BookingPanel.closedListingButtonText" />
              </div>
            ) : null}
          </>
        ) : (
          isOwnListing && (
            <div className={css.buttonWrapper}>
              {!isDraft && (
                <Button
                  className={classNames(css.customButton, css.violetButton)}
                  onClick={() => handleSearchCoach(listing)}
                >
                  <span className={classNames(css.icon, css.violetDark)}>
                    <IconSearch className={css.iconSearch} />
                  </span>
                  <FormattedMessage id="ListingPage.searchCoaches" />
                </Button>
              )}
              <Button className={css.customButton} onClick={handleClickViewCoachList}>
                <span className={css.icon}>
                  <IconShortList />
                </span>
                <FormattedMessage id="ListingPage.viewCoachList" />
              </Button>
            </div>
          )
        )}
      </div>
      {agreementModal}
      {bookingPanelFormModal}
      {addCoachListSuccessModal}
    </div>
  );
};

BookingPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  subTitle: null,
  unitType: config.bookingUnitType,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
  currentUser: null,
  projectListings: [],
  addToCoachList: () => {},
  addToCoachListInProgress: false,
  addToCoachListError: null,
  onResetCoachListError: () => {},
};

BookingPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  unitType: propTypes.bookingUnitType,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  subTitle: oneOfType([node, string]),
  authorDisplayName: oneOfType([node, string]).isRequired,
  onManageDisableScrolling: func.isRequired,
  onFetchTimeSlots: func.isRequired,
  monthlyTimeSlots: object,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  currentUser: object,
  projectListings: array,
  addToCoachList: func,
  addToCoachListInProgress: bool,
  addToCoachListError: propTypes.error,
  onResetCoachListError: func,
};

export default compose(
  withRouter,
  injectIntl
)(BookingPanel);
