import React from 'react';
import { string, object } from 'prop-types';
import classNames from 'classnames';
import NamedLink from '../NamedLink/NamedLink';
import { formatArticlePrismicData } from '../../util/data';
import { formatPrismicDate } from '../../util/dates';

import css from './ArticleCard.module.css';


const ArticleCard = props => {
  const { rootClassName, className, article } = props;
  const classes = classNames(rootClassName || css.root, className);
  const { slug, postDate, title, image, alt } = formatArticlePrismicData(article);
  const formattedPostDate = formatPrismicDate(postDate);

  return (
    <div className={classes}>
      <NamedLink name="KnowledgeHubPage" params={{ slug }} className={css.square}>
        <img src={image} loading="lazy" alt={alt} />
      </NamedLink>
      <NamedLink name="KnowledgeHubPage" params={{ slug }} className={css.title}>
        {title}
      </NamedLink>
      <div className={css.postDate}>{formattedPostDate}</div>
    </div>
  );
};

ArticleCard.defaultProps = {
  rootClassName: null,
  className: null,
  article: null,
};

ArticleCard.propTypes = {
  rootClassName: string,
  className: string,
  article: object,
};

export default ArticleCard;
