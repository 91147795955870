import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { LinkTabNavHorizontal } from '../../components';
import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { getListingType, createSlug } from '../../util/urlHelpers';
import { BUYER_ROLE, COACH_ROLE } from '../../marketplace-custom-config';
import { connect } from 'react-redux';

import css from './UserNav.module.css';

const listingTab = (listing, selectedPageName) => {
  if (!listing) {
    return {
      text: <FormattedMessage id="UserNav.newListing" />,
      selected: selectedPageName === 'NewListingPage',
      linkProps: {
        name: 'NewListingPage',
      },
    }
  }

  const currentListing = ensureOwnListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', state } = currentListing.attributes;
  const slug = createSlug(title);
  const isDraft = state === LISTING_STATE_DRAFT;

  return {
    text: <FormattedMessage id="UserNav.editListing" />,
    selected: selectedPageName === 'EditListingPage',
    linkProps: {
      name: 'EditListingPage',
      params: {
        id,
        slug,
        type: getListingType(isDraft),
        tab: 'photos',
      },
    },
  };
};

const UserNav = props => {
  const { className, rootClassName, selectedPageName, listing, currentUser } = props;
  const classes = classNames(rootClassName || css.root, className);
  const role = currentUser && currentUser.attributes.profile.publicData.role;
  const isCoach = role === COACH_ROLE;
  const isBuyer = role === BUYER_ROLE;

  const createProfileListingTabMaybe = isCoach ? [{
    ...listingTab(listing, selectedPageName),
  }] :
  [];

  const tabs = [
    {
      text: isBuyer ? (
        <FormattedMessage id="UserNav.companyProfileSettingsPage" />
      ) : (
        <FormattedMessage id="UserNav.profileSettingsPage" />
      ),
      selected: selectedPageName === 'ProfileSettingsPage',
      disabled: false,
      linkProps: {
        name: 'ProfileSettingsPage',
      },
    },
    ...createProfileListingTabMaybe,
    {
      text: <FormattedMessage id="UserNav.contactDetailsPage" />,
      selected: ACCOUNT_SETTINGS_PAGES.includes(selectedPageName),
      disabled: false,
      linkProps: {
        name: 'ContactDetailsPage',
      },
    },
  ];

  return (
    <LinkTabNavHorizontal className={classes} tabRootClassName={css.tab} tabs={tabs} skin="dark" />
  );
};

UserNav.defaultProps = {
  className: null,
  rootClassName: null,
  currentUser: null
};

const { string, object } = PropTypes;

UserNav.propTypes = {
  className: string,
  rootClassName: string,
  selectedPageName: string.isRequired,
  currentUser: object
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  return { currentUser };
}

export default connect(mapStateToProps)(UserNav);
