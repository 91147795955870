import React from 'react';
import { array, bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { Process } from '../../components';

import css from './SectionHowItWorksHK.module.css';

const SectionHowItWorksHK = props => {
  const { rootClassName, className, processContentList, isShowTitleForCoachee,BelowText } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      
      <Process processContentList={processContentList} />
      <FormattedMessage id={BelowText} />
    </div>
  );
};

SectionHowItWorksHK.defaultProps = {
  rootClassName: null,
  className: null,
  processContentList: [],
  isShowTitleForCoachee: false,
  BelowText: null,
};

SectionHowItWorksHK.propTypes = {
  rootClassName: string,
  className: string,
  processContentList: array,
  isShowTitleForCoachee: bool,
  BelowText: string,
};

export default SectionHowItWorksHK;