import React from 'react';
import classNames from 'classnames';
import { node, string, oneOfType } from 'prop-types';

import {
  Button
} from '../../components';

import css from './SplitButton.module.css';
const SplitButton = (props) => {
  const {
    className,
    rootClassName,
    icon,
    buttonText,
    iconRootClassName,
    iconClassName,
    hoverPopUp,
    ...rest
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const iconClasses = classNames(iconRootClassName || css.icon, iconClassName);
  return (
    <Button
      className={classes}
      {...rest}
    >
      <span className={iconClasses}>
        {icon}
      </span>
      {buttonText}
      {hoverPopUp}
    </Button>
  );
};

SplitButton.defaultProps = {
  rootClassName: null,
  className: null,
  iconRootClassName: null,
  iconClassName: null,
  icon: null,
  buttonText: null,
  hoverPopUp: null
};

SplitButton.propTypes = {
  rootClassName: string,
  className: string,
  icon: node,
  buttonText: oneOfType([node, string]),
  iconRootClassName: string,
  iconClassName: string,
  hoverPopUp: node
};

export default SplitButton;
