import React from 'react';
import { LINE_ITEM_STAKEHOLDER_BRIEF_AND_DEBRIEF_HOURS } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';

import css from './BookingBreakdown.module.css';

const LineItemStakeholderBriefAndDebrief = props => {
  const { transaction } = props;

  const stakeholderBriefAndDebrief = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_STAKEHOLDER_BRIEF_AND_DEBRIEF_HOURS && !item.reversal
  );

  return stakeholderBriefAndDebrief ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="BookingBreakdown.stakeholderBriefAndDebriefHours" />
      </span>
      <span className={css.itemValue}>{stakeholderBriefAndDebrief.quantity.toString()}</span>
    </div>
  ) : null;
};

export default LineItemStakeholderBriefAndDebrief;
