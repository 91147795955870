import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  maxLength,
  required,
  composeValidators,
  requiredFieldArrayCheckbox,
  validYear,
  validURL,
  maxWords
} from '../../util/validators';
import { Form, Button, FieldTextInput, FieldCheckboxGroup } from '../../components';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';

import css from './EditListingDescriptionForm.module.css';

const PREVIOUS_CLIENTS_MAX_LENGTH = 250;
const DESCRIPTION_MAX_WORDS = 1000;

const EditListingDescriptionFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        filterConfig,
      } = formRenderProps;

      // description
      const descriptionMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.description',
      });
      const descriptionPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionPlaceholder',
      });
      const descriptionRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionRequired',
      });
      const descriptionMaxWordsMessage = intl.formatMessage(
        { id: 'EditListingDescriptionForm.maxWords' },
        {
          maxWords: DESCRIPTION_MAX_WORDS,
        }
      );
      const maxWordsMessage = maxWords(
        descriptionMaxWordsMessage,
        DESCRIPTION_MAX_WORDS
      );

      // introduction video
      const introductionVideoMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.introductionVideo',
      });
      const introductionVideoPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.introductionVideoPlaceholder',
      });
      const introductionVideoInvalidMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.introductionVideoInvalidUrl',
      });

      // coaching experience
      const coachingExperienceMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.coachingExperience',
      });
      const coachingExperiencePlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.coachingExperiencePlaceholder',
      });
      const coachingExperienceRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.coachingExperienceRequired',
      });
      const coachingExperienceInvalidYearMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.coachingExperienceInvalidYear',
      });

      // previous clients
      const previousClientsMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.previousClients',
      });
      const previousClientsPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.previousClientsPlaceholder',
      });
      const previousClientsMaxLengthMessage = intl.formatMessage(
        { id: 'EditListingDescriptionForm.maxLength' },
        {
          maxLength: PREVIOUS_CLIENTS_MAX_LENGTH,
        }
      );
      const maxLengthMessage = maxLength(
        previousClientsMaxLengthMessage,
        PREVIOUS_CLIENTS_MAX_LENGTH
      );

      // coaching format
      const coachingFormatMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.coachingFormat',
      });
      const coachingFormatRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.coachingFormatRequired',
      });

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const coachingFormatOptions = findOptionsForSelectFilter('coachingFormat', filterConfig);

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}

          <FieldTextInput
            id="description"
            name="description"
            className={css.description}
            type="textarea"
            label={descriptionMessage}
            placeholder={descriptionPlaceholderMessage}
            autoFocus
            validate={composeValidators(
              required(descriptionRequiredMessage),
              maxWordsMessage
            )}
          />

          <FieldTextInput
            id="introductionVideo"
            name="introductionVideo"
            className={css.description}
            type="text"
            label={introductionVideoMessage}
            placeholder={introductionVideoPlaceholderMessage}
            validate={validURL(introductionVideoInvalidMessage)}
          />

          <FieldTextInput
            id="coachingExperience"
            name="coachingExperience"
            className={css.description}
            type="text"
            label={coachingExperienceMessage}
            placeholder={coachingExperiencePlaceholderMessage}
            validate={composeValidators(
              required(coachingExperienceRequiredMessage),
              validYear(coachingExperienceInvalidYearMessage)
            )}
          />

          <FieldTextInput
            id="previousClients"
            name="previousClients"
            className={css.description}
            type="text"
            label={previousClientsMessage}
            placeholder={previousClientsPlaceholderMessage}
            validate={maxLengthMessage}
          />

          <FieldCheckboxGroup
            id="coachingFormat"
            name="coachingFormat"
            className={css.coachingFormat}
            label={coachingFormatMessage}
            validate={requiredFieldArrayCheckbox(coachingFormatRequiredMessage)}
            options={coachingFormatOptions}
          />

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingDescriptionFormComponent.defaultProps = {
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

export default compose(injectIntl)(EditListingDescriptionFormComponent);
