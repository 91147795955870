import classNames from 'classnames';
import { bool, func, object, string } from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import {
  Avatar,
  FieldTextInput,
  Form,
  PrimaryButton,
  RangeSliderRating,
  SecondaryButton,
} from '../../components';
import FormattedMessageWithClass from '../../components/FormattedMessageWithClass/FormattedMessageWithClass';
import { intlShape } from '../../util/reactIntl';
import { createSlug } from '../../util/urlHelpers';
import { isGreaterThan } from '../../util/validators';

import css from './ReviewChemistrySessionForm.module.css';

const MINIMUM_RATE = 0;
const MAXIMUM_RATE = 5;
const STEP = 1;

const CircleCheckbox = ({ className, activeClassName, isActive, onClick }) => {
  return (
    <div
      className={classNames(css.circle, className, { [activeClassName]: isActive })}
      onClick={onClick}
    >
      <div className={css.center} />
    </div>
  );
};

const ReviewChemistrySessionForm = ({ listing, ...rest }) => {
  return (
    <FinalForm
      {...rest}
      listing={listing}
      render={fieldRenderProps => {
        const {
          isEditable,
          setIsEditable,
          handleSubmit,
          listing: {
            id: { uuid: coachListingId },
            attributes: { title },
            author: {
              attributes: {
                profile: { displayName },
              },
            },
          },
          form,
          initialValues: { chemistry, skillsAndExperience, feedback, buyerFeedback },
          intl,
          isSelectedCoach,
          handleSelectedCoach,
          inProgress,
          confirmed,
          isBuyer,
          isCoachee,
          values,
          pristine,
          invalid,
          coacheeName,
        } = fieldRenderProps;
        const chemistryRef = useRef(null);
        const skillsAndExperienceRef = useRef(null);

        const isUserAbleToUpdate = isEditable === coachListingId;

        const currentCoachIsSelected = isSelectedCoach === coachListingId;

        const feedbackName = isBuyer ? 'buyerFeedback' : 'feedback';

        const isDisabled =
          inProgress ||
          pristine ||
          (isBuyer && !form.getFieldState(feedbackName)?.value) ||
          invalid;

        const avatarLink = {
          name: 'ListingPage',
          params: { id: coachListingId, slug: createSlug(title) },
        };

        const chemistryLabel = intl.formatMessage({
          id: 'ReviewChemistrySessionForm.chemistry',
        });
        const feedbackLabel = intl.formatMessage({
          id: isBuyer
            ? 'ReviewChemistrySessionForm.buyerFeedback'
            : 'ReviewChemistrySessionForm.feedback',
        });
        const feedbackNote = intl.formatMessage({ id: 'ReviewChemistrySessionForm.feedbackNote' });

        const greaterThanZeroMessage = intl.formatMessage({
          id: 'ReviewChemistrySessionForm.greaterThanMinimum',
        });
        const greaterThanZero = isGreaterThan(greaterThanZeroMessage, MINIMUM_RATE);

        const isNotReviewedByCoachee = !skillsAndExperience && !chemistry;
        const isDisabledRating = !isUserAbleToUpdate || isBuyer;
        const isShowCoacheeFeedbackForBuyer =
          !isNotReviewedByCoachee && isBuyer && feedback && feedback.trim().length !== 0;

        const calcTrackPosition = value =>
          ((value - MINIMUM_RATE) * 100) / (MAXIMUM_RATE - MINIMUM_RATE) + '% 100%';

        const setColorForSlider = (chemistryVal, skillsAndExperienceVal) => {
          const { style: skillsAndExperienceStyle } = skillsAndExperienceRef.current;
          const { style: chemistryStyle } = chemistryRef.current;

          skillsAndExperienceStyle.backgroundSize = calcTrackPosition(skillsAndExperienceVal);
          chemistryStyle.backgroundSize = calcTrackPosition(chemistryVal);
        };

        useEffect(() => {
          if (!chemistryRef.current || !skillsAndExperienceRef.current) return;

          const { value: skillsAndExperienceVal } = skillsAndExperienceRef.current;
          const { value: chemistryVal } = chemistryRef.current;

          setColorForSlider(chemistryVal, skillsAndExperienceVal);
        }, []);

        const handleRatingChange = event => {
          if (!chemistryRef.current || !skillsAndExperienceRef.current) return;

          const { chemistry, skillsAndExperience } = event.values;
          setColorForSlider(chemistry, skillsAndExperience);
        };

        const renderRateLabels = fieldName =>
          new Array(Math.floor((MAXIMUM_RATE - MINIMUM_RATE) / STEP) + 1).fill().map((_, index) => {
            const value = STEP * index;
            const rateValue = values?.[fieldName];
            const isActiveClassName =
              +rateValue === value ? (isBuyer ? css.buyerActiveLabel : css.activeLabel) : '';

            return (
              <span key={`${fieldName}-${index}`} className={isActiveClassName}>
                {value}
              </span>
            );
          });

        const handleClickAddNotes = () => {
          setIsEditable(coachListingId);
        };

        const handleClickCancel = () => {
          setIsEditable(null);
          form.reset();
          if (!(isNotReviewedByCoachee && isBuyer)) {
            form.resetFieldState('chemistry');
            form.resetFieldState('skillsAndExperience');
          }
        };

        return (
          <Form onSubmit={handleSubmit}>
            <div
              className={classNames(css.reviewChemistrySessionForm, {
                [css.active]: currentCoachIsSelected,
              })}
            >
              <CircleCheckbox
                className={css.circleOnTabletAndDesktop}
                activeClassName={css.active}
                isActive={currentCoachIsSelected}
                onClick={() => handleSelectedCoach(coachListingId)}
              />
              <Avatar
                avatarLinkProps={avatarLink}
                user={listing.author}
                className={css.rootForImage}
                initialsClassName={css.avatarText}
              />
              <div className={css.info}>
                <div className={css.heading}>
                  <div className={css.titleWrapper}>
                    <h3 className={css.title}>{displayName}</h3>
                  </div>
                  <CircleCheckbox
                    className={css.circleOnMobile}
                    activeClassName={css.active}
                    isActive={currentCoachIsSelected}
                    onClick={() => handleSelectedCoach(coachListingId)}
                  />
                  {confirmed && (
                    <FormattedMessageWithClass
                      id="ReviewChemistrySessionForm.selected"
                      className={css.confirmLabel}
                    />
                  )}
                </div>

                {isNotReviewedByCoachee && isBuyer ? (
                  <FormattedMessageWithClass
                    id="ReviewChemistrySessionForm.waitingForCoacheeReview"
                    className={css.waitingCoacheeReview}
                  />
                ) : (
                  <div className={css.ratingWrapper}>
                    <RangeSliderRating
                      className={classNames(css.slider, {
                        [css.buyerSlider]: isBuyer,
                      })}
                      id="chemistry"
                      name="chemistry"
                      min={MINIMUM_RATE}
                      max={MAXIMUM_RATE}
                      defaultValue={MINIMUM_RATE}
                      step={STEP}
                      label={chemistryLabel}
                      disabled={isDisabledRating}
                      sliderRef={chemistryRef}
                      validate={greaterThanZero}
                      errorClassName={css.error}
                    >
                      <div className={css.rates}>{renderRateLabels('chemistry')}</div>
                    </RangeSliderRating>

                    <RangeSliderRating
                      className={classNames(css.slider, {
                        [css.buyerSlider]: isBuyer,
                      })}
                      id="skillsAndExperience"
                      name="skillsAndExperience"
                      min={MINIMUM_RATE}
                      max={MAXIMUM_RATE}
                      defaultValue={MINIMUM_RATE}
                      step={STEP}
                      label={intl.formatMessage({
                        id: 'ReviewChemistrySessionForm.skillsAndExperience',
                      })}
                      disabled={isDisabledRating}
                      sliderRef={skillsAndExperienceRef}
                      validate={greaterThanZero}
                      errorClassName={css.error}
                    >
                      <div className={css.rates}>{renderRateLabels('skillsAndExperience')}</div>
                    </RangeSliderRating>
                  </div>
                )}
                {isShowCoacheeFeedbackForBuyer && (
                  <div className={css.coacheeFeedback}>
                    <div className={css.label}>
                      {intl.formatMessage(
                        { id: 'ReviewChemistrySessionForm.coacheeFeedback' },
                        { coacheeName }
                      )}
                    </div>
                    <div className={css.feedbackDisplay}>{feedback}</div>
                  </div>
                )}
                {isUserAbleToUpdate ? (
                  <FieldTextInput
                    id={feedbackName}
                    name={feedbackName}
                    type="textarea"
                    label={feedbackLabel}
                    inputClassName={css.inputClassName}
                    note={feedbackNote}
                    noteClassName={css.note}
                  />
                ) : (
                  <>
                    <div className={css.feedbackLabelGroup}>
                      <div className={css.label}>{feedbackLabel}</div>
                      <div className={css.note}>{feedbackNote}</div>
                    </div>
                    <div className={css.feedbackDisplay}>{isBuyer ? buyerFeedback : feedback}</div>
                  </>
                )}
              </div>
              {isUserAbleToUpdate && (
                <div className={css.buttonWrapper}>
                  <SecondaryButton className={css.cancelButton} onClick={handleClickCancel}>
                    <FormattedMessage id="ReviewChemistrySessionForm.cancel" />
                  </SecondaryButton>
                  <PrimaryButton
                    type="submit"
                    className={css.saveButton}
                    inProgress={inProgress}
                    disabled={isDisabled}
                    inProgressClassName={css.submitting}
                  >
                    <FormattedMessage id="ReviewChemistrySessionForm.save" />
                  </PrimaryButton>
                </div>
              )}

              {!isUserAbleToUpdate && (
                <PrimaryButton className={css.addNotesButton} onClick={handleClickAddNotes}>
                  {isBuyer ? (
                    <FormattedMessage id="ReviewChemistrySessionForm.addNotes" />
                  ) : (
                    <FormattedMessage id="ReviewChemistrySessionForm.addFeedback" />
                  )}
                </PrimaryButton>
              )}
            </div>
            <FormSpy onChange={handleRatingChange} />
          </Form>
        );
      }}
    />
  );
};

ReviewChemistrySessionForm.propTypes = {
  listing: object,
  onSubmit: func.isRequired,
  isEditable: string,
  setIsEditable: func.isRequired,
  handleSelectedCoach: func.isRequired,
  isSelectedCoach: string,
  initialValues: object,
  intl: intlShape.isRequired,
  confirmed: bool,
  isCoachee: bool,
  isBuyer: bool,
  inProgress: bool,
  isBuyerView: bool,
  coacheeName: string,
};

ReviewChemistrySessionForm.defaultProps = {
  listing: null,
  isEditable: null,
  isSelectedCoach: null,
  initialValues: null,
  confirmed: false,
  isCoachee: false,
  isBuyer: false,
  inProgress: false,
  isBuyerView: false,
  coacheeName: null,
};

export default ReviewChemistrySessionForm;
