import React from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { array, func, string } from 'prop-types';
import IMGHERO from '../../assets/CTA Laptop.png';
import BOLDLYLOGO from '../../assets/boldly_logo_tagline_white.png'
import {
  Button,
  NamedLink
} from '../../components';
import css from './SectionButton.module.css';
import { FormattedMessage } from 'react-intl';

const SectionButton = (props) => {
  const { rootClassName, className, buttonList, handleClickButtons } = props;

  const classes = classNames(rootClassName || css.root, className);

  const history = useHistory();


  const handleClick = (e, type) => {
    if (handleClickButtons) {
      e.preventDefault();
      handleClickButtons(type);
    }
  };

  return (
    <>
      <div className={css.rootN}>
        <div className={css.heroContentN}>
          <>
            <div className={css.containera}>
              <div className={css.content}>
                <div className={css.heading_text}>

                  <img src={BOLDLYLOGO} className={css.IconBoldly} />

                  <h1><FormattedMessage id="SectionButton.Title" /></h1>
                  <h1><FormattedMessage id="SectionButton.Title1" /></h1>
                  <p ><FormattedMessage id="SectionButton.SubTitle" /></p>
                  <p> <FormattedMessage id="SectionButton.SubTitle2" /></p>
                  <p><FormattedMessage id="SectionButton.SubTitle3" /></p>
                  <div className={css.GetCoachButton}>
                    <button onClick={(e)=>history.push('/s')} className={css.GetButton}><FormattedMessage id="TransactionPanel.getACoach" /></button>
                  </div>
                </div>

              </div>
              <div className={css.image}>
                <img src={IMGHERO} alt="Description of the image" className={css.responsiveImage} />
              </div>
            </div>

          </>

        </div>

      </div>
    </>



  );
};

SectionButton.defaultProps = {
  rootClassName: null,
  className: null,
  buttonList: [],
  handleClickButtons: null,
};

SectionButton.propTypes = {
  rootClassName: string,
  className: string,
  buttonList: array,
  handleClickButtons: func,
};

export default SectionButton;
