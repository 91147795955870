import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconMessage.module.css';

const IconMessage = props => {
  const { className } = props;

  return (
    <svg
      className={classNames(css.root, className)}
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0002 9.83333H12.3335C13.0699 9.83333 13.6668 9.23638 13.6668 8.5V1.83333C13.6668 1.09695 13.0699 0.5 12.3335 0.5H1.66683C0.93045 0.5 0.333496 1.09695 0.333496 1.83333V8.5C0.333496 9.23638 0.93045 9.83333 1.66683 9.83333H7.00016V11.1667C7.00016 12.448 8.63135 12.9917 9.40016 11.9667L11.0002 9.83333ZM8.3335 8.5V11.1667L10.3335 8.5H12.3335V1.83333H1.66683V8.5H8.3335Z"
        fill="#7C9BFF"
      />
    </svg>
  );
};

IconMessage.defaultProps = { className: null, rootClassName: null };

const { string } = PropTypes;

IconMessage.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconMessage;
