import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.module.css';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: 4th November, 2022</p>

      <p>
        Lanterne Rouge Online Ltd.; Lanterne Rouge Online Pte. Ltd.; and Lanterne Rouge Holdings
        Pte. Ltd.; trading under the registered trademark BOLDLY (“we”, “us”, or “our”), are
        committed to protecting and respecting your privacy and personal data. This privacy policy
        (together with any other documents referred to herein) sets out the basis on which any
        personal data we collect from you, or that you provide to us, will be processed by us and
        the choices you have regarding this information. Please read the following carefully to
        understand our practices regarding your personal data and how we will treat it. This privacy
        policy applies to the information we collect when you access or use our products, services
        and/or technology platforms (including without limitation, our websites, mobile
        applications, and other BOLDLY controlled property (collectively the “Sites”)) or other
        services that link to this privacy policy (collectively the “Services”). It also applies
        when you apply to work with us, contact us or otherwise interact with us through our Sites.
      </p>

      <p>
        If you are under 18 years of age, please do not send any personal data about yourself to us.
      </p>

      <h2>1. Information collection</h2>
      <p>
        We collect personal information from individuals we interact with in the course of providing
        our Sites and Services, dealing with suppliers, employees and potential employees and as is
        necessary for the operation of our business and provision of our Services.
      </p>
      <p className={css.listTitle}>
        In order to provide our Services the main types of information we collect are:
      </p>
      <ul>
        <li>
          Information that you provide when registering to use our Sites or subscribing to our
          Services, alerts, newsletters, or to attend our events, or when applying to work with us.
          This may include data such as name, postal address, telephone number, email address, other
          forms of electronic communication, details from your LinkedIn profile or background
          information.
        </li>
        <li>Login credentials for any of our Sites.</li>
        <li>
          Information you provide in connection with the delivery of our Services including
          responses to questionnaires, activities, participation in any interactive features of our
          Sites or Services including video or audio calls and other information generated in
          connection with coaching sessions.
        </li>
        <li>
          If you contact us, for instance to report a problem with our Site or Services, we may keep
          a record of that correspondence.
        </li>
        <li>
          Information you provide in response to any promotions or research surveys that we send
          (subject to your agreement to be contacted for such purposes).
        </li>
      </ul>
      <p className={css.listTitle}>
        We also automatically collect information when you use our Services or Sites, including:
      </p>
      <ul>
        <li>
          Data related to your use of and visit to the Sites such as time of login, how you
          navigated to our Sites, browsing information, what you viewed, clicked and searched for.
        </li>
        <li>
          Data related to the type of device you used to access our Services including web browser
          used and browsing information collected through cookies (see more on this further below),
          web beacons, pixels and other similar tracking technologies, IP address, device
          identifiers, operating system and mobile network information.
        </li>
      </ul>
      <p>
        The information we collect automatically will be associated with the personal data you
        provide.
      </p>
      <p>
        Our Sites are not designed to respond to “do not track” signals from browsers and we do not
        track or collect information about your online activities over time and across third-party
        web sites.
      </p>

      <h2>2. Data Retention</h2>
      <p>
        We only retain personal data for so long as it is necessary for the purpose for which it was
        collected, to meet legal or regulatory obligations or for other legitimate business
        purposes. Data may be archived as long as the purpose for which the data was used still
        exists.
      </p>

      <h2>3. Use of Information</h2>
      <p className={css.listTitle}>
        We only use and process personal data for legitimate business purposes. The purposes for
        which information may be used include:
      </p>
      <ul>
        <li>To conduct our business, provide and improve our Sites and Services.</li>
        <li>
          To fix errors, monitor usage and improve performance and reliability of our Sites and
          Services. We may use third party service providers to assist us with this and their
          services are not intended to identify one user specifically.
        </li>
        <li>
          To personalise and enhance the content we think is relevant for you and provide alerts,
          newsletters, surveys, research or other information that you have requested.
        </li>
        <li>
          To communicate and stay in touch with our clients, suppliers and business contacts (such
          as to notify of technical issues, notify of offers, promotions, events, respond to queries
          and provide other relevant information and insights we think are relevant for you).
        </li>
        <li>
          For Employees and Coaches to assess qualifications, verify information and references,
          provide appropriate compensation and contact potential Employees on opportunities of
          interest.
        </li>
        <li>
          To fulfill our and our affiliates’ legal obligations and contractual obligations such as
          those entered into with Clients and Citizens.
        </li>
        <li>
          Any other purpose for which the information is collected or which is incidental to the
          above.
        </li>
      </ul>
      <p>
        Our Sites use Google Analytics, a web traffic analysis service provided by Google Inc.
        ("Google"). Please refer to{' '}
        <a href="http://www.google.com/policies/privacy/partners" target="_blank">
          http://www.google.com/policies/privacy/partners
        </a>{' '}
        to find out more about how Google uses data when you use our Sites and how to control the
        information sent to Google. You can prevent Google's collection and processing of data
        through adjustment to the Google Ads Settings page or downloading and installing their
        browser plug-in{' ('}
        <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">
          https://tools.google.com/dlpage/gaoptout
        </a>
        {')'}.
      </p>

      <h2>4. Marketing Practices</h2>
      <p>
        We use personal data to send promotional communications. Where required by law, we will
        obtain consent prior to sending such communications. If you no longer wish to receive these
        communications you can unsubscribe by following the link or contacting us as set out below
        in this Privacy Policy. Please note we will still send communications about your account or
        any ongoing Services.
      </p>

      <h2>5. Disclosure of Your Information and International Transfers</h2>
      <p className={css.listTitle}>
        In order to deliver our Services and Sites we may disclose personal data to:
      </p>
      <ul>
        <li>
          Our Employees, Agents, Coaches, Consultants, Advisers, Auditors, Contractors and service
          providers in connection with our operations or Services (wherever they may be located
          throughout the world).
        </li>
        <li>
          Our overseas offices, affiliates, business partners and counterparts (wherever they may be
          located throughout the world).
        </li>
        <li>
          Financial institutions, professional advisers, purchasers or business partners in
          connection with any financing, sale, reorganization, merger or acquisition of our business
          (wherever they may be located throughout the world).
        </li>
      </ul>
      <p>
        Where the Services are purchased for you by an Employer or sponsoring entity we may share
        information relating to the use of the Sites or Services such as to confirm your
        participation in a program, number of sessions attended, types of activities or programs
        selected. We will not share details of the content discussed or produced during your use of
        the Services without your prior consent to do so except as outlined in this Privacy Policy.
      </p>
      <p>
        We and our third party service providers may hold your data on servers located throughout
        the world. By using the Sites or Services you voluntarily consent to trans-border transfers
        of and hosting of such information and acknowledge that the location of recipients of your
        personal data and/or third party service providers may include countries where local laws
        grant fewer rights than you have in your own country.
      </p>
      <p>
        We use reasonable endeavours to protect the confidentiality of personal data shared with any
        of the abovementioned recipients to ensure it is treated in a manner consistent with this
        Privacy Policy. We have also implemented a generally accepted standard of internal security
        measures and processes to protect the confidentiality, availability, misuse, loss,
        alteration and integrity of your personal data. Despite these precautions we cannot
        guarantee that unauthorized persons will not obtain access to your personal data.
        Accordingly,
      </p>
      <p className={css.listTitle}>We may also disclose personal data:</p>
      <ul>
        <li>To persons under a duty of confidentiality to us.</li>
        <li>
          Where we believe it is necessary to do so in order to protect the rights, property or
          safety of you or others or in connection with an investigation of suspected or actual
          illegal activity.
        </li>
        <li>Where required under applicable laws and regulations or legal processes.</li>
        <li>To enforce our terms and conditions of use of our Services or Sites.</li>
        <li>Where we believe it is necessary for us to do so to protect your interests.</li>
      </ul>
      <p>
        We do not sell or otherwise disclose the personal information we collect, except as
        described in this Privacy Policy.
      </p>

      <h2>6. Cookies</h2>
      <p>
        Our website uses cookies to distinguish you from other users of our website. Cookies are a
        small file of letters and numbers that are stored on your browser or hard drive to uniquely
        identify the browser or to store information or settings on the user’s device. This helps us
        provide you with a good experience when you browse our Sites and also allows us to improve
        our Sites. By continuing to browse our Sites, you are agreeing to our use of cookies. It is
        possible to disable or restrict cookies or adjust their settings through your browser
        however please note this may impact your ability to use some of the features of our Sites.
      </p>
      <p>
        We use strictly necessary cookies. These are cookies that are required for the operation of
        our website. They include, for example, cookies that enable you to log into secure areas of
        our website, including the use of e-billing services.
      </p>
      <p>
        Please note that third parties (for example, advertising networks and providers of external
        services like web traffic analysis services) may also use cookies, over which we have no
        control. These cookies are likely to be analytical/performance cookies or targeting cookies.
      </p>

      <h2>7. Third Party Websites and Social Networking</h2>
      <p>
        Our website may contain links to other sites and you may be able to sign into your account
        with us through your social network accounts such as, but not limited to Twitter, LinkedIn
        and Facebook ("Linked Sites"). We are not responsible for the privacy policies or practices
        of such other Linked Sites. Please note that this Privacy Policy does not apply to the use
        of third-party sites, services or applications that you may access through our Sites. We
        encourage our users to be aware when leaving our website to read the privacy policies or
        statements of each Linked Site that collects your personal data. You are encouraged to
        review the privacy policies of such third party vendors to understand their privacy
        practices and treatment of your personal data.
      </p>
      <p>
        By associating your social network account with your account with us, you give us permission
        to access information that you have made available in your public profile on these social
        network accounts. The information you make available through this will depend on the
        settings and selections you have made in your respective social network accounts. We use the
        information we receive through your social network accounts pursuant to the social network’s
        terms of use and this Privacy Policy. If you would like to disconnect a social media account
        from us, please refer to the settings of that social network account.
      </p>

      <h2>8. Children</h2>
      <p>
        The Sites and Services are not designed for or directed at persons under 18 years of age. We
        request that persons under 18 years of age do not provide us with their personal data and we
        do not intentionally collect or maintain personal data for persons under 18 years of age.
      </p>

      <h2>9. Consent and Changes to our Privacy Policy</h2>
      <p>
        By using our Sites or Services, you consent to the collection, use and storage of your
        personal data as set forth in this Privacy Policy. We may make changes to this Privacy
        Policy from time to time. Any changes we may make to our Privacy Policy in the future will
        be posted on this page and the revised date of the Privacy Policy will indicate the date on
        which notice of the changes has been given. We may also choose to provide additional notice
        through email or a statement on our website. Your continued use of our Services or Sites
        constitutes your agreement to this Privacy Policy and any updates.
      </p>

      <h2>10. Your Rights</h2>
      <p>
        To the extent provided by the laws of your jurisdiction, you may request we check, correct,
        amend, delete, restrict or transfer your data, provide more information on our privacy
        practices and the type of data held or object to certain uses of your data such as the use
        of personal data for marketing or research and statistical purposes. You may also withdraw
        consent that you have previously provided relating to our handling of your personal
        information. Unless there is a legal or legitimate reason not to, we will apply your
        preferences going forward.
      </p>
      <p>
        You may exercise any of your rights by notifying us. Some of these requests can be made
        through your account. Alternately, please send such requests, questions or complaints to:
      </p>
      <p>Name: Lanterne Rouge Holdings Pte Ltd</p>
      <p>Address: 33 UBI AVENUE 3 #08-61 VERTEX SINGAPORE 408868</p>
      <p>
        Tel: <a href="tel:+65 6834 3323">+65 6834 3323</a>
      </p>
      <p>
        Email: <a href="mailto:Alex@boldly.app">Alex@boldly.app</a>
      </p>
      <p>
        In accordance with the laws of certain jurisdictions, we have the right to and may charge a
        minimum fee for processing any data access request.
      </p>
      <p>
        If you are a European Resident and have a concern with our personal data practices that we
        are not able to resolve, you have the right to lodge a complaint with the data privacy
        authority where you reside. For contact details of your local Data Protection Authority,
        please see:{' '}
        <a
          href="http://ec.europa.eu/justice/data-protection/article-29/structure/data-protection-authorities/index_en.htm"
          target="_blank"
        >
          http://ec.europa.eu/justice/data-protection/article-29/structure/data-protection-authorities/index_en.htm
        </a>
        .
      </p>
    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
