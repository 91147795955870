import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form, PrimaryButton, FieldTextInput, FieldCurrencyInput, FieldRadioButton } from '..';
import config from '../../config';
import { required } from '../../util/validators';
import { propTypes } from '../../util/types';
import { INTERESTED_ENGAGEMENT } from '../../marketplace-custom-config';

import css from './TransactionPanel.module.css';

const EngagementDetailsFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        handleSubmit,
        inProgress,
        intl,
        invalid,
        pristine,
        transitTransitionInProgress,
        transitTransitionError,
        values,
        sendEmailToNotAttendedChemistry,
      } = fieldRenderProps;

      const { interestedOrNot } = values;

      // interested or not
      const interestedOptionLabel = intl.formatMessage({
        id: 'EngagementDetailsForm.interestedOption',
      });
      const notInterestedOptionLabel = intl.formatMessage({
        id: 'EngagementDetailsForm.notInterestedOption',
      });
      const interestedOrNotRequired = required(
        intl.formatMessage({
          id: 'EngagementDetailsForm.interestedOrNotRequired',
        })
      );

      // rate
      const rateMessage = intl.formatMessage({
        id: 'EngagementDetailsForm.price',
      });
      const rateRequired = required(
        intl.formatMessage({
          id: 'EngagementDetailsForm.rateRequired',
        })
      );

      // comments
      const commentsMessage = intl.formatMessage({
        id: 'EngagementDetailsForm.comments',
      });

      const submitEngagementDetailsFailed = transitTransitionError ? (
        <p className={css.engagementError}>
          <FormattedMessage id="EngagementDetailsForm.submitFailed" />
        </p>
      ) : null;

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress || transitTransitionInProgress;
      const submitDisabled = invalid || submitInProgress || sendEmailToNotAttendedChemistry;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div>
            <div className={css.engagementFormRow}>
              <div className={css.engagementRadio}>
                <FieldRadioButton
                  id="interestedEngagement"
                  name="interestedOrNot"
                  label={interestedOptionLabel}
                  value="interested"
                  checkedClassName={css.checkedAvailable}
                  showAsRequired={pristine}
                  validate={interestedOrNotRequired}
                />
                <FieldRadioButton
                  id="notInterestedEngagement"
                  name="interestedOrNot"
                  label={notInterestedOptionLabel}
                  value="notInterested"
                  checkedClassName={css.checkedNotAvailable}
                  showAsRequired={pristine}
                  validate={interestedOrNotRequired}
                />
              </div>
              <FieldCurrencyInput
                id="rate"
                key={interestedOrNot}
                name="rate"
                className={css.priceInput}
                label={rateMessage}
                currencyConfig={config.currencyConfig}
                validate={interestedOrNot === INTERESTED_ENGAGEMENT ? rateRequired : null}
              />
            </div>

            <FieldTextInput
              id="comments"
              name="comments"
              className={css.comment}
              type="textarea"
              label={commentsMessage}
            />
          </div>

          {submitEngagementDetailsFailed}

          <div className={css.saveEngagementWrapper}>
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="EngagementDetailsForm.submitBtn" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

EngagementDetailsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  form: null,
  inProgress: false,
  transitTransitionInProgress: false,
  transitTransitionError: null,
  sendEmailToNotAttendedChemistry: false,
};

const { string, bool } = PropTypes;

EngagementDetailsFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  form: string,
  inProgress: bool,
  intl: intlShape.isRequired,
  transitTransitionInProgress: bool,
  transitTransitionError: propTypes.error,
  sendEmailToNotAttendedChemistry: bool,
};

const EngagementDetailsForm = compose(injectIntl)(EngagementDetailsFormComponent);
EngagementDetailsForm.displayName = 'EngagementDetailsForm';

export default EngagementDetailsForm;
