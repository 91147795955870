import React, { Component } from 'react';
import { arrayOf, func, number, shape, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import {
  Form,
  LocationAutocompleteInputField,
} from '../../components';
import { PLACE_TYPE_COUNTRY, PLACE_TYPE_PLACE } from '../../util/types';
import { FilterPopup } from '../../components';
import css from './LocationFilter.module.css';
import * as validators from '../../util/validators';
import { Form as FinalForm, FormSpy } from 'react-final-form';

const getKeywordQueryParam = queryParamNames => {
  return Array.isArray(queryParamNames)
    ? queryParamNames[0]
    : typeof queryParamNames === 'string'
    ? queryParamNames
    : 'keywords';
};
const identity = v => v;

class LocationFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true
    };
    this.filter = null;
    this.filterContent = null;
    this.shortKeywordTimeout = null;
    this.mobileInputRef = React.createRef();
    this.toggleIsOpen = this.toggleIsOpen.bind(this);
    this.handleOnSelectLocation = this.handleOnSelectLocation.bind(this);
    this.handleClear = this.handleClear.bind(this);
  }

  componentWillUnmount() {
    window.clearTimeout(this.shortKeywordTimeout);
  }

  handleOnSelectLocation = form => {
    const { name } = this.props;
    if (form.dirty && form.values[name] && form.values[name].selectedPlace) {
      const { address, bounds, origin } = form.values[name].selectedPlace;
      this.props.onSubmit({ address, bounds, origin });
    } else if (form.dirty && form.values[name] && form.values[name].search === '') {
      this.props.onSubmit(null);
      this.props.onSubmit({ address: null, bounds: null, origin: null })
    }
  };

  toggleIsOpen = () => this.setState((prevState, props) => {
    return { isOpen: !prevState.isOpen };
  });

  handleClear = form => {
    form.reset();
    this.props.onSubmit({ address: null, bounds: null, origin: null });
  } 

  render() {
    const {
      rootClassName,
      className,
      id,
      name,
      label,
      initialValues,
      contentPlacementOffset,
      onSubmit,
      queryParamNames,
      intl,
      showAsPopup,
      ...rest
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);

    const urlParam = getKeywordQueryParam(queryParamNames);
    const hasInitialValues =
      !!initialValues && !!initialValues[urlParam] && initialValues[urlParam].length > 0;
    const labelForPopup = hasInitialValues
      ? initialValues[urlParam].split(',')[0]
      : label;

    const labelClass = hasInitialValues ? css.filterLabelSelected : css.filterLabel;

    const filterText = intl.formatMessage({ id: 'LocationFilter.filterText' });
    const filterTextMobile = intl.formatMessage({ id: 'LocationFilter.filterTextMobile' });

    const placeholder = intl.formatMessage({ id: 'LocationSearchFormFilter.placeholder' });

    const addressRequiredMessage = intl.formatMessage({
      id: 'LocationFilter.addressRequired',
    });
    const addressNotRecognizedMessage = intl.formatMessage({
      id: 'LocationFilter.addressNotRecognized',
    });

    // pass the initial values with the name key so that
    // they can be passed to the correct field initialValues[urlParam]
    const namedInitialValues = {
      [name]: {
        search: initialValues.address || undefined,
        selectedPlace: {
          address: initialValues.address || undefined,
          bounds: initialValues.bounds || undefined,
          origin: initialValues.origin || undefined,
        }
      }
    };

    const handleSubmit = values => {
      const { address, bounds, origin } = values && values[name] && values[name].selectedPlace || {};
      onSubmit({ address, bounds, origin });
    };

    return showAsPopup ? (
      <FilterPopup
        className={classes}
        rootClassName={rootClassName}
        popupClassName={css.popupSize}
        name={name}
        label={labelForPopup}
        isSelected={hasInitialValues}
        id={`${id}.popup`}
        showAsPopup
        labelMaxWidth={250}
        contentPlacementOffset={contentPlacementOffset}
        onSubmit={handleSubmit}
        initialValues={namedInitialValues}
        keepDirtyOnReinitialize
        {...rest}
      >
        <LocationAutocompleteInputField
          name={name}
          labelClassName={css.locationLabel}
          inputClassName={css.locationInput}
          iconClassName={css.iconGlass}
          label={filterText}
          placeholder={placeholder}
          useDefaultPredictions={false}
          formatOnBlur={v => {
            return v && v.selectedPlace
          }}
          validate={validators.composeValidators(
            validators.autocompleteSearchRequired(addressRequiredMessage),
            validators.autocompletePlaceSelected(addressNotRecognizedMessage)
          )}
          placeTypes={[
            PLACE_TYPE_COUNTRY,
            PLACE_TYPE_PLACE
          ]}
        />
      </FilterPopup>
    ) : (
      <div className={css.locationFilterPlain}>
        <FinalForm
          initialValues={namedInitialValues}
          onSubmit={() => {}}
          render={formRenderProps => {
            const { id, handleSubmit, form, values } = formRenderProps;

            return (
              <Form
                id={id}
                onSubmit={handleSubmit}
              >
                <div className={
                  classNames(css.buttonWrapper, { 
                  [css.shouldAddPadding]: !this.state.isOpen 
                })}>
                  <button type="button" className={css.labelButton} onClick={this.toggleIsOpen}>
                    <span className={labelClass}>{label}</span>
                  </button>
                  <button type="button" className={css.clearButton} onClick={() => this.handleClear(form)}>
                    <FormattedMessage id={'FilterPlain.clear'} />
                  </button>
                </div>
                <div className={
                  classNames(css.locationFilterForm, { 
                  [css.isHideLocationForm]: !this.state.isOpen 
                })}>
                  <fieldset className={css.fieldPlain}>
                    <LocationAutocompleteInputField
                      inputClassName={css.locationInput}
                      iconClassName={css.iconGlass}
                      validClassName={css.validLocation}
                      name={name}
                      label={filterTextMobile}
                      placeholder={placeholder}
                      useDefaultPredictions={false}
                      format={identity}
                      valueFromForm={values.location}
                      closeOnBlur={false}
                      validate={validators.composeValidators(
                        validators.autocompleteSearchRequired(addressRequiredMessage),
                        validators.autocompletePlaceSelected(addressNotRecognizedMessage)
                      )}
                      placeTypes={[
                        PLACE_TYPE_COUNTRY,
                        PLACE_TYPE_PLACE
                      ]}
                    />
                  </fieldset>
                  <FormSpy onChange={v => this.handleOnSelectLocation(v)} subscription={{ values: true, dirty: true }} />
                </div>
              </Form>
            );
          }}
        />
      </div>
    );
  }
}

LocationFilter.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  contentPlacementOffset: 0,
};

LocationFilter.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  name: string.isRequired,
  queryParamNames: arrayOf(string).isRequired,
  label: string.isRequired,
  onSubmit: func.isRequired,
  initialValues: shape({
    keyword: string,
  }),
  contentPlacementOffset: number,

  // form injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(LocationFilter);
