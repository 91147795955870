import React from 'react';
import { array, bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { Process } from '../../components';
import { useHistory } from 'react-router-dom';


import css from './SectionHowItWorks.module.css';

const SectionHowItWorks = props => {
  const { rootClassName, className, processContentList, isShowTitleForCoachee } = props;

  const history = useHistory();


  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.title}>
        {isShowTitleForCoachee ? (
          <FormattedMessage id="SectionHowItWorks.titleForCoachee" />
        ) : (
          <>
          <div className={css.section}>
          <h2 className={`${css.headingTexts} h1`}>
          {
            <FormattedMessage id="SectionHowItWorks.titleLineOne" />
          
          }
          </h2>
          <h2 className={`${css.h11} h1`}>
            {          <FormattedMessage id="SectionHowItWorks.titleLineTwo" />
            }
          </h2>
          </div>
         
          </>
        )}
      </div>
      <p className={css.SectionHowItWorkstitleBottom}>
        <FormattedMessage id="SectionHowItWorks.titleBottom" />
      </p>
      <p className={css.SectionHowItWorkstitleBottom}>
        <FormattedMessage id="SectionHowItWorks.titleBottom1" />

      </p>
      
      <Process processContentList={processContentList} />
      <p className={css.SectionHowItWorkstitleBottom}>
      <FormattedMessage id="SectionHowItWorks.subTitle" />
      </p>
      <div className={css.GetCoachButton}>
      
      <button className={css.GetButton} onClick={(e)=>history.push('/a')}>GET A COACH</button>
    </div>
    </div>
  );
};

SectionHowItWorks.defaultProps = {
  rootClassName: null,
  className: null,
  processContentList: [],
  isShowTitleForCoachee: false,
};

SectionHowItWorks.propTypes = {
  rootClassName: string,
  className: string,
  processContentList: array,
  isShowTitleForCoachee: bool,
};

export default SectionHowItWorks;