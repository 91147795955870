import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconCalendar.module.css';

const IconCalendar = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" width="17.5" height="20" viewBox="0 0 17.5 20">
      <path d="M6.25,1.25V2.5h5V1.25a1.25,1.25,0,1,1,2.5,0V2.5h1.875A1.875,1.875,0,0,1,17.5,4.375V6.25H0V4.375A1.875,1.875,0,0,1,1.875,2.5H3.75V1.25a1.25,1.25,0,0,1,2.5,0ZM0,7.5H17.5V18.125A1.875,1.875,0,0,1,15.625,20H1.875A1.875,1.875,0,0,1,0,18.125Zm2.5,4.375a.626.626,0,0,0,.625.625h1.25A.627.627,0,0,0,5,11.875v-1.25A.627.627,0,0,0,4.375,10H3.125a.626.626,0,0,0-.625.625Zm5,0a.627.627,0,0,0,.625.625h1.25A.627.627,0,0,0,10,11.875v-1.25A.627.627,0,0,0,9.375,10H8.125a.627.627,0,0,0-.625.625ZM13.125,10a.627.627,0,0,0-.625.625v1.25a.627.627,0,0,0,.625.625h1.25A.627.627,0,0,0,15,11.875v-1.25A.627.627,0,0,0,14.375,10ZM2.5,16.875a.626.626,0,0,0,.625.625h1.25A.627.627,0,0,0,5,16.875v-1.25A.627.627,0,0,0,4.375,15H3.125a.626.626,0,0,0-.625.625ZM8.125,15a.627.627,0,0,0-.625.625v1.25a.627.627,0,0,0,.625.625h1.25A.627.627,0,0,0,10,16.875v-1.25A.627.627,0,0,0,9.375,15ZM12.5,16.875a.627.627,0,0,0,.625.625h1.25A.627.627,0,0,0,15,16.875v-1.25A.627.627,0,0,0,14.375,15h-1.25a.627.627,0,0,0-.625.625Z" fill="#fff" />
    </svg>
  );
};

const { string } = PropTypes;

IconCalendar.defaultProps = {
  className: null,
  rootClassName: null,
};

IconCalendar.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconCalendar;
