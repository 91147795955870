import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconEmptyChemistry.module.css';

const IconEmptyChemistry = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      width="323"
      height="323"
      viewBox="0 0 323 323"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M247.394 125.497C247.394 125.497 218.51 150.85 251.768 205.14C281.305 253.354 214.916 274.254 186.307 268.658C149.247 261.408 127.969 214.566 99.6282 229.408C71.2872 244.251 43.2107 176.994 84.4354 152.246C135.717 121.46 102.805 103.57 107.411 80.9965C110.717 64.796 157.758 39.7483 183.122 68.3201C204.466 92.3629 214.934 83.7614 224.689 80.5518C238.759 75.9225 269.471 100.002 247.394 125.497Z"
        fill="#F2F3F3"
      />
      <path
        d="M165.613 154.067L156.15 192.883C155.798 194.323 155.734 195.817 155.96 197.281C156.187 198.746 156.7 200.151 157.469 201.417C158.239 202.683 159.251 203.785 160.447 204.66C161.642 205.535 162.999 206.166 164.439 206.516L211.503 217.99C212.713 231.277 223.708 232.259 228.047 232.714C228.089 232.717 228.132 232.705 228.167 232.681C228.202 232.657 228.229 232.621 228.242 232.581C228.255 232.54 228.254 232.496 228.24 232.456C228.226 232.415 228.198 232.381 228.162 232.358C225.047 230.459 224.117 225.253 223.91 221.015L227.403 221.867C228.843 222.218 230.337 222.282 231.802 222.056C233.266 221.829 234.671 221.317 235.937 220.547C237.203 219.777 238.305 218.765 239.18 217.57C240.055 216.374 240.686 215.017 241.037 213.578L250.5 174.762C250.851 173.322 250.915 171.828 250.689 170.363C250.462 168.899 249.95 167.494 249.18 166.228C248.41 164.962 247.398 163.86 246.203 162.985C245.007 162.11 243.65 161.479 242.211 161.128L179.246 145.778C177.807 145.427 176.312 145.362 174.848 145.589C173.383 145.815 171.978 146.328 170.712 147.098C169.446 147.868 168.344 148.879 167.469 150.075C166.594 151.271 165.963 152.627 165.613 154.067Z"
        fill="#CBD3EC"
      />
      <path
        d="M243.682 173.216L217.365 166.8C217.191 166.758 217.017 166.864 216.974 167.037L215.624 172.575C215.582 172.748 215.688 172.923 215.861 172.965L242.179 179.381C242.352 179.424 242.527 179.317 242.569 179.144L243.919 173.606C243.961 173.433 243.855 173.258 243.682 173.216Z"
        fill="#EFF3FF"
      />
      <path
        d="M239.924 188.63L168.619 171.246C168.446 171.204 168.271 171.31 168.229 171.484L166.879 177.022C166.837 177.195 166.943 177.37 167.116 177.412L238.421 194.796C238.594 194.838 238.769 194.732 238.811 194.558L240.161 189.02C240.204 188.847 240.097 188.672 239.924 188.63Z"
        fill="#EFF3FF"
      />
      <path
        d="M236.166 204.044L164.861 186.66C164.688 186.618 164.513 186.724 164.471 186.898L163.121 192.436C163.079 192.609 163.185 192.784 163.358 192.826L234.663 210.21C234.836 210.252 235.011 210.146 235.053 209.972L236.403 204.434C236.446 204.261 236.339 204.086 236.166 204.044Z"
        fill="#EFF3FF"
      />
      <path
        d="M192.111 107.504L207.585 157.197C208.16 159.04 208.366 160.978 208.191 162.9C208.016 164.823 207.464 166.692 206.567 168.401C205.669 170.11 204.444 171.625 202.961 172.86C201.478 174.095 199.766 175.026 197.923 175.6L137.67 194.362C137.183 211.735 122.967 213.91 117.364 214.856C117.309 214.863 117.252 214.852 117.204 214.824C117.156 214.795 117.118 214.751 117.098 214.699C117.077 214.647 117.074 214.59 117.09 214.536C117.106 214.482 117.139 214.435 117.185 214.402C121.08 211.679 121.863 204.835 121.787 199.307L117.314 200.7C115.471 201.275 113.533 201.48 111.611 201.305C109.689 201.131 107.82 200.579 106.111 199.681C104.402 198.784 102.887 197.559 101.652 196.076C100.417 194.593 99.4857 192.881 98.9122 191.038L83.4376 141.344C82.8632 139.502 82.6576 137.564 82.8323 135.641C83.0071 133.719 83.5589 131.85 84.4563 130.141C85.3536 128.432 86.5789 126.917 88.062 125.682C89.5452 124.446 91.2573 123.515 93.1003 122.942L173.708 97.8412C175.551 97.2668 177.489 97.0611 179.411 97.2359C181.334 97.4107 183.203 97.9625 184.912 98.8598C186.621 99.7572 188.136 100.982 189.371 102.466C190.606 103.949 191.537 105.661 192.111 107.504Z"
        fill="#A0AFE0"
      />
      <path
        d="M135.182 125.302L92.0167 138.726C91.8464 138.779 91.7512 138.96 91.8042 139.131L94.0677 146.409C94.1207 146.579 94.3017 146.674 94.4721 146.621L137.638 133.197C137.808 133.144 137.903 132.963 137.85 132.793L135.587 125.515C135.534 125.344 135.353 125.249 135.182 125.302Z"
        fill="#EFF3FF"
      />
      <path
        d="M189.656 129.915L98.1839 158.4C98.0136 158.453 97.9185 158.634 97.9715 158.804L100.237 166.081C100.291 166.251 100.472 166.346 100.642 166.293L192.114 137.808C192.284 137.755 192.379 137.574 192.326 137.404L190.06 130.127C190.007 129.957 189.826 129.862 189.656 129.915Z"
        fill="#EFF3FF"
      />
      <path
        d="M195.792 149.749L104.299 178.203C104.129 178.256 104.034 178.437 104.087 178.608L106.35 185.886C106.403 186.056 106.584 186.151 106.755 186.098L198.248 157.644C198.418 157.591 198.513 157.41 198.46 157.24L196.197 149.962C196.144 149.792 195.963 149.696 195.792 149.749Z"
        fill="#EFF3FF"
      />
    </svg>
  );
};

IconEmptyChemistry.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconEmptyChemistry.propTypes = { rootClassName: string, className: string };

export default IconEmptyChemistry;
