import React, { Component } from 'react';
import { array, arrayOf, bool, func, number, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { createSlug } from '../../util/urlHelpers';
import {
  TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
  txIsAccepted,
  txIsCanceled,
  txIsDeclined,
  txIsEnquired,
  txIsPaymentExpired,
  txIsPaymentPending,
  txIsRequested,
  txHasBeenDelivered,
  txIsAddedToCoachList,
  txIsCoachResponded,
  TRANSITION_COACH_RESPOND,
  txIsInvitedToChemistry,
  txIsRequestedEngagement,
  TRANSITION_CUSTOMER_OFFER_FIRST_TIME,
  txIsCustomerMadeOffer,
  TRANSITION_CUSTOMER_OFFER,
  txIsCoachDeclined,
  TRANSITION_COACH_DECLINE,
  txIsCoachAccepted,
  txIsCoacheeSelectsCoach,
  txIsRecordedCompleted,
} from '../../util/transaction';
import {
  LINE_ITEM_NIGHT,
  LINE_ITEM_DAY,
  propTypes,
  PROJECT_LISTING_PAYMENT,
  COACH_BOOKING_BETWEEN_BUYER_AND_ADMIN,
} from '../../util/types';
import {
  ensureListing,
  ensureTransaction,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { isMobileSafari } from '../../util/userAgent';
import { convertMoneyToNumber, formatMoney } from '../../util/currency';
import { getUTCDateObject } from '../../util/dates';
import {
  AvatarLarge,
  AvatarMedium,
  Button,
  BookingPanel,
  NamedLink,
  ReviewModal,
  UserDisplayName,
} from '../../components';
import { SendMessageForm } from '../../forms';
import config from '../../config';
import { withRouter } from 'react-router-dom';

// These are internal components that make this file more readable.
import AddressLinkMaybe from './AddressLinkMaybe';
import BreakdownMaybe from './BreakdownMaybe';
import DetailCardHeadingsMaybe from './DetailCardHeadingsMaybe';
import DetailCardImage from './DetailCardImage';
import FeedSection from './FeedSection';
import SaleActionButtonsMaybe from './SaleActionButtonsMaybe';
import ConfirmProjectActionButtonsMaybe from './ConfirmProjectActionButtonsMaybe';
import PanelHeading, {
  HEADING_ENQUIRED,
  HEADING_PAYMENT_PENDING,
  HEADING_PAYMENT_EXPIRED,
  HEADING_REQUESTED,
  HEADING_ACCEPTED,
  HEADING_DECLINED,
  HEADING_CANCELED,
  HEADING_DELIVERED,
  HEADING_ADDED_TO_COACH_LIST,
  HEADING_COACH_RESPOND,
  HEADING_INVITED_TO_CHEMISTRY,
  HEADING_REQUESTED_ENGAGEMENT,
  HEADING_CUSTOMER_MADE_OFFER,
  HEADING_COACH_DECLINED,
  HEADING_COMPLETED,
  HEADING_AFTER_CHEMISTRY,
  HEADING_COACH_ACCEPTED,
} from './PanelHeading';
import { generateSearchParamsByEngagementListing } from '../../util/urlHelpers';
import { compose } from 'redux';
import RedirectToCheckoutButtonMaybe from './RedirectToCheckoutButtonMaybe';

import css from './TransactionPanel.module.css';

// Helper function to get display names for different roles
const displayNames = (currentUser, currentProvider, currentCustomer, intl) => {
  const authorDisplayName = <UserDisplayName user={currentProvider} intl={intl} />;
  const customerDisplayName = <UserDisplayName user={currentCustomer} intl={intl} />;

  let otherUserDisplayName = '';
  let otherUserDisplayNameString = '';
  const currentUserIsCustomer =
    currentUser.id && currentCustomer.id && currentUser.id.uuid === currentCustomer.id.uuid;
  const currentUserIsProvider =
    currentUser.id && currentProvider.id && currentUser.id.uuid === currentProvider.id.uuid;

  if (currentUserIsCustomer) {
    otherUserDisplayName = authorDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentProvider, '');
  } else if (currentUserIsProvider) {
    otherUserDisplayName = customerDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentCustomer, '');
  }

  return {
    authorDisplayName,
    customerDisplayName,
    otherUserDisplayName,
    otherUserDisplayNameString,
  };
};

export class TransactionPanelComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendMessageFormFocused: false,
      isReviewModalOpen: false,
      reviewSubmitted: false,
    };
    this.isMobSaf = false;
    this.sendMessageFormName = 'TransactionPanel.SendMessageForm';

    this.onOpenReviewModal = this.onOpenReviewModal.bind(this);
    this.onSubmitReview = this.onSubmitReview.bind(this);
    this.onSendMessageFormFocus = this.onSendMessageFormFocus.bind(this);
    this.onSendMessageFormBlur = this.onSendMessageFormBlur.bind(this);
    this.onMessageSubmit = this.onMessageSubmit.bind(this);
    this.scrollToMessage = this.scrollToMessage.bind(this);
    this.handleSearchCoach = this.handleSearchCoach.bind(this);
    this.onSubmitEngagementForm = this.onSubmitEngagementForm.bind(this);
    this.onConfirmProject = this.onConfirmProject.bind(this);
    this.onRedirectToConfirmCoachPayment = this.onRedirectToConfirmCoachPayment.bind(this);
  }

  componentDidMount() {
    this.isMobSaf = isMobileSafari();
  }

  onOpenReviewModal() {
    this.setState({ isReviewModalOpen: true });
  }

  onSubmitReview(values) {
    const { onSendReview, transaction, transactionRole } = this.props;
    const currentTransaction = ensureTransaction(transaction);
    const { reviewRating, reviewContent } = values;
    const rating = Number.parseInt(reviewRating, 10);
    onSendReview(transactionRole, currentTransaction, rating, reviewContent)
      .then(r => this.setState({ isReviewModalOpen: false, reviewSubmitted: true }))
      .catch(e => {
        // Do nothing.
      });
  }

  onSendMessageFormFocus() {
    this.setState({ sendMessageFormFocused: true });
    if (this.isMobSaf) {
      // Scroll to bottom
      window.scroll({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
    }
  }

  onSendMessageFormBlur() {
    this.setState({ sendMessageFormFocused: false });
  }

  onMessageSubmit(values, form) {
    const message = values.message ? values.message.trim() : null;
    const { transaction, onSendMessage } = this.props;
    const ensuredTransaction = ensureTransaction(transaction);

    if (!message) {
      return;
    }
    onSendMessage(ensuredTransaction.id, message)
      .then(messageId => {
        form.reset();
        this.scrollToMessage(messageId);
      })
      .catch(e => {
        // Ignore, Redux handles the error
      });
  }

  scrollToMessage(messageId) {
    const selector = `#msg-${messageId.uuid}`;
    const el = document.querySelector(selector);
    if (el) {
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  }

 handleSearchCoach = listing => {
    const { history } = this.props;
    const searchParams = generateSearchParamsByEngagementListing(listing);

    history.push(
      createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams)
    );
  };

  onSubmitEngagementForm(values) {
    const { transaction, onTransitTransition } = this.props;
    const transactionId = transaction.id.uuid;
    const { rate: rateMoney, ...rest } = values;

    const params = {
      metadata: {
        engagementFeedback: {
          ...rest,
        },
      },
    };

    if (rateMoney) {
      params.metadata.engagementFeedback.rate = {
        amount: rateMoney.amount,
        currency: rateMoney.currency,
      };
      params.metadata.engagementFeedback.rateForEmail = {
        amount: convertMoneyToNumber(rateMoney),
        currency: rateMoney.currency,
      };
    }

    onTransitTransition(transactionId, TRANSITION_COACH_RESPOND, params);
  }

  onConfirmProject(values, hasEngagementDetails) {
    const { transaction, onTransitNegotiatedTransition } = this.props;
    const { startDate: startDateRaw, pricePerHour, numberOfHours, totalPrice } = values;
    const { amount, currency } = pricePerHour;

    const pricePerHourObj = {
      amount,
      currency,
    };

    const pricePerHourForEmail = {
      amount: convertMoneyToNumber(pricePerHour),
      currency,
    };

    const transactionId = transaction.id.uuid;
    const startDate = new Date(startDateRaw.date);

    const params = {
      negotiatedTotal: totalPrice,
      protectedData: {
        engagementDetails: {
          startDate: startDate.getTime(),
          startDateObj: getUTCDateObject(startDate),
          pricePerHour: pricePerHourObj,
          pricePerHourForEmail,
          numberOfHours: parseInt(numberOfHours),
        },
      },
    };

    const transition = hasEngagementDetails
      ? TRANSITION_CUSTOMER_OFFER
      : TRANSITION_CUSTOMER_OFFER_FIRST_TIME;

    onTransitNegotiatedTransition(transactionId, transition, params);
  }

  async onRedirectToConfirmCoachPayment() {
    const {
      history,
      callSetInitialValues,
      onInitializeCardPaymentData,
      transaction,
      onShowAdminListing,
    } = this.props;
    const currentTransaction = ensureTransaction(transaction);
    const adminListing = await onShowAdminListing();

    const bookingData = {
      bookingType: COACH_BOOKING_BETWEEN_BUYER_AND_ADMIN,
      buyerWithCoachTransaction: currentTransaction,
    };

    const initialValues = {
      listing: adminListing,
      buyerWithCoachTransaction: currentTransaction,
      bookingData,
      confirmPaymentError: null,
    };

    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected bookingDates
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);

    callSetInitialValues(setInitialValues, initialValues);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        { id: config.adminListing, slug: createSlug(config.adminListingTitle) },
        {}
      )
    );
  }

  render() {
    const {
      rootClassName,
      className,
      currentUser,
      transaction,
      totalMessagePages,
      oldestMessagePageFetched,
      messages,
      initialMessageFailed,
      savePaymentMethodFailed,
      fetchMessagesInProgress,
      fetchMessagesError,
      sendMessageInProgress,
      sendMessageError,
      sendReviewInProgress,
      sendReviewError,
      onFetchTimeSlots,
      onManageDisableScrolling,
      onShowMoreMessages,
      transactionRole,
      intl,
      onAcceptSale,
      onDeclineSale,
      acceptInProgress,
      declineInProgress,
      acceptSaleError,
      declineSaleError,
      onSubmitBookingRequest,
      monthlyTimeSlots,
      nextTransitions,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      handleMoveToListingPage,
      projectListing,
      transitTransitionInProgress,
      transitTransitionError,
      engagementListing,
      showAdminListingInProgress,
      showAdminListingError,
    } = this.props;

    const currentTransaction = ensureTransaction(transaction);
    const { lastTransition } = currentTransaction.attributes;
    const currentListing = ensureListing(currentTransaction.listing);
    const { title: coachName } = currentListing.attributes;
    const currentProvider = ensureUser(currentTransaction.provider);
    const currentCustomer = ensureUser(currentTransaction.customer);
    const isCustomer = transactionRole === 'customer';
    const isProvider = transactionRole === 'provider';
    const currentEngagementListing = ensureListing(engagementListing);
    const {
      title: engagementTitle,
      publicData: { firstName, familyName } = {},
    } = currentEngagementListing.attributes;
    const engagementName = `${firstName} ${familyName}`;

    const listingLoaded = !!currentListing.id;
    const listingDeleted = listingLoaded && currentListing.attributes.deleted;
    const iscustomerLoaded = !!currentCustomer.id;
    const isCustomerBanned = iscustomerLoaded && currentCustomer.attributes.banned;
    const isCustomerDeleted = iscustomerLoaded && currentCustomer.attributes.deleted;
    const isProviderLoaded = !!currentProvider.id;
    const isProviderBanned = isProviderLoaded && currentProvider.attributes.banned;
    const isProviderDeleted = isProviderLoaded && currentProvider.attributes.deleted;

    const { projectListing: txProjectListing = {}, bookingType } = currentTransaction.attributes.protectedData || {};

    const isTxForProjectListing = bookingType === PROJECT_LISTING_PAYMENT;

    const { companyName } = currentCustomer.attributes.profile.publicData;
    const { projectListingId, projectListingTitle } = txProjectListing;

    const stateDataFn = tx => {
      if (txIsEnquired(tx)) {
        const transitions = Array.isArray(nextTransitions)
          ? nextTransitions.map(transition => {
            return transition.attributes.name;
          })
          : [];
        const hasCorrectNextTransition =
          transitions.length > 0 && transitions.includes(TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY);
        return {
          headingState: HEADING_ENQUIRED,
          showBookingPanel: isCustomer && !isProviderBanned && hasCorrectNextTransition,
        };
      } else if (txIsPaymentPending(tx)) {
        return {
          headingState: HEADING_PAYMENT_PENDING,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsPaymentExpired(tx)) {
        return {
          headingState: HEADING_PAYMENT_EXPIRED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsAddedToCoachList(tx)) {
        return {
          headingState: HEADING_ADDED_TO_COACH_LIST,
          hideDetailCardImage: true,
        };
      } else if (txIsCoachResponded(tx)) {
        return {
          headingState: HEADING_COACH_RESPOND,
          showDetailCardHeadings: true,
        };
      } else if (txIsInvitedToChemistry(tx)) {
        return {
          headingState: HEADING_INVITED_TO_CHEMISTRY,
          showDetailCardHeadings: true,
        };
      } else if (txIsCoacheeSelectsCoach(tx)) {
        return {
          headingState: HEADING_AFTER_CHEMISTRY,
          showDetailCardHeadings: true,
        };
      } else if (txIsRequestedEngagement(tx)) {
        return {
          headingState: HEADING_REQUESTED_ENGAGEMENT,
          showDetailCardHeadings: true,
          showConfirmProjectButton: isCustomer && !isProviderBanned,
          showChatMessageForm: true,
        };
      } else if (txIsCustomerMadeOffer(tx)) {
        return {
          headingState: HEADING_CUSTOMER_MADE_OFFER,
          showDetailCardHeadings: true,
          showSaleButtons: isProvider && !isCustomerBanned,
          showChatMessageForm: true,
        };
      } else if (txIsCoachDeclined(tx)) {
        return {
          headingState: HEADING_COACH_DECLINED,
          showDetailCardHeadings: true,
          showConfirmProjectButton: isCustomer && !isProviderBanned,
          showSaleButtons:
            isProvider && !isCustomerBanned && lastTransition !== TRANSITION_COACH_DECLINE,
        };
      } else if (txIsCoachAccepted(tx)) {
        return {
          headingState: HEADING_COACH_ACCEPTED,
          showDetailCardHeadings: true,
          showRedirectToCheckoutButton: isCustomer && !isProviderBanned,
        };
      } else if (txIsRecordedCompleted(tx)) {
        return {
          headingState: HEADING_COMPLETED,
          showDetailCardHeadings: true,
        };
      } else if (txIsRequested(tx)) {
        return {
          headingState: HEADING_REQUESTED,
          showDetailCardHeadings: isCustomer,
          showSaleButtons: isProvider && !isCustomerBanned,
        };
      } else if (txIsAccepted(tx)) {
        return {
          headingState: HEADING_ACCEPTED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
        };
      } else if (txIsDeclined(tx)) {
        return {
          headingState: HEADING_DECLINED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsCanceled(tx)) {
        return {
          headingState: HEADING_CANCELED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txHasBeenDelivered(tx)) {
        return {
          headingState: HEADING_DELIVERED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
        };
      } else {
        return { headingState: 'unknown' };
      }
    };
    const stateData = stateDataFn(currentTransaction);

    const deletedListingTitle = intl.formatMessage({
      id: 'TransactionPanel.deletedListingTitle',
    });

    const { publicData, geolocation } = currentListing.attributes;
    const location = publicData && publicData.location ? publicData.location : {};

    const listingTitle = currentEngagementListing.attributes.deleted
      ? deletedListingTitle
      : currentEngagementListing.attributes.title;

    const unitType = config.bookingUnitType;
    const isNightly = unitType === LINE_ITEM_NIGHT;
    const isDaily = unitType === LINE_ITEM_DAY;

    const unitTranslationKey = isNightly
      ? 'TransactionPanel.perNight'
      : isDaily
        ? 'TransactionPanel.perDay'
        : 'TransactionPanel.perUnit';

    const {
      authorDisplayName,
      customerDisplayName,
      otherUserDisplayName,
      otherUserDisplayNameString,
    } = displayNames(currentUser, currentProvider, currentCustomer, intl);

    const price = currentListing.attributes.price;
    const bookingSubTitle = price
      ? `${formatMoney(intl, price)} ${intl.formatMessage({ id: unitTranslationKey })}`
      : '';

    const firstImage =
      currentEngagementListing.images && currentEngagementListing.images.length > 0
        ? currentEngagementListing.images[0]
        : null;

    const redirectToCheckoutButton = (
      <RedirectToCheckoutButtonMaybe
        showButtons={stateData.showRedirectToCheckoutButton}
        onRedirectToConfirmCoachPayment={this.onRedirectToConfirmCoachPayment}
        inProgress={showAdminListingInProgress}
        hasError={showAdminListingError}
      />
    );

    const confirmProjectButton = (
      <ConfirmProjectActionButtonsMaybe
        transaction={currentTransaction}
        showButtons={stateData.showConfirmProjectButton}
        transitTransitionInProgress={transitTransitionInProgress}
        transitTransitionError={transitTransitionError}
        onConfirmProject={this.onConfirmProject}
        onManageDisableScrolling={onManageDisableScrolling}
      />
    );

    const saleButtons = (
      <SaleActionButtonsMaybe
        showButtons={stateData.showSaleButtons}
        acceptInProgress={acceptInProgress}
        declineInProgress={declineInProgress}
        acceptSaleError={acceptSaleError}
        declineSaleError={declineSaleError}
        onAcceptSale={() => onAcceptSale(currentTransaction.id)}
        onDeclineSale={onDeclineSale}
        onManageDisableScrolling={onManageDisableScrolling}
        transaction={currentTransaction}
      />
    );

    const showSendMessageForm =
      !isCustomerBanned &&
      !isCustomerDeleted &&
      !isProviderBanned &&
      !isProviderDeleted &&
      stateData.showChatMessageForm;

    const sendMessagePlaceholder = intl.formatMessage(
      { id: 'TransactionPanel.sendMessagePlaceholder' },
      { name: otherUserDisplayNameString }
    );

    const sendingMessageNotAllowed = intl.formatMessage({
      id: 'TransactionPanel.sendingMessageNotAllowed',
    });

    const paymentMethodsPageLink = (
      <NamedLink name="PaymentMethodsPage">
        <FormattedMessage id="TransactionPanel.paymentMethodsPageLink" />
      </NamedLink>
    );

    const classes = classNames(rootClassName || css.root, className);

    return (
      <div className={classes}>
        <div className={css.container}>
          <div
            className={classNames(css.txInfo, {
              [css.txInfoWithoutCardImage]: stateData.hideDetailCardImage,
            })}
          >
            {isTxForProjectListing ? (
              <div className={css.hideFromDesktop}>
                <div className={css.projectListingImage}>
                  <div className={css.projectListingInfo}>
                    <div className={css.companyName}>{companyName}</div>
                    <div className={css.projectListingTitle}>{projectListingTitle}</div>
                  </div>
                </div>
                <div className={css.avatarWrapper}>
                  <AvatarMedium user={currentCustomer} disableProfileLink />
                </div>
              </div>
            ) : (
              <DetailCardImage
                rootClassName={css.imageWrapperMobile}
                avatarWrapperClassName={classNames(css.avatarWrapperMobile, {
                  [css.hideDetailCardImage]: stateData.hideDetailCardImage,
                })}
                listingTitle={listingTitle}
                image={firstImage}
                provider={currentProvider}
                isCustomer={isCustomer}
                listingId={currentListing.id && currentListing.id.uuid}
                listingDeleted={listingDeleted}
                engagementTitle={engagementTitle}
                hideDetailCardImage={stateData.hideDetailCardImage}
              />
            )}

            {isProvider ? (
              <div className={css.avatarWrapperProviderDesktop}>
                <AvatarLarge user={currentCustomer} className={css.avatarDesktop} />
              </div>
            ) : null}

            <PanelHeading
              panelHeadingState={stateData.headingState}
              transactionRole={transactionRole}
              providerName={authorDisplayName}
              customerName={customerDisplayName}
              isCustomerBanned={isCustomerBanned}
              listingId={currentListing.id && currentListing.id.uuid}
              listingTitle={listingTitle}
              listingDeleted={listingDeleted}
              companyName={companyName}
              onSubmitEngagementForm={this.onSubmitEngagementForm}
              transaction={currentTransaction}
              intl={intl}
              transitTransitionInProgress={transitTransitionInProgress}
              transitTransitionError={transitTransitionError}
              engagementTitle={engagementTitle}
              engagementName={engagementName}
            />

            {isTxForProjectListing && (
              <div className={classNames(css.listingTitle, css.hideFromDesktop)}>
                {listingTitle}
              </div>
            )}

            <div className={css.bookingDetailsMobile}>
              <AddressLinkMaybe
                rootClassName={css.addressMobile}
                location={location}
                geolocation={geolocation}
                showAddress={stateData.showAddress}
              />
              <BreakdownMaybe
                transaction={currentTransaction}
                transactionRole={transactionRole}
                isPaymentForProjectListing={isTxForProjectListing}
                listingPrice={price}
              />
            </div>

            {savePaymentMethodFailed ? (
              <p className={css.genericError}>
                <FormattedMessage
                  id="TransactionPanel.savePaymentMethodFailed"
                  values={{ paymentMethodsPageLink }}
                />
              </p>
            ) : null}
            {isTxForProjectListing ? (
              <div className={classNames(css.breakdown, css.contentContainer)}>
                <h1 className={css.title}>
                  <FormattedMessage id="TransactionPanel.titleForProjectListing" />
                </h1>
                <p className={css.subTitle}>
                  <FormattedMessage id="TransactionPanel.subTitleForProjectListing" />
                </p>
                <Button
                  className={css.button}
                  onClick={() => this.handleSearchCoach(projectListing)}
                >
                  <FormattedMessage id="TransactionPanel.getACoach" />
                </Button>
              </div>
            ) : (
              <>
                <FeedSection
                  rootClassName={css.feedContainer}
                  currentTransaction={currentTransaction}
                  currentUser={currentUser}
                  fetchMessagesError={fetchMessagesError}
                  fetchMessagesInProgress={fetchMessagesInProgress}
                  initialMessageFailed={initialMessageFailed}
                  messages={messages}
                  oldestMessagePageFetched={oldestMessagePageFetched}
                  onOpenReviewModal={this.onOpenReviewModal}
                  onShowMoreMessages={() => onShowMoreMessages(currentTransaction.id)}
                  totalMessagePages={totalMessagePages}
                  companyName={companyName}
                />
                {showSendMessageForm ? (
                  <SendMessageForm
                    formId={this.sendMessageFormName}
                    rootClassName={css.sendMessageForm}
                    messagePlaceholder={sendMessagePlaceholder}
                    inProgress={sendMessageInProgress}
                    sendMessageError={sendMessageError}
                    onFocus={this.onSendMessageFormFocus}
                    onBlur={this.onSendMessageFormBlur}
                    onSubmit={this.onMessageSubmit}
                  />
                ) : (
                  stateData.showChatMessageForm && (
                    <div className={css.sendingMessageNotAllowed}>{sendingMessageNotAllowed}</div>
                  )
                )}
              </>
            )}

            {stateData.showConfirmProjectButton ? (
              <div className={css.mobileActionButtons}>{confirmProjectButton}</div>
            ) : null}
            {stateData.showSaleButtons ? (
              <div className={css.mobileActionButtons}>{saleButtons}</div>
            ) : null}
            {stateData.showRedirectToCheckoutButton ? (
              <div className={css.mobileActionButtons}>{redirectToCheckoutButton}</div>
            ) : null}
          </div>

          <div
            className={classNames(css.asideDesktop, {
              [css.hideDetailCardImage]: stateData.hideDetailCardImage,
            })}
          >
            <div className={css.detailCard}>
              {isTxForProjectListing ? (
                <>
                  <div className={classNames(css.projectListingImage, css.hideFromMobile)}>
                    <div className={css.projectListingInfo}>
                      <div className={css.companyName}>{companyName}</div>
                      <div className={css.projectListingTitle}>{projectListingTitle}</div>
                    </div>
                  </div>
                  <div className={classNames(css.avatarWrapper, css.hideFromMobile)}>
                    <AvatarMedium user={currentCustomer} disableProfileLink />
                  </div>
                </>
              ) : (
                <DetailCardImage
                  avatarWrapperClassName={css.avatarWrapperDesktop}
                  listingTitle={listingTitle}
                  image={firstImage}
                  provider={currentProvider}
                  isCustomer={isCustomer}
                  listingId={currentListing.id && currentListing.id.uuid}
                  listingDeleted={listingDeleted}
                  engagementTitle={engagementTitle}
                  hideDetailCardImage={stateData.hideDetailCardImage}
                />
              )}

              <DetailCardHeadingsMaybe
                transaction={currentTransaction}
                showDetailCardHeadings={stateData.showDetailCardHeadings}
                coachName={coachName}
                location={location}
                geolocation={geolocation}
                showAddress={stateData.showAddress}
                companyName={companyName}
                engagementName={engagementName}
              />
              {stateData.showBookingPanel ? (
                <BookingPanel
                  className={css.bookingPanel}
                  titleClassName={css.bookingTitle}
                  isOwnListing={false}
                  listing={currentListing}
                  title={listingTitle}
                  subTitle={bookingSubTitle}
                  authorDisplayName={authorDisplayName}
                  onSubmit={onSubmitBookingRequest}
                  onManageDisableScrolling={onManageDisableScrolling}
                  monthlyTimeSlots={monthlyTimeSlots}
                  onFetchTimeSlots={onFetchTimeSlots}
                  onFetchTransactionLineItems={onFetchTransactionLineItems}
                  lineItems={lineItems}
                  fetchLineItemsInProgress={fetchLineItemsInProgress}
                  fetchLineItemsError={fetchLineItemsError}
                />
              ) : null}
              {isTxForProjectListing && (
                <div className={classNames(css.listingTitle, css.hideFromMobile)}>
                  {listingTitle}
                </div>
              )}

              <BreakdownMaybe
                className={css.breakdownContainer}
                transaction={currentTransaction}
                transactionRole={transactionRole}
                isPaymentForProjectListing={isTxForProjectListing}
                listingPrice={price}
                showHeading
              />
              {stateData.showConfirmProjectButton ? (
                <div className={css.desktopActionButtons}>{confirmProjectButton}</div>
              ) : null}
              {stateData.showSaleButtons ? (
                <div className={css.desktopActionButtons}>{saleButtons}</div>
              ) : null}
              {stateData.showRedirectToCheckoutButton ? (
                <div className={css.desktopActionButtons}>{redirectToCheckoutButton}</div>
              ) : null}
            </div>
          </div>
        </div>
        <ReviewModal
          id="ReviewOrderModal"
          isOpen={this.state.isReviewModalOpen}
          onCloseModal={() => this.setState({ isReviewModalOpen: false })}
          onManageDisableScrolling={onManageDisableScrolling}
          onSubmitReview={this.onSubmitReview}
          revieweeName={otherUserDisplayName}
          reviewSent={this.state.reviewSubmitted}
          sendReviewInProgress={sendReviewInProgress}
          sendReviewError={sendReviewError}
        />
      </div>
    );
  }
}

TransactionPanelComponent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  acceptSaleError: null,
  declineSaleError: null,
  fetchMessagesError: null,
  initialMessageFailed: false,
  savePaymentMethodFailed: false,
  sendMessageError: null,
  sendReviewError: null,
  monthlyTimeSlots: null,
  nextTransitions: null,
  lineItems: null,
  fetchLineItemsError: null,
  projectListing: null,
  showAdminListingInProgress: false,
  showAdminListingError: null,
};

TransactionPanelComponent.propTypes = {
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  transaction: propTypes.transaction.isRequired,
  totalMessagePages: number.isRequired,
  oldestMessagePageFetched: number.isRequired,
  messages: arrayOf(propTypes.message).isRequired,
  initialMessageFailed: bool,
  savePaymentMethodFailed: bool,
  fetchMessagesInProgress: bool.isRequired,
  fetchMessagesError: propTypes.error,
  sendMessageInProgress: bool.isRequired,
  sendMessageError: propTypes.error,
  sendReviewInProgress: bool.isRequired,
  sendReviewError: propTypes.error,
  onFetchTimeSlots: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onShowMoreMessages: func.isRequired,
  onSendMessage: func.isRequired,
  onSendReview: func.isRequired,
  onSubmitBookingRequest: func.isRequired,
  monthlyTimeSlots: object,
  nextTransitions: array,
  handleMoveToListingPage: func,
  projectListing: propTypes.ownListing,
  showAdminListingInProgress: bool,
  showAdminListingError: propTypes.error,

  // Sale related props
  onAcceptSale: func.isRequired,
  onDeclineSale: func.isRequired,
  acceptInProgress: bool.isRequired,
  declineInProgress: bool.isRequired,
  acceptSaleError: propTypes.error,
  declineSaleError: propTypes.error,

  // line items
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const TransactionPanel = compose(
  withRouter,
  injectIntl
)(TransactionPanelComponent);

export default TransactionPanel;
