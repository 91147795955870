import classNames from 'classnames';
import { string, object, number } from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import ValidationError from '../ValidationError/ValidationError';
import css from './RangeSliderRating.module.css';

const RangeSliderRatingComponent = (props) => {
  const {
    id,
    label,
    rootClassName,
    labelClassName,
    className,
    meta,
    customErrorText,
    children,
    onChange,
    input,
    sliderRef,
    errorClassName,
    ...rest
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const { valid, invalid, touched, error } = meta;

  const errorText = customErrorText || error;
  const hasError = !!customErrorText || !!(touched && invalid && error);

  const fieldMeta = { touched: hasError, error: errorText };

  const handleOnChange = (input, event) => {
    input.onChange(event);
    input.onBlur(event);
    onChange && onChange(event);
  };

  return (
    <span className={classes}>
      {label && <label htmlFor={id}>{label}</label>}
      <input
        {...input}
        {...rest}
        id={id}
        className={css.input}
        ref={sliderRef}
        onChange={event => handleOnChange(input, event)}
      />
      {children}
      <ValidationError fieldMeta={fieldMeta} className={errorClassName} />
    </span>
  );
};

RangeSliderRatingComponent.propTypes = {
  id: string,
  label: string,
  min: number,
  max: number,
  step: number,
  rootClassName: string,
  className: string,
  meta: object,
  sliderRef: object,
  errorClassName: string,
};

RangeSliderRatingComponent.defaultProps = {
  id: null,
  label: null,
  min: 1,
  max: 5,
  step: 1,
  rootClassName: null,
  className: null,
  meta: object,
  sliderRef: null,
  errorClassName: null,
};

const RangeSliderRating = props => (
  <Field type="range" {...props} component={RangeSliderRatingComponent} />
);

export default RangeSliderRating;
