import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconAddToShortList.module.css';

const IconAddToShortList = props => {
  const { className, rootClassName, onClick } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="37.13"
      height="31.581"
      viewBox="0 0 37.13 31.581"
    >
      <g transform="translate(-3550.006 606)">
        <path
          d="M10.749,12.7h-9a1.75,1.75,0,0,1,0-3.5h8.662c-.021.261-.032.53-.032.8a10.017,10.017,0,0,0,.369,2.7Z"
          transform="translate(3558.756 -608)"
          fill="#fff"
        />
        <circle cx="3" cy="3" r="3" transform="translate(3550.006 -600)" fill="#fff" />
        <path
          d="M19.75,22.28h-18a1.75,1.75,0,0,1,0-3.5H15.591A10.011,10.011,0,0,0,20.381,20a10.158,10.158,0,0,0,1.02-.051A1.751,1.751,0,0,1,19.75,22.28Z"
          transform="translate(3558.756 -608)"
          fill="#fff"
        />
        <circle cx="3" cy="3" r="3" transform="translate(3550.006 -590.419)" fill="#fff" />
        <circle cx="3" cy="3" r="3" transform="translate(3550.006 -580.419)" fill="#fff" />
        <path
          d="M18,1.75H0A1.75,1.75,0,0,1-1.75,0,1.75,1.75,0,0,1,0-1.75H18A1.75,1.75,0,0,1,19.75,0,1.75,1.75,0,0,1,18,1.75Z"
          transform="translate(3560.506 -577.419)"
          fill="#fff"
        />
      </g>
      <g transform="translate(-808.87 -507)">
        <path
          d="M-5855.13-6157.4a8.009,8.009,0,0,1-8-8,8.01,8.01,0,0,1,8-8,8.009,8.009,0,0,1,8,8A8.008,8.008,0,0,1-5855.13-6157.4Zm-4.067-8.934a1,1,0,0,0-1,1,1,1,0,0,0,1,1h3.066v3.351a1,1,0,0,0,1,1,1,1,0,0,0,1-1v-3.351h2.935a1,1,0,0,0,1-1,1,1,0,0,0-1-1h-2.935v-3.481a1,1,0,0,0-1-1,1,1,0,0,0-1,1v3.481Z"
          transform="translate(6693.13 6680.396)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

const { string, func } = PropTypes;

IconAddToShortList.defaultProps = {
  className: null,
  rootClassName: null,
  onClick: () => {},
};

IconAddToShortList.propTypes = {
  className: string,
  rootClassName: string,
  onClick: func,
};

export default IconAddToShortList;
