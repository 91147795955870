import { storableError } from '../../util/errors';
import { client } from '../../prismic';

// ================ Action types ================ //

export const FETCH_BLOG_REQUEST = 'app/SingleBlogPage/FETCH_BLOG_REQUEST';
export const FETCH_BLOG_SUCCESS = 'app/SingleBlogPage/FETCH_BLOG_SUCCESS';
export const FETCH_BLOG_ERROR = 'app/SingleBlogPage/FETCH_BLOG_ERROR';

export const FETCH_RELATED_BLOG_REQUEST = 'app/SingleBlogPage/FETCH_RELATED_BLOG_REQUEST';
export const FETCH_RELATED_BLOG_SUCCESS = 'app/SingleBlogPage/FETCH_RELATED_BLOG_SUCCESS';
export const FETCH_RELATED_BLOG_ERROR = 'app/SingleBlogPage/FETCH_RELATED_BLOG_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchBlogInProgress: false,
  fetchBlogError: null,
  blog: null,
  relatedBlogs: [],
};

export default function blogPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_BLOG_REQUEST:
      return {
        ...state,
        relatedBlogs: [],
        blog: null,
        fetchBlogInProgress: true,
        fetchBlogError: null,
      };
    case FETCH_BLOG_SUCCESS: {
      return {
        ...state,
        fetchBlogInProgress: false,
        blog: payload,
      };
    }
    case FETCH_BLOG_ERROR:
      console.error(payload);
      return { ...state, fetchBlogInProgress: false, fetchBlogError: payload };

    case FETCH_RELATED_BLOG_SUCCESS: {
      return {
        ...state,
        relatedBlogs: payload,
      };
    }

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchBlogRequest = (searchParams) => ({
  type: FETCH_BLOG_REQUEST,
  payload: searchParams,
});
const fetchBlogSuccess = (response) => ({
  type: FETCH_BLOG_SUCCESS,
  payload: response,
});
const fetchBlogError = e => ({
  type: FETCH_BLOG_ERROR,
  error: true,
  payload: e,
});

const fetchRelatedBlogsSuccess = (response) => ({
  type: FETCH_RELATED_BLOG_SUCCESS,
  payload: response,
});


// ================ Thunks ================ //

export const fetchBlogByID = (slug) => (dispatch, getState, sdk) => {
  dispatch(fetchBlogRequest());

  const paramsTest = {
    fetchLinks: [
      'blog_post.title_of_article',
      'blog_post.hero_image',
      'blog_post.writer_name',
      'blog_post.post_date'
    ]
  };

  return client
    .getByUID('blog_post', slug, paramsTest)
    .then(response => {
      dispatch(fetchBlogSuccess(response));
      
      // Get content relationship data
      const { related_blog_posts } = response.data;
      const relatedPosts = related_blog_posts
        .map(post => post.blog)
        .filter(blog => blog.id);
      dispatch(fetchRelatedBlogsSuccess(relatedPosts));
    })
    .catch(e => {
      dispatch(fetchBlogError(storableError(e)));
    });
};

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const { slug } = params;

  return dispatch(fetchBlogByID(slug));
};
