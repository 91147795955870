import * as prismic from '@prismicio/client'
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { client } from '../../prismic';
import { COACHING_BLOGS, COACHING_CULTURE_BLOGS, MOST_RECENT_BLOGS } from '../../marketplace-custom-config';

// ================ Action types ================ //

export const FETCH_BLOGS_REQUEST = 'app/BlogPage/FETCH_BLOGS_REQUEST';
export const FETCH_BLOGS_SUCCESS = 'app/BlogPage/FETCH_BLOGS_SUCCESS';
export const FETCH_BLOGS_ERROR = 'app/BlogPage/FETCH_BLOGS_ERROR';

export const FETCH_NEWEST_BLOG_REQUEST = 'app/BlogPage/FETCH_NEWEST_BLOG_REQUEST';
export const FETCH_NEWEST_BLOG_SUCCESS = 'app/BlogPage/FETCH_NEWEST_BLOG_SUCCESS';
export const FETCH_NEWEST_BLOG_ERROR = 'app/BlogPage/FETCH_NEWEST_BLOG_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchBlogsInProgress: false,
  fetchBlogsError: null,
  pagination: null,
  blogs: [],
  fetchNewestBlogInProgress: false,
  fetchNewestBlogError: null,
  newestBlog: null,
  searchParams: null,
};

const BLOGS_PAGE_SIZE = 12;

export default function blogPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_BLOGS_REQUEST:
      return { ...state, searchParams: payload, fetchBlogsInProgress: true, fetchBlogsError: null };
    case FETCH_BLOGS_SUCCESS: {
      return {
        ...state,
        fetchBlogsInProgress: false,
        blogs: payload.data,
        pagination: payload.pagination,
      };
    }
    case FETCH_BLOGS_ERROR:
      return { ...state, fetchNewestBlogInProgress: false, fetchNewestBlogError: payload };

    case FETCH_NEWEST_BLOG_REQUEST:
      return { ...state, fetchNewestBlogInProgress: true, fetchNewestBlogError: null };
    case FETCH_NEWEST_BLOG_SUCCESS: {
      return {
        ...state,
        newestBlog: payload,
      };
    }
    case FETCH_NEWEST_BLOG_ERROR:
      console.error(payload);
      return { ...state, fetchNewestBlogInProgress: false, fetchNewestBlogError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchBlogsRequest = (searchParams) => ({
  type: FETCH_BLOGS_REQUEST,
  payload: searchParams,
});
const fetchBlogsSuccess = (response) => ({
  type: FETCH_BLOGS_SUCCESS,
  payload: response,
});
const fetchBlogsError = e => ({
  type: FETCH_BLOGS_ERROR,
  error: true,
  payload: e,
});

const fetchNewestBlogsRequest = (searchParams) => ({
  type: FETCH_NEWEST_BLOG_REQUEST,
  payload: searchParams,
});
const fetchNewestBlogSuccess = (response) => ({
  type: FETCH_NEWEST_BLOG_SUCCESS,
  payload: response,
});
const fetchNewestBlogError = e => ({
  type: FETCH_NEWEST_BLOG_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const fetchBlogPosts = (blogParams, search) => (dispatch, getState, sdk) => {
  dispatch(fetchBlogsRequest(search));

  return client
    .getByType('blog_post', blogParams)
    .then(response => {
      const { results_per_page, total_results_size, total_pages, page, results } = response;

      const pagination = {
        page,
        perPage: results_per_page,
        totalItems: total_results_size,
        totalPages: total_pages,
      };

      dispatch(fetchBlogsSuccess({ data: results, pagination }));
      return response;
    })
    .catch(e => {
      dispatch(fetchBlogsError(storableError(e)));
      throw e;
    });
};

export const fetchNewestBlogPost = () => (dispatch, getState, sdk) => {
  dispatch(fetchNewestBlogsRequest());

  const recentPostParams = {
    type: 'blog_post',
    orderings: {
      field: 'my.blog_post.post_date',
      direction: 'desc',
    },
  };

  return client
    .getFirst(recentPostParams)
    .then(response => {
      dispatch(fetchNewestBlogSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchNewestBlogError(storableError(e)));
    });
};

const getBlogPrismicParams = category => {
  const recentPostParams = {
    orderings: {
      field: 'my.blog_post.post_date',
      direction: 'desc',
    },
  };

  switch (category) {
    case MOST_RECENT_BLOGS:
      return recentPostParams;

    case COACHING_BLOGS:
    case COACHING_CULTURE_BLOGS:
      return {
        ...recentPostParams,
        predicates: [prismic.predicate.at('my.blog_post.category', category)]
      };

    default:
      return recentPostParams;
  }
};

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const { newestBlog } = getState().BlogPage;
  const { page = 1, category } = parse(search);

  const blogParams = {
    pageSize: BLOGS_PAGE_SIZE,
    page,
    ...getBlogPrismicParams(category)
  }

  if(newestBlog) {
    return dispatch(fetchBlogPosts(blogParams, search));
  }
  return Promise.all([
    dispatch(fetchBlogPosts(blogParams, search)),
    dispatch(fetchNewestBlogPost()),
  ]);
};
